import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { memberQueryKeys } from "./memberQueryKeys";

export function useMemberChangePicture(
  {
    api,
  }: {
    api: ApiType;
  },
  mutationOpts?: any,
) {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: ({
      memberId,
      blob,
      filename,
    }: {
      memberId: string;
      blob: string;
      filename: string;
    }) => {
      return api.memberApi.updatePicture(memberId, { blob, name: filename });
    },
    onSuccess: (_, { memberId }: { memberId: string }) => {
      queryClient.invalidateQueries({
        queryKey: memberQueryKeys.details(memberId),
      });
    },
    ...mutationOpts,
  });
  return mutation;
}
