import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import { InvoiceStatus } from '@gymflow/common';

function StatusBadge({ status }) {
  let color;
  let text;
  switch (status) {
    case InvoiceStatus.Paid:
      color = 'primary';
      text = 'Paid';
      break;
    case InvoiceStatus.PastDue:
      color = 'danger';
      text = 'Past Due';
      break;
    case InvoiceStatus.Void:
      color = 'secondary';
      text = 'Void';
      break;
    default:
      color = 'secondary';
      text = status.replace('_', ' ');
      break;
  }
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Badge color={color}>{text}</Badge>
    </div>
  );
}

StatusBadge.propTypes = {
  status: PropTypes.oneOf(Object.values(InvoiceStatus)).isRequired,
};

export default StatusBadge;
