import PropTypes from "prop-types";
import classNames from "classnames";
import { Badge, Card, CardBody, Col, Row } from "reactstrap";
import Alert from "react-bootstrap-sweetalert";

import { format } from "date-fns";
import { pluralize } from "@gymflow/helpers";
import { useContext } from "react";
import { humanizeTimeInterval } from "../../helpers/date";
import { AlertContext } from "../../providers";

function UserCircleIcon() {
  return (
    <svg
      className="event-icon"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.3163 19.4384C5.92462 18.0052 7.34492 17 9 17H15C16.6551 17 18.0754 18.0052 18.6837 19.4384M16 9.5C16 11.7091 14.2091 13.5 12 13.5C9.79086 13.5 8 11.7091 8 9.5C8 7.29086 9.79086 5.5 12 5.5C14.2091 5.5 16 7.29086 16 9.5ZM22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
        stroke="currentcolor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function MapPinIcon() {
  return (
    <svg
      className="event-icon"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 14.2864C3.14864 15.1031 2 16.2412 2 17.5C2 19.9853 6.47715 22 12 22C17.5228 22 22 19.9853 22 17.5C22 16.2412 20.8514 15.1031 19 14.2864M18 8C18 12.0637 13.5 14 12 17C10.5 14 6 12.0637 6 8C6 4.68629 8.68629 2 12 2C15.3137 2 18 4.68629 18 8ZM13 8C13 8.55228 12.5523 9 12 9C11.4477 9 11 8.55228 11 8C11 7.44772 11.4477 7 12 7C12.5523 7 13 7.44772 13 8Z"
        stroke="currentcolor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function MiniEventCard({
  activityName,
  sessionCost,
  hostFirstName,
  hostLastName,
  isFullDay,
  startDate,
  endDate,
  showRsvpButton,
  renderRsvpButton,
  facilityName,
  activityDescription,
}) {
  const { hide, setAlert } = useContext(AlertContext);
  return (
    <Card className="w-100">
      <CardBody className="p-3">
        <Row className="flex-nowrap">
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
          <div
            className="cursor-pointer overflow-hidden px-3"
            style={{ fontSize: "1.2rem", fontWeight: "700" }}
            onClick={(e) => {
              e.stopPropagation();
              setAlert(
                <Alert title={activityName} onConfirm={hide}>
                  {activityDescription}
                </Alert>,
              );
            }}
          >
            {activityName}
          </div>
          <div
            className={classNames("justify-content-end flex h-9", {
              "d-none": sessionCost === null,
            })}
          >
            <Badge color="primary">
              {sessionCost} {pluralize("Credit", "Credits", sessionCost)}
            </Badge>
          </div>
        </Row>
        <Row className="mt-2">
          <Col className="event-grid-date" style={{ fontWeight: "500" }}>
            {`${format(new Date(startDate), "eeee, MMMM do")}`}
          </Col>
        </Row>
        <Row className="mt-2" style={{ fontWeight: "500" }}>
          <Col className="event-grid-date">
            {isFullDay
              ? "Full Day"
              : `${format(new Date(startDate), "h:mm a")} ${humanizeTimeInterval(startDate, endDate)}`}
          </Col>
        </Row>
        <Row className="mt-2">
          <Col className="d-flex">
            <UserCircleIcon />
            <div className="text-muted ml-2" style={{ marginTop: "-2px" }}>
              {hostFirstName} {hostLastName}
            </div>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col className="d-flex">
            <MapPinIcon />
            <div className="text-muted ml-2" style={{ marginTop: "-2px" }}>
              {facilityName}
            </div>
          </Col>
        </Row>
        {showRsvpButton && (
          <Row className="mt-2">
            <Col>{renderRsvpButton()}</Col>
          </Row>
        )}
      </CardBody>
    </Card>
  );
}

MiniEventCard.defaultProps = {
  sessionCost: null,
};

MiniEventCard.propTypes = {
  sessionCost: PropTypes.number,
  activityName: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  hostFirstName: PropTypes.string.isRequired,
  hostLastName: PropTypes.string.isRequired,
  showRsvpButton: PropTypes.bool,
  renderRsvpButton: PropTypes.func,
  isFullDay: PropTypes.bool.isRequired,
};

export default MiniEventCard;
