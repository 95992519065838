import {
  useClubAsPublic,
  useMemberCreateAsPublic,
  useRuleValuesAsPublic,
} from "@gymflow/api";
import { useRecordForm } from "@gymflow/common";
import { UserFormRule, UserMemberPost } from "@gymflow/types";
import { Formik } from "formik";
import { useContext, useMemo, useState } from "react";
import { toFormikValidationSchema } from "zod-formik-adapter";

import { getDefaultsFromZodSchema } from "../../helpers";
import { requestErrorParser } from "../../helpers/requestErrorParser";
import { useRedirectUrl } from "../../hooks";
import {
  generateSchema,
  ModalContext,
  SignUpForm,
  SignUpFormMapper,
  SuccessModal,
  useClubSettings,
} from "../../providers";
import useGymflowModels from "../../store";

export function SiteRegistration() {
  const { setModal, hide: hideModal } = useContext(ModalContext);

  const { api } = useGymflowModels();
  const settings = useClubSettings();
  const { data: club } = useClubAsPublic({ api, clubId: settings.clubId });
  const { redirectToCustomizedWebsiteOr } = useRedirectUrl();

  const { data } = useRuleValuesAsPublic({ api, clubId: settings.clubId });
  const requiredFields: Record<keyof UserFormRule, boolean> | null =
    useMemo(() => {
      if (!data) {
        return null;
      }
      const formConfig = data.find(
        (r) => r.ruleType === "USER_FORM_REQUIREMENT_CONFIG",
      );
      if (!formConfig) {
        return null;
      }
      return Object.keys(formConfig.userFormRule!).reduce(
        (acc, r) => {
          if (!formConfig.userFormRule) {
            return acc;
          }
          const field = r as keyof UserFormRule;
          acc[field] = formConfig.userFormRule[field].isRequired;
          return acc as Record<keyof UserFormRule, boolean>;
        },
        {} as Record<keyof UserFormRule, boolean>,
      );
    }, [data]);

  const createMemberMutation = useMemberCreateAsPublic({ api });

  const rules = data?.find(
    (r) => r.ruleType === "USER_FORM_REQUIREMENT_CONFIG",
  );
  const schema = generateSchema({
    rules: rules?.userFormRule || undefined,
    defaultNationality: settings.default_nationality,
    postCodeFormat: settings.postal_code_country,
    dateFormat: settings.date_format,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { initialValues, getValues } = useRecordForm({
    record: null,
    fields: getDefaultsFromZodSchema(schema),
    mapper: new SignUpFormMapper({
      dateFormat: settings.date_format,
      rules: rules?.userFormRule,
    }),
  });
  const [submittingError, setSubmittingError] = useState("");
  return (
    <div className="flex justify-center">
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={async (values, helpers) => {
          setIsSubmitting(true);
          try {
            const newUser = getValues(values) as UserMemberPost;
            await createMemberMutation.mutateAsync(newUser);
          } catch (e: any) {
            if (e?.response?.data?.errors) {
              setSubmittingError("Error: " + requestErrorParser(e));
            }
          } finally {
            setIsSubmitting(false);

            setModal(
              <SuccessModal
                title="Account created"
                onClose={() => {
                  hideModal();
                  helpers.resetForm();
                  redirectToCustomizedWebsiteOr();
                }}
              >
                Your account was successfully created.
              </SuccessModal>,
            );
          }
        }}
        validationSchema={toFormikValidationSchema(schema)}
      >
        <SignUpForm
          className="max-w-[40rem]"
          requiredFields={requiredFields}
          clubWaiverLink={club?.termsConditions}
          isSubmitting={isSubmitting}
          submittingError={submittingError}
          description="Sign up to create an account with this club."
        />
      </Formik>
    </div>
  );
}
