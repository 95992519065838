import { Switch as HeadlessSwitch } from "@headlessui/react";
import classNames from "classnames";

export interface SwitchProps {
  label?: string;
  value: boolean;
  onChange: (checked: boolean) => void;
  disabled?: boolean;
}

export function LabeledSwitch({
  label,
  value,
  onChange,
  disabled,
}: SwitchProps) {
  return (
    <div className="flex flex-row items-center gap-x-2">
      <Switch value={value} onChange={onChange} disabled={disabled} />
      {label && <div>{label}</div>}
    </div>
  );
}

export function Switch({ label, value, onChange, disabled }: SwitchProps) {
  return (
    <HeadlessSwitch
      checked={value}
      onChange={onChange}
      className={classNames(
        value ? "bg-primary-700" : "bg-gray-200",
        "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-[transparent] transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2",
      )}
      disabled={disabled}
    >
      <span
        aria-hidden="true"
        className={classNames(
          value ? "translate-x-5" : "translate-x-0",
          "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
        )}
      />
    </HeadlessSwitch>
  );
}
