import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import { AsyncButton } from '@gymflow/common';

function WriteOffInvoiceAlert({ onConfirm, onCancel }) {
  const renderButtons = () => (
    <>
      <Button type="button" onClick={onCancel} className="btn btn-sm btn-outline-primary">
        Cancel
      </Button>
      <AsyncButton type="button" className="btn btn-sm btn-primary" onClick={onConfirm}>
        Write off
      </AsyncButton>
    </>
  );

  return (
    <ReactBSAlert title="Write off Invoice" closeOnClickOutside={false} customButtons={renderButtons()}>
      Are you sure you want to write off this invoice?
    </ReactBSAlert>
  );
}

WriteOffInvoiceAlert.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default WriteOffInvoiceAlert;
