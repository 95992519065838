import { useMemberEmailExistsAsPublic } from "@gymflow/api";
import classNames from "classnames";
import { useState } from "react";
import { z } from "zod";

import { Button, TextInput } from "../../../components/atoms";
import useGymflowModels from "../../../store";

export function EmailExistsForm({
  email: initialEmail,
  onSubmit,
}: {
  email?: string;
  onSubmit: (newEmail: string, isAlreadyRegistered: boolean) => void;
}) {
  const { api } = useGymflowModels();
  const [email, setEmail] = useState(initialEmail || "");
  const [error, setError] = useState("");

  const validEmail = z.string().email().safeParse(email).success;
  const { data } = useMemberEmailExistsAsPublic(
    { api, email },
    { enabled: validEmail },
  );
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
      }}
      className="flex w-full flex-col items-stretch gap-8"
    >
      <div className="flex flex-col items-center gap-6">
        <div className="dark:text-darkGray-50 text-center text-2xl font-bold text-gray-900">
          Sign In or Sign Up
        </div>
        <div className="dark:text-darkGray-400 text-center text-sm font-medium text-gray-600">
          Enter your email to continue
        </div>
      </div>
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-2">
          <div className="dark:text-textSecondary-700 text-sm text-gray-600">
            Email:
          </div>
          <TextInput
            autoFocus
            value={email}
            onChange={({ currentTarget: { value } }) => {
              setEmail(value);
            }}
          />
          <div className={classNames("text-error-500", { invisible: !error })}>
            {error}
          </div>
        </div>
        <div>
          <Button
            intent="primary"
            type="submit"
            className="mt-2 w-full"
            onClick={() => {
              if (validEmail) {
                setError("");
                onSubmit(email, data || false);
              } else {
                setError("Invalid email.");
              }
            }}
          >
            Continue
          </Button>
        </div>
      </div>
    </form>
  );
}
