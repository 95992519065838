/* eslint-disable react/prop-types */
import {
  memberSearchQueryFn,
  useMutationMembershipLinkMember,
} from "@gymflow/api";
import {
  UserMemberBean,
  UserMemberSubscriptionBeanWithMembership,
} from "@gymflow/types";
import { ModalContext } from "apps/portal/src/providers";
import useGymflowModels from "apps/portal/src/store";
import { cn } from "libs/common/src/lib/helpers/cn";
import React, { ReactNode, useContext, useState } from "react";

import { PaginatedSelect } from "../../../atoms";
import { UserMemberAvatar } from "../../../molecules/UserMemberAvatar";
import { ConfirmModal } from "../../../templates";

export interface LinkMemberToMembershipModalProps {
  memberId: string;
  membership: UserMemberSubscriptionBeanWithMembership;
}

export const LinkMemberToMembershipModal: React.FC<
  LinkMemberToMembershipModalProps
> = ({ memberId, membership }) => {
  const { api } = useGymflowModels();

  const { hide } = useContext(ModalContext);
  const [selectedMember, setSelectedMember] = useState<{
    label: string | ReactNode;
    value: string;
  }>();
  const { mutateAsync: linkMember } = useMutationMembershipLinkMember({
    api,
  });
  return (
    <ConfirmModal
      title="Link Account"
      type="default"
      confirmText="Link"
      isConfirmDisabled={!selectedMember?.value}
      onConfirm={async () => {
        if (!selectedMember?.value) return;
        await linkMember({
          primarySubscriptionId: membership.id,
          secondaryMemberId: selectedMember.value,
        });
        hide();
      }}
      cancelText="Cancel"
      onCancel={async () => {
        hide();
      }}
    >
      <div className="flex flex-col gap-4">
        <div>
          Linking another user to this account will give them the same
          membership but for no charge. If the primary membership is cancelled
          so will any linked accounts.
        </div>
        {
          <PaginatedSelect
            isSearchable
            onChange={(v: any) => setSelectedMember(v)}
            placeholder="Search..."
            value={
              selectedMember
                ? {
                    value: selectedMember.value,
                    label: <div className="py-4">{selectedMember.label}</div>,
                  }
                : undefined
            }
            loadOptions={async (searchTerm: string) => {
              if (!searchTerm) {
                return {
                  options: [],
                };
              }
              const users = await memberSearchQueryFn(api, {
                term: searchTerm,
                size: 10,
              });
              return {
                options: users
                  .filter(
                    (user) =>
                      !(
                        user.profileType === "LEAD" ||
                        (user as UserMemberBean).subscriptions.some(
                          (subscription) => !!subscription.active && !!subscription.membershipBean,
                        ) ||
                        !(user as UserMemberBean).isFullUser
                      ),
                  )
                  ?.map((user) => {
                    return {
                      label: (
                        <div
                          className={cn(" flex flex-col justify-start gap-1")}
                        >
                          <UserMemberAvatar {...user} disableLink />
                        </div>
                      ),
                      value: user.id,
                    };
                  }),
              };
            }}
          />
        }
      </div>
    </ConfirmModal>
  );
};
