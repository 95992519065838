import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import noop from 'lodash/noop';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import { AsyncButton } from '@gymflow/common';
import { formatCurrency } from '@gymflow/helpers';

function RefundInvoiceAlert({ onConfirm, onCancel, currency, amount }) {
  const renderButtons = () => (
    <>
      <Button type="button" onClick={onCancel} className="btn btn-sm btn-outline-primary">
        Cancel
      </Button>
      <AsyncButton type="button" className="btn btn-sm btn-primary" onClick={onConfirm}>
        Refund
      </AsyncButton>
    </>
  );

  return (
    <ReactBSAlert
      title="Refund Invoice"
      closeOnClickOutside={false}
      customButtons={renderButtons()}
      onConfirm={noop}
    >
      Are you sure you want to refund this invoice for <b>{formatCurrency(amount, currency)}</b>?
    </ReactBSAlert>
  );
}

RefundInvoiceAlert.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
};

export default RefundInvoiceAlert;
