import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  activityCategoryListQueryFn,
  activityQueryKeys,
  appointableCategoriesQueryFn,
  appointableQueryKeys,
  clubStaleTime,
  useClub,
  useSessionPackCreate,
  useSessionPackEdit,
} from "@gymflow/api";
import {
  ExpiryType,
  FormikInput,
  FormMapper,
  NotificationContext,
  onlyNumbersProps,
  useRecordForm,
} from "@gymflow/common";
import { SessionPackDTO } from "@gymflow/types";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useFormik } from "formik";
import omit from "lodash/omit";
import {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Tooltip } from "react-tooltip";

import { ModalContext, useClubSettings } from "../../../providers";
import useGymflowModels from "../../../store";
import {
  ActivityCategorySelect,
  Button,
  PaginatedSelect,
  PrimaryButton,
  renderMultiSelectPlaceholder,
  SlideSideBar,
  Switch,
} from "../../atoms";
import { FileInputLabel } from "../../forms/FileInputLabel";
import { CollapsibleSections } from "../../molecules";
import { NewAppointableCategoryModal } from "../Appointable/NewAppointableCategoryModal";
import {
  APPOINTABLE_CATEGORIES,
  CLASS_CATEGORIES,
  createSessionPackSchema,
  CREDIT_EXPIRE,
  CREDIT_EXPIRY_INTERVAL,
  CREDIT_EXPIRY_VALUE,
  CREDITS,
  DESCRIPTION,
  IS_PUBLIC,
  NAME,
  PRICE,
  TERMS_CONDITIONS,
  UNLIMITED_CREDITS,
} from "./CreditPackSchema";

export const durationOptions = [
  { label: "1 Week", value: { value: 1, interval: ExpiryType.Weekly } },
  { label: "2 Weeks", value: { value: 2, interval: ExpiryType.Weekly } },
  { label: "3 Weeks", value: { value: 3, interval: ExpiryType.Weekly } },
  { label: "1 Month", value: { value: 1, interval: ExpiryType.Monthly } },
  { label: "2 Months", value: { value: 2, interval: ExpiryType.Monthly } },
  { label: "3 Months", value: { value: 3, interval: ExpiryType.Monthly } },
  { label: "4 Months", value: { value: 4, interval: ExpiryType.Monthly } },
  { label: "5 Months", value: { value: 5, interval: ExpiryType.Monthly } },
  { label: "6 Months", value: { value: 6, interval: ExpiryType.Monthly } },
  { label: "7 Months", value: { value: 7, interval: ExpiryType.Monthly } },
  { label: "8 Months", value: { value: 8, interval: ExpiryType.Monthly } },
  { label: "9 Months", value: { value: 9, interval: ExpiryType.Monthly } },
  { label: "10 Months", value: { value: 10, interval: ExpiryType.Monthly } },
  { label: "11 Months", value: { value: 11, interval: ExpiryType.Monthly } },
  { label: "12 Months", value: { value: 12, interval: ExpiryType.Monthly } },
  {
    label: "No Expiry",
    value: { value: null, interval: ExpiryType.NA },
  },
];

const TypedFormikInput = FormikInput as React.FC<{ [key: string]: any }>;
const mapper = new FormMapper({
  inValue: [
    {
      key: "termsConditions",
      transform: (url: any, { termsConditionsFilename }: any) => ({
        name: termsConditionsFilename,
        url,
      }),
    },
    {
      key: "expiryType",
      transform: (expiryType: any, others: any) => {
        if (expiryType === ExpiryType.NA) {
          others.expiryValue = null;
        }
        return expiryType;
      },
    },
  ],
});
export const CreditPackFormSidebarProviderContext = createContext<{
  showCreditPackForm: ({
    currentCreditPack,
    type,
    onClose,
  }: {
    currentCreditPack?: SessionPackDTO;
    type?: "classPack" | "appointmentPack";
    onClose?: (newSessionPack?: SessionPackDTO) => void;
  }) => void;
}>({} as any);

export const CreditPackFormSidebarProvider: React.FC<{
  children: ReactElement | ReactElement[];
}> = ({ children }) => {
  const [sidebarState, setSidebarState] = useState<
    | {
        currentCreditPack?: SessionPackDTO;
        type?: "classPack" | "appointmentPack";
        isVisible: boolean;
        onClose: (newSessionPack?: SessionPackDTO) => void;
      }
    | undefined
  >();
  const { api } = useGymflowModels();

  const editSessionPack = useSessionPackEdit({ api });
  const createSessionPack = useSessionPackCreate({ api });

  const settings = useClubSettings();
  const { data: club } = useClub(
    { api, clubId: settings.clubId },
    { staleTime: clubStaleTime },
  );

  const { notifyDanger } = useContext(NotificationContext);
  const { initialValues, getPatchedValues, getValues } = useRecordForm({
    record: sidebarState?.currentCreditPack ?? null,
    fields: (createSessionPackSchema() as any).default(),
    mapper,
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: async (values: any) => {
      if (sidebarState?.currentCreditPack) {
        await onSubmit({
          id: sidebarState?.currentCreditPack.id,
          patchedFields: omit(
            omit(getPatchedValues(values), "creditExpire"),
            "membershipInclusionOnly",
          ),
        });
      } else {
        await onSubmit(
          omit(
            omit(getValues(values), "creditExpire"),
            "membershipInclusionOnly",
          ),
        );
      }
    },
    validationSchema: createSessionPackSchema(),
  });
  const { handleSubmit, setFieldValue, values, resetForm } = formik;
  const hide = useCallback(
    (newSessionPack?: SessionPackDTO) => {
      resetForm();
      sidebarState?.onClose(newSessionPack);
      setOpenSectionIdx(0);
      setSidebarState(undefined);
    },
    [resetForm, sidebarState],
  );
  const onSubmit = useCallback(
    async (values: any) => {
      try {
        let result;
        if (sidebarState?.currentCreditPack) {
          result = await editSessionPack.mutateAsync({
            sessionPackId: values.id,
            patchedFields: values.patchedFields,
          });
        } else {
          result = await createSessionPack.mutateAsync({
            ...values,
            taxRate: club?.defaultTaxRate,
          });
        }
        hide(result ?? undefined);
      } catch (e) {
        notifyDanger(e);
      }
    },
    [
      sidebarState?.currentCreditPack,
      hide,
      editSessionPack,
      createSessionPack,
      club?.defaultTaxRate,
      notifyDanger,
    ],
  );

  const {
    data: classCategories,
    hasNextPage: hasMoreCategories,
    fetchNextPage: fetchNextCategories,
  } = useInfiniteQuery({
    queryKey: [...activityQueryKeys.categories(), "infinite"],
    queryFn: ({ pageParam }) => {
      return activityCategoryListQueryFn({
        api,
        filter: { page: pageParam, limit: 100, status: ["ACTIVE"] },
      });
    },
    getNextPageParam: (lastPage) => {
      if (lastPage.last) {
        return undefined;
      }
      return lastPage.number + 1;
    },
  });
  useEffect(() => {
    if (hasMoreCategories) {
      fetchNextCategories();
    }
  }, [
    hasMoreCategories,
    classCategories?.pageParams.length,
    fetchNextCategories,
  ]);

  const classCategoryOptions =
    classCategories?.pages
      .flatMap((t) => t.content)
      .map((e) => ({
        label: e.name,
        value: e.id,
      })) ?? [];

  const {
    data: appointableCategories,
    hasNextPage: hasMoreAppointableCategories,
    fetchNextPage: fetchNextAppointableCategories,
  } = useInfiniteQuery({
    queryKey: [...appointableQueryKeys.categoriesAll(), "infinite"],
    queryFn: ({ pageParam }) => {
      return appointableCategoriesQueryFn({
        api,
        paginationOption: { page: pageParam, size: 1 },
        filter: { statusList: ["ACTIVE"] },
      });
    },
    getNextPageParam: (lastPage) => {
      if (lastPage.last) {
        return undefined;
      }
      return lastPage.number + 1;
    },
  });
  useEffect(() => {
    if (hasMoreAppointableCategories) {
      fetchNextAppointableCategories();
    }
  }, [
    hasMoreAppointableCategories,
    appointableCategories?.pageParams.length,
    fetchNextAppointableCategories,
  ]);

  const appointableCategoryOptions =
    appointableCategories?.pages
      .flatMap((t) => t.content)
      .map((e) => ({
        label: e.name,
        value: e.id,
      })) ?? [];

  const { setModal } = useContext(ModalContext);
  const sections = [
    {
      title: "Details",
      body: (
        <>
          <div className="flex flex-col gap-y-1">
            <label className="!m-0 text-gray-700" htmlFor={NAME}>
              Name*
            </label>
            <TypedFormikInput
              placeholder="Enter a pack name"
              autoComplete="off"
              data-testid={NAME}
              maxLength="128"
              name={NAME}
              type="text"
              formikProps={formik}
              className="!p-3 text-gray-700"
            />
          </div>

          <div className="flex h-32 flex-col gap-y-1">
            <label className="!m-0 text-gray-700" htmlFor={DESCRIPTION}>
              Description
            </label>
            <TypedFormikInput
              placeholder="Write a nice description for customer to read before purchasing."
              autoComplete="off"
              data-testid={DESCRIPTION}
              maxLength="500"
              name={DESCRIPTION}
              id={DESCRIPTION}
              formikProps={formik}
              type="textarea"
              className="flex !h-full !max-h-full !p-3 !text-base text-gray-700"
            />
          </div>
          <div className="flex flex-row items-center justify-between">
            <label
              className="!m-0 flex flex-row items-center gap-x-2 text-gray-700"
              htmlFor={IS_PUBLIC}
            >
              Available Online
              <FontAwesomeIcon
                data-tooltip-id="available-online-tooltip"
                data-tooltip-content="Makes the credit pack available for purchase via your website integration and app."
                className="cursor-pointer"
                icon={faQuestionCircle}
              />
              <Tooltip
                className="!bg-primary-700 flex max-w-sm flex-col items-center rounded-lg text-center text-xs"
                id="available-online-tooltip"
              />
            </label>
            <Switch
              value={values[IS_PUBLIC]}
              onChange={(value) => {
                setFieldValue(IS_PUBLIC, value);
              }}
            />
          </div>

          {/* <div className="flex flex-row items-center justify-between">
            <label
              className="!m-0 flex flex-row items-center gap-x-2 text-gray-700"
              htmlFor={MEMBERSHIP_INCLUSION_ONLY}
            >
              Membership Inclusions Only
              <FontAwesomeIcon
                data-tooltip-id="inclusions-only-tooltip"
                data-tooltip-content="Used for including with memberships only. Is not available for sale and does not show up in any lists."
                className="cursor-pointer"
                icon={faQuestionCircle}
              />
              <Tooltip
                className="!bg-primary-700 z-10 flex max-w-xs flex-col items-center rounded-lg text-center text-xs"
                id="inclusions-only-tooltip"
              />
            </label>
            <Switch
              value={values[MEMBERSHIP_INCLUSION_ONLY]}
              onChange={(value) => {
                setFieldValue(MEMBERSHIP_INCLUSION_ONLY, value);
              }}
            />
          </div> */}
          <div className="flex flex-col">
            <div className="flex flex-row items-center justify-between">
              <label
                className="!m-0 flex flex-row items-center gap-x-2 text-gray-700"
                htmlFor={TERMS_CONDITIONS}
              >
                Terms & Conditions*
                <FontAwesomeIcon
                  data-tooltip-id="terms-tooltip"
                  data-tooltip-content="Typically includes your cancellation, refund and booking terms."
                  className="cursor-pointer"
                  icon={faQuestionCircle}
                />
                <Tooltip
                  className="!bg-primary-700 flex max-w-xs flex-col items-center rounded-lg text-center text-xs"
                  id="terms-tooltip"
                />
              </label>
              <FileInputLabel
                accept="application/pdf"
                value={values[TERMS_CONDITIONS]}
                onChange={(file) => setFieldValue(TERMS_CONDITIONS, file)}
              />
            </div>
            <label className="text-error-500">
              {formik.touched[TERMS_CONDITIONS] &&
                (formik.errors[TERMS_CONDITIONS] as any)}
            </label>
          </div>
        </>
      ),
    },
    {
      title: "Credits",
      body: (
        <>
          <div className="flex flex-row items-center justify-between">
            <label
              className="!m-0 flex flex-row items-center gap-x-2 text-gray-700"
              htmlFor={UNLIMITED_CREDITS}
            >
              Unlimited Sessions
              <FontAwesomeIcon
                data-tooltip-id="unlimited-sessions-tooltip"
                data-tooltip-content="Enables unlimited bookings for selected categories. "
                className="cursor-pointer"
                icon={faQuestionCircle}
              />
              <Tooltip
                className="!bg-primary-700 z-10 flex max-w-xs flex-col items-center rounded-lg text-center text-xs"
                id="unlimited-sessions-tooltip"
              />
            </label>
            <Switch
              value={values[UNLIMITED_CREDITS]}
              onChange={async (checked) => {
                await setFieldValue(UNLIMITED_CREDITS, checked);
                if (checked) {
                  await setFieldValue(CREDITS, 0);
                }
                setFieldValue(UNLIMITED_CREDITS, checked);
              }}
            />
          </div>
          {!values[UNLIMITED_CREDITS] && (
            <div className="flex flex-col gap-y-2">
              <label className="!m-0 text-gray-700" htmlFor={CREDITS}>
                Credits*
              </label>
              <TypedFormikInput
                placeholder="Enter number of credits"
                autoComplete="off"
                data-testid={CREDITS}
                name={CREDITS}
                type="text"
                formikProps={formik}
                disabled={!!values[UNLIMITED_CREDITS]}
                className="flex !h-full !max-h-full !p-3 !text-base text-gray-700"
                {...onlyNumbersProps}
              />
            </div>
          )}
          <div className="flex flex-col gap-y-2">
            <label className="!m-0 text-gray-700" htmlFor={CREDIT_EXPIRE}>
              Credits Expire*
            </label>
            <PaginatedSelect
              isSearchable
              loadOptions={async (inputValue) => ({
                options: durationOptions.filter((e) =>
                  e.label.toLowerCase().includes(inputValue.toLowerCase()),
                ),
              })}
              onChange={({ value }) => {
                formik.setValues({
                  ...formik.values,
                  [CREDIT_EXPIRY_VALUE]: value.value,
                  [CREDIT_EXPIRY_INTERVAL]: value.interval,
                });
              }}
              value={durationOptions.find(
                (d) =>
                  d.value.value === values[CREDIT_EXPIRY_VALUE] &&
                  d.value.interval === values[CREDIT_EXPIRY_INTERVAL],
              )}
            />
            <label className="text-error-500">
              {formik.touched[CREDIT_EXPIRY_INTERVAL] &&
                (formik.errors[CREDIT_EXPIRY_INTERVAL] as any)}
            </label>
          </div>
          {sidebarState &&
            (!sidebarState.type || sidebarState.type === "classPack") && (
              <div className="flex flex-col gap-y-2">
                <label
                  className="!m-0 flex flex-row items-center gap-x-1 text-gray-700"
                  htmlFor={CLASS_CATEGORIES}
                >
                  Class Categories*
                  <FontAwesomeIcon
                    data-tooltip-id="class-categories-tooltip"
                    data-tooltip-content="Users will need to own credits with these categories to be able to book the class."
                    className="cursor-pointer"
                    icon={faQuestionCircle}
                  />
                  <Tooltip
                    className="!bg-primary-700 z-10 flex max-w-xs flex-col items-center rounded-lg text-center text-xs"
                    id="class-categories-tooltip"
                  />
                </label>
                <ActivityCategorySelect
                  allowMultipleSelection
                  isSearchable
                  onChange={(newValues) => {
                    setFieldValue(
                      CLASS_CATEGORIES,
                      newValues.map((v: any) => v.value),
                    );
                  }}
                  value={classCategoryOptions.filter(
                    (op) => values[CLASS_CATEGORIES]?.includes(op.value),
                  )}
                  showManageOption
                  placeholder={renderMultiSelectPlaceholder({
                    value: classCategoryOptions.filter(
                      (op) => values[CLASS_CATEGORIES]?.includes(op.value),
                    ),
                  })}
                />
                <label className="text-error-500">
                  {formik.touched[CLASS_CATEGORIES] &&
                    (formik.errors[CLASS_CATEGORIES] as any)}
                </label>
              </div>
            )}
          {sidebarState &&
            (!sidebarState.type || sidebarState.type === "appointmentPack") && (
              <div className="flex flex-col gap-y-2">
                <label
                  className="!m-0 flex flex-row items-center gap-x-1 text-gray-700"
                  htmlFor={APPOINTABLE_CATEGORIES}
                >
                  Appointment Categories*
                  <FontAwesomeIcon
                    data-tooltip-id="appointment-categories-tooltip"
                    data-tooltip-content="Will allow the owner of this pack to book any appointment of the same categories selected."
                    className="cursor-pointer"
                    icon={faQuestionCircle}
                  />
                  <Tooltip
                    className="!bg-primary-700 z-10 flex max-w-xs flex-col items-center rounded-lg text-center text-xs"
                    id="appointment-categories-tooltip"
                  />
                </label>

                <PaginatedSelect
                  isMulti
                  isSearchable
                  loadOptions={async (inputValue) => ({
                    options: [
                      ...appointableCategoryOptions.filter((e) =>
                        e.label
                          .toLowerCase()
                          .includes(inputValue.toLowerCase()),
                      ),
                    ],
                  })}
                  onChange={(newValues) => {
                    setFieldValue(
                      APPOINTABLE_CATEGORIES,
                      newValues.map((v: any) => v.value),
                    );
                  }}
                  value={appointableCategoryOptions.filter(
                    (op) => values[APPOINTABLE_CATEGORIES]?.includes(op.value),
                  )}
                  refetchOnMenuOpen
                  showMenuFooterButton
                  menuFooterClick={async () => {
                    setModal(
                      <NewAppointableCategoryModal
                        onCancel={() => setModal(null)}
                        api={api}
                      />,
                    );
                  }}
                  placeholder={renderMultiSelectPlaceholder({
                    value: appointableCategoryOptions.filter(
                      (op) =>
                        values[APPOINTABLE_CATEGORIES]?.includes(op.value),
                    ),
                  })}
                />
                <label className="text-error-500">
                  {formik.touched[APPOINTABLE_CATEGORIES] &&
                    (formik.errors[APPOINTABLE_CATEGORIES] as any)}
                </label>
              </div>
            )}
        </>
      ),
    },
    {
      title: "Pricing",
      body: (
        <div className="flex flex-col gap-y-2">
          <label className="!m-0 text-gray-700" htmlFor={PRICE}>
            Price*
          </label>
          <TypedFormikInput
            placeholder="Enter the price"
            autoComplete="off"
            data-testid={PRICE}
            name={PRICE}
            type="text"
            formikProps={formik}
            className="flex !h-full !max-h-full !p-3 !text-base text-gray-700"
            {...onlyNumbersProps}
          />
        </div>
      ),
    },
  ];
  const [openSectionIdx, setOpenSectionIdx] = useState(0);
  const showCreditPackForm = useCallback(
    (params: {
      currentCreditPack?: SessionPackDTO;
      type?: "classPack" | "appointmentPack";
      onClose?: (newSessionPack?: SessionPackDTO) => void;
    }) => {
      if (
        params.currentCreditPack?.id !== sidebarState?.currentCreditPack?.id ||
        (!params.currentCreditPack && !sidebarState?.isVisible)
      ) {
        setSidebarState({
          isVisible: true,
          currentCreditPack: params?.currentCreditPack,
          type: params?.type,
          onClose: (newSessionPack) => {
            params?.onClose?.(newSessionPack);
          },
        });
        if (params.currentCreditPack)
          formik.setValues(params.currentCreditPack);
      }
    },
    [sidebarState?.currentCreditPack?.id, sidebarState?.isVisible],
  );
  return (
    <CreditPackFormSidebarProviderContext.Provider
      value={{
        showCreditPackForm,
      }}
    >
      {children}
      <SlideSideBar
        isOpen={!!sidebarState?.isVisible}
        hide={hide}
        className="!w-[32rem] max-w-full"
        isLoading={false}
      >
        <div className="flex h-full max-h-full flex-col overflow-y-auto overflow-x-hidden">
          <div className="flex flex-col justify-between border-b border-gray-200 p-8">
            <div className="mb-1 flex flex-row items-center justify-between">
              <div className="text-xl font-semibold text-gray-900">
                {sidebarState?.currentCreditPack
                  ? "Update Credit Pack"
                  : "Create Credit Pack"}
              </div>

              <FontAwesomeIcon
                onClick={() => {
                  hide();
                }}
                className="cursor-pointer text-xl text-gray-600"
                icon={faClose}
              />
            </div>
            <div className="text-sm font-medium text-gray-600">
              {sidebarState?.currentCreditPack
                ? "Update a credit pack which will enable the owner to book classes or appointments for the category assigned."
                : "Create a credit pack which will enable the owner to book classes or appointments for the category assigned."}
            </div>
          </div>
          <CollapsibleSections
            sections={sections}
            onChangeSection={(_, sectionIdx) => {
              setOpenSectionIdx(sectionIdx);
            }}
            openSectionIndex={openSectionIdx}
          />
          <div className="flex h-20 w-full flex-row items-center justify-end gap-x-2 border-t border-gray-200 bg-gray-50 px-6 py-4 mt-auto">
            <Button
              className="bg-gray-25 flex h-11 w-full cursor-pointer items-center justify-center rounded-lg border-gray-300 px-4 font-semibold capitalize text-gray-500 ring-1 ring-gray-300 hover:bg-gray-100"
              onClick={() => {
                hide();
              }}
            >
              Cancel
            </Button>
            <PrimaryButton
              className="bg-primary-600 text-gray-25 hover:bg-primary-300 mt-0 flex h-11 w-full cursor-pointer items-center justify-center rounded-lg px-4 font-semibold capitalize"
              onClick={async () => {
                try {
                  const errors = await createSessionPackSchema()
                    .validate(values, { abortEarly: false })
                    .catch((err) => err);
                  if (errors.inner) {
                    const flatErrors = (errors.inner as { path: string }[]).map(
                      (e) => e.path,
                    );
                    let fieldsPerSection = [
                      [NAME, DESCRIPTION, TERMS_CONDITIONS, IS_PUBLIC],
                      [
                        CREDITS,
                        CREDIT_EXPIRE,
                        CLASS_CATEGORIES,
                        APPOINTABLE_CATEGORIES,
                        CREDIT_EXPIRY_INTERVAL,
                      ],
                      [PRICE],
                    ];
                    for (const [idx, fields] of fieldsPerSection.entries()) {
                      if (fields.some((e) => flatErrors.includes(e))) {
                        setOpenSectionIdx(idx);
                        break;
                      }
                    }
                  }
                  handleSubmit();
                } catch (err) {
                  notifyDanger(err);
                }
              }}
            >
              {sidebarState?.currentCreditPack ? "Update" : "Create"}
            </PrimaryButton>
          </div>
        </div>
      </SlideSideBar>
    </CreditPackFormSidebarProviderContext.Provider>
  );
};
