import { useMutation, useQueryClient } from "@tanstack/react-query";

import { ruleQueryKeys } from "./ruleQueryKeys";

export function useRuleValuesEdit({
  api,
}: {
  api: {
    ruleApi: {
      updateValues: (clubId: number, patchedFields: unknown) => Promise<any>;
    };
  };
}) {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: ({
      clubId,
      patchedFields,
    }: {
      clubId: number;
      patchedFields: { [k: string]: any };
    }) => api.ruleApi.updateValues(clubId, patchedFields),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ruleQueryKeys.all() });
    },
  });
  return mutation;
}
