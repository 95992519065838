import { cn } from "@gymflow/helpers";

import { Button } from "./Button";

export type BadgePickerProps<T extends string | number> = {
  className?: string;
  options: {
    label: string;
    value: T;
  }[];
} & (
  | {
      isMulti: true;
      value: T[];
      onChange: (newValue: T[]) => void;
      hasAllOption?: boolean;
    }
  | {
      hasAllOption?: false;
      isMulti: false;
      value: T;
      onChange: (newValue: T) => void;
    }
);

export const BadgePicker = <T extends number | string>({
  className,
  options,
  value,
  isMulti,
  hasAllOption,
  onChange,
}: BadgePickerProps<T>) => {
  return (
    <div
      className={cn(
        "max-lg:no-scrollbar flex flex-row gap-2 max-lg:!overflow-x-auto max-lg:p-1 lg:flex-wrap",
        className,
      )}
    >
      {hasAllOption && (
        <Button
          size="small"
          className={cn("min-w-fit !ring-gray-500", {
            "!text-secondary-500 !border-secondary-500 !ring-secondary-500":
              value.length === 0,
          })}
          onClick={() => {
            onChange([]);
          }}
        >
          All
        </Button>
      )}
      {options.map((e) => {
        const isSelected = isMulti
          ? value.includes(e.value)
          : e.value === value;
        return (
          <Button
            key={e.value}
            size="small"
            className={cn("min-w-fit !ring-gray-500", {
              "!text-secondary-500 !border-secondary-500 !ring-secondary-500":
                isSelected,
            })}
            onClick={() => {
              if (!isMulti) {
                onChange(e.value);
                return;
              }
              if (isSelected) {
                onChange(value.filter((v) => v !== e.value));
              } else {
                onChange([...value, e.value]);
              }
            }}
          >
            {e.label}
          </Button>
        );
      })}
    </div>
  );
};
