import { Field, FieldProps, FormikProps } from "formik";
import { DayPickerProps } from "react-day-picker";

import { ErrorMessage } from "../../../atoms";
import { DatePicker } from "../../DatePicker";
import { formattedDate } from "./utils/formattedDate";

type FormSingleDatePickerProps<T> = {
  name: keyof T;
  labelUnderPicker?: string;
} & Pick<DayPickerProps, "modifiers" | "disabled">;

export const FormSingleDatePicker = <T,>({
  name,
  labelUnderPicker,
  ...props
}: FormSingleDatePickerProps<T>) => (
  <Field name={name}>
    {({
      field,
      meta: { touched, error },
    }: FieldProps<Date, FormikProps<T>>) => (
      <div className="flex flex-col gap-1.5">
        {labelUnderPicker && (
          <div className="text-sm font-medium text-gray-950 ">
            {labelUnderPicker}
          </div>
        )}
        <DatePicker
          {...props}
          label={formattedDate(field.value)}
          mode="single"
          handleDateSave={(value) =>
            field.onChange({
              target: {
                name,
                value,
              },
            })
          }
        />
        {touched && error && <ErrorMessage text={error} />}
      </div>
    )}
  </Field>
);
