import React from "react";

export interface QuickActionsIconProps {
  className?: string;
}

export const QuickActionsIcon: React.FC<QuickActionsIconProps> = ({
  className,
}) => {
  return (
    <svg
      className={className}
      width="300"
      height="300"
      viewBox="0 0 23 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#667085"
      >
      <path
        d="M8.5 15.5H3M6 10H1.5M8.5 4.5H3.5M16.5 1L9.90357 10.235C9.6116 10.6438 9.46562 10.8481 9.47194 11.0185C9.47744 11.1669 9.54858 11.3051 9.6661 11.3958C9.80108 11.5 10.0522 11.5 10.5546 11.5H15.5L14.5 19L21.0964 9.76499C21.3884 9.35624 21.5344 9.15187 21.5281 8.98147C21.5226 8.83312 21.4514 8.69489 21.3339 8.60418C21.1989 8.5 20.9478 8.5 20.4454 8.5H15.5L16.5 1Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
