import { formatCurrency, membershipHelper } from "@gymflow/helpers";
import format from "date-fns/format";
import parse from "date-fns/parse";
import moment from "moment-timezone";
import { useContext, useState } from "react";

import {
  PARAMETER_DATE_FORMAT,
  PARAMETER_DATE_FORMAT_WITHOUT_TZ,
} from "../api/eventApi";
import { PromotionCodeValidity } from "../constants/PromotionCodeValidity";
import { tzDateTimeStringToUtc } from "../helpers/date";
import { DATE_FORMAT } from "../helpers/form/constants";
import { NotificationContext } from "../providers/Notification";

const usePaymentSummary = ({ fetchSummary, timezone, currency }) => {
  const { notify } = useContext(NotificationContext);

  const [summary, setSummary] = useState({
    lineItems: [],
    total: formatCurrency(0, currency),
    discount: formatCurrency(0, currency),
  });

  const checkPromoCodeValidity = (validity) => {
    if (validity === PromotionCodeValidity.No) {
      notify({ message: "Promotion code is not valid.", type: "danger" });
      return false;
    }

    if (validity === PromotionCodeValidity.NotForThisPurchase) {
      notify({
        message: "Promotion code is not applicable to this purchase.",
        type: "danger",
      });
      return false;
    }
    return true;
  };

  const onUpdateMembershipSummary = async ({
    startDate,
    promoCode: promotionCode,
    userMemberId,
    membershipId,
    membershipName,
    isRecurring,
    billingPeriod,
    billingType,
    calculateProrata,
    weeklyBillingDay,
    monthlyBillingDay,
  } = {}) => {
    const todayInGym = moment().tz(timezone).format(DATE_FORMAT);
    startDate = startDate || todayInGym;

    const { data: updatedSummary } = await fetchSummary({
      membershipId,
      startDate: tzDateTimeStringToUtc(
        moment(startDate, DATE_FORMAT).format(PARAMETER_DATE_FORMAT_WITHOUT_TZ),
        timezone,
        PARAMETER_DATE_FORMAT,
      ),
      userMemberId,
      promotionCode,
    });

    if (!checkPromoCodeValidity(updatedSummary.validPromotionCode)) {
      return false;
    }

    let upfrontAddons = [];
    if (updatedSummary.addonsProratedList) {
      upfrontAddons = updatedSummary.addonsProratedList
        .filter((addon) => !addon.recurring)
        .map(({ name, amount }) => ({
          name,
          price: formatCurrency(amount, currency),
        }));
    }
    let proratedDates;
    if (isRecurring) {
      proratedDates = membershipHelper.calculateRecurringDates(
        {
          billingPeriod,
          billingType,
          calculateProrata,
          monthlyBillingDay,
          type: isRecurring ? "RECURRING" : "PREPAID",
          weeklyBillingDay,
        },
        parse(startDate, "dd/MM/yyyy", new Date()),
      );
    }
    const lineItemName = proratedDates?.dayDiff
      ? `${membershipName} Prorata (${proratedDates.dayDiff} days)`
      : `${membershipName}`;
    const totalDescription = proratedDates?.nextBillingDate
      ? `Your next membership payment will be on the ${format(
          proratedDates.nextBillingDate,
          `do 'of' LLLL`,
        )}`
      : "";

    setSummary({
      lineItems: [
        {
          name: lineItemName,
          price: formatCurrency(
            isRecurring
              ? updatedSummary.recurringMembershipAndAddonsProratedAmount
              : updatedSummary.prepaidMembershipAmount,
            currency,
          ),
        },
        ...upfrontAddons,
      ],
      total: formatCurrency(
        updatedSummary.upfrontPriceDetails.totalAmountToPay,
        currency,
      ),
      totalDescription,
      discount: generateDiscountLine({
        upfrontDiscount:
          updatedSummary?.upfrontPriceDetails?.totalAmountDifference,
        recurringAmount: updatedSummary?.promotionRecurringAmount,
        recurringAmountType: updatedSummary?.promotionRecurringDiscountType,
        recurringCycles: updatedSummary?.promotionRecurringDiscountDuration,
      }),
    });

    return true;
  };

  const onUpdateSessionPackSummary = async ({
    promoCode: promotionCode,
    userMemberId,
    sessionPackId,
    sessionPackName,
  } = {}) => {
    const { data: updatedSummary } = await fetchSummary({
      creditPackId: sessionPackId,
      userMemberId,
      promotionCode,
    });

    if (!checkPromoCodeValidity(updatedSummary.validPromotionCode)) {
      return false;
    }

    setSummary({
      lineItems: [
        {
          name: sessionPackName,
          price: formatCurrency(
            updatedSummary.upfrontPriceDetails.totalAmountBeforeDiscount,
            currency,
          ),
        },
      ],
      total: formatCurrency(
        updatedSummary.upfrontPriceDetails.totalAmountToPay,
        currency,
      ),
      discount:
        updatedSummary.upfrontPriceDetails.totalAmountDifference > 0
          ? formatCurrency(
              updatedSummary.upfrontPriceDetails.totalAmountDifference,
              currency,
            )
          : undefined,
    });
    return true;
  };

  const onUpdateProductsSummary = async ({
    promoCode: promotionCode,
    cart,
    userMemberId,
  } = {}) => {
    const { data: updatedSummary } = await fetchSummary({
      products: cart.map(({ id, quantity }) => ({ productId: id, quantity })),
      userMemberId,
      promotionCode,
    });

    if (!checkPromoCodeValidity(updatedSummary.validPromotionCode)) {
      return false;
    }

    const lineItems = updatedSummary.productOrderPriceDetails.map(
      ({ productId, quantity, totalAmount }) => ({
        name: cart.find((p) => p.id === productId).name,
        price: formatCurrency(totalAmount, currency),
        quantity,
      }),
    );

    setSummary({
      lineItems,
      beforeDiscount: formatCurrency(
        updatedSummary.upfrontPriceDetails.totalAmountBeforeDiscount,
        currency,
      ),
      total: formatCurrency(
        updatedSummary.upfrontPriceDetails.totalAmountToPay,
        currency,
      ),
      discount:
        updatedSummary.upfrontPriceDetails.totalAmountDifference > 0
          ? formatCurrency(
              updatedSummary.upfrontPriceDetails.totalAmountDifference,
              currency,
            )
          : undefined,
    });

    return true;
  };

  const generateDiscountLine = ({
    upfrontDiscount,
    recurringAmount,
    recurringAmountType,
    recurringCycles,
  }) => {
    let discountLine = "";
    if (upfrontDiscount) {
      discountLine += formatCurrency(upfrontDiscount, currency);
    }

    if (recurringAmount) {
      if (discountLine !== "") {
        discountLine += " plus ";
      }
      if (recurringAmountType === "PERCENTAGE") {
        discountLine += `${recurringAmount}% off `;
      } else {
        discountLine += `${formatCurrency(recurringAmount, currency)} off `;
      }
      if (recurringCycles) {
        discountLine += `next ${recurringCycles} payments`;
      } else {
        discountLine += `next payments`;
      }
    }

    return discountLine === "" ? undefined : discountLine;
  };

  return {
    summary,
    onUpdateMembershipSummary,
    onUpdateSessionPackSummary,
    onUpdateProductsSummary,
  };
};

export default usePaymentSummary;
