import { useOpenLeadReport, useOpenLeadReportCsv } from "@gymflow/api";
import { downloadCsv } from "@gymflow/helpers";
import { LeadReportItemDTO } from "@gymflow/types";
import { createColumnHelper, SortingState } from "@tanstack/react-table";
import { format } from "date-fns";
import qs from "qs";
import { useState } from "react";
import { useHistory, useLocation } from "react-router";

import { LinkButton } from "../../components/atoms";
import { ReportPagination } from "../../components/molecules";
import { Table } from "../../components/organisms";
import { Report } from "../../components/organisms/Report";
import {
  OpenLeadFilter,
  OpenLeadFilterSideBar,
} from "../../components/templates/OpenLeadFilterSideBar";
import { usePortalRoutes } from "../../hooks/usePortalRoutes";
import { useClubSettings } from "../../providers";
import useGymflowModels from "../../store";

export function LeadOpen() {
  const { createLeadLink, createMemberLink } = usePortalRoutes();
  const history = useHistory();
  const { api } = useGymflowModels();

  const settings = useClubSettings();

  const location = useLocation();
  const searchParams = qs.parse(location.search, { ignoreQueryPrefix: true });

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState(25);
  const [currentSort, setCurrentSort] = useState<SortingState>([]);
  const [filters, setFilters] = useState<OpenLeadFilter>({
    sources: [],
    status: [],
  });

  const createSort = function () {
    return currentSort?.[0]
      ? { field: currentSort[0]?.id, desc: currentSort[0]?.desc }
      : undefined;
  };
  const { data, isFetching } = useOpenLeadReport({
    api,
    sort: createSort(),
    leadStatusList: filters.status.map((e) => e.value.id),
    leadSourceList: filters.sources.map((e) => e.value),
    limit: pageSize,
    page: currentPage,
    tz: settings.timezone,
  });
  const downloadCsvMutation = useOpenLeadReportCsv({
    api,
  });

  const [filtersAreVisible, setFiltersAreVisible] = useState(false);

  const columnHelper = createColumnHelper<LeadReportItemDTO>();
  const columnsDefinition = [
    columnHelper.accessor(
      (row) => {
        return {
          firstName: row.firstName,
          lastName: row.lastName,
          id: row.leadId,
          profileType: row.profileType,
          userMemberId: row.userMemberId,
        };
      },
      {
        id: "name",
        cell: (info) => {
          const memberName = `${info.getValue().firstName} ${
            info.getValue().lastName
          }`;
          return (
            <div>
              <LinkButton
                to={() => {
                  if (info.getValue().profileType === "USER") {
                    return createMemberLink(info.getValue().userMemberId);
                  } else {
                    return createLeadLink(info.getValue().id);
                  }
                }}
              >
                {memberName}
              </LinkButton>
            </div>
          );
        },
        header: () => {
          return <div >Name</div>;
        },
        enableSorting: false,
      },
    ),
    columnHelper.accessor("mobileNumber", {
      cell: (info) => <div className="text-gray-600">{info.getValue()}</div>,
      header: "Phone Number",
      enableSorting: false,
    }),
    columnHelper.accessor("source", {
      cell: (info) => {
        return <div className="text-gray-600">{info.getValue()}</div>;
      },
      header: "Lead Source",
      enableSorting: false,
    }),
    columnHelper.accessor("status", {
      cell: (info) => {
        return <div className="text-gray-600">{info.getValue()}</div>;
      },
      header: "Lead Status",
      enableSorting: false,
    }),
    columnHelper.accessor("createdDate", {
      cell: (info) => (
        <div className="text-gray-600">
          {format(new Date(info.getValue()), "P")}
        </div>
      ),
      header: "Created Date",
      enableSorting: false,
    }),
  ];
  return (
    <>
      <OpenLeadFilterSideBar
        isVisible={filtersAreVisible}
        onClose={() => setFiltersAreVisible(false)}
        value={filters}
        onChange={setFilters}
      />
      <Report
        title="Open Leads"
        description="Count of all leads that are currently in the lead pipeline, which is any lead not in either a “Deal Lost” or “Deal Closed” status."
        onDownloadClick={async () => {
          const data = await downloadCsvMutation.mutateAsync({
            leadStatusList: filters.status.map((e) => e.value.id),
            leadSourceList: filters.sources.map((e) => e.value),
          });
          downloadCsv(data, "OpenLeads.csv");
        }}
        showFilters={() => setFiltersAreVisible(true)}
        close={() => {
          history.push({
            pathname: searchParams["backRoute"] as string,
          });
        }}
        table={
          <>
            <Table
              data={data?.leadReportByOpenStatusViewDTO.content ?? []}
              columns={columnsDefinition}
              pageCount={data?.leadReportByOpenStatusViewDTO.totalPages}
              pageIndex={data?.leadReportByOpenStatusViewDTO.number}
              onSortingChange={setCurrentSort}
              sort={currentSort}
              isFetching={isFetching}
              pageSize={pageSize}
              rowClassName="h-14"
            />
            <div className="border-t-[0.063rem] border-gray-300">
              <ReportPagination
                pageCount={
                  data?.leadReportByOpenStatusViewDTO.totalPages as number
                }
                currentPage={
                  data?.leadReportByOpenStatusViewDTO.number as number
                }
                onPageChange={(newPage) => {
                  setCurrentPage(newPage);
                }}
                pageSize={pageSize}
                setPageSize={(newValue) => {
                  setPageSize(newValue);
                  setCurrentPage(0);
                }}
              />
            </div>
          </>
        }
      />
    </>
  );
}
