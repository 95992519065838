import { useTaskCreate } from "@gymflow/api";
import { NotificationContext } from "@gymflow/common";
import { useClubSettings } from "apps/portal/src/providers";
import useGymflowModels from "apps/portal/src/store";
import { useCallback, useContext } from "react";

import { TaskForm } from "./components/TaskForm/TaskForm";
import { TaskFormProps, TaskFormValues } from "./components/TaskForm/type";
import { normalizeOutgoingData } from "./utils/dataNormalizer";

type CreateTaskSidebarProps = Pick<TaskFormProps, "onClose" | "initialValues">;

export const CreateTaskForm = ({
  onClose,
  initialValues,
}: CreateTaskSidebarProps) => {
  const { api } = useGymflowModels();
  const { timezone: tz } = useClubSettings();
  const { notify, notifyDanger } = useContext(NotificationContext);
  const { mutateAsync: mutateTaskCreate } = useTaskCreate({ api, tz });

  const onSubmit = useCallback(
    async (values: TaskFormValues) => {
      try {
        await mutateTaskCreate(normalizeOutgoingData(values));
        notify({ message: "Task Created" });
        onClose();
      } catch (e) {
        notifyDanger(e);
      }
    },
    [mutateTaskCreate, notify, notifyDanger, onClose],
  );

  return (
    <TaskForm
      initialValues={initialValues}
      title="Create Task"
      description="Assign tasks to owners and relate them to any user or lead."
      submitButtonText="Create Task"
      onClose={onClose}
      onSubmit={onSubmit}
    />
  );
};
