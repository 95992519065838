const smsApi = (axiosInstance, apiUrl, clubId) => {
  const clubPrefix = `club/${clubId}/`;
  const state = {
    baseUrl: `${clubPrefix}`,
    http: axiosInstance,
    apiUrl,
  };

  return Object.assign(state, {
    sendSMSToMembers({ smsPayload, userMemberIds }) {
      return state.http.post(`${clubPrefix}user-member-send-sms/no-filter`, { smsPayload, userMemberIds });
    },
    sendSMSToMembersFilter({
      smsPayload,
      userMemberIdsToExclude,
      createdFrom,
      createdTo,
      smsCommunication,
      emailCommunication,
      membershipIdList,
      membershipTypeList,
      membershipStartFrom,
      membershipStartTo,
      membershipCancellationFrom,
      membershipCancellationTo,
      membershipExpireFrom,
      membershipExpireTo,
      assignedStaffIdList,
      creditsRemainingFrom,
      creditsRemainingTo,
      membershipStatusList,
    }) {
      return state.http.post(`${clubPrefix}user-member-send-sms/filter`, {
        smsPayload,
        userMemberIdsToExclude,
        createdFrom,
        createdTo,
        smsCommunication,
        emailCommunication,
        membershipIdList,
        membershipTypeList,
        membershipStartFrom,
        membershipStartTo,
        membershipCancellationFrom,
        membershipCancellationTo,
        membershipExpireFrom,
        membershipExpireTo,
        assignedStaffIdList,
        creditsRemainingFrom,
        creditsRemainingTo,
        membershipStatusList,
      });
    },
    sendSMSToLeads({ smsPayload, leadIds }) {
      return state.http.post(`${clubPrefix}lead-send-sms/no-filter`, { smsPayload, leadIds });
    },
    sendSMSToLeadsFilter({
      smsPayload,
      leadIdsToExclude,
      createdFrom,
      createdTo,
      smsCommunication,
      emailCommunication,
      leadStatusIdList,
      leadSourceIdList,
    }) {
      return state.http.post(`${clubPrefix}lead-send-sms/filter`, {
        smsPayload,
        leadIdsToExclude,
        createdFrom,
        createdTo,
        smsCommunication,
        emailCommunication,
        leadStatusIdList,
        leadSourceIdList,
      });
    },
  });
};

export default smsApi;
