import { RuleClubDTO, RuleDTO } from "@gymflow/types";
import { z } from "zod";

export const RuleSchema = z.object({
  advancedBookings: z.object({
    windowType: z.enum(["DAYS", "MONTHS", "HOURS"]),
    windowValue: z.number(),
  }),
  automaticCheckout: z.object({
    windowValue: z.number(),
  }),
  cancellations: z.object({
    windowRule: z.object({
      windowType: z.enum(["DAYS", "MONTHS", "HOURS"]),
      windowValue: z.number(),
    }),
    fee: z.object({
      enabled: z.boolean(),
      value: z
        .number({
          invalid_type_error: "The fee value must be a number",
          required_error: "The fee value is required",
          coerce: true,
        })
        .min(0, "The fee value must be greater than 0"),
    }),
  }),
  noShows: z.object({
    enabled: z.boolean(),
    value: z
      .number({
        invalid_type_error: "The fee value must be a number",
        required_error: "The fee value is required",
        coerce: true,
      })
      .min(0, "The fee value must be greater than 0"),
  }),
  subscriptionCancellation: z.object({
    enabled: z.boolean(),
    value: z.number(),
  }),
});
export type UIRuleState = ReturnType<(typeof RuleSchema)["parse"]>;

export const mapRuleClubDTOListToUIRuleState = (
  rules: RuleClubDTO[],
  ruleTemplates: RuleDTO[],
): UIRuleState => {
  const bookingCancellationRuleId = ruleTemplates.find(
    (template) => template.ruleType === "BOOKING_CANCELLATION_WINDOW",
  )?.id;
  const advancedBookingsRuleId = ruleTemplates.find(
    (template) => template.ruleType === "BOOKING_LIMIT_WINDOW",
  )?.id;
  const noShowRuleId = ruleTemplates.find(
    (template) => template.ruleType === "NO_SHOW_FEE",
  )?.id;
  const automaticCheckoutRuleId = ruleTemplates.find(
    (template) => template.ruleType === "AUTOMATIC_CHECK_OUT",
  )?.id;
  const subscriptionCancellationRuleId = ruleTemplates.find(
    (template) => template.ruleType === "SUBSCRIPTION_CANCELLATION",
  )?.id;
  const bookingCancellationRule = rules.find(
    (e) => e.ruleId === bookingCancellationRuleId,
  )?.bookingCancellationRule;
  const bookingRule = rules.find((e) => e.ruleId === advancedBookingsRuleId)
    ?.bookingRule;
  const noShowRule = rules.find((e) => e.ruleId === noShowRuleId)
    ?.noShowFeeRule;
  const automaticCheckoutRule = rules.find(
    (e) => e.ruleId === automaticCheckoutRuleId,
  )?.checkOutRule;
  const subscriptionCancellationRule = rules.find(
    (e) => e.ruleId === subscriptionCancellationRuleId,
  )?.subscriptionCancellationRule;
  // The 'undefined' checks are done using || cause the BE returns empty strings instead of undefined's
  return {
    advancedBookings: {
      windowType: bookingRule?.windowType || "HOURS",
      windowValue: Number(bookingRule?.windowValue || 0),
    },
    cancellations: {
      windowRule: {
        windowType: bookingCancellationRule?.windowType || "HOURS",
        windowValue: Number(bookingCancellationRule?.windowValue || 0),
      },
      fee: {
        enabled: !!Number(bookingCancellationRule?.cancellationFee),
        value: Number(bookingCancellationRule?.cancellationFee || 0),
      },
    },
    noShows: {
      enabled: !!Number(noShowRule?.noShowFeeValue),
      value: Number(noShowRule?.noShowFeeValue || 0),
    },
    automaticCheckout: {
      windowValue: Number(automaticCheckoutRule?.windowValue || 0),
    },
    subscriptionCancellation: {
      enabled:
        subscriptionCancellationRule?.allowCustomerCancelMemberships || false,
      value: Number(subscriptionCancellationRule?.minimumPeriodNotice),
    },
  };
};
export const UIRuleStateToMapRuleClubDTOList = (
  rules: UIRuleState,
  ruleTemplates: RuleDTO[],
) => {
  const cancellationRuleId = ruleTemplates.find(
    (template) => template.ruleType === "BOOKING_CANCELLATION_WINDOW",
  )!.id;
  const advancedBookingsRuleId = ruleTemplates.find(
    (template) => template.ruleType === "BOOKING_LIMIT_WINDOW",
  )!.id;
  const noShowRuleId = ruleTemplates.find(
    (template) => template.ruleType === "NO_SHOW_FEE",
  )!.id;
  const automaticCheckoutRuleId = ruleTemplates.find(
    (template) => template.ruleType === "AUTOMATIC_CHECK_OUT",
  )!.id;
  const subscriptionCancellationRuleId = ruleTemplates.find(
    (template) => template.ruleType === "SUBSCRIPTION_CANCELLATION",
  )!.id;

  return [
    {
      ruleId: cancellationRuleId,
      bookingCancellationRule: {
        ...rules.cancellations.windowRule,
        cancellationFee: rules.cancellations.fee.enabled
          ? rules.cancellations.fee.value
          : 0,
      },
    },
    {
      ruleId: advancedBookingsRuleId,
      bookingRule: {
        ...rules.advancedBookings,
      },
    },
    {
      ruleId: automaticCheckoutRuleId,
      checkOutRule: {
        ...rules.automaticCheckout,
      },
    },
    {
      ruleId: noShowRuleId,
      noShowFeeRule: {
        noShowFeeValue: rules.noShows.enabled ? rules.noShows.value : 0,
      },
    },
    {
      ruleId: subscriptionCancellationRuleId,
      subscriptionCancellationRule: {
        allowCustomerCancelMemberships: rules.subscriptionCancellation.enabled,
        minimumPeriodNotice: rules.subscriptionCancellation.value,
      },
    },
  ] as RuleClubDTO[];
};
