import {
  clubStaleTime,
  leadQueryFn,
  leadStatusListQueryFn,
  useClub,
  useClubFeatureFlags,
  useMemberDelete,
  useMutationDeleteLead,
  useMutationLeadStatus,
  useTaskCreate,
} from "@gymflow/api";
import { AlertContext, NotificationContext } from "@gymflow/common";
import { cn } from "@gymflow/helpers";
import { LeadPipelineItem } from "@gymflow/types";
import { useCallback, useContext, useState } from "react";

import { useSendEmailsNew } from "../../../hooks";
import { Can, Subject, Verb } from "../../../permissions";
import { ModalContext, useClubSettings } from "../../../providers";
import useGymflowModels from "../../../store";
import {
  buttonVariants,
  CheckDoneIcon,
  DotsVerticalIcon,
  FilePlusIcon,
  MailIcon,
  PaginatedSelect,
  ThumbsDownIcon,
  ThumbsUpIcon,
  TrashIcon,
} from "../../atoms";
import { Popover, PopoverContent, PopoverTrigger } from "../../atoms/Popover";
import { CreateEditTaskSidebarProviderContext } from "../../organisms";
import TaskAlert from "../../Task/TaskAlert";
import { ConfirmModal } from "../../templates";
import { NoteFormSideBarProviderContext } from "../../UserMember/Notes/NoteFormSideBarProvider";
import SendEmailAlertWithProvider from "../../UserMember/SendEmails/SendEmailAlert";

export function LeadActionsDropdown({
  className,
  dealLostStatusId,
  dealWonStatusId,
  lead,
  showCreateTask,
  showCreateNote,
  showCreateTaskAndNoteResponsive,
}: {
  className?: string;
  dealLostStatusId: number;
  dealWonStatusId: number;
  lead: LeadPipelineItem;
  showCreateTask?: boolean;
  showCreateNote?: boolean;
  showCreateTaskAndNoteResponsive?: boolean;
}) {
  const { api } = useGymflowModels();
  const {
    changeLeadStatusMutation: { mutateAsync: changeStatus },
  } = useMutationLeadStatus({ api });

  const { notify, notifyDanger } = useContext(NotificationContext);

  const { setAlert, hide } = useContext(AlertContext);
  const { setModal, hide: hideModal } = useContext(ModalContext);

  const { clubId, timezone } = useClubSettings();
  const { data: club } = useClub({ clubId, api }, { staleTime: clubStaleTime });

  const { sendEmailToLeads } = useSendEmailsNew();
  const createTaskMutation = useTaskCreate({ api, tz: timezone });
  const { mutateAsync: deleteLeadMutate } = useMutationDeleteLead({ api });
  const { mutateAsync: deleteMemberMutate } = useMemberDelete({ api });
  const { triggerNewNote } = useContext(NoteFormSideBarProviderContext);
  const { openCreateTaskSidebar } = useContext(
    CreateEditTaskSidebarProviderContext,
  );
  const { data: featureFlags } = useClubFeatureFlags({ clubId, api });
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const onClickCreateTask = useCallback(async () => {
    setIsPopoverOpen(false);

    if (featureFlags?.featureFlags.PORTAL_REDESIGN_TASK) {
      const dataLead = await leadQueryFn({ api, leadId: lead.leadId });

      const { id: leadId, userMemberId, firstName, lastName } = dataLead;

      openCreateTaskSidebar({
        initialValues: {
          relatedUsers: [
            {
              id: userMemberId ?? leadId,
              label: `${firstName} ${lastName}`,
              value: dataLead,
              imageUrl: undefined, // lead does't have picture
            },
          ],
        },
      });
      return;
    }

    const { leadId, userMemberId, firstName, lastName } = lead;

    setAlert(
      <TaskAlert
        defaultRelatedLeads={
          !lead?.userMemberId
            ? [
                {
                  id: leadId,
                  firstName,
                  lastName,
                },
              ]
            : []
        }
        defaultRelatedUsers={
          lead?.userMemberId
            ? [
                {
                  id: userMemberId,
                  firstName,
                  lastName,
                },
              ]
            : []
        }
        onCancel={hide}
        onSubmit={async (values) => {
          await createTaskMutation.mutateAsync(values);
          notify({ message: "Task Created" });
          hide();
        }}
      />,
    );
  }, [
    api,
    createTaskMutation,
    featureFlags?.featureFlags.PORTAL_REDESIGN_TASK,
    hide,
    lead,
    notify,
    openCreateTaskSidebar,
    setAlert,
  ]);

  return (
    <Popover
      open={isPopoverOpen}
      onOpenChange={(e) => {
        setIsPopoverOpen(e);
      }}
    >
      <PopoverTrigger
        className={cn(
          buttonVariants({ size: "small", intent: "default" }),
          "mt-0 h-11 w-11 bg-white text-gray-700 hover:bg-gray-50",
          className,
        )}
      >
        <div className="flex items-center justify-center">
          <DotsVerticalIcon
            className="h-6 w-6"
            pathClassName="stroke-gray-700"
          />
        </div>
      </PopoverTrigger>
      <PopoverContent
        align="start"
        side="bottom"
        asChild
        className="flex w-fit flex-col p-2"
        hideWhenDetached
      >
        <div>
          <Can I={Verb.Create} a={Subject.Email}>
            <div
              className="flex cursor-pointer items-center gap-2 p-2 text-sm text-gray-700 hover:bg-gray-50"
              onClick={async () => {
                setIsPopoverOpen(false);
                setAlert(
                  <SendEmailAlertWithProvider
                    allowMarketing={lead.emailCommunication}
                    from={club?.email!}
                    to={`${lead.firstName} ${lead.lastName}`}
                    onSubmit={(values: any) => {
                      const bcc = values.bcc ? values.bcc.split(",") : [];
                      return sendEmailToLeads({
                        emailPayload: {
                          marketing: values.marketing,
                          body: values.body,
                          subject: values.subject,
                          bccList: bcc,
                        },
                        leadIds: [lead.leadId],
                      });
                    }}
                    onCancel={hide}
                  />,
                );
              }}
            >
              <MailIcon className="h-4 w-4" pathClassName="stroke-gray-700" />
              <div>Email</div>
            </div>
          </Can>
          {showCreateTaskAndNoteResponsive && (
            <div className="flex flex-col justify-center gap-1 p-2 text-sm text-gray-700 lg:hidden">
              <div>Change status to:</div>
              <PaginatedSelect
                value={{ label: lead.leadStatusName }}
                onChange={async (newValue) => {
                  await changeStatus({
                    leadId: lead.leadId,
                    newColumn: newValue.value,
                  });
                }}
                loadOptions={async () => {
                  const response = await leadStatusListQueryFn({ api });
                  return {
                    options: response
                      .sort((a, b) => {
                        return a.statusOrder - b.statusOrder;
                      })
                      .filter((e) => e.presetType !== "DEAL_CLOSED")
                      .filter((e) => e.presetType !== "DEAL_LOST")
                      .filter((e) => e.id !== lead.leadStatusId)
                      .map((source) => ({
                        value: source.id,
                        label: source.name,
                      })),
                    hasMore: false,
                  };
                }}
              />
            </div>
          )}
          {(showCreateTask || showCreateTaskAndNoteResponsive) && (
            <div
              className={cn(
                "flex cursor-pointer items-center gap-2 p-2 text-sm text-gray-700 hover:bg-gray-50",
                {
                  "flex lg:hidden": showCreateTaskAndNoteResponsive,
                },
              )}
              onClick={onClickCreateTask}
            >
              <CheckDoneIcon
                className="h-4 w-4"
                pathClassName="stroke-gray-700"
              />
              <div>Add Task</div>
            </div>
          )}

          {(showCreateNote || showCreateTaskAndNoteResponsive) && (
            <div
              className={cn(
                "flex cursor-pointer items-center gap-2 p-2 text-sm text-gray-700 hover:bg-gray-50",
                {
                  "flex lg:hidden": showCreateTaskAndNoteResponsive,
                },
              )}
              onClick={async () => {
                setIsPopoverOpen(false);
                triggerNewNote({
                  ...(lead?.userMemberId
                    ? {
                        userMemberId: lead.userMemberId,
                      }
                    : {
                        leadId: lead.leadId,
                      }),
                  name: `${lead.firstName} ${lead.lastName}`,
                });
              }}
            >
              <FilePlusIcon
                className="h-4 w-4"
                pathClassName="stroke-gray-700"
              />
              <div>Add Note</div>
            </div>
          )}
          <div
            className="flex cursor-pointer items-center gap-2 p-2 text-sm text-gray-700 hover:bg-gray-50"
            onClick={async () => {
              setIsPopoverOpen(false);
              try {
                await changeStatus({
                  leadId: lead.leadId,
                  newColumn: dealWonStatusId,
                });
              } catch (e) {
                notifyDanger(e);
              }
            }}
          >
            <div>
              <ThumbsUpIcon pathClassName="stroke-[#079455]" />
            </div>
            <div>Deal Won</div>
          </div>
          <div
            className="flex cursor-pointer items-center gap-2 p-2 text-sm text-gray-700 hover:bg-gray-50"
            onClick={async () => {
              setIsPopoverOpen(false);
              try {
                await changeStatus({
                  leadId: lead.leadId,
                  newColumn: dealLostStatusId,
                });
              } catch (e) {
                notifyDanger(e);
              }
            }}
          >
            <ThumbsDownIcon pathClassName="stroke-[#F04438]" />
            <div>Deal Lost</div>
          </div>
          <div
            className="flex cursor-pointer items-center gap-2 p-2 text-sm text-gray-700 hover:bg-gray-50"
            onClick={async () => {
              setIsPopoverOpen(false);
              if (lead.userMemberId) {
                setModal(
                  <ConfirmModal
                    title="Delete Member?"
                    onCancel={hideModal}
                    onConfirm={async () => {
                      try {
                        await deleteMemberMutate({
                          clubId,
                          memberId: lead.userMemberId!,
                        });

                        notify({ message: "Lead deleted." });
                      } catch (e) {
                        notifyDanger(e);
                      }
                      hideModal();
                    }}
                    confirmText="Delete"
                  >
                    This lead has a member associated with it. Deleting it will
                    also delete the member. Are you sure?
                  </ConfirmModal>,
                );
              } else {
                try {
                  await deleteLeadMutate({
                    leadId: lead.leadId,
                  });
                } catch (e) {
                  notifyDanger(e);
                }
                notify({ message: "Lead deleted." });
              }
            }}
          >
            <TrashIcon className="h-4 w-4" pathClassName="stroke-[#F04438]" />
            <div>Delete</div>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
}
