import { AlertProvider, NotificationProvider } from "@gymflow/common";
import { useContext } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import Footer from "../../components/Kiosk/Footer";
import { usePortalRoutes } from "../../hooks/usePortalRoutes";
import { AbilityContext, Verb } from "../../permissions";
import {
  ApiResolverProvider,
  AuthenticatedProvider,
  ClubSettingsProvider,
  PermissionsProvider,
  useApiResolverContext,
} from "../../providers";
import { kiosk as routes } from "../../routes";
import { RouteFeature } from "../../routes/feature";
import { RouteLayout } from "../../routes/layout";

function Kiosk({ match }) {
  const ability = useContext(AbilityContext);
  const { createClubLink } = usePortalRoutes();

  const defaultRoute = createClubLink(
    RouteLayout.Kiosk,
    RouteFeature.KioskCheckIn,
  );
  const getRoutes = () => {
    const nodes = routes.map((prop) => {
      const hasAccess =
        !prop.permission || ability.can(Verb.View, prop.permission);
      if (hasAccess) {
        return (
          <Route path={match.path + prop.path} key={prop.path}>
            {(props) => <prop.component {...props} />}
          </Route>
        );
      }
      return null;
    });
    nodes.push(
      <Route key={nodes.length}>
        <Redirect to={defaultRoute} />
      </Route>,
    );
    return nodes;
  };

  return (
    <div className="flex h-[100vh] w-full flex-col">
      <div>
        <NotificationProvider>
          <AlertProvider>
            <Switch>{getRoutes()}</Switch>
          </AlertProvider>
        </NotificationProvider>
      </div>
      <Footer />
    </div>
  );
}

function KioskWithAuth({ ...props }) {
  const { clubId } = useApiResolverContext();

  return (
    <ClubSettingsProvider clubId={clubId}>
      <PermissionsProvider allowedRoleCategory="STAFF">
        <Kiosk {...props} />
      </PermissionsProvider>
    </ClubSettingsProvider>
  );
}

function KioskWithClub(props) {
  return (
    <ApiResolverProvider userType="STAFF">
      {({ authController }) => (
        <AuthenticatedProvider authController={authController}>
          <KioskWithAuth {...props} />
        </AuthenticatedProvider>
      )}
    </ApiResolverProvider>
  );
}

export default KioskWithClub;
