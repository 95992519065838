import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import { AsyncButton } from '@gymflow/common';

function CancelAuthorizationAlert({ onConfirm, onCancel }) {
  const renderButtons = () => (
    <>
      <Button type="button" onClick={onCancel} className="btn btn-sm btn-outline-primary">
        Close
      </Button>
      <AsyncButton type="button" className="btn btn-sm btn-primary" onClick={onConfirm}>
        Cancel Authorization
      </AsyncButton>
    </>
  );

  return (
    <ReactBSAlert title="Cancel Authorization" closeOnClickOutside={false} customButtons={renderButtons()}>
      Are you sure you want to cancel the pending authorization request on this invoice?
    </ReactBSAlert>
  );
};

CancelAuthorizationAlert.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default CancelAuthorizationAlert;
