import classNames from "classnames";
import omit from "lodash/omit";
import { useMemo } from "react";
import { twMerge } from "tailwind-merge";

import { useProgressButton } from "../../hooks/useProgressButton";
import { Spinner } from "./Spinner";

interface ButtonProps extends Omit<React.HTMLProps<HTMLButtonElement>, "type"> {
  showSpinner?: boolean;
}
/*** @deprecated use Button intent=secondary instead */
export function AccentButton({
  children,
  className,
  showSpinner,
  ...props
}: ButtonProps) {
  const { disabled, onClick, inProgress } = useProgressButton({
    onClick: props.onClick,
    disabled: props.disabled,
  });
  const buttonProps = useMemo(
    () => omit(props, ["disabled", "onClick"]),
    [props],
  );

  return (
    <button
      type="button"
      className={twMerge(
        "hover:bg-accent/90 bg-accent mt-2 flex h-11 cursor-pointer items-center justify-center rounded-lg px-4 font-semibold capitalize text-white",
        className,
      )}
      onClick={onClick}
      disabled={disabled}
      {...buttonProps}
    >
      <Spinner
        className={classNames(
          { hidden: !inProgress && !showSpinner },
          "absolute text-white",
        )}
      />
      <div className={classNames({ invisible: inProgress || showSpinner })}>
        {children}
      </div>
    </button>
  );
}
