import { ChangedEventRsvp, EventRsvpStatus } from "@gymflow/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { calendarQueryKeys } from "../calendar";

import { eventOccurrenceQueryKeys } from "./eventOccurrenceQueryKeys";

export function useEventOccurrenceRsvp({
  api,
}: {
  api: {
    eventApi: {
      updateRsvp: (rsvpId: number, status: EventRsvpStatus) => Promise<any>;
      addAttendeeToRsvp: (
        userMemberId: string,
        occurrenceId: number,
      ) => Promise<any>;
      checkInAll: (occurrenceId: number) => Promise<any>;
    };
  };
}) {
  const queryClient = useQueryClient();

  const updateRsvpMutation = useMutation({
    mutationFn: async ({
      rsvpId,
      status,
    }: {
      rsvpId: number;
      status: EventRsvpStatus;
    }) => {
      const result = await api.eventApi.updateRsvp(rsvpId, status);
      return result.data as {
        invoiceNumber?: string;
        eventRsvpDTO: ChangedEventRsvp;
      };
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: eventOccurrenceQueryKeys.all(),
      });
      queryClient.invalidateQueries({
        queryKey: calendarQueryKeys.eventOccurrences(),
      });
    },
  });

  const addAttendeeToRsvpMutation = useMutation({
    mutationFn: async ({
      userMemberId,
      occurrenceId,
    }: {
      userMemberId: string;
      occurrenceId: number;
    }) => {
      const response = await api.eventApi.addAttendeeToRsvp(
        userMemberId,
        occurrenceId,
      );
      return response.data as ChangedEventRsvp;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: eventOccurrenceQueryKeys.all(),
      });
      queryClient.invalidateQueries({
        queryKey: calendarQueryKeys.eventOccurrences(),
      });
    },
  });

  const checkInAllMutation = useMutation({
    mutationFn: async ({ occurrenceId }: { occurrenceId: number }) => {
      await api.eventApi.checkInAll(occurrenceId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: eventOccurrenceQueryKeys.all(),
      });
      queryClient.invalidateQueries({
        queryKey: calendarQueryKeys.eventOccurrences(),
      });
    },
  });

  return {
    updateRsvp: updateRsvpMutation,
    addAttendeeToRsvp: addAttendeeToRsvpMutation,
    checkInAll: checkInAllMutation,
  };
}
