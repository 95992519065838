import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  useActivityCategoryList,
  useAppointableCategories,
  useSessionPack,
  useSessionPackDisable,
  useSessionPackEdit,
  useSessionPackEnable,
  useSessionPackList,
} from "@gymflow/api";
import { isMobile } from "@gymflow/common";
import { formatCurrency } from "@gymflow/helpers";
import { createColumnHelper, SortingState } from "@tanstack/react-table";
import { usePageSize } from "apps/portal/src/hooks";
import classNames from "classnames";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useWindowSize } from "usehooks-ts";

import { useClubSettings } from "../../../providers";
import useGymflowModels from "../../../store";
import { Badge, Button, PaginatedSelect, Switch } from "../../atoms";
import { PaginatedTable } from "../../organisms/PaginatedTable";
import { SettingsContainer } from "../SettingsContainer";
import { CreditPackFormSidebarProviderContext } from "./CreditPackFormSidebarProvider";

const tabs = ["classPack", "appointmentPack"] as const;
const typeToString: { [key in (typeof tabs)[number]]: string } = {
  classPack: "Class Packs",
  appointmentPack: "Appointment Packs",
};
const filters: {
  [key in (typeof tabs)[number]]: {};
} = {
  classPack: {
    hasActivityCategory: true,
  },
  appointmentPack: {
    hasAppointableCategory: true,
  },
};
export function CreditPackTab() {
  const [currentPage, setCurrentPage] = useState(0);
  const [showingStatus, setShowingStatus] = useState<"ACTIVE" | "INACTIVE">(
    "ACTIVE",
  );
  const [showingType, setShowingType] =
    useState<(typeof tabs)[number]>("classPack");
  const [sort, setSort] = useState<SortingState>([]);

  const { api } = useGymflowModels();

  const settings = useClubSettings();
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const pageSize = usePageSize({
    tableContainerRef,
    rowHeight: 56,
  });

  const { data, isLoading } = useSessionPackList({
    api,
    opts: {
      page: currentPage,
      limit: pageSize,
      sort: sort?.[0]
        ? {
            field: sort[0].id === "card" ? "name" : sort[0].id,
            desc: sort[0].desc,
          }
        : { field: "createdDate", desc: true },
      extraParams: {
        status: showingStatus,
        ...filters[showingType],
      },
    },
  });

  const [editingCreditPackId, setEditingCreditPackId] = useState<number>();

  const { data: editing } = useSessionPack({
    api,
    sessionPackId: editingCreditPackId,
  });
  const { data: activityCategories } = useActivityCategoryList({
    api,
    filter: {
      limit: 100,
      page: 0,
    },
  });
  const { data: appointableCategories } = useAppointableCategories({
    api,
    paginationOption: {
      page: 0,
      size: 100,
    },
  });
  const editSessionPack = useSessionPackEdit({ api });

  const enableSessionPack = useSessionPackEnable({ api });
  const disableSessionPack = useSessionPackDisable({ api });
  const { showCreditPackForm } = useContext(
    CreditPackFormSidebarProviderContext,
  );
  const showAlert = useCallback(() => {
    showCreditPackForm({
      currentCreditPack: editing ?? undefined,
      type: showingType,
      onClose: () => {
        setEditingCreditPackId(undefined);
      },
    });
  }, [editing, showingType, showCreditPackForm]);

  useEffect(() => {
    if (editingCreditPackId && editing) {
      showAlert();
    }
  }, [editing, editingCreditPackId, showAlert]);

  const columnHelper = createColumnHelper<(typeof data)["content"][number]>();

  const actionButtons = (item: number) => {
    return (
      <>
        <div
          className="text-primary-700 cursor-pointer text-sm font-semibold"
          onClick={() => {
            setEditingCreditPackId(item);
          }}
        >
          Edit
        </div>
        <div
          className="text-warning-600 cursor-pointer text-sm font-semibold"
          onClick={() => {
            if (showingStatus === "ACTIVE") {
              disableSessionPack.mutate(item);
            } else {
              enableSessionPack.mutate(item);
            }
          }}
        >
          {showingStatus === "ACTIVE" ? "Disable" : "Enable"}
        </div>
      </>
    );
  };
  const columnsDesktop = [
    columnHelper.accessor("name", {}),
    columnHelper.accessor("price", {
      id: "price",
      cell: (cell) => {
        return formatCurrency(cell.getValue(), settings.defaultCurrency);
      },
    }),
    columnHelper.accessor(
      (e) => ({
        sessionsIncluded: e.sessionsIncluded,
        sessionsUnlimited: e.sessionsUnlimited,
      }),
      {
        id: "sessionsIncluded",
        header: () => <div>Credits</div>,
        cell: (cell) => {
          return cell.getValue().sessionsUnlimited
            ? "∞"
            : `${cell.getValue().sessionsIncluded}`;
        },
      },
    ),
    ...(showingType === "classPack"
      ? [
          columnHelper.accessor("activityCategoryIdList", {
            header: "Class Category",
            cell: (cell) => {
              return (
                <div className="flex flex-row gap-2 overflow-x-auto">
                  {cell.row.original.activityCategoryIdList?.map((id) => (
                    <Badge key={id}>
                      {
                        activityCategories?.content.find((e) => e.id === id)
                          ?.name
                      }
                    </Badge>
                  ))}
                </div>
              );
            },
          }),
        ]
      : [
          columnHelper.accessor("appointableCategoryIdList", {
            header: "Class Category",
            cell: (cell) => {
              return (
                <div className="flex flex-row gap-2 overflow-x-auto">
                  {cell.row.original.appointableCategoryIdList?.map((id) => (
                    <Badge key={id}>
                      {
                        appointableCategories?.content.find((e) => e.id === id)
                          ?.name
                      }
                    </Badge>
                  ))}
                </div>
              );
            },
          }),
        ]),
    columnHelper.accessor(
      (e) => ({
        isPublic: e.isPublic,
        id: e.id,
      }),
      {
        id: "isPublic",
        header: () => <div>On Web</div>,
        cell: (column) => {
          const value = column.getValue();
          return (
            <Switch
              value={!!value.isPublic}
              onChange={(newValue) => {
                editSessionPack.mutate({
                  sessionPackId: value.id,
                  patchedFields: {
                    isPublic: newValue,
                  },
                });
              }}
            />
          );
        },
      },
    ),
    columnHelper.accessor("id", {
      header: () => <div>Actions</div>,
      cell: (column) => {
        const value = column.getValue();
        return (
          <div className="flex flex-row items-center gap-x-2">
            {actionButtons(value)}
          </div>
        );
      },
      enableSorting: false,
    }),
  ];
  const mobileColumns = [
    columnHelper.accessor((e) => e, {
      id: "card",
      header: () => <div>Name</div>,
      cell: (column) => {
        const item = column.getValue();
        return (
          <div className="flex w-full flex-col justify-center gap-y-2 py-2">
            <div>
              <div className="text-lg font-semibold">{item.name}</div>
              <div className="text-sm text-gray-600">
                {formatCurrency(item.price, settings.defaultCurrency)}
              </div>
              <div className="text-sm text-gray-600">
                {item.sessionsUnlimited ? "∞" : `${item.sessionsIncluded}`}{" "}
                Credits
              </div>
            </div>
            <div className="flex flex-row items-center gap-x-3">
              {actionButtons(item.id)}
            </div>
          </div>
        );
      },
    }),
  ];

  const windowSize = useWindowSize({ debounceDelay: 100 });
  return (
    <SettingsContainer
      title="Credit Packs"
      subTitle="Create, Update and manage credit packs for bookings."
      actions={
        <>
          <PaginatedSelect
            className="h-11 bg-white"
            placeholder="Enabled"
            value={[
              {
                label: "Enabled",
                value: "ACTIVE",
              },
              {
                label: "Disabled",
                value: "INACTIVE",
              },
            ].find((e) => e.value === showingStatus)}
            onChange={({ value }) => setShowingStatus(value)}
            loadOptions={async () => {
              return {
                options: [
                  {
                    label: "Enabled",
                    value: "ACTIVE",
                  },
                  {
                    label: "Disabled",
                    value: "INACTIVE",
                  },
                ],
              };
            }}
          />
          <Button
            intent="secondary"
            className="text-white"
            onClick={() => {
              showAlert();
            }}
          >
            <div className="flex flex-row items-center gap-x-2">
              <FontAwesomeIcon icon={faPlus} />
              <div>Credit Pack</div>
            </div>
          </Button>
        </>
      }
    >
      <div className="flex flex-row gap-x-2 border-b border-gray-200">
        {tabs.map((e) => (
          <div
            key={e}
            onClick={() => setShowingType(e)}
            className={classNames(
              "max-w-min cursor-pointer flex-nowrap whitespace-nowrap px-1 pb-3 text-sm font-semibold text-gray-500",
              {
                "text-accent border-accent border-b-2 ": showingType === e,
              },
            )}
          >
            {typeToString[e]}
          </div>
        ))}
      </div>
      <PaginatedTable
        tableProps={{
          data: data?.content ?? [],
          columns: isMobile(windowSize.width) ? mobileColumns : columnsDesktop,
          pageCount: data?.totalPages,
          pageIndex: data?.number,
          onSortingChange: setSort,
          sort: sort,
          isFetching: isLoading,
          pageSize: pageSize,
          tableContainerRef,
        }}
        hasNextPage={!!data && data?.number < data?.totalPages - 1}
        hasPreviousPage={!!data && data?.number > 0}
        goToNextPage={() => {
          setCurrentPage(currentPage + 1);
        }}
        goToPreviousPage={() => {
          setCurrentPage(currentPage - 1);
        }}
      />
    </SettingsContainer>
  );
}
