import React from "react";
import styled from "styled-components";

/**
 * @deprecated rewrite in tailwind
 */
const Card = styled.div`
  min-width: 250px;
  min-height: 150px;
  background: linear-gradient(to bottom right, #384c79, #192237);
  border-radius: 8px;
  padding: 16px;
  color: white;

  & .brand {
    height: 48px;
    font-size: 1.25em;
  }

  & .series-number {
    height: 20px;
    color: white;
  }

  & .user-info {
    margin-top: 24px;
  }
`;

/**
 * @deprecated rewrite in tailwind
 */
const Dot = styled.div`
  width: 6px;
  height: 6px;
  background: white;
  border-radius: 50%;
`;

const PaymentCard = ({ brand, cardHolderName, expire, last4Digits }) => {
  return (
    <Card>
      <div className="brand">{brand}</div>
      <div className="d-flex align-items-center series-number">
        <Dot className="mr-1" />
        <Dot className="mr-1" />
        <Dot className="mr-1" />
        <Dot className="mr-4" />
        <Dot className="mr-1" />
        <Dot className="mr-1" />
        <Dot className="mr-1" />
        <Dot className="mr-4" />
        <Dot className="mr-1" />
        <Dot className="mr-1" />
        <Dot className="mr-1" />
        <Dot className="mr-4" />
        <span style={{ letterSpacing: "4px" }}>{last4Digits}</span>
      </div>
      <div className="d-flex justify-content-between font-size-sm user-info">
        <div>
          <div className="text-muted">Card Holder</div>
          <div>{cardHolderName}</div>
        </div>
        <div>
          <div className="text-muted">Expires</div>
          <div>{expire}</div>
        </div>
      </div>
    </Card>
  );
};

export default PaymentCard;
