import React, { useCallback, useMemo } from "react";
import PropTypes, { InferProps } from "prop-types";
import classNames from "classnames";
import {
  Form,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormGroup,
} from "reactstrap";
import { useFormik } from "formik";
import ReactBSAlert from "react-bootstrap-sweetalert";
import * as Yup from "yup";
import {
  AsyncButton,
  FormikInput,
  formikHelpers,
  FormMapper,
  renderErrorRowOnTouch,
  useRecordForm,
} from "@gymflow/common";
import { currencies, formatCurrency, SYMBOL_PLACEMENT } from "@gymflow/helpers";
import noop from "lodash/noop";
import isEmpty from "lodash/isEmpty";
import styled from "styled-components";

const mapper = new FormMapper();

const AMOUNT = "amount";

/**
 * @deprecated rewrite in tailwind
 */
const Button = styled.button`
  margin-right: 8px;
`;

/**
 * @deprecated rewrite in tailwind
 */
const SubmitButton = styled(AsyncButton)`
  color: white;
  borderColor: rgb(40, 96, 144),
  box-shadow: rgba(0, 0, 0, 0.075) 0px 1px 1px inset, rgb(165, 202, 234) 0px 0px 8px
`;

/**
 * @param {InferProps<AdjustInvoiceAlert.propTypes>} props
 * @returns {React.ReactElement}
 */
function AdjustInvoiceAlert({
  onCancel,
  onConfirm,
  chargeableAmount,
  totalAmount,
  title,
  text,
  confirmButtonText,
  currency,
}) {
  const calculateDiff = useCallback(
    (value) => {
      return value - chargeableAmount;
    },
    [chargeableAmount, totalAmount],
  );

  const schema = useMemo(
    () =>
      Yup.object().shape({
        [AMOUNT]: Yup.number()
          .required()
          .min(0)
          .default(chargeableAmount)
          .test(function (value) {
            const { createError } = this;

            if (value > totalAmount) {
              return createError({
                message:
                  "Amount must be less than or equal to " +
                  formatCurrency(totalAmount, currency) +
                  ".",
              });
            }
            return true;
          })
          .test(function (value) {
            const { createError } = this;

            if (calculateDiff(value) === 0) {
              return createError({
                message:
                  "This value would result in no changes to the invoice.",
              });
            }
            return true;
          }),
      }),
    [calculateDiff, totalAmount],
  );

  const { initialValues } = useRecordForm({
    fields: schema.default(),
    record: null,
    mapper,
  });

  const formikProps = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: noop,
  });
  const { errorClass } = formikHelpers(formikProps);
  const { validateForm, errors, touched, values, isValid } = formikProps;

  const renderAmountToApply = () => {
    if (!isValid) {
      return null;
    }

    const diff = calculateDiff(values[AMOUNT]);
    if (diff === 0) {
      return null;
    }
    if (diff < 0) {
      return (
        <label style={{ color: "#f05519" }}>
          Amount due will decrease by {formatCurrency(Math.abs(diff), currency)}
          .
        </label>
      );
    }
    return (
      <label style={{ color: "#f05519" }}>
        Amount due will increase by {formatCurrency(Math.abs(diff), currency)}.
      </label>
    );
  };

  const renderButtons = () => (
    <>
      <Button
        type="button"
        onClick={onCancel}
        className="btn btn-sm btn-outline-primary"
      >
        Cancel
      </Button>
      <SubmitButton
        type="button"
        className="btn btn-sm btn-primary"
        onClick={async () => {
          formikProps.submitForm();
          const fieldErrors = await validateForm(values);
          const isValidated = isEmpty(fieldErrors);
          if (isValidated) {
            await onConfirm({ amount: calculateDiff(values[AMOUNT]) });
          }
        }}
      >
        {confirmButtonText}
      </SubmitButton>
    </>
  );

  const renderInput = () => {
    const { symbolPlacement: placement, symbol } = currencies[currency];
    return (
      <>
        <div className="ml-auto mr-auto" style={{ maxWidth: "130px" }}>
          <InputGroup>
            {placement === SYMBOL_PLACEMENT.Before && (
              <InputGroupAddon addonType="prepend">
                <InputGroupText>{symbol}</InputGroupText>
              </InputGroupAddon>
            )}

            <FormikInput
              name={AMOUNT}
              type="number"
              data-testid={AMOUNT}
              autoComplete="off"
              maxLength="8"
              formikProps={formikProps}
              showErrorLabel={false}
            />

            {placement === SYMBOL_PLACEMENT.After && (
              <InputGroupAddon addonType="append">
                <InputGroupText>{symbol}</InputGroupText>
              </InputGroupAddon>
            )}
          </InputGroup>
        </div>
        {renderErrorRowOnTouch(AMOUNT, touched, errors)}
      </>
    );
  };

  return (
    <ReactBSAlert
      customButtons={renderButtons()}
      title={title}
      showCancel
      confirmBtnText={confirmButtonText}
      onConfirm={noop}
      onCancel={noop}
      closeOnClickOutside={false}
    >
      <Form className="form-horizontal" role="form">
        <Row className="mt-3">
          <Col>{text}</Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <FormGroup
              className={classNames("mr-auto", "ml-auto", errorClass(AMOUNT))}
            >
              {renderInput()}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>{renderAmountToApply()}</Col>
        </Row>
      </Form>
    </ReactBSAlert>
  );
}

AdjustInvoiceAlert.defaultProps = {
  confirmButtonText: "Apply",
  text: "Enter the final amount you would like billed",
  title: "Change Amount Due",
};

AdjustInvoiceAlert.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  chargeableAmount: PropTypes.number.isRequired,
  totalAmount: PropTypes.number.isRequired,
  title: PropTypes.string,
  text: PropTypes.string,
  confirmButtonText: PropTypes.string,
  currency: PropTypes.string.isRequired,
};

export default AdjustInvoiceAlert;
