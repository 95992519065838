import { useLeadReport, useLeadReportCsv } from "@gymflow/api";
import { DATE_FORMAT } from "@gymflow/common";
import { downloadCsv, iso8601WithoutTz } from "@gymflow/helpers";
import { LeadReportItemDTO, PresetLeadStatusType } from "@gymflow/types";
import { createColumnHelper, SortingState } from "@tanstack/react-table";
import { add, format, parse } from "date-fns";
import moment from "moment";
import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router";

import { LinkButton } from "../../components/atoms";
import { ReportPagination } from "../../components/molecules";
import { Table } from "../../components/organisms";
import { Report } from "../../components/organisms/Report";
import {
  LeadFilter,
  LeadFilterSideBar,
} from "../../components/templates/LeadFilterSideBar";
import { usePortalRoutes } from "../../hooks/usePortalRoutes";
import useQueryParam from "../../hooks/useQueryParam";
import { useClubSettings } from "../../providers";
import useGymflowModels from "../../store";

export interface LeadReportProps {
  leadStatus: PresetLeadStatusType;
}

export function LeadStatus({ leadStatus }: LeadReportProps) {
  const { createLeadLink, createMemberLink } = usePortalRoutes();
  const history = useHistory();
  const { api } = useGymflowModels();

  const settings = useClubSettings();

  const location = useLocation();
  const searchParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const defaultDates = useMemo(
    () => ({
      startDate: moment().startOf("month").format(DATE_FORMAT),
      endDate: moment().format(DATE_FORMAT),
    }),
    [],
  );
  const [dates, setDates] = useQueryParam("dates", "string") as any;

  useEffect(() => {
    if (!dates) {
      setDates(defaultDates);
    }
  }, []);

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState(25);
  const [currentSort, setCurrentSort] = useState<SortingState>([]);
  const [filters, setFilters] = useState<LeadFilter>({
    sources: [],
  });

  const createSort = function () {
    return currentSort?.[0]
      ? { field: currentSort[0]?.id, desc: currentSort[0]?.desc }
      : undefined;
  };
  const { data, isFetching } = useLeadReport({
    api,
    startDate: dates
      ? format(
          parse(dates.startDate, "dd/MM/yyyy", new Date()),
          iso8601WithoutTz,
        )
      : undefined,
    endDate: dates
      ? format(
          add(parse(dates.endDate, "dd/MM/yyyy", new Date()), {
            days: 1,
            seconds: -1,
          }),
          iso8601WithoutTz,
        )
      : undefined,
    sort: createSort(),
    presetLeadStatus: leadStatus,
    leadSourceList: filters.sources.map((e) => e.value),
    limit: pageSize,
    page: currentPage,
    tz: settings.timezone,
  });
  const downloadCsvMutation = useLeadReportCsv({
    api,
    tz: settings.timezone,
  });

  const [filtersAreVisible, setFiltersAreVisible] = useState(false);

  const columnHelper = createColumnHelper<LeadReportItemDTO>();
  const columnsDefinition = [
    columnHelper.accessor(
      (row) => {
        return {
          firstName: row.firstName,
          lastName: row.lastName,
          id: row.leadId,
          profileType: row.profileType,
          userMemberId: row.userMemberId,
        };
      },
      {
        id: "name",
        cell: (info) => {
          const memberName = `${info.getValue().firstName} ${
            info.getValue().lastName
          }`;
          return (
            <div>
              <LinkButton
                to={() => {
                  if (info.getValue().profileType === "USER") {
                    return createMemberLink(info.getValue().userMemberId);
                  } else {
                    return createLeadLink(info.getValue().id);
                  }
                }}
              >
                {memberName}
              </LinkButton>
            </div>
          );
        },
        header: () => {
          return <div >Name</div>;
        },
        enableSorting: false,
      },
    ),
    columnHelper.accessor("mobileNumber", {
      cell: (info) => <div className="text-gray-600">{info.getValue()}</div>,
      header: "Phone Number",
      enableSorting: false,
    }),
    columnHelper.accessor("source", {
      cell: (info) => {
        return <div className="text-gray-600">{info.getValue()}</div>;
      },
      header: "Lead Source",
      enableSorting: false,
    }),
    columnHelper.accessor("createdDate", {
      cell: (info) => (
        <div className="text-gray-600">
          {format(new Date(info.getValue()), "P")}
        </div>
      ),
      header: "Created Date",
      enableSorting: false,
    }),
  ];
  const handleDateRangeChange = useCallback(
    (newDate: any) => {
      if (newDate) {
        setDates(newDate);
      }
    },
    [setDates],
  );
  return (
    <>
      <LeadFilterSideBar
        isVisible={filtersAreVisible}
        onClose={() => setFiltersAreVisible(false)}
        value={filters}
        onChange={setFilters}
      />
      <Report
        title={
          {
            NEW_LEAD: "New Leads",
            TRIAL: "Trial Leads",
            DEAL_CLOSED: "Closed Leads",
            DEAL_LOST: "Lost Leads",
          }[leadStatus]
        }
        description={
          {
            NEW_LEAD:
              'Count of all leads with a "New Lead" status created within the date range selected.',
            TRIAL: "",
            DEAL_CLOSED:
              'Count of all leads with a "Deal Closed" status created within the date range selected. ',
            DEAL_LOST:
              'Count of all leads with a "Deal Lost" status created within the date range selected.',
          }[leadStatus]
        }
        onDownloadClick={async () => {
          const data = await downloadCsvMutation.mutateAsync({
            startDate: format(
              parse(dates?.startDate, "dd/MM/yyyy", new Date()),
              iso8601WithoutTz,
            ),
            endDate: format(
              add(parse(dates.endDate, "dd/MM/yyyy", new Date()), {
                days: 1,
                seconds: -1,
              }),
              iso8601WithoutTz,
            ),
            presetLeadStatus: leadStatus,
            leadSourceList: filters.sources.map((e) => e.value),
          });
          const reportBaseFileNamesMap = {
            NEW_LEAD: "NewLeads",
            TRIAL: "TrialLeads",
            DEAL_CLOSED: "ClosedLeads",
            DEAL_LOST: "LostLeads",
          };
          downloadCsv(data, `${reportBaseFileNamesMap[leadStatus]}.csv`);
        }}
        showFilters={() => setFiltersAreVisible(true)}
        handleDateRangeChange={handleDateRangeChange}
        dateRange={
          dates?.startDate && dates?.endDate
            ? {
                startDate: dates.startDate,
                endDate: dates.endDate,
              }
            : undefined
        }
        close={() => {
          history.push({
            pathname: searchParams["backRoute"] as string,
            search: qs.stringify({
              dates: {
                startDate: dates?.startDate,
                endDate: dates?.endDate,
              },
            }),
          });
        }}
        table={
          <>
            <Table
              data={data?.leadReportByPresetStatusViewDTO.content ?? []}
              columns={columnsDefinition}
              pageCount={data?.leadReportByPresetStatusViewDTO.totalPages}
              pageIndex={data?.leadReportByPresetStatusViewDTO.number}
              onSortingChange={setCurrentSort}
              sort={currentSort}
              isFetching={isFetching}
              pageSize={pageSize}
              rowClassName="h-14"
            />
            <div className="border-t-[0.063rem] border-gray-300">
              <ReportPagination
                pageCount={
                  data?.leadReportByPresetStatusViewDTO.totalPages as number
                }
                currentPage={
                  data?.leadReportByPresetStatusViewDTO.number as number
                }
                onPageChange={(newPage) => {
                  setCurrentPage(newPage);
                }}
                pageSize={pageSize}
                setPageSize={(newValue) => {
                  setPageSize(newValue);
                  setCurrentPage(0);
                }}
              />
            </div>
          </>
        }
      />
    </>
  );
}
