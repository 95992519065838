import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  useActivityCategoryListAsPublic,
  useAppointableCategoriesAsPublic,
} from "@gymflow/api";
import { cn } from "@gymflow/helpers";
import { PopoverClose } from "@radix-ui/react-popover";
import useGymflowModels from "apps/portal/src/store";
import React from "react";

import {
  Button,
  FilterIcon,
  LabeledForm,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../atoms";
import { BadgePicker } from "../../atoms/BadgePicker";
import { RefreshCcwIcon } from "../../atoms/icons/RefreshCcwIcon";

export type CreditPackType = "CLASSES" | "APPOINTMENTS";
export type HostedPagesCreditPacksFilterPopoverProps = {
  filter: {
    appointableCategories: number[];
    classCategories: number[];
  };
  onChange: (
    newFilter: HostedPagesCreditPacksFilterPopoverProps["filter"],
  ) => void;
  type: CreditPackType;
};

export const HostedPagesCreditPacksFilterPopover: React.FC<
  HostedPagesCreditPacksFilterPopoverProps
> = ({ filter, onChange, type }) => {
  const { api } = useGymflowModels();
  const { data: appointableCategories } = useAppointableCategoriesAsPublic({
    api,
    paginationOption: { page: 0, size: 200 },
    filter: {
      statusList: ["ACTIVE"],
    },
  });
  const { data: classCategories } = useActivityCategoryListAsPublic({
    api,
    filter: {
      limit: 200,
      statusList: ["ACTIVE"],
    },
  });
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button className="relative">
          <div className="flex flex-row items-center gap-1">
            <FilterIcon pathClassName="stroke-gray-950 dark:stroke-white" />
            <div className="hidden font-medium text-gray-950 dark:text-white lg:flex">
              Filter
            </div>
          </div>
          <div
            className={cn(
              "bg-secondary-500 absolute right-1 top-1 h-2 w-2 rounded-full transition-all duration-200",
              {
                "opacity-0":
                  type === "CLASSES"
                    ? filter.classCategories.length === 0
                    : filter.appointableCategories.length === 0,
              },
            )}
          />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className="flex w-fit max-w-[90vw] flex-col lg:max-w-lg"
        align="end"
      >
        <div className="flex h-12 flex-row items-center justify-between px-4 pt-4">
          <div className="text-lg font-medium text-gray-950 dark:text-white">
            Filters
          </div>
          <div className="flex items-center gap-3">
            {!(type === "CLASSES"
              ? filter.classCategories.length === 0
              : filter.appointableCategories.length === 0) && (
              <Button
                intent="transparent"
                className="flex h-8 items-center gap-1"
                onClick={() => {
                  onChange({
                    appointableCategories: [],
                    classCategories: [],
                  });
                }}
              >
                <RefreshCcwIcon className="h-5 w-5" />
                <div className="text-sm font-semibold">Reset</div>
              </Button>
            )}
            <PopoverClose className="h-5 w-5" asChild>
              <FontAwesomeIcon
                icon={faClose}
                className="h-5 w-5 text-gray-500"
              />
            </PopoverClose>
          </div>
        </div>
        <div className="flex max-w-full p-4 pt-0">
          {type === "APPOINTMENTS" ? (
            <LabeledForm label="Appointable Category" className="gap-y-2">
              <BadgePicker
                className="max-w-[80vw] lg:max-w-full"
                isMulti
                hasAllOption
                options={appointableCategories.content.map((e) => ({
                  label: e.name,
                  value: e.id,
                }))}
                onChange={(newValue) => {
                  onChange({
                    ...filter,
                    appointableCategories: newValue,
                  });
                }}
                value={filter.appointableCategories}
              />
            </LabeledForm>
          ) : (
            <LabeledForm label="Class Category" className="gap-y-2">
              <BadgePicker
                className="max-w-[80vw] lg:max-w-full"
                isMulti
                hasAllOption
                options={classCategories.content.map((e) => ({
                  label: e.name,
                  value: e.id,
                }))}
                onChange={(newValue) => {
                  onChange({
                    ...filter,
                    classCategories: newValue,
                  });
                }}
                value={filter.classCategories}
              />
            </LabeledForm>
          )}
        </div>
      </PopoverContent>
    </Popover>
  );
};
