import { useContext } from "react";

import {
  AddPaymentMethodModal,
  AddPaymentMethodModalProps,
} from "../components/molecules";
import { ModalContext } from "../providers";

export const useAddPaymentMethodAlert = ({
  userMember,
  onConfirm,
  asMember,
}: Partial<AddPaymentMethodModalProps>) => {
  const { stackModal, popModal } = useContext(ModalContext);
  const showAddPaymentMethodAlert = () => {
    if (!userMember) return;
    return stackModal(
      <AddPaymentMethodModal
        userMember={userMember}
        onConfirm={async (newPaymentMethodId) => {
          onConfirm?.(newPaymentMethodId);
          popModal();
        }}
        asMember={asMember}
      />,
    );
  };
  return showAddPaymentMethodAlert;
};
