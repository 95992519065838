import { RuleClubDTO } from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { ruleAsPublicQueryKeys } from "./ruleAsPublicQueryKeys";

export function useRuleValuesAsPublic(
  {
    api,
    clubId,
  }: {
    api: {
      public: {
        ruleApi: { getValuesByClubId: (clubId: number) => Promise<any> };
      };
    };
    clubId: number;
  },
  opts?: UseQueryOptions<RuleClubDTO[]>,
) {
  const result = useQuery({
    queryKey: ruleAsPublicQueryKeys.values(clubId),
    queryFn: async () => {
      const result = await api.public.ruleApi.getValuesByClubId(clubId);
      return result.data as RuleClubDTO[];
    },
    enabled: !!clubId,
    initialData: [],
    ...opts,
  });

  return result;
}
