import { useAutoAnimate } from "@formkit/auto-animate/react";
import { faAdd, faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAddPaymentMethodAlert } from "apps/portal/src/hooks/useAddPaymentMethodAlert";

import {
  useMemberPaymentMethod,
  useQueryMemberPaymentMethodListNew,
} from "../../../../../../libs/api/src";
import { cn } from "../../../../../../libs/helpers/src";
import {
  PaymentMethodDTO,
  UserMemberBean,
} from "../../../../../../libs/types/src";
import { useClubSettings } from "../../../providers";
import useGymflowModels from "../../../store";
import { Button, SlideSideBar, Spinner, TrashIcon } from "../../atoms";
import { StarIcon } from "../../atoms/icons/StarIcon";

export type UserMemberPaymentMethodSidebarProps = {
  userMember: UserMemberBean;
  hide: () => void;
  isOpen: boolean;
};

export const UserMemberPaymentMethodSidebar = ({
  userMember,
  hide,
  isOpen,
}: UserMemberPaymentMethodSidebarProps) => {
  const { api } = useGymflowModels();
  const settings = useClubSettings();
  const { data: paymentMethods, isLoading: isLoadingPaymentMethods } =
    useQueryMemberPaymentMethodListNew({
      api,
      memberId: userMember.id,
      clubId: settings.clubId,
      enabled: true,
    });

  const showAddPaymentMethodAlert = useAddPaymentMethodAlert({ userMember });

  return (
    <SlideSideBar
      unmount={false}
      className="w-[28rem]"
      isOpen={isOpen}
      hide={hide}
    >
      <div className="flex flex-col justify-between border-b border-gray-200 p-8">
        <div className="flex flex-row items-center justify-between">
          <div className="text-xl font-semibold text-gray-900">
            Payment Methods
          </div>

          <FontAwesomeIcon
            onClick={() => {
              hide();
            }}
            className="cursor-pointer text-xl text-gray-600"
            icon={faClose}
          />
        </div>
        <div className="text-sm font-medium text-gray-600">
          Add or remove payment methods.
        </div>
      </div>
      <div className="flex h-full max-h-full flex-col gap-3 p-8 pt-4">
        <Button
          onClick={() => {
            showAddPaymentMethodAlert();
          }}
          intent="secondary-outline"
          className="flex flex-row gap-1"
        >
          <FontAwesomeIcon icon={faAdd} className="text-secondary-500" />
          Add Card
        </Button>
        <div className="relative flex h-full max-h-full w-full">
          {isLoadingPaymentMethods && <Spinner />}
          <PaymentMethodList
            paymentMethods={paymentMethods}
            userMember={userMember}
          />
        </div>
      </div>
    </SlideSideBar>
  );
};
const PaymentMethodList = ({
  userMember,
  paymentMethods,
}: {
  userMember: UserMemberBean;
  paymentMethods?: PaymentMethodDTO[];
}) => {
  const { api } = useGymflowModels();
  const settings = useClubSettings();
  const { removePaymentMethodMutation, assignDefaultPaymentMethodMutation } =
    useMemberPaymentMethod({
      api,
    });
  const [parent] = useAutoAnimate();
  if (!paymentMethods) return undefined;

  return (
    <div
      ref={parent}
      className="absolute inset-0 -mx-8 flex flex-col gap-2 overflow-y-auto px-8"
    >
      {paymentMethods
        .sort((a, b) =>
          a.defaultPaymentMethod
            ? -1
            : b.defaultPaymentMethod
            ? 1
            : a.id.localeCompare(b.id),
        )
        .map((paymentMethod) => {
          return (
            <div
              className="flex w-full flex-row items-center gap-2"
              key={paymentMethod.id}
            >
              <div className="flex h-fit max-h-fit flex-1 flex-row items-center justify-between rounded-xl border border-gray-200 px-4 py-3">
                <div className="flex w-full flex-col items-start">
                  <div className="text-sm font-bold text-gray-950 dark:text-[#ffffff]">
                    Card ending in {paymentMethod.last4Digits}
                  </div>
                  <div className="text-sm font-normal text-gray-500">
                    Expiry {paymentMethod.expMonth}/{paymentMethod.expYear}
                  </div>
                </div>
                <Button
                  className="h-fit min-h-0 min-w-0 p-2"
                  intent="transparent"
                  onClick={async () => {
                    await assignDefaultPaymentMethodMutation.mutateAsync({
                      memberId: userMember.id,
                      paymentMethodId: paymentMethod.id,
                      clubId: settings.clubId,
                    });
                  }}
                  tooltip="Set as default"
                >
                  <StarIcon
                    className="h-5 w-5"
                    pathClassName={cn({
                      "fill-secondary-500 stroke-secondary-500":
                        paymentMethod.defaultPaymentMethod,
                      "fill-transparent stroke-gray-500":
                        !paymentMethod.defaultPaymentMethod,
                    })}
                  />
                </Button>
              </div>
              <Button
                className={cn(
                  "flex h-10 max-h-none w-10 items-center justify-center p-0",
                  {
                    invisible: paymentMethod.defaultPaymentMethod,
                  },
                )}
                intent="error-outline"
                onClick={async () => {
                  await removePaymentMethodMutation.mutateAsync({
                    clubId: settings.clubId,
                    memberId: userMember.id,
                    paymentMethodId: paymentMethod.id,
                  });
                }}
              >
                <TrashIcon
                  pathClassName="stroke-error-500"
                  className="h-5 w-5"
                />
              </Button>
            </div>
          );
        })}
    </div>
  );
};
