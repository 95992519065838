import {
  AbilityBuilder,
  AbilityTuple,
  createMongoAbility,
  MongoAbility,
  MongoQuery,
} from "@casl/ability";
import { StripeAccountType, UserRole } from "@gymflow/types";

import { Subject } from "./Subject";
import { Verb } from "./Verb";

const setupPermissions = ({
  builder,
  roles,
  userId,
  stripeAccountType,
}: {
  builder: AbilityBuilder<MongoAbility<AbilityTuple, MongoQuery>>;
  roles: UserRole[];
  userId: string;
  stripeAccountType: StripeAccountType;
}) => {
  const { can, cannot } = builder;
  if (roles.includes("OWNER") || roles.includes("SUPPORT")) {
    can(Verb.View, Subject.Dashboard);
    can(Verb.View, Subject.StaffCalendar);
    can(Verb.View, Subject.Leads);
    can(Verb.View, Subject.MemberList);
    can(Verb.Download, Subject.MemberList);
    can(Verb.View, Subject.MemberProfile);
    can(Verb.Create, Subject.Email);
    can(Verb.Create, Subject.SMS);
    can(Verb.View, Subject.Settings);
    can(Verb.View, Subject.Sales);
    can(Verb.View, Subject.Reports);
    can(Verb.View, Subject.GlobalSearch);
    can(Verb.Credit, Subject.Invoices);
    can(Verb.Collect, Subject.Invoices);
    can(Verb.Refund, Subject.Invoices);
    can(Verb.WriteOff, Subject.Invoices);
    can(Verb.View, Subject.Marketing);
    can(Verb.View, Subject.Task);
    can(Verb.View, Subject.StaffAccount);
    can(Verb.View, Subject.Appointments);
    can(Verb.View, Subject.AppointmentAvailability);
    can(Verb.View, Subject.FacilityAvailability);
    can(Verb.View, Subject.Access);
    can(Verb.Edit, Subject.CheckoutPrice);
  }

  if (roles.includes("MANAGER")) {
    can(Verb.View, Subject.Dashboard);
    can(Verb.View, Subject.StaffCalendar);
    can(Verb.View, Subject.Leads);
    can(Verb.View, Subject.MemberList);
    can(Verb.Download, Subject.MemberList);
    can(Verb.View, Subject.MemberProfile);
    can(Verb.Create, Subject.Email);
    can(Verb.Create, Subject.SMS);
    can(Verb.View, Subject.Settings);
    can(Verb.View, Subject.Sales);
    can(Verb.View, Subject.Reports);
    can(Verb.View, Subject.GlobalSearch);
    can(Verb.Credit, Subject.Invoices);
    can(Verb.Collect, Subject.Invoices);
    can(Verb.Refund, Subject.Invoices);
    can(Verb.WriteOff, Subject.Invoices);
    can(Verb.View, Subject.Marketing);
    can(Verb.View, Subject.Task);
    can(Verb.View, Subject.Appointments);
    can(Verb.View, Subject.AppointmentAvailability);
    can(Verb.View, Subject.FacilityAvailability);
    can(Verb.View, Subject.Access);
    can(Verb.Edit, Subject.CheckoutPrice);
  }

  if (roles.includes("RECEPTION")) {
    can(Verb.View, Subject.StaffCalendar);
    can(Verb.View, Subject.Leads);
    can(Verb.View, Subject.MemberList);
    can(Verb.View, Subject.MemberProfile);
    can(Verb.Create, Subject.Email);
    can(Verb.Create, Subject.SMS);
    can(Verb.View, Subject.Sales);
    can(Verb.View, Subject.GlobalSearch);
    can(Verb.Credit, Subject.Invoices);
    can(Verb.Collect, Subject.Invoices);
    can(Verb.WriteOff, Subject.Invoices);
    can(Verb.View, Subject.Marketing);
    can(Verb.View, Subject.Task);
    can(Verb.View, Subject.Appointments);
    can(Verb.View, Subject.AppointmentAvailability, {
      id: { $eq: userId },
    });
    can(Verb.View, Subject.FacilityAvailability);
    can(Verb.View, Subject.Access);
  }

  if (roles.includes("TRAINER")) {
    can(Verb.View, Subject.StaffCalendar, {
      "event.userEventHost.id": userId,
    });
    can(Verb.View, Subject.Leads);
    can(Verb.View, Subject.MemberList);
    can(Verb.View, Subject.MemberProfile, {
      assignedStaffMembers: { $elemMatch: { id: userId } },
    });
    can(Verb.Create, Subject.Email);
    can(Verb.Create, Subject.SMS);
    can(Verb.View, Subject.Sales);
    can(Verb.Credit, Subject.Invoices);
    can(Verb.Collect, Subject.Invoices);
    can(Verb.WriteOff, Subject.Invoices);
    can(Verb.View, Subject.Marketing);
    can(Verb.View, Subject.Task);
    can(Verb.View, Subject.Appointments);
    can(Verb.View, Subject.AppointmentAvailability, {
      id: { $eq: userId },
    });
    can(Verb.View, Subject.FacilityAvailability);
    can(Verb.View, Subject.Access);
  }

  if (roles.includes("MEMBER")) {
    can(Verb.View, Subject.MyProfile);
    can(Verb.View, Subject.UserCalendar);
    can(Verb.View, Subject.CustomerSales);
    can(Verb.Pay, Subject.Invoices);
  }

  if (stripeAccountType === "NOT_CONNECTED") {
    cannot(Verb.View, Subject.MemberList);
    cannot(Verb.View, Subject.MemberProfile);
    cannot(Verb.View, Subject.Sales);
    cannot(Verb.View, Subject.GlobalSearch);
  }

  if (stripeAccountType === "NOT_CONNECTED") {
    can(Verb.Create, Subject.StripeConnect);
  }

  if (stripeAccountType === "CONNECTED") {
    can(Verb.View, Subject.StripeConnect);
  }
};

export const create = ({
  roles,
  userId,
  stripeAccountType,
}: {
  roles: UserRole[];
  userId: string;
  stripeAccountType: StripeAccountType;
}) => {
  const builder = new AbilityBuilder(createMongoAbility);

  setupPermissions({ builder, roles, userId, stripeAccountType });

  return builder.build();
};

export const update = ({
  ability,
  roles,
  userId,
  stripeAccountType,
}: {
  ability: MongoAbility<AbilityTuple, MongoQuery>;
  roles: UserRole[];
  userId: string;
  stripeAccountType: StripeAccountType;
}) => {
  const builder = new AbilityBuilder(createMongoAbility);

  setupPermissions({ builder, roles, userId, stripeAccountType });
  ability.update(builder.rules);
};
