import { useRuleValuesAsPublic } from "@gymflow/api";
import { permissions, RuleName } from "@gymflow/common";
import { formatCurrency } from "@gymflow/helpers";
import { useStoreState } from "easy-peasy";
import moment from "moment-timezone";

import { useClubSettings } from "../providers";
import useGymflowModels from "../store";

const useMemberRules = () => {
  const { api } = useGymflowModels();
  const settings = useClubSettings();
  const clubId = settings.clubId;
  const { data: rules } = useRuleValuesAsPublic({ api, clubId });

  const validateMemberBooking = (startDate: string) => {
    if (!rules) return;
    const rule = rules.find(
      ({ ruleType }) => ruleType === RuleName.BookingWindow,
    );
    if (!rule) {
      return null;
    }
    const ruleValue = rule.bookingRule;

    if (ruleValue && ruleValue.windowType && ruleValue.windowValue) {
      const isValid = moment()
        //@ts-expect-error
        .add(ruleValue.windowValue, ruleValue.windowType)
        .isAfter(moment(startDate));
      if (!isValid) {
        let interval = ruleValue.windowType.toLowerCase();
        // for some reason the window value that comes from the BE is a string, hence the conversion
        if (Number(ruleValue.windowValue) === 1) {
          interval = interval.slice(0, -1);
        }

        return `You can only book ${ruleValue.windowValue} ${interval} in advance.`;
      }
    }

    const error = permissions.membership.validateMemberBook(startDate);
    if (error) {
      return error;
    }

    return null;
  };
  const { settingsStore } = useGymflowModels();
  const { defaultCurrency } = useStoreState(settingsStore);
  const validateMemberCancel = (startDate: string, isStaff?: boolean) => {
    if (!rules) return;
    const rule = rules.find(
      ({ ruleType }) => ruleType === RuleName.CancellationWindow,
    );
    if (!rule) {
      return null;
    }
    const ruleValue = rule.bookingCancellationRule;
    if (ruleValue && ruleValue.windowType && ruleValue.windowValue) {
      const isValid = moment()
        //@ts-expect-error
        .add(ruleValue.windowValue, ruleValue.windowType)
        .isBefore(moment(startDate));
      if (!isValid) {
        if (ruleValue.cancellationFee === 0)
          return isStaff
            ? "Would you like to treat this as a late cancellation? Credits used to make this booking will not be returned."
            : "You're cancelling late, the credit for this booking will not be returned.";
        return isStaff
          ? `Would you like to charge this user the late cancellation fee of ${formatCurrency(
              ruleValue.cancellationFee,
              defaultCurrency,
            )} If the payment is not successful the fee will show as outstanding on the users profile and the booking will still be cancelled.`
          : `You're cancelling late, you must pay ${formatCurrency(
              ruleValue.cancellationFee,
              defaultCurrency,
            )} to cancel this booking and the credit for this booking will not be returned.`;
      }
    }

    return null;
  };

  const validateNoShow = () => {
    if (!rules) return;
    const rule = rules.find(({ ruleType }) => ruleType === RuleName.NoShowFee);
    if (!rule) {
      return null;
    }
    const ruleValue = rule.noShowFeeRule;
    if (ruleValue && ruleValue.noShowFeeValue) {
      return `Would you like to charge this user the no show fee of ${formatCurrency(
        ruleValue.noShowFeeValue,
        defaultCurrency,
      )}? If the payment is not successful the fee will show as outstanding on the users profile.`;
    }

    return null;
  };

  return {
    validateMemberBooking,
    validateMemberCancel,
    validateNoShow,
  };
};

export default useMemberRules;
