import { formatCurrency, membershipHelper } from "@gymflow/helpers";
import { MembershipBean } from "@gymflow/types";
import { usePortalRoutes } from "apps/portal/src/hooks";
import { useClubSettings } from "apps/portal/src/providers";
import { RouteFeature, RouteLayout } from "apps/portal/src/routes";
import React from "react";

import { Button, CreditsIcon, InfinityIcon } from "../../atoms";
import { HostedPagesCard } from "../HostedPagesCard";

export type HostedPagesMembershipCardProps = {
  membership: MembershipBean;
};

export const HostedPagesMembershipCard: React.FC<
  HostedPagesMembershipCardProps
> = ({ membership }) => {
  const settings = useClubSettings();
  const { createClubLink } = usePortalRoutes();
  const totalSessions = (membership.membershipAddonList ?? []).reduce(
    (acc, next) => {
      return acc + (next.sessionsUnlimited ? Infinity : next.credits);
    },
    0,
  );

  return (
    <HostedPagesCard.Container>
      <HostedPagesCard.Header>
        <div className="flex flex-row items-start justify-between gap-4">
          <div className="flex-wrap font-bold text-gray-950 dark:text-white">
            {membership.name}
          </div>

          <div className="flex flex-row gap-2">
            {!Number.isFinite(totalSessions) ? (
              <InfinityIcon className="dark:stroke-darkGray-50 stroke-gray-950" />
            ) : (
              <div className="font-bold text-gray-950 dark:text-white">
                {totalSessions || "-"}
              </div>
            )}
            <CreditsIcon pathClassName="stroke-secondary-500" />
          </div>
        </div>
        <HostedPagesCard.Description description={membership.description} />
      </HostedPagesCard.Header>
      <HostedPagesCard.Separator />
      <HostedPagesCard.Footer>
        <HostedPagesCard.Price
          price={formatCurrency(
            membership.defaultPrice,
            settings.defaultCurrency,
          )}
          description={membershipHelper.getBillingRecurrence(membership)}
        />

        <Button
          link={createClubLink(
            RouteLayout.Site,
            RouteFeature.SiteBuyMembershipId.replace(
              ":membershipId",
              membership.id.toString(),
            ),
          )}
          intent="secondary-outline"
          className="w-fit"
        >
          View More
        </Button>
      </HostedPagesCard.Footer>
    </HostedPagesCard.Container>
  );
};
