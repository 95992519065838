import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FacilityWithCategoryNameDTO,
  useFacilityDisable,
  useFacilityEnable,
  useFacilityList,
  useMutationFacilityAvailabilityEdit,
} from "@gymflow/api";
import { FacilityStatus } from "@gymflow/types";
import { createColumnHelper, SortingState } from "@tanstack/react-table";
import { usePageSize } from "apps/portal/src/hooks";
import { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import { usePortalRoutes } from "../../../hooks/usePortalRoutes";
import { Can, Subject, Verb } from "../../../permissions";
import { RouteFeature } from "../../../routes/feature";
import { RouteLayout } from "../../../routes/layout";
import useGymflowModels from "../../../store";
import {
  Badge,
  Button,
  PaginatedSelect,
  PlaceholderAvatar,
  SquarishAvatar,
} from "../../atoms";
import { PaginatedTable } from "../../organisms/PaginatedTable";
import { SettingsContainer } from "../SettingsContainer";
import {
  FacilitySideBarContext,
  FacilitySideBarFormProvider,
} from "./FacilitySideBarForm";

function FacilitiesTab() {
  const history = useHistory();
  const { createClubLink } = usePortalRoutes();
  const [currentPage, setCurrentPage] = useState(0);
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const pageSize = usePageSize({
    tableContainerRef,
    rowHeight: 56,
  });
  const [sort, setSort] = useState<SortingState>([]);
  const [statusOptionSelected, setStatusOptionSelected] =
    useState<FacilityStatusOption>({
      label: "Enabled",
      value: "ACTIVE",
    });

  const { api } = useGymflowModels();
  const { data, isLoading } = useFacilityList({
    api,
    opts: {
      page: currentPage,
      limit: pageSize,
      sort: sort?.[0]
        ? {
            field: sort[0].id,
            desc: sort[0].desc,
          }
        : { field: "createdDate", desc: true },
      extraParams: {
        status: statusOptionSelected.value,
      },
    },
  });
  const enableFacilityMutation = useFacilityEnable({ api });
  const disableFacilityMutation = useFacilityDisable({ api });

  const editAvailabilityMutation = useMutationFacilityAvailabilityEdit({ api });

  const { openNew, openExisting } = useContext(FacilitySideBarContext);
  const [editingFacilityId, setEditingFacilityId] = useState<number>();

  useEffect(() => {
    if (editingFacilityId) {
      openExisting({
        facilityId: editingFacilityId,
        onClose: () => {
          setEditingFacilityId(undefined);
        },
      });
    }
  }, [editingFacilityId, openExisting]);

  const columnHelper = createColumnHelper<FacilityWithCategoryNameDTO>();
  const columns = [
    columnHelper.accessor("name", {
      cell: (column) => {
        const picture = column.row.original.picture;
        return (
          <div className="flex items-center gap-2">
            {picture ? (
              <SquarishAvatar pictureUrl={picture} />
            ) : (
              <PlaceholderAvatar name={column.getValue()} />
            )}
            <div>{column.getValue()}</div>
          </div>
        );
      },
    }),
    columnHelper.accessor("categoryName", {
      header: "Category",
      id: "facilityCategoryId",
      cell: (column) => <Badge>{column.getValue()}</Badge>,
    }),
    columnHelper.display({
      id: "actions",
      header: "Actions",
      cell: (column) => {
        const facilityId = column.row.original.id;
        const isActive = statusOptionSelected.value === "ACTIVE";
        return (
          <div className="flex items-center gap-2">
            <Button
              className="min-w-0"
              intent="link"
              onClick={() => {
                setEditingFacilityId(facilityId);
              }}
            >
              Edit
            </Button>
            <Button
              intent="link-warning"
              showSpinner={
                disableFacilityMutation.isLoading ||
                enableFacilityMutation.isLoading
              }
              onClick={async () => {
                if (isActive) {
                  await disableFacilityMutation.mutateAsync(facilityId);

                  await editAvailabilityMutation.mutateAsync({
                    facilityId,
                    availableForAppointments: false,
                    availabilitySlotList: [],
                  });
                } else {
                  await enableFacilityMutation.mutateAsync(facilityId);
                }
              }}
            >
              {isActive ? "Disable" : "Enable"}
            </Button>
            {isActive && (
              <Can I={Verb.View} a={Subject.Appointments}>
                <Can I={Verb.View} a={Subject.FacilityAvailability}>
                  <Button
                    intent="link"
                    onClick={() => {
                      history.push(
                        createClubLink(
                          RouteLayout.Staff,
                          RouteFeature.FacilityAvailability.replace(
                            ":facilityId",
                            facilityId.toString(),
                          ),
                        ),
                      );
                    }}
                  >
                    Availability
                  </Button>
                </Can>
              </Can>
            )}
          </div>
        );
      },
      enableSorting: false,
    }),
  ];

  return (
    <SettingsContainer
      title="Facilities"
      subTitle="Create, Update and manage your available facilities list."
      actions={
        <>
          <PaginatedSelect
            className="h-11 bg-white"
            placeholder="Enabled"
            value={statusOptionSelected}
            onChange={(value) => setStatusOptionSelected(value)}
            loadOptions={async () => {
              return {
                options: [
                  {
                    label: "Enabled",
                    value: "ACTIVE",
                  },
                  {
                    label: "Disabled",
                    value: "DISABLED",
                  },
                ] satisfies FacilityStatusOption[],
              };
            }}
          />
          <Button
            intent="secondary"
            className="text-white"
            onClick={() => {
              openNew();
            }}
          >
            <div className="flex flex-row items-center gap-x-2">
              <FontAwesomeIcon icon={faPlus} />
              <div>Facility</div>
            </div>
          </Button>
        </>
      }
    >
      <PaginatedTable
        tableProps={{
          data: data?.content ?? [],
          columns: columns,
          pageCount: data?.totalPages,
          pageIndex: data?.number,
          onSortingChange: setSort,
          sort: sort,
          isFetching: isLoading,
          pageSize: pageSize,
          tableContainerRef
        }}
        hasPreviousPage={!!data && data?.number > 0}
        hasNextPage={!!data && data?.number < data?.totalPages - 1}
        goToNextPage={() => {
          setCurrentPage((e) => e + 1);
        }}
        goToPreviousPage={() => {
          setCurrentPage((e) => e - 1);
        }}
      />
    </SettingsContainer>
  );
}

interface FacilityStatusOption {
  readonly value: FacilityStatus;
  readonly label: string;
}

function FacilitiesTabWithProvider() {
  return (
    <FacilitySideBarFormProvider>
      <FacilitiesTab />
    </FacilitySideBarFormProvider>
  );
}

export { FacilitiesTabWithProvider as FacilitiesTab };
