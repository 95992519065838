import { useEmailTemplateList } from "@gymflow/api";
import { AsyncButton, isMobile } from "@gymflow/common";
import classNames from "classnames";
import { Button } from "reactstrap";

import { ResourceTableCard } from "../ResourceTableCard";
import useTemplateAlert from "./useTemplateAlert";

export function Templates() {
  const { showAlert, editingTemplateId, setEditingTemplateId } = useTemplateAlert();

  const renderButtons = ({ resource: { id, isEditable }, beingEdited }) => (
    <AsyncButton
      className={classNames("btn-link", { "d-none": !isEditable })}
      data-testid="btn-edit"
      onClick={() => setEditingTemplateId(id)}
      size="sm"
      disabled={beingEdited}
    >
      Edit
    </AsyncButton>
  );

  return (
    <div className="relative flex h-full max-h-full w-full flex-col">
      <Button
        color="primary"
        size="sm"
        className="absolute w-fit"
        style={{ top: "-60px", right: "0" }}
        onClick={() => {
          showAlert();
        }}
      >
        Create Template
      </Button>
      <ResourceTableCard
        columns={[
          {
            accessor: "templateName",
            Header: "Name",
          },
          {
            accessor: "templateType",
            Header: "Type",
            show: !isMobile(),
          },
          {
            Header: "Actions",
            accessor: "actions",
            sortable: false,
            filterable: false,
            minWidth: isMobile() ? 110 : 160,
          },
        ]}
        renderButtons={renderButtons}
        showEditButtons
        useQueryHook={useEmailTemplateList}
        onEditClick={setEditingTemplateId}
        selectedId={editingTemplateId}
        findParams={{ extraParams: { isAutomationTemplate: false } }}
      />
    </div>
  );
}
