import {
  useMutationFacilityAvailabilityOverrideDelete,
  useMutationFacilityAvailabilityOverrideEdit,
  useQueryFacilityAvailabilityOverride,
  useQueryFacilityAvailabilityOverrideList,
} from "@gymflow/api";
import { NotificationContext } from "@gymflow/common";
import { AvailabilityOverrideDTO } from "@gymflow/types";
import { createColumnHelper } from "@tanstack/react-table";
import moment from "moment-timezone";
import { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router";

import { usePageSize } from "../../../hooks/usePageSize";
import { ModalContext } from "../../../providers";
import useGymflowModels from "../../../store";
import { Button } from "../../atoms";
import { Pagination } from "../../molecules";
import { Table } from "../Table";
import { FacilityAvailabilityOverrideFormModal } from "./FacilityAvailabilityOverrideFormModal";

export function FacilityBlockedTimeTable() {
  const { facilityId } = useParams<{ facilityId: string }>();
  const { setModal, hide } = useContext(ModalContext);
  const { api } = useGymflowModels();
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const pageSize = usePageSize({
    tableContainerRef,
    rowHeight: 56,
  });
  const [currentPage, setCurrentPage] = useState<number>(0);

  const { data, isFetching } = useQueryFacilityAvailabilityOverrideList({
    api,
    facilityId: !!facilityId ? +facilityId : undefined,
    opts: {
      limit: pageSize,
      page: currentPage,
      sort: { field: "overrideDate" },
    },
  });

  const [editingId, setEditingId] = useState<number | null>(null);
  const { data: editingOverride, isFetching: isFetchingEdit } =
    useQueryFacilityAvailabilityOverride({
      api,
      facilityId: !!facilityId ? +facilityId : undefined,
      availabilityOverrideId: editingId,
    });
  const deleteOverrideMutation = useMutationFacilityAvailabilityOverrideDelete({
    api,
  });
  const editOverrideMutation = useMutationFacilityAvailabilityOverrideEdit({
    api,
  });
  const columnHelper = createColumnHelper<AvailabilityOverrideDTO>();
  const { notifyDanger } = useContext(NotificationContext);

  useEffect(() => {
    if (!editingId || !editingOverride) {
      return;
    }
    const { id, ...editValues } = editingOverride;
    setModal(
      <FacilityAvailabilityOverrideFormModal
        overrideId={editingId}
        facilityId={+facilityId}
        onCancel={() => {
          hide();
          setEditingId(null);
        }}
        onConfirm={async (newValues) => {
          try {
            await editOverrideMutation.mutateAsync({
              facilityId: +facilityId,
              availabilityOverrideId: editingId,
              patchedFields: newValues,
            });
          } catch (e) {
            notifyDanger(e);
          }
          setEditingId(null);
          hide();
        }}
        value={editValues!}
      />,
    );
  }, [editingOverride, editingId]);

  const columns = [
    columnHelper.accessor("overrideDate", {
      header: "Date",
      enableSorting: false,
      cell: (column) => {
        return (
          <div>
            {moment(column.row.original.overrideDate, "YYYY-MM-DD").format(
              "dddd, Do MMMM, YYYY",
            )}
          </div>
        );
      },
    }),
    columnHelper.accessor("startTime", {
      header: "Blocked Time",

      enableSorting: false,
      cell: (column) => {
        return (
          <div className="text-gray-600">
            {column.row.original.isUnavailableAllDay
              ? "All Day"
              : `${moment(column.row.original.startTime, "HH:mm").format(
                  "hh:mm a",
                )} - ${moment(column.row.original.endTime, "HH:mm").format(
                  "hh:mm a",
                )}`}
          </div>
        );
      },
    }),
    columnHelper.display({
      id: "actions",
      header: "Actions",
      cell: (column) => {
        return (
          <div className="flex gap-2">
            <Button
              className="min-w-0"
              intent="link"
              showSpinner={
                column.row.original.id === editingId && isFetchingEdit
              }
              onClick={() => {
                setEditingId(column.row.original.id);
              }}
            >
              Edit
            </Button>
            <Button
              intent="link"
              showSpinner={deleteOverrideMutation.isLoading}
              onClick={async () => {
                await deleteOverrideMutation.mutateAsync({
                  facilityId: +facilityId,
                  availabilityOverrideId: column.row.original.id,
                });
              }}
            >
              Delete
            </Button>
          </div>
        );
      },
    }),
  ];
  return (
    <>
      <Table
        data={data?.content || []}
        columns={columns}
        isFetching={isFetching}
        pageCount={data?.totalPages}
        pageSize={10}
        rowClassName="h-14"
        tableContainerRef={tableContainerRef}
      />

      <Pagination
        className="min-h-fit p-4"
        hasNextPage={!!data && data?.number < data?.totalPages - 1}
        hasPreviousPage={!!data && data?.number > 0}
        goToNextPage={() => {
          setCurrentPage((e) => e + 1);
        }}
        goToPreviousPage={() => {
          setCurrentPage((e) => e - 1);
        }}
      />
    </>
  );
}
