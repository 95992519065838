import { createContext, useCallback, useState } from "react";
import NotificationAlert from "react-notification-alert";

import { GenericValidatorParser } from "../helpers/GenericValidatorParser";

export const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const [areNotificationsLoaded, setNotificationsLoaded] = useState(false);
  const [notificationApi, setNotificationApi] = useState(null);
  const [tries, setTries] = useState(0);

  const scheduleNotification = useCallback(
    (opts) => {
      if (tries > 10) {
        setTries(0);
        return;
      }
      setTimeout(() => {
        if (notificationApi) {
          setTries(0);
          notificationApi.notificationAlert(opts);
        } else {
          setTries(tries + 1);
          scheduleNotification(opts);
        }
      }, 100);
    },
    [notificationApi, tries],
  );

  const notify = useCallback(
    (options) => {
      const TOP_LEFT = "tl";
      const opts = { place: TOP_LEFT, autoDismiss: 15, ...options };

      scheduleNotification(opts);
    },
    [scheduleNotification],
  );

  const notifyDanger = useCallback(
    (error) => {
      let text = "";
      if (typeof error === "string") {
        text = error;
      } else if (!error.response) {
        // eslint-disable-next-line no-console
        console.error(error);
        return;
      } else {
        const { response } = error;
        switch (response.status) {
          case 400: {
            if (response?.data?.errors) {
              const parser = new GenericValidatorParser(response.data.errors);
              text = parser.toString();
            } else if (response?.data?.error_message) {
              text = response.data.error_message;
            } else {
              text = "Something unexpected happened.";
            }

            break;
          }
          case 409:
            text = response.data.error_message;
            break;
          default:
            text = "Something unexpected happened.";
        }
      }
      const options = {
        message: (
          <div>
            <div>{text}</div>
          </div>
        ),
        type: "danger",
        icon: "tim-icons icon-alert-circle-exc",
      };
      notify(options);
    },
    [notify],
  );

  return (
    <NotificationContext.Provider value={{ notifyDanger, notify }}>
      <div className="rna-container">
        <NotificationAlert
          ref={(referenceApi) => {
            if (referenceApi !== null) {
              setNotificationApi(referenceApi);
              setNotificationsLoaded(true);
            } else if (areNotificationsLoaded) {
              setNotificationApi(referenceApi);
            }
          }}
        />
      </div>

      {areNotificationsLoaded && children}
    </NotificationContext.Provider>
  );
};
