import { subject } from "@casl/ability";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEventOccurrence, useEventOccurrenceRsvp } from "@gymflow/api";
import { DATE_FORMAT_WITH_SECONDS, NotificationContext } from "@gymflow/common";
import classNames from "classnames";
import moment from "moment-timezone";
import { useContext } from "react";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { ActionMeta } from "react-select/dist/declarations/src/types";

import {
  MemberAddToClassSearchResult,
  MemberSelect,
} from "../components/atoms";
import {
  EventOccurrenceButtons,
  RsvpTable,
  SideBarOccurrenceFormProvider,
} from "../components/molecules";
import { Can, Subject, Verb } from "../permissions";
import useGymflowModels from "../store";

export function EventOccurrence() {
  const history = useHistory();
  const toast = useContext(NotificationContext);
  const { api } = useGymflowModels();
  const { eventId } = useParams<{ eventId: string }>();
  const { data: eventOccurrence, isFetching } = useEventOccurrence(
    {
      api,
      eventId: +eventId,
    },
    {
      enabled: !!eventId,
    },
  );
  const { addAttendeeToRsvp } = useEventOccurrenceRsvp({
    api,
  });

  const idsAlreadyRsvp =
    eventOccurrence?.eventRsvpList.map((rsvp) => rsvp.userMember.id) ?? [];

  return (
    <SideBarOccurrenceFormProvider>
      <div className="flex h-screen flex-col justify-between bg-white">
        <Can
          not
          I={Verb.View}
          a={subject(Subject.StaffCalendar, { ...eventOccurrence })}
        >
          <div
            className={classNames(
              "flex h-24 flex-col items-center justify-center px-6 py-4",
              { hidden: isFetching },
            )}
          >
            <div className="text-error-700">
              You are not authorized to view this event.
            </div>
            <Link
              to=""
              onClick={(e) => {
                e.preventDefault();
                history.goBack();
              }}
            >
              Go back
            </Link>
          </div>
        </Can>
        <Can
          I={Verb.View}
          a={subject(Subject.StaffCalendar, { ...eventOccurrence })}
        >
          <div className="flex flex-row items-center justify-between px-6 py-4">
            <div className="flex flex-1 flex-row flex-wrap items-center justify-between">
              <div className="mr-4 flex max-w-[800px] flex-col">
                <div className="text-lg font-semibold">
                  {!isFetching && eventOccurrence?.event.activity.name}
                  {isFetching && (
                    <div className="animate-pulse ">
                      <div className="h-7 w-20 rounded bg-gray-400"></div>
                    </div>
                  )}
                </div>
                <div className="text-sm text-gray-600">
                  {!isFetching && eventOccurrence?.startDate && (
                    <>
                      {moment(
                        eventOccurrence.startDate,
                        DATE_FORMAT_WITH_SECONDS,
                      ).format("dddd, MMMM Do, YYYY @ hh:mm a")}
                      {" with "}
                      <span className="font-bold">
                        {`${eventOccurrence.event.userEventHost.firstName} ${eventOccurrence.event.userEventHost.lastName}`}
                      </span>
                    </>
                  )}
                  {isFetching && (
                    <div className="mt-4 animate-pulse">
                      <div className="h-2 w-20 rounded bg-gray-400"></div>
                    </div>
                  )}
                </div>
                <div className="text-sm text-gray-600">
                  {!isFetching &&
                    eventOccurrence?.event.isBookable &&
                    `${eventOccurrence.bookedCount}/${eventOccurrence.event.capacity} Booked`}

                  {isFetching && (
                    <div className="mt-4 animate-pulse">
                      <div className="h-2 w-20 rounded bg-gray-400"></div>
                    </div>
                  )}
                </div>
                <div className="text-sm text-gray-600">
                  {!isFetching &&
                    eventOccurrence?.event.isBookable &&
                    `${eventOccurrence.waitingCount}/${eventOccurrence.event.waitListCapacity} Waiting`}
                  {isFetching && (
                    <div className="mt-4 animate-pulse">
                      <div className="h-2 w-20 rounded bg-gray-400"></div>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex flex-wrap items-center"></div>
            </div>
            <div
              className="flex h-11 w-11 cursor-pointer items-center justify-center self-start"
              onClick={() => {
                history.goBack();
              }}
            >
              <FontAwesomeIcon
                className="text-xl text-gray-500"
                icon={faClose}
              />
            </div>
          </div>
          {eventOccurrence?.event.isBookable && (
            <div className="border-t border-t-gray-200 p-6">
              <AddMemberSelect
                onChange={async (memberToAdd) => {
                  try {
                    await addAttendeeToRsvp.mutateAsync({
                      userMemberId: memberToAdd.value.id,
                      occurrenceId: eventOccurrence!.id,
                    });
                  } catch (e) {
                    toast.notifyDanger(e);
                  }
                }}
                disabledMemberIds={idsAlreadyRsvp}
                placeholder="Search to add users"
                value={null}
              />
            </div>
          )}
          {eventOccurrence?.event.isBookable && (
            <RsvpTable
              rsvpList={eventOccurrence?.eventRsvpList ?? []}
              startDate={eventOccurrence?.startDate}
              isLoading={isFetching}
            />
          )}
        </Can>
        <EventOccurrenceButtons
          occurrenceId={eventOccurrence?.id}
          rsvpList={eventOccurrence?.eventRsvpList ?? []}
          isBookable={!!eventOccurrence?.event.isBookable}
          isRecurring={!!eventOccurrence?.event.isRecurring}
          startDate={eventOccurrence?.startDate}
        />
      </div>
    </SideBarOccurrenceFormProvider>
  );
}

function AddMemberSelect({
  onChange,
  disabledMemberIds,
  value,
  placeholder,
}: {
  onChange: (newValue: any, action: ActionMeta<any>) => void;
  disabledMemberIds?: string[];
  value: any;
  placeholder?: string;
}) {
  return (
    <MemberSelect
      onChange={onChange}
      disabledMemberIds={disabledMemberIds}
      placeholder={placeholder}
      value={value}
      resultMap={(searchResult, idx) => ({
        label: (
          <MemberAddToClassSearchResult
            isFirst={idx === 0}
            key={searchResult.id}
            name={`${searchResult.firstName} ${searchResult.lastName}`}
            email={searchResult.email}
          />
        ),
        value: searchResult,
      })}
    />
  );
}
