import startCase from 'lodash/startCase';

const GLOBAL_KEY = 'global';

export class GenericValidatorParser {
  constructor(errors) {
    if (typeof errors !== 'object' || errors === null) {
      throw new Error('Invalid error object');
    }
    this.fieldErrors = this.filterOutGlobal(errors);
    this.global = errors[GLOBAL_KEY];
  }

  getFieldErrors() {
    return this.fieldErrors;
  }

  getFieldError(fieldName) {
    if (typeof fieldName === 'undefined') {
      throw new Error('getErrorField() requires a field name');
    }
    const message = this.fieldErrors[fieldName];
    if (typeof message === 'undefined') {
      throw new Error("Field name doesn't exist.");
    }
    return message;
  }

  getGlobalError() {
    return this.global;
  }

  toString() {
    let text = '';

    const fieldErrors = this.getFieldErrors();
    const fieldErrorString = Object.keys(fieldErrors).reduce((innerText, field) => {
      if (field === 'id' || field.endsWith('Id')) {
        innerText += fieldErrors[field];
      } else {
        innerText += startCase(field) + ': ' + fieldErrors[field];
      }
      return innerText;
    }, '');
    if (typeof this.getGlobalError() !== 'undefined') {
      text = `${this.getGlobalError()}\n`;
    }
    text += fieldErrorString;

    return text;
  }

  /**
   * @private
   */
  filterOutGlobal(errors) {
    return Object.keys(errors)
      .filter((key) => key !== GLOBAL_KEY)
      .reduce((acc, key) => {
        acc[key] = errors[key];
        return acc;
      }, {});
  }
}
