import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cn } from "@gymflow/helpers";
import { useFormik, useFormikContext } from "formik";
import omit from "lodash/omit";
import { useRef } from "react";
import { Tooltip } from "react-tooltip";
import { Input } from "reactstrap";

import { renderErrorRowOnTouch } from "../../helpers/form";
import useFocusOnError from "../../hooks/useFocusOnError";

export const LabeledFormikInput = ({
  label,
  tooltip,
  ...props
}: { label?: string; tooltip?: string } & Parameters<
  typeof FormikInput
>[0]) => {
  return (
    <div className="flex flex-col gap-y-1">
      <div className="inline-flex items-center gap-x-2 text-sm font-semibold">
        {label ?? props.placeholder}

        {tooltip && (
          <>
            <FontAwesomeIcon
              data-tooltip-id="category-tooltip"
              data-tooltip-content={tooltip}
              className="cursor-pointer"
              icon={faQuestionCircle}
            />
            <Tooltip
              className="!bg-primary-700 flex max-w-sm flex-col items-center rounded-lg text-center text-xs"
              id="category-tooltip"
            />
          </>
        )}
      </div>
      <FormikInput {...props} />
    </div>
  );
};

function FormikInput(
  immutableProps: {
    name: string;
    component?: React.ComponentType;
    validate?: any;
    formikProps:
      | ReturnType<typeof useFormik>
      | ReturnType<typeof useFormikContext<any>>;
    onChange?: (...params: any[]) => any;
    errorRowOn?: "submit" | "touch";
    showErrorLabel?: boolean;
    // TODO: Remove the injection of this property, this should be read by useSettings inside MobileNumberInput
    phone_number_country?: string;
  } & React.ComponentProps<typeof Input>,
) {
  const props = {
    ...immutableProps,
    showErrorLabel:
      immutableProps.showErrorLabel === undefined
        ? true
        : immutableProps.showErrorLabel,
  } as const;
  const { name, component, validate, formikProps } = props;

  const { errors, touched, registerField, getFieldProps } = formikProps;
  let fieldProps = omit(props, [
    "as",
    "name",
    "validate",
    "component",
    "showErrorLabel",
  ]) as Omit<
    typeof props,
    "name" | "validate" | "component" | "showErrorLabel"
  >;

  registerField(name, { validate });
  const field = getFieldProps(name);

  const fieldRef = useRef();
  useFocusOnError({ fieldRef, name, formikProps });

  const FieldComponent = component || Input;

  const refProps = {} as any;
  if (FieldComponent === Input) {
    refProps.innerRef = fieldRef;
  } else {
    refProps.ref = fieldRef;
  }

  if (FieldComponent === Input) {
    fieldProps = omit(fieldProps, ["formikProps"]) as any;
  }

  const handleBlur = (e: any) => {
    validate?.flush?.();
    field.onBlur(e);
  };

  if (typeof field.value === "undefined") {
    delete field.value;
  }

  if (props.onChange) {
    const formikOnChange = field.onChange;
    field.onChange = function (...args: any[]) {
      props.onChange?.apply(this, args as any);
      // @ts-ignore
      formikOnChange.apply(this, args);
    };
  }

  return (
    <>
      <FieldComponent
        // TODO: Review this ts error
        // @ts-ignore
        value=""
        // @ts-ignore
        name={name}
        {...refProps}
        {...fieldProps}
        {...field}
        phone_number_country={immutableProps.phone_number_country}
        className={cn(
          refProps.fieldProps,
          refProps.field,
          "h-10 !rounded-lg border-gray-300 !px-[0.7rem] !py-2 !text-gray-500 placeholder:text-gray-500",
          refProps.className,
          immutableProps.className,
          {
            "!bg-gray-100": props.disabled,
          },
        )}
        onBlur={handleBlur}
      />
      {props.showErrorLabel && renderErrorRowOnTouch(name, touched, errors)}
    </>
  );
}

export function FormikInputWithContext(
  props: Parameters<typeof FormikInput>[0],
) {
  const formik = useFormikContext() as ReturnType<typeof useFormik>;

  return <FormikInput {...props} formikProps={formik} />;
}

export default FormikInput;
