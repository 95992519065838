import { cn } from "@gymflow/helpers";
import { Transition } from "@headlessui/react";

import { SearchIcon } from "../../../icons";
import { LoadingDots } from "../../../LoadingDots";
import { OptionItem } from "./components/OptionItem";
import styles from "./OptionsList.module.css";
import { OptionsListProps } from "./types";

export const OptionsList = <T,>({
  dropPosition = "bottom",
  options,
  withImageByDefault = false,
  value,
  handleSelectOption,
  show,
  notFound = "Nothing found",
  notFoundClassName,
  onScroll,
  loading,
}: OptionsListProps<T>) => (
  <Transition
    show={show}
    className={cn(
      "absolute z-10 flex max-h-[19.25rem] w-full flex-col gap-0.5 overflow-y-auto rounded-xl border border-gray-200 bg-white px-2 py-1 shadow-md",
      styles["scrollbarCustom"],
      {
        "bottom-full -mb-6": dropPosition === "top",
        "top-full -mt-6": dropPosition === "bottom",
      },
    )}
    onScroll={onScroll}
    enter="transition ease-out duration-100"
    enterFrom="transform opacity-0 scale-95"
    enterTo="transform opacity-100 scale-100"
    leave="transition ease-in duration-75"
    leaveFrom="transform opacity-100 scale-100"
    leaveTo="transform opacity-0 scale-95"
  >
    {options.length > 0
      ? options.map((option) => (
          <OptionItem
            key={option.id}
            option={option}
            isSelected={value.some((v) => v.id === option.id)}
            onSelect={handleSelectOption}
            withImageByDefault={withImageByDefault}
          />
        ))
      : !loading && (
          <div
            className={cn(
              "flex items-center justify-center gap-2 p-2 text-sm font-medium text-gray-500",
              notFoundClassName,
            )}
          >
            {notFound ? (
              notFound
            ) : (
              <>
                <SearchIcon /> Nothing found
              </>
            )}
          </div>
        )}
    {loading && <LoadingDots />}
  </Transition>
);
