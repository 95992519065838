import {
  useMembershipPausingReport,
  useMembershipPausingReportCsv,
} from "@gymflow/api";
import { DATE_FORMAT, PARAMETER_DATE_FORMAT_WITHOUT_TZ } from "@gymflow/common";
import { downloadCsv } from "@gymflow/helpers";
import { MembershipPausingDTO } from "@gymflow/types";
import { createColumnHelper, SortingState } from "@tanstack/react-table";
import moment from "moment-timezone";
import qs from "qs";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";

import { LinkButton } from "../../components/atoms";
import { ReportPagination } from "../../components/molecules";
import { Table } from "../../components/organisms";
import { Report } from "../../components/organisms/Report";
import {
  MembershipPausingFilters,
  MembershipPausingFilterSidebar,
} from "../../components/templates";
import { usePortalRoutes } from "../../hooks/usePortalRoutes";
import { useClubSettings } from "../../providers";
import useGymflowModels from "../../store";

export function MembershipPausing() {
  const { createMemberLink } = usePortalRoutes();
  const history = useHistory();
  const { api } = useGymflowModels();

  const settings = useClubSettings();
  const location = useLocation();
  const searchParams = qs.parse(location.search, { ignoreQueryPrefix: true });

  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState(25);
  const [currentSort, setCurrentSort] = useState<SortingState>([]);
  const [filters, setFilters] = useState<MembershipPausingFilters>({});

  const createSort = function () {
    return currentSort?.[0]
      ? { field: currentSort[0]?.id, desc: currentSort[0]?.desc }
      : undefined;
  };

  const { data, isFetching } = useMembershipPausingReport({
    api,
    startDate: moment(startDate, PARAMETER_DATE_FORMAT_WITHOUT_TZ).format(
      PARAMETER_DATE_FORMAT_WITHOUT_TZ,
    ),
    endDate: moment(endDate, PARAMETER_DATE_FORMAT_WITHOUT_TZ).format(
      PARAMETER_DATE_FORMAT_WITHOUT_TZ,
    ),
    sort: createSort(),
    limit: pageSize,
    page: currentPage,
    tz: settings.timezone,
    membershipIncluded:
      filters?.membershipIncluded &&
      filters.membershipIncluded.map(({ id }) => id),
    membershipExcluded:
      filters?.membershipExcluded &&
      filters.membershipExcluded.map(({ id }) => id),
  });
  const downloadCsvMutation = useMembershipPausingReportCsv({
    api,
    tz: settings.timezone,
  });

  useEffect(() => {
    if (searchParams?.["startDate"]) {
      setStartDate(
        moment(searchParams["startDate"] as string, DATE_FORMAT).format(
          PARAMETER_DATE_FORMAT_WITHOUT_TZ,
        ),
      );
    }
    if (searchParams?.["endDate"]) {
      setEndDate(
        moment(searchParams["endDate"] as string, DATE_FORMAT)
          .endOf("day")
          .format(PARAMETER_DATE_FORMAT_WITHOUT_TZ),
      );
    }
  }, [searchParams?.["startDate"], searchParams?.["endDate"]]);

  const [filtersAreVisible, setFiltersAreVisible] = useState(false);

  const columnHelper = createColumnHelper<MembershipPausingDTO>();
  const columnsDefinition = [
    columnHelper.accessor(
      (row) => {
        return {
          firstName: row.firstName,
          lastName: row.lastName,
          id: row.userMemberId,
        };
      },
      {
        id: "firstName",
        cell: (info) => {
          const memberName = `${info.getValue().firstName} ${
            info.getValue().lastName
          }`;
          return (
            <div>
              <LinkButton to={createMemberLink(info.getValue().id)}>
                {memberName}
              </LinkButton>
            </div>
          );
        },
        header: () => {
          return <div >Name</div>;
        },
        enableSorting: false,
      },
    ),
    columnHelper.accessor("membershipName", {
      cell: (info) => <div className="text-gray-600">{info.getValue()}</div>,
      header: "Membership Name",
      enableSorting: false,
    }),
    columnHelper.accessor("pauseDate", {
      cell: (info) => {
        const pauseDate = info.getValue();
        return pauseDate ? (
          <div className="text-gray-600">
            {moment(pauseDate, PARAMETER_DATE_FORMAT_WITHOUT_TZ).format(
              settings.date_format,
            )}
          </div>
        ) : null;
      },
      header: "Pause Date",
      enableSorting: false,
    }),
    columnHelper.accessor("resumeDate", {
      cell: (info) => {
        const resumeDate = info.getValue();
        return resumeDate ? (
          <div className="text-gray-600">
            {moment(resumeDate, PARAMETER_DATE_FORMAT_WITHOUT_TZ).format(
              settings.date_format,
            )}
          </div>
        ) : null;
      },
      header: "Resume Date",
      enableSorting: false,
    }),
  ];

  const handleDateRangeChange = useCallback((newDate: any) => {
    if (newDate) {
      history.push({
        search: qs.stringify({
          startDate: newDate.startDate,
          endDate: newDate.endDate,
          backRoute: searchParams["backRoute"],
        }),
      });
    }
  }, []);

  return (
    <>
      <MembershipPausingFilterSidebar
        isVisible={filtersAreVisible}
        onClose={() => setFiltersAreVisible(false)}
        value={filters}
        onChange={setFilters}
      />
      <Report
        title="Memberships Pausing"
        description="List of all subscriptions that are pausing within the specified date period."
        onDownloadClick={async () => {
          const data = await downloadCsvMutation.mutateAsync({
            startDate: moment(
              startDate,
              PARAMETER_DATE_FORMAT_WITHOUT_TZ,
            ).format(PARAMETER_DATE_FORMAT_WITHOUT_TZ),
            endDate: moment(endDate, PARAMETER_DATE_FORMAT_WITHOUT_TZ).format(
              PARAMETER_DATE_FORMAT_WITHOUT_TZ,
            ),
            membershipIncluded:
              filters?.membershipIncluded &&
              filters.membershipIncluded.map(({ id }) => id),
            membershipExcluded:
              filters?.membershipExcluded &&
              filters.membershipExcluded.map(({ id }) => id),
          });
          downloadCsv(data, `MembershipsPausing.csv`);
        }}
        showFilters={() => setFiltersAreVisible(true)}
        handleDateRangeChange={handleDateRangeChange}
        dateRange={
          startDate && endDate
            ? {
                startDate: moment(
                  startDate,
                  PARAMETER_DATE_FORMAT_WITHOUT_TZ,
                ).format(DATE_FORMAT),
                endDate: moment(
                  endDate,
                  PARAMETER_DATE_FORMAT_WITHOUT_TZ,
                ).format(DATE_FORMAT),
              }
            : undefined
        }
        close={() => {
          history.push({
            pathname: searchParams["backRoute"] as string,
            search: qs.stringify({
              dates: {
                startDate: searchParams["startDate"],
                endDate: searchParams["endDate"],
              },
            }),
          });
        }}
        table={
          <>
            <Table
              data={data?.content ?? []}
              columns={columnsDefinition}
              pageCount={data?.totalPages}
              pageIndex={data?.number}
              onSortingChange={setCurrentSort}
              sort={currentSort}
              isFetching={isFetching}
              pageSize={pageSize}
              rowClassName="h-14"
            />
            <div className="border-t-[0.063rem] border-gray-300">
              <ReportPagination
                pageCount={data?.totalPages as number}
                currentPage={data?.number as number}
                onPageChange={(newPage) => {
                  setCurrentPage(newPage);
                }}
                pageSize={pageSize}
                setPageSize={(newValue) => {
                  setPageSize(newValue);
                  setCurrentPage(0);
                }}
              />
            </div>
          </>
        }
      />
    </>
  );
}
