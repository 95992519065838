import { useAutoAnimate } from "@formkit/auto-animate/react";
import {
  memberAsMemberQueryKeys,
  useMemberAsMember,
  useMemberInvoiceListAsMember,
  useMutationMemberSaleAsMember,
  useMutationPayInvoiceAsMember,
  useQueryCreditPackAsMember,
  useQueryCreditPackSummaryAsMember,
  useQueryMembershipAsMember,
  useQueryMembershipSummaryAsMember,
} from "@gymflow/api";
import { useParseErrors } from "@gymflow/common";
import {
  formatCurrency,
  LUXON_DATE_FORMAT,
  membershipHelper,
  portalIsLocalhost,
} from "@gymflow/helpers";
import { InvoiceDTO } from "@gymflow/types";
import { useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { DateTime } from "luxon";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";

import { usePortalRoutes } from "../../hooks";
import {
  ModalWrapper,
  useApiResolverContext,
  useAuthenticatedUser,
  useClubSettings,
} from "../../providers";
import { RouteFeature, RouteLayout } from "../../routes";
import useGymflowModels from "../../store";
import { Button, ErrorMessage, LabeledForm, Spinner } from "../atoms";
import { DatePicker } from "../molecules";
import { PaymentMethodPicker } from "../molecules/PaymentMethodPicker";
import { ShopCartType } from "../Shop/ShopTypes";
import { HostedPagesBackHeader } from "./HostedPagesBackHeader";

export type HostedPagesCheckoutProps = {
  type: ShopCartType["type"];
};

export const HostedPagesCheckout: React.FC<HostedPagesCheckoutProps> = ({
  type,
}) => {
  const { reinitializeWithAuth } = useApiResolverContext();
  useEffect(() => {
    reinitializeWithAuth();
  }, [reinitializeWithAuth]);
  const history = useHistory();
  const [currentInvoiceId, setCurrentInvoiceId] = useState<string>();
  const [scaRedirectUrl, setScaRedirectUrl] = useState<string>();
  const { id: memberId } = useAuthenticatedUser();
  const { createClubLink } = usePortalRoutes();
  useEffect(() => {
    if (!memberId) {
      history.replace(
        createClubLink(RouteLayout.Site, RouteFeature.SiteBuyMembership),
      );
    }
  }, [createClubLink, history, memberId]);
  const { api } = useGymflowModels();
  const settings = useClubSettings();
  const { data: userMember } = useMemberAsMember({
    api,
    memberId,
    tz: settings.timezone,
  });
  const params = useParams<{ id: string }>();
  const cartId = Number(params.id);

  const [promotionCodeInput, setPromotionCodeInput] = useState<string>();
  const [currentPaymentMethodId, setCurrentPaymentMethodId] =
    useState<string>();

  const [cart, setCart] = useState<ShopCartType>();
  const { data: creditPack, isSuccess: isCreditPackSuccess } =
    useQueryCreditPackAsMember({
      api,
      id: type === "CREDIT_PACK" && cartId !== undefined ? +cartId : undefined,
      enabled: !!api.customerCreditPackApi,
    });
  useEffect(() => {
    if (isCreditPackSuccess) {
      setCart({
        type: "CREDIT_PACK",
        payload: creditPack,
      });
    }
  }, [isCreditPackSuccess, creditPack]);
  const { data: membership, isSuccess: isMembershipSuccess } =
    useQueryMembershipAsMember({
      api,
      id: type === "MEMBERSHIP" && cartId !== undefined ? +cartId : undefined,
      enabled: !!api.customerMembershipApi,
    });
  useEffect(() => {
    if (isMembershipSuccess) {
      setCart({
        type: "MEMBERSHIP",
        payload: membership,
      });
    }
  }, [isMembershipSuccess, membership]);

  const [promotionCode, setPromotionCode] = useState<string>();
  const { data: creditPackSummary, isFetching: creditPackSummaryIsFetching } =
    useQueryCreditPackSummaryAsMember({
      api,
      opts: {
        sessionPackId: cart?.type === "CREDIT_PACK" ? cart.payload.id : 0,
        promotionCode: promotionCode,
      },
      enabled: cart?.type === "CREDIT_PACK",
    });
  const { data: membershipSummary, isFetching: membershipSummaryIsFetching } =
    useQueryMembershipSummaryAsMember({
      api,
      opts: {
        membershipId: cart?.type === "MEMBERSHIP" ? cart.payload.id : 0,
        promotionCode: promotionCode,
      },
      enabled: cart?.type === "MEMBERSHIP",
    });

  const isFetchingSummary =
    creditPackSummaryIsFetching || membershipSummaryIsFetching; //|| productSummaryIsFetching;
  const summary = creditPackSummary || membershipSummary; // || productSummary;
  const { data: invoices } = useMemberInvoiceListAsMember({
    api,
    opts: {
      extraParams: {
        number: currentInvoiceId,
      },
    },
    enabled: !!currentInvoiceId,
  });
  const invoice = invoices?.content?.[0];
  const [startDate, setStartDate] = useState<string>(
    DateTime.now()
      .setZone(settings.timezone)
      .startOf("day")
      .toFormat(LUXON_DATE_FORMAT),
  );

  const { mutateAsync: payFailed } = useMutationPayInvoiceAsMember({
    api,
  });
  const { mutateAsync: checkout } = useMutationMemberSaleAsMember({
    api,
    tz: settings.timezone,
    clubId: settings.clubId,
  });
  const parseError = useParseErrors();
  const onPay = async () => {
    if (!cart) return;
    try {
      let result: { invoice: InvoiceDTO };
      const paymentMethod = isFree ? undefined : currentPaymentMethodId;
      if (cart.type === "MEMBERSHIP") {
        result = await checkout({
          paymentMethod,
          promotionCode,
          startDate,
          membershipId: cart.payload.id,
        });
      } else if (cart.type === "CREDIT_PACK") {
        result = await checkout({
          paymentMethod,
          promotionCode,
          startDate,
          sessionPackId: cart.payload.id,
        });
      } else {
        result = await checkout({
          paymentMethod,
          promotionCode,
          products: cart.payload.map((e) => ({
            productId: e.product.id,
            quantity: e.quantity,
          })),
        });
      }
      setCurrentInvoiceId(result.invoice.number);
      setScaRedirectUrl(result.invoice.scaRedirectUrl);
    } catch (e) {
      if (e instanceof AxiosError) {
        await parseError(e?.response);
      }
    }
  };
  const queryClient = useQueryClient();
  const scaFinished = useCallback(() => {
    queryClient.invalidateQueries(memberAsMemberQueryKeys.all());
    setScaRedirectUrl(undefined);
  }, [queryClient]);

  useEffect(() => {
    if (!portalIsLocalhost()) return;
    const listener = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        scaFinished();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [scaFinished]);
  const isFree = summary?.upfrontPriceDetails?.totalAmountToPay === 0;
  const [parent] = useAutoAnimate();
  const paymentsLoadingStatus = queryClient.getQueryState(
    memberAsMemberQueryKeys.paymentMethods(settings.clubId, memberId),
  )?.status;
  const arePaymentMethodsLoading =
    !paymentsLoadingStatus || paymentsLoadingStatus === "loading";
  return (
    <div className="flex h-full w-full flex-col items-center bg-gray-50 dark:bg-gray-800">
      <HostedPagesBackHeader label="Checkout" />
      <div
        ref={parent}
        className="flex h-full w-full max-w-lg flex-col gap-4 px-4 py-6"
      >
        {invoice && (
          <div className="flex w-full flex-col items-center gap-y-3">
            <div className="text-3xl font-bold text-gray-950 dark:text-[#ffffff]">
              {formatCurrency(invoice.totalAmount, settings.defaultCurrency)}
            </div>
            <div className="flex flex-col items-center gap-1">
              {invoice.status === "PAID" && (
                <>
                  <div className="text-success-600 dark:text-success-400 text-center font-bold">
                    Payment Successful
                  </div>
                  <div className="font-medium text-gray-600 dark:text-gray-400">
                    {DateTime.fromISO(
                      invoice.issueDate ?? new Date().toISOString(),
                    ).toLocaleString(DateTime.DATE_MED)}
                  </div>
                </>
              )}
              {invoice.status === "PAST_DUE" && (
                <div className="flex flex-col items-center gap-3">
                  <div className="text-error-600 dark:text-error-400 text-center font-bold">
                    Payment Failed
                  </div>
                  <div className="font-medium text-gray-600 dark:text-gray-400">
                    <Button
                      onClick={async () => {
                        const newInvoice = await payFailed({
                          invoiceNumber: invoice.number,
                          amount: invoice.totalAmount,
                          paymentMethodId: currentPaymentMethodId,
                        });
                        if (newInvoice.scaRedirectUrl) {
                          setScaRedirectUrl(newInvoice.scaRedirectUrl);
                        }
                      }}
                    >
                      Retry Payment
                    </Button>
                  </div>
                </div>
              )}
              {invoice.status === "AWAITING_AUTHORIZATION" && (
                <div className="text-warning-600 text-center font-bold">
                  Payment Awaiting Authorization
                </div>
              )}
            </div>
          </div>
        )}
        {!isFree && userMember && (
          <div className="max-w-full">
            <PaymentMethodPicker
              userMember={userMember}
              currentPaymentMethodId={currentPaymentMethodId}
              onChange={setCurrentPaymentMethodId}
              disabled={!!invoice}
              asMember
            />
          </div>
        )}
        {cart?.type === "MEMBERSHIP" && (
          <LabeledForm label="Membership Start Date">
            <DatePicker
              label={DateTime.fromFormat(startDate, LUXON_DATE_FORMAT)
                .setZone("local", { keepLocalTime: true })
                .toLocaleString(DateTime.DATE_MED)}
              mode="single"
              modifiers={{
                disabled: (d) => {
                  if (!membership?.fixedStartDate) return false;
                  const selected = DateTime.fromFormat(
                    startDate,
                    LUXON_DATE_FORMAT,
                  ).setZone("local", { keepLocalTime: true });
                  return !selected.hasSame(DateTime.fromJSDate(d), "day");
                },
              }}
              hidden={{
                before: DateTime.now().toJSDate(),
              }}
              selected={DateTime.fromFormat(startDate, LUXON_DATE_FORMAT)
                .setZone("local", { keepLocalTime: true })
                .toJSDate()}
              handleDateSave={(newDate) => {
                if (!newDate) {
                  return;
                }
                setStartDate(
                  DateTime.fromJSDate(newDate).toFormat(LUXON_DATE_FORMAT),
                );
              }}
            />
          </LabeledForm>
        )}
        {!!invoice && promotionCode && (
          <div className="flex flex-row items-center justify-between">
            <div className="text-base text-gray-500">Promotional Code</div>
            <div className="text-base font-bold text-gray-950">
              {promotionCode}
            </div>
          </div>
        )}
        {!invoice && (
          <LabeledForm label="Promotional Code">
            <div className="inline-flex h-10 flex-row items-start justify-start rounded-lg border border-gray-300 bg-white shadow-sm dark:border-gray-700 dark:bg-gray-800">
              <div className="flex h-full flex-1 items-center justify-start gap-2 overflow-hidden rounded-lg rounded-r-none border-r border-gray-300 bg-white dark:border-gray-700 dark:bg-gray-950">
                <input
                  placeholder="Enter Code"
                  className="h-full w-full px-3 py-2 text-gray-500 outline-none dark:bg-gray-950"
                  value={promotionCodeInput ?? ""}
                  onChange={(e) => {
                    setPromotionCodeInput(e.target.value);
                  }}
                  disabled={promotionCode !== undefined}
                />
              </div>
              <Button
                onClick={() => {
                  if (!promotionCode) {
                    setPromotionCode(promotionCodeInput);
                  } else {
                    setPromotionCode(undefined);
                    setPromotionCodeInput("");
                  }
                }}
                className="h-full min-h-0 !rounded-lg !rounded-l-none !border-none focus:!outline-none"
              >
                {isFetchingSummary ? (
                  <Spinner />
                ) : promotionCode === undefined ? (
                  "Apply"
                ) : (
                  "Remove"
                )}
              </Button>
            </div>
          </LabeledForm>
        )}
        <div className="flex w-full border-b-2 border-dashed border-gray-400 dark:border-gray-600" />
        <div className="flex w-full flex-col gap-2">
          {summary &&
            promotionCode &&
            summary?.validPromotionCode !== "YES" && (
              <div className="flex w-full flex-row justify-between font-semibold text-red-500">
                The current promotion code is not valid
              </div>
            )}
          {summary && cart && cart.type !== "PRODUCT" && (
            <div className="flex w-full flex-row justify-between">
              <div className="font-medium text-gray-500">
                {cart.payload.name}
              </div>
              <div className="font-medium text-gray-500">
                {formatCurrency(
                  summary.upfrontPriceDetails.totalAmountBeforeDiscount,
                  settings.defaultCurrency,
                )}
              </div>
            </div>
          )}
          {summary &&
            cart &&
            cart.type === "PRODUCT" &&
            cart.payload.map((e) => (
              <div
                key={e.product.id}
                className="flex w-full flex-row justify-between text-gray-950 dark:text-[#ffffff]"
              >
                <div>{e.product.name}</div>
                <div className="flex flex-row gap-1">
                  <div>{e.quantity} x</div>
                  <div className="font-medium">
                    {formatCurrency(e.product.price, settings.defaultCurrency)}
                  </div>
                </div>
              </div>
            ))}
          {!!summary &&
            "promotionUpfrontAmount" in summary &&
            summary.promotionUpfrontAmount !== undefined && (
              <div className="text-success-600 dark:text-success-400 flex w-full flex-row justify-between">
                <div className="font-medium">Upfront Discount</div>
                <div className="font-medium">
                  {summary.promotionUpfrontDiscountType === "CURRENCY"
                    ? formatCurrency(
                        -summary.promotionUpfrontAmount,
                        settings.defaultCurrency,
                      )
                    : `-${summary.promotionUpfrontAmount}%`}
                </div>
              </div>
            )}
          {!!summary &&
            "promotionRecurringAmount" in summary &&
            "promotionRecurringDiscountType" in summary &&
            "promotionRecurringAmount" in summary &&
            summary.promotionRecurringAmount !== undefined && (
              <div className="text-success-600 dark:text-success-400 flex w-full flex-row justify-between">
                <div className="font-medium">
                  Recurring Discount (each cycle)
                </div>
                <div className="font-medium">
                  {summary.promotionRecurringDiscountType === "CURRENCY"
                    ? formatCurrency(
                        -(summary.promotionRecurringAmount ?? 0),
                        settings.defaultCurrency,
                      )
                    : `-${summary.promotionRecurringAmount}%`}
                </div>
              </div>
            )}
          {summary && (
            <div className="flex w-full flex-row items-center justify-between text-gray-950 dark:text-[#ffffff]">
              <div className="text-xl font-bold">Amount Due</div>
              <div className="text-xl font-bold">
                {formatCurrency(
                  summary.upfrontPriceDetails.totalAmountToPay,
                  settings.defaultCurrency,
                )}
              </div>
            </div>
          )}
        </div>
        {!invoice && (
          <div className="mt-auto flex w-full flex-col gap-1">
            {cart?.type === "MEMBERSHIP" &&
              userMember?.subscriptions.some(
                membershipHelper.isCurrentSubscription,
              ) && <ErrorMessage text="You already have a subscription" />}
            {!arePaymentMethodsLoading &&
              !currentPaymentMethodId &&
              !isFree && (
                <ErrorMessage text="Please select a payment method to continue" />
              )}
            <Button
              intent="secondary"
              className="w-full"
              disabled={
                (!currentPaymentMethodId && !isFree) ||
                !summary ||
                (cart?.type === "MEMBERSHIP" &&
                  userMember?.subscriptions.some(
                    membershipHelper.isCurrentSubscription,
                  ))
              }
              onClick={onPay}
            >
              {isFree
                ? "Get For Free"
                : `Pay ${
                    summary
                      ? formatCurrency(
                          summary.upfrontPriceDetails.totalAmountToPay,
                          settings.defaultCurrency,
                        )
                      : ""
                  }`}
            </Button>
          </div>
        )}
      </div>
      {scaRedirectUrl && (
        <ModalWrapper
          className="h-[90vh] w-full !max-w-[90vw]"
          onCancel={() => {}}
        >
          <iframe
            className="flex h-full w-full"
            title="Payment Authorization"
            src={scaRedirectUrl}
            onLoad={(e) => {
              const iframe = e.target as HTMLIFrameElement;
              try {
                // This operation `throws` when the iframe domain is not our own
                // @ts-expect-error
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const _ = iframe.contentWindow?.location.host;
                scaFinished();
              } catch {}
            }}
          />
        </ModalWrapper>
      )}
    </div>
  );
};
