import { FacilityDTO } from "@gymflow/types";

import {
  ArrowLeftIcon,
  ArrowRightIcon,
  PlaceholderAvatar,
  SquarishAvatar,
  UsersIcon,
} from "../../atoms";

export function SelectFacilityHost({
  facilities,
  onSelectHost,
  onBack,
  serviceCategory,
}: {
  facilities: FacilityDTO[];
  onSelectHost: ({ host }: { host: FacilityDTO | null }) => Promise<void>;
  onBack: () => void;
  serviceCategory: string;
}) {
  return (
    <div className="flex flex-col gap-6">
      <div className="flex items-center gap-4">
        <div
          className="dark:hover:bg-darkGray-900 dark:border-darkGray-800 cursor-pointer rounded-lg border border-gray-300 px-3 py-2 hover:bg-gray-50"
          onClick={onBack}
        >
          <ArrowLeftIcon pathClassName="stroke-gray-500 dark:stroke-textTertiary-600" />
        </div>
        <div className="text-2xl font-bold dark:text-white">Select Host</div>
      </div>
      <div className="flex flex-col gap-4">
        <div
          className="dark:hover:bg-darkGray-900 dark:border-darkGray-800 flex cursor-pointer items-center justify-between gap-4 rounded-lg border border-gray-300 p-4 hover:bg-gray-50"
          onClick={() => {
            onSelectHost({ host: null });
          }}
        >
          <div>
            <div className="dark:bg-darkGray-800 dark:border-darkGray-800 h-12 w-12 rounded-lg border border-gray-300 bg-gray-100 p-3">
              <UsersIcon className="dark:stroke-textTertiary-600 stroke-gray-500" />
            </div>
          </div>
          <div className="flex-1">
            <div className="dark:text-darkGray-200 text-base font-medium text-gray-900">
              No Preference
            </div>
            <div className="dark:text-darkGray-400 text-sm font-normal text-gray-400">
              Maximum Availability
            </div>
          </div>
          <div>
            <ArrowRightIcon pathClassName="stroke-gray-500 dark:stroke-textTertiary-600" />
          </div>
        </div>
        {facilities.map((facility) => {
          const fullName = `${facility.name}`;
          return (
            <div
              key={facility.id}
              className="dark:border-darkGray-800 dark:hover:bg-darkGray-900 flex cursor-pointer items-center justify-between gap-4 rounded-lg border border-gray-300 p-4 hover:bg-gray-50"
              onClick={() => {
                onSelectHost({ host: facility });
              }}
            >
              <div className="rounded-lg">
                {facility.picture ? (
                  <SquarishAvatar
                    pictureUrl={facility.picture}
                    className="h-12 w-12"
                  />
                ) : (
                  <PlaceholderAvatar name={fullName} className="h-12 w-12" />
                )}
              </div>
              <div className="flex-1">
                <div className="dark:text-darkGray-200 text-base font-medium text-gray-900">
                  {fullName}
                </div>
                <div className="dark:text-darkGray-400 text-sm font-normal text-gray-400">
                  {serviceCategory}
                </div>
              </div>
              <div>
                <ArrowRightIcon pathClassName="stroke-gray-500 dark:stroke-textTertiary-600" />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
