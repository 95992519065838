import {
  InvoiceDTO,
  PaymentMethodDTO,
  UserMemberSubscriptionSignUpBeanAsMember,
} from "@gymflow/types";
import axios, { AxiosInstance } from "axios";
import { assert } from "check-types";

import endpoints from "./endpoints";
// @ts-ignore
import { canFind, canFindById, canTrimFields } from "./features/apiResource";
// @ts-ignore
import canSignUp from "./features/canSignUp";

const profileEndpoints = {
  createUserFullDetails: `${endpoints.customerUserMembers}`,
  findByEmail: `${endpoints.customerUserMembers}/email/`,
  resumeSubscription: `${endpoints.customerUserMembers}/service/resume/:subscriptionId`,
  pauseSubscription: `${endpoints.customerUserMembers}/service/pause/:subscriptionId`,
  cancelSubscription: `${endpoints.customerUserMembers}/service/cancel/:subscriptionId`,
  findInvoices: `${endpoints.customerUserMembers}/invoices`,
  payInvoice: `${endpoints.customerUserMembers}/pay-invoice/`,
  invoiceFile: `${endpoints.customerUserMembers}/invoice-file/:invoiceNumber`,
};

export type ProfileApiType = ReturnType<typeof profileApi>;

const profileApi = (
  axiosInstance: AxiosInstance,
  apiUrl: string,
  clubId: number,
) => {
  const clubPrefix = `club/${clubId}/` as const;
  const initialState = {
    fieldsToTrim: [],
    baseUrl: `${clubPrefix}${endpoints.customerUserMembers}`,
    http: axiosInstance,
    apiUrl,
  };
  const state = {
    ...initialState,
    ...canTrimFields(initialState),
    ...canFind(initialState),
    ...canFindById(initialState),
    ...canSignUp(),
  };

  return Object.assign(state, {
    create(newResource: any) {
      const trimmedData = state.trimFields(newResource);

      return state.http.post(
        `${clubPrefix}${profileEndpoints.createUserFullDetails}`,
        state.createRequestBody(trimmedData),
      );
    },

    update(_: any, clubId: number, patchedFields: any) {
      assert.number(clubId, "clubId must be a number");
      return state.http.patch(
        `${state.baseUrl}`,
        state.createRequestBody(state.trimFields(patchedFields)),
      );
    },

    checkout(patchedFields: {
      paymentMethod?: string;
      subscriptionSignUpBean: UserMemberSubscriptionSignUpBeanAsMember;
    }) {
      return (state.http as AxiosInstance).patch<{
        invoice: InvoiceDTO;
      }>(`${state.baseUrl}`, state.trimFields(patchedFields));
    },

    findById() {
      return state.http.get(`${state.baseUrl}`);
    },

    async updatePicture(_: any, { blob, name }: any) {
      const blobResponse = await axios.get(blob, {
        responseType: "blob",
      });
      const formData = new FormData();
      formData.append("file", blobResponse.data, name);
      return state.http.post(`/${state.baseUrl}/picture`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },

    async fetchPaymentMethods(_: string, clubId: number) {
      const result = await state.http.get<PaymentMethodDTO[]>(
        `${clubPrefix}customer/payment-method`,
      );
      // TODO: cleanup PAYMENT_REDESIGN
      (result as any).data = (result as any).data.map((e: any) => ({
        ...e,
        id: e.paymentMethodId,
      }));
      return result;
    },
    attachPaymentMethod(_: string, clubId: number, paymentMethodId: string) {
      return state.http.put(
        `${clubPrefix}customer/payment-method/card/${paymentMethodId} `,
      );
    },

    removePaymentMethod(_: string, clubId: number, paymentMethodId: string) {
      return state.http.delete(
        `${clubPrefix}customer/payment-method/${paymentMethodId}`,
      );
    },

    assignDefaultPaymentMethod(
      _: string | undefined,
      __: number | undefined,
      paymentMethodId: string,
    ) {
      return state.http.put(
        `${clubPrefix}customer/payment-method/default/${paymentMethodId}`,
      );
    },

    resumeSubscription(_: string, subscriptionId: number) {
      const url = `${clubPrefix}${profileEndpoints.resumeSubscription}`.replace(
        ":subscriptionId",
        `${subscriptionId}`,
      );

      return state.http.post(url);
    },

    pauseSubscription(_: string, subscriptionId: number) {
      const url = `${clubPrefix}${profileEndpoints.pauseSubscription}`.replace(
        ":subscriptionId",
        `${subscriptionId}`,
      );

      return state.http.post(url);
    },

    cancelSubscription(_: string, subscriptionId: number) {
      const url = `${clubPrefix}${profileEndpoints.cancelSubscription}`.replace(
        ":subscriptionId",
        `${subscriptionId}`,
      );

      return state.http.post(url);
    },

    findInvoices(
      _: string | undefined,
      { page = 0, limit = 10, extraParams = {}, sort }: any,
    ) {
      const url = `${clubPrefix}${profileEndpoints.findInvoices}`;

      assert.maybe.number(page, "page must be number or undefined");
      assert.maybe.number(limit, "limit must be number or undefined");

      const config = {
        params: {
          page,
          size: limit,
          ...extraParams,
        },
      };
      if (sort) {
        assert.string(
          sort.field,
          "sort.field must be a string if sort is specified.",
        );
        config.params.sort = state.createSortParam(sort.field, sort.desc);
      }

      return state.http.get(url, config);
    },

    payInvoice(invoiceNumber: string, amount: number) {
      const url = `${clubPrefix}${profileEndpoints.payInvoice}` + invoiceNumber;
      return (state.http as AxiosInstance).post<InvoiceDTO>(url, {
        amountToPay: amount,
      });
    },

    invoiceFile(clubId: number, invoiceNumber: string) {
      assert.number(clubId, "clubId must be a number");
      assert.string(invoiceNumber, "invoiceNumber must be a string");

      const url = `${clubPrefix}${profileEndpoints.invoiceFile}`.replace(
        ":invoiceNumber",
        invoiceNumber,
      );

      return state.http.get(url);
    },
    updateEmailAndPassword(payload: any) {
      const url = `${clubPrefix}customer/user/member/account`;
      return state.http.patch(url, payload);
    },
  });
};

export default profileApi;
