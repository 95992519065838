import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { TokenPageableRequestParams } from "@gymflow/types";
import { useInfiniteQuery } from "@tanstack/react-query";

import { memberQueryKeys } from "./memberQueryKeys";

export function useInfiniteQueryMemberInvoiceListNew({
  api,
  memberId,
  params,
}: {
  api: ApiType;
  memberId: string;
  params: TokenPageableRequestParams;
}) {
  return useInfiniteQuery({
    queryKey: memberQueryKeys.invoicesNew(memberId, params),
    queryFn: async ({ pageParam }: { pageParam?: string }) => {
      return (
        await api.memberApi.findInvoicesNew({
          memberId,
          ...params,
          nextPageToken: pageParam,
        })
      ).data;
    },
    getNextPageParam: (lastPage) => lastPage?.nextPageToken,
  });
}
