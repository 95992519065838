/* eslint-disable react/prop-types */
import { UTCDateMini } from "@date-fns/utc";
import {
  useMemberCalculateProRataForResumingSubscription,
  useMemberSubscription,
  useQueryMemberPaymentMethodListNew,
} from "@gymflow/api";
import {
  NotificationContext,
  PaymentConfirmationStatus,
  useParseErrors,
  usePaymentAuthorizationAlert,
} from "@gymflow/common";
import {
  dateStringToDate,
  formatCurrency,
  lastSecondOfYesterday,
} from "@gymflow/helpers";
import {
  InvoiceStatus,
  UserMemberSubscriptionBeanWithMembership,
} from "@gymflow/types";
import { useQuery } from "@tanstack/react-query";
import { ModalContext, useClubSettings } from "apps/portal/src/providers";
import useGymflowModels from "apps/portal/src/store";
import { isSameMonth } from "date-fns";
import React, { useCallback, useContext } from "react";

import { ConfirmModal } from "../../../templates";
import { NotPrimaryMembershipWarning } from "../NotPrimaryMembershipWarning";

export interface ResumeMembershipModalProps {
  memberId: string;
  membership: UserMemberSubscriptionBeanWithMembership;
}

export const ResumeMembershipModal: React.FC<ResumeMembershipModalProps> = ({
  memberId,
  membership,
}) => {
  const { timezone, clubId, defaultCurrency, minimum_charge } =
    useClubSettings();
  const { api } = useGymflowModels();
  const calculateProRataMutation =
    useMemberCalculateProRataForResumingSubscription({
      api,
      tz: timezone,
    });
  const { data: paymentMethods } = useQueryMemberPaymentMethodListNew({
    api,
    clubId,
    memberId,
    enabled: true,
  });
  const defaultPaymentMethod = paymentMethods?.find(
    (pm) => pm.defaultPaymentMethod,
  );
  const { data: prorata } = useQuery({
    queryKey: ["prorata", membership.id, memberId],
    queryFn: async () => {
      const result = await calculateProRataMutation.mutateAsync({
        subscriptionId: membership.id,
        resumeDate: lastSecondOfYesterday(timezone),
        memberId,
      });
      return result > 0 ? result : 0;
    },
  });

  const parseError = useParseErrors();

  const { hide } = useContext(ModalContext);

  const { resumeSubscriptionEarlyMutation } = useMemberSubscription(
    {
      api,
      tz: timezone,
    },
    {
      onError: (error) => {
        parseError((error as any)?.response);
      },
    },
  );
  const { notify } = useContext(NotificationContext);
  const { show: showPaymentConfirmationAlert } = usePaymentAuthorizationAlert();
  const checkForScaResponse = useCallback(
    async ({
      status,
      paymentIntentIdForAuthorization,
    }: {
      status: InvoiceStatus;
      paymentIntentIdForAuthorization: string;
    }) => {
      if (status === "AWAITING_AUTHORIZATION") {
        const paymentConfirmationResult = await showPaymentConfirmationAlert({
          paymentIntentIdForAuthorization,
          confirmPayment: api.strongCustomerAuthorizationApi.confirmPayment,
          messageText: (
            <>
              The user must authorize this payment before it will be processed.
              <br />
              Please ask the user to authorize the payment by clicking the link
              sent to their email.
            </>
          ),
        });

        if (
          paymentConfirmationResult.status === PaymentConfirmationStatus.Waiting
        ) {
          notify({
            message: "Awaiting payment, check later.",
            type: "warning",
          });
        } else if (
          paymentConfirmationResult.status === PaymentConfirmationStatus.Failed
        ) {
          notify({ message: "Payment Failed.", type: "danger" });
        }
      }
    },
    [
      api.strongCustomerAuthorizationApi.confirmPayment,
      notify,
      showPaymentConfirmationAlert,
    ],
  );
  const resumeSubscriptionByUser = useCallback(
    async (isWaived: boolean) => {
      const result = await resumeSubscriptionEarlyMutation.mutateAsync({
        ...(isWaived === true
          ? { isWaived: true }
          : {
              isWaived: false,
              paymentMethodId: defaultPaymentMethod?.id,
            }),
        resumeDate: lastSecondOfYesterday(timezone),
        subscriptionId: membership.id,
        memberId,
      }) as any;
      await checkForScaResponse({
        status: result.data?.invoice?.status,
        paymentIntentIdForAuthorization:
          result.data?.invoice?.paymentIntentIdForAuthorization,
      });
    },
    [
      checkForScaResponse,
      defaultPaymentMethod?.id,
      memberId,
      membership.id,
      resumeSubscriptionEarlyMutation,
      timezone,
    ],
  );
  const isChargeable = !(
    isSameMonth(
      dateStringToDate(membership.pauseStartDate),
      new UTCDateMini(),
    ) ||
    prorata === undefined ||
    prorata < minimum_charge
  );
  return (
    <ConfirmModal
      onConfirm={async () => {
        if (isChargeable) {
          await resumeSubscriptionByUser(false);
        } else {
          await resumeSubscriptionByUser(true);
        }
        hide();
      }}
      title="Resume Membership"
      type="default"
      onCancel={async () => {
        if (isChargeable) {
          await resumeSubscriptionByUser(true);
        }
        hide();
      }}
      onHide={async () => {
        hide();
      }}
      confirmText={isChargeable ? "Charge" : "Resume"}
      cancelText={isChargeable ? "Waive" : "Close"}
    >
      <div className="flex flex-col gap-4">
        <div>Memberships will not be billed while paused.</div>
        <div>Members can resume at anytime in the members app.</div>
        {isSameMonth(
          dateStringToDate(membership.pauseStartDate),
          new UTCDateMini(),
        ) ? (
          <div>
            You&apos;re resuming this membership in the same month it was paused
            so there aren&apos;t any fees to collect.
          </div>
        ) : prorata !== undefined ? (
          prorata < minimum_charge ? (
            <div>
              Due to the prorata being below the minimum allowed, you can only
              Waive the fees.
            </div>
          ) : (
            <div>
              Membership Pro-rata due:{" "}
              {formatCurrency(prorata, defaultCurrency)}
            </div>
          )
        ) : undefined}
        <NotPrimaryMembershipWarning membership={membership} />
      </div>
    </ConfirmModal>
  );
};
