import { useTaskDelete, useTaskEdit } from "@gymflow/api";
import { NotificationContext } from "@gymflow/common";
import { useClubSettings } from "apps/portal/src/providers";
import useGymflowModels from "apps/portal/src/store";
import { useCallback, useContext } from "react";

import { TaskForm } from "./components/TaskForm/TaskForm";
import { TaskFormProps, TaskFormValues } from "./components/TaskForm/type";
import { normalizeOutgoingData } from "./utils/dataNormalizer";

type EditTaskSidebarProps = {
  taskId?: number;
  onUpdate?: () => void;
} & Pick<TaskFormProps, "onClose" | "initialValues">;

export const EditTaskForm = ({
  onClose,
  initialValues,
  taskId,
  onUpdate,
}: EditTaskSidebarProps) => {
  const { notify, notifyDanger } = useContext(NotificationContext);
  const { api } = useGymflowModels();
  const { timezone: tz } = useClubSettings();
  const { mutateAsync: mutateAsyncUpdateTask } = useTaskEdit({ api, tz });
  const { mutateAsync: mutateAsyncRemoveTask } = useTaskDelete({ api });

  const onSubmit = useCallback(
    async (values: TaskFormValues) => {
      if (!taskId) return;

      try {
        await mutateAsyncUpdateTask({
          taskId,
          patchedFields: normalizeOutgoingData(values),
        });

        notify({ message: "Task Updated" });
        onUpdate && onUpdate();
        onClose();
      } catch (e) {
        notifyDanger(e);
      }
    },
    [mutateAsyncUpdateTask, notify, notifyDanger, onClose, onUpdate, taskId],
  );

  const onDelete = useCallback(async () => {
    if (!taskId) return;

    try {
      await mutateAsyncRemoveTask(taskId);

      notify({ message: "Task Deleted" });
      onUpdate && onUpdate();
      onClose();
    } catch (e) {
      notifyDanger(e);
    }
  }, [mutateAsyncRemoveTask, notify, notifyDanger, onClose, onUpdate, taskId]);

  return (
    <TaskForm
      initialValues={initialValues}
      title="Edit Task"
      description="Edit tasks by updating the assigned owners and linking them to any user or lead."
      submitButtonText="Save"
      onClose={onClose}
      onDelete={onDelete}
      onSubmit={onSubmit}
    />
  );
};
