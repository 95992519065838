import { useAutoAnimate } from "@formkit/auto-animate/react";
import { useQueryMembershipAsPublic } from "@gymflow/api";
import { formatCurrency, membershipHelper } from "@gymflow/helpers";
import { usePortalRoutes } from "apps/portal/src/hooks";
import {
  useApiResolverContext,
  useAuthenticatedUser,
  useClubSettings,
} from "apps/portal/src/providers";
import { useLoginOverlay } from "apps/portal/src/providers/authentication/loginOverlay/context";
import { RouteFeature, RouteLayout } from "apps/portal/src/routes";
import React, { useEffect } from "react";
import { useParams } from "react-router";

import useGymflowModels from "../../../store";
import { Button, CreditsIcon, Spinner } from "../../atoms";
import { HostedPagesBackHeader } from "../HostedPagesBackHeader";
import { HostedPagesCard } from "../HostedPagesCard";

export type HostedPagesMembershipProps = {};

export const HostedPagesMembership: React.FC<
  HostedPagesMembershipProps
> = ({}) => {
  const { reinitializeWithAuth } = useApiResolverContext();
  useEffect(() => {
    reinitializeWithAuth();
  }, [reinitializeWithAuth]);
  const auth = useAuthenticatedUser();
  const { showLoginOverlay } = useLoginOverlay();
  const { createClubLink } = usePortalRoutes();
  const { api } = useGymflowModels();
  const settings = useClubSettings();
  const { membershipId } = useParams<{ membershipId: string }>();
  const { data, isLoading } = useQueryMembershipAsPublic({
    api,
    membershipId: parseInt(membershipId),
  });

  const [parent] = useAutoAnimate();
  return (
    <div className="flex h-full max-h-full w-full flex-col">
      <HostedPagesBackHeader label="Memberships" />
      <div
        ref={parent}
        className="flex h-full flex-col items-center bg-gray-50 dark:bg-gray-800"
      >
        {isLoading && <Spinner />}
        {!isLoading && data && (
          <div className="flex h-full w-[32rem] max-w-full flex-col items-center justify-between gap-4 overflow-y-auto overflow-x-hidden p-6">
            <div className="flex w-full flex-col items-center gap-8">
              <div className="flex w-full flex-col gap-1">
                <div className="flex w-full text-xl font-bold text-gray-950 dark:text-[#ffffff]">
                  {data.name}
                </div>
                <div className="flex w-full text-sm text-gray-600 dark:text-gray-400">
                  {data.description}
                </div>
              </div>
              {data.addonsProratedList?.length &&
                data.addonsProratedList?.length > 0 && (
                  <div className="flex w-full flex-col gap-1 rounded-xl border border-gray-500 p-3">
                    {data.addonsProratedList?.map((addon) => (
                      <div
                        className="flex flex-row gap-1 font-medium text-gray-950 dark:text-[#ffffff]"
                        key={addon.id}
                      >
                        <CreditsIcon pathClassName="stroke-secondary-500" />
                        {addon.name}
                      </div>
                    ))}
                  </div>
                )}
              <div className="flex w-full flex-col gap-1">
                <HostedPagesCard.Price
                  price={formatCurrency(
                    data.defaultPrice,
                    settings.defaultCurrency,
                  )}
                  description={membershipHelper.getBillingRecurrence(data)}
                />
                {data.membershipAddonList?.map((addon) => (
                  <div
                    className="flex flex-row items-center gap-1"
                    key={addon.id}
                  >
                    <div className="font-semibold text-gray-950 dark:text-[#ffffff]">
                      +{formatCurrency(addon.price, settings.defaultCurrency)}
                    </div>
                    <div className="text-xs font-medium text-gray-500">
                      {addon.name}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {auth.id ? (
              <Button
                link={createClubLink(
                  RouteLayout.Site,

                  RouteFeature.SiteBuyMembershipIdCheckout.replace(
                    ":id",
                    data.id.toString(),
                  ),
                )}
                intent="secondary"
                className="w-fit px-16"
              >
                Go To Checkout
              </Button>
            ) : (
              <Button
                onClick={() => {
                  showLoginOverlay();
                }}
                intent="secondary"
                className="w-fit px-16"
              >
                Go To Checkout
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
