import {
  Membership,
  MembershipBean,
  UserMemberSubscriptionBean,
  UserMemberSubscriptionBeanWithMembership,
} from "@gymflow/types";
import {
  add,
  Day,
  differenceInCalendarDays,
  endOfMonth,
  getDate,
  nextDay,
} from "date-fns";
import { capitalize } from "lodash";

import { toNumberWeekday } from "./Weekday";

const getBillingDay = (item: UserMemberSubscriptionBean) => {
  return item.billingType === "MONTHLY"
    ? `${item.monthlyBillingDay}`
    : capitalize(item.weeklyBillingDay);
};
const isCurrentSubscription = (sub?: UserMemberSubscriptionBean) =>
  sub &&
  (sub.active ||
    ["ACTIVE", "PAUSED", "PENDING", "OVERDUE"].includes(sub.status)) &&
  !!sub.membershipBean;

export const calculateRecurringDates = (
  membership: MembershipBean,
  startDate: Date,
) => {
  if (!(membership.type === "RECURRING" && membership.billingType))
    throw new Error("Invalid Membership: Should be recurring");
  const result: {
    nextBillingDate: Date;
    dayDiff?: number;
  } = {
    nextBillingDate: new Date(),
    dayDiff: undefined,
  };

  const start = startDate;
  let nextBillingDate: Date;
  let startOfCycle: Date = new Date();
  if (membership.billingType === "WEEKLY") {
    const weekdayIndex =
      membership.calculateProrata === "NO"
        ? (start.getDay() as Day)
        : toNumberWeekday(membership.weeklyBillingDay);
    if (start.getDay() === weekdayIndex) {
      startOfCycle = start;
      nextBillingDate = add(startOfCycle, {
        weeks: membership.billingPeriod,
      });
    } else {
      startOfCycle = nextDay(start, weekdayIndex);
      nextBillingDate = startOfCycle;
    }
    result.nextBillingDate = nextBillingDate;
  } else if (membership.billingType === "MONTHLY") {
    const startDayOfMonth = getDate(start);
    if (membership.monthlyBillingDay) {
      if (startDayOfMonth < membership.monthlyBillingDay) {
        startOfCycle = new Date(start.getTime());
        startOfCycle.setDate(membership.monthlyBillingDay);
        nextBillingDate = startOfCycle;
      } else if (startDayOfMonth > membership.monthlyBillingDay) {
        startOfCycle = add(endOfMonth(start), {
          days: membership.monthlyBillingDay,
        });
        nextBillingDate = startOfCycle;
      } else {
        startOfCycle = start;
        nextBillingDate = add(startOfCycle, {
          months: membership.billingPeriod,
        });
      }
      result.nextBillingDate = nextBillingDate;
    }
  } else {
    throw Error("Non supported billingType");
  }

  if (membership.calculateProrata === "YES") {
    const dayDiff = differenceInCalendarDays(startOfCycle, start);
    if (dayDiff > 0) {
      result.dayDiff = dayDiff;
    }
  }
  return result;
};

const getBillingRecurrence = (membershipBean: MembershipBean | Membership) => {
  return membershipBean.billingType && membershipBean.type === "RECURRING"
    ? `Per ${
        membershipBean.billingPeriod > 1
          ? `${membershipBean.billingPeriod} `
          : ``
      }${
        (
          {
            MONTHLY: "Month",
            WEEKLY: "Week",
          } as const
        )[membershipBean.billingType]
      }${membershipBean.billingPeriod !== 1 ? "s" : ""}`
    : "Once off";
};

const getSubscriptionBillingRecurrence = (
  subscription: UserMemberSubscriptionBeanWithMembership,
) => {
  return subscription.billingType &&
    subscription.billingPeriod &&
    subscription.membershipBean.type === "RECURRING"
    ? `Per ${
        subscription.billingPeriod > 1 ? `${subscription.billingPeriod} ` : ``
      }${
        (
          {
            MONTHLY: "Month",
            WEEKLY: "Week",
          } as const
        )[subscription.billingType]
      }${subscription.billingPeriod !== 1 ? "s" : ""}`
    : "Once off";
};

export const membershipHelper = {
  getBillingDay,
  calculateRecurringDates,
  getSubscriptionBillingRecurrence,
  getBillingRecurrence,
  isCurrentSubscription,
};
