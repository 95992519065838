import { UserMemberListFilter } from "@gymflow/api";
import {
  ApiTokenListResponse,
  HighlightedInvoiceDTO,
  InvoiceNewDTO,
  MembershipType,
  PaymentMethodDTO,
  SubscriptionPromotion,
  SubscriptionPromotionSummary,
  TokenPageableRequestParams,
  UserMemberBean,
  UserMemberListItem,
  UserMemberPost,
  UserMemberSearchByFullNameResult,
} from "@gymflow/types";
import axios, { AxiosInstance } from "axios";

import endpoints from "./endpoints";
import {
  canCheckIfEmailExists,
  canCreate,
  canFind,
  canFindById,
  canTrimFields,
} from "./features/apiResource";
import canSignUp from "./features/canSignUp";
import { SortType } from "./features/types";

const userMemberEndpoints = {
  createUserFullDetails: `${endpoints.userMembers}/full`,
  findByEmail: `${endpoints.userMembers}/email/`,
  catalogEmails: "catalog/user/email",
  findByFullName: "catalog/user/search",
  findGlobal: "catalog/global/search",
  resumeEarlySubscription: `${endpoints.userMembers}/:memberId/membership/resume-early/:subscriptionId`,
  calculateProRata: `${endpoints.userMembers}/:memberId/calculate-prorata-resume-subscription/:subscriptionId`,
  expireSubscription: `${endpoints.userMembers}/:memberId/membership/expire/:subscriptionId`,
  changeSubscriptionPrice: `${endpoints.userMembers}/:memberId/change-subscription-price`,
  pauseSubscription: `${endpoints.userMembers}/:memberId/membership/pause/:subscriptionId`,
  cancelPauseSubscription: `${endpoints.userMembers}/:memberId/membership/unschedule-pause/:subscriptionId`,
  cancelSubscription: `${endpoints.userMembers}/:memberId/membership/cancel/:subscriptionId`,
  updatePendingDate: `${endpoints.userMembers}/:memberId/membership/update-pending-date/:subscriptionId`,
  revokeCancellation: `${endpoints.userMembers}/:memberId/membership/revoke-cancellation/:subscriptionId`,
  updateCredits: `${endpoints.userMembers}/:memberId/membership/update-sessions/:subscriptionId`,
  changeSubscription: `${endpoints.userMembers}/:memberId/change-subscription`,
  calculatePriceChangeSubscription: `${endpoints.userMembers}/:memberId/calculate-price-change-subscription/:membershipId`,
  findInvoices: `${endpoints.userMembers}/:memberId/invoices`,
  creditInvoice: `${endpoints.userMembers}/:memberId/credit-invoice/`,
  debitInvoice: `${endpoints.userMembers}/:memberId/debit-invoice/`,
  collectInvoice: `${endpoints.userMembers}/:memberId/collect-invoice/`,
  refundInvoice: `${endpoints.userMembers}/:memberId/refund-invoice/`,
  invoiceFile: `${endpoints.userMembers}/:memberId/invoice-file/:invoiceNumber`,
  overdueMembersTotal: `${endpoints.userMembers}/past-due/total`,
  membershipOverdue: `${endpoints.userMembers}/membership-overdue`,
  membershipEnding: `${endpoints.userMembers}/membership-ending`,
  membershipEndingCsv: `${endpoints.userMembers}/membership-ending/csv`,
  membershipPending: `${endpoints.userMembers}/membership-pending`,
  membershipPaused: `${endpoints.userMembers}/membership-paused`,
  eventAttendance: `${endpoints.eventOccurrence}/rsvp/attendance`,
  eventAttendanceCsv: `${endpoints.eventOccurrence}/rsvp/attendance/csv`,
  eventAttendanceTotal: `${endpoints.eventOccurrence}/rsvp/attendance/total`, // TODO: remove
  classesRun: `${endpoints.eventOccurrence}/classes-run`,
  classesRunCsv: `${endpoints.eventOccurrence}/classes-run/csv`,
  noShows: `${endpoints.eventOccurrence}/no-shows`,
  noShowsCsv: `${endpoints.eventOccurrence}/no-shows/csv`,
  lateCancellations: `${endpoints.eventOccurrence}/late-cancellations`,
  lateCancellationsCsv: `${endpoints.eventOccurrence}/late-cancellations/csv`,
  memberStatusList: `${endpoints.userMembers}/member-status`,
  memberStatusListCsv: `${endpoints.userMembers}/member-status/csv`,
  changeBillingDate: `${endpoints.userMembers}/change-subscription-billing-date/:subscriptionId`,
  assignStaff: `${endpoints.userMembers}/:memberId/assign-staff/:staffId`,
  membershipSold: `${endpoints.userMembers}/membership-sold`,
  membershipSoldCsv: `${endpoints.userMembers}/membership-sold/csv`,
  membershipPausing: `${endpoints.userMembers}/membership-pausing`,
  membershipPausingCsv: `${endpoints.userMembers}/membership-pausing/csv`,
  membershipResuming: `${endpoints.userMembers}/membership-resuming`,
  membershipResumingCsv: `${endpoints.userMembers}/membership-resuming/csv`,
  accountDelete: `${endpoints.userMembers}/:memberId/account-delete`,
} as const;

export type UserMemberApiType = ReturnType<typeof userMemberApi>;

const userMemberApi = (
  axiosInstance: AxiosInstance,
  apiUrl: string,
  clubId: number,
) => {
  const clubPrefix = `club/${clubId}/` as const;
  const initialState = {
    fieldsToTrim: [
      "firstName",
      "lastName",
      "mobileNumber",
      "dateBirth",
      "postCode",
      "addressLine1",
      "addressLine2",
      "city",
      "email",
      "emergencyContact",
      "emergencyContactName",
      "username",
    ],
    baseUrl: `${clubPrefix}${endpoints.userMembers}`,
    http: axiosInstance,
    apiUrl,
  };
  const state = {
    ...initialState,
    ...canTrimFields(initialState),
    ...canFind(initialState),
    ...canFindById(initialState),
    ...canCheckIfEmailExists(initialState, clubId),
    ...canSignUp(),
  };

  return {
    ...state,
    update(id: string, _clubId: number, patchedFields: any) {
      return state.http.patch(
        `${state.baseUrl}/${id}`,
        state.createRequestBody(state.trimFields(patchedFields)),
      );
    },
    checkout(id: string, _clubId: number, patchedFields: any) {
      return state.http.patch(
        `${state.baseUrl}/${id}`,
        state.trimFields(patchedFields),
      );
    },
    findByEmail(email: string) {
      return state.http
        .get<UserMemberBean>(
          `${clubPrefix}${
            userMemberEndpoints.findByEmail
          }${email.toLowerCase()}`,
        )
        .catch((err) => {
          if (err.response.status === 404) {
            return null;
          }
          throw err;
        });
    },

    findByFullName(term: string, { page = 0, limit = 10 }) {
      return state.http.get<UserMemberSearchByFullNameResult[]>(
        `${clubPrefix}${userMemberEndpoints.findByFullName}`,
        {
          params: { q: term, page, size: limit },
        },
      );
    },

    catalogEmails(email: string) {
      return state.http.get(
        `${clubPrefix}${userMemberEndpoints.catalogEmails}`,
        { params: { q: email } },
      );
    },

    globalSearch(term: string, fields: "name" | "email" | undefined, size = 5) {
      return state.http.get(`${clubPrefix}${userMemberEndpoints.findGlobal}`, {
        params: { q: term, fields, size },
      });
    },

    create(newResource: UserMemberPost) {
      const trimmedData = state.trimFields(newResource);

      return state.http.post(
        `${clubPrefix}${userMemberEndpoints.createUserFullDetails}`,
        state.createRequestBody(trimmedData),
      );
    },

    createWithMinimumDetails(newMember: any) {
      return canCreate(state).create.bind(state)(
        state.createRequestBody(newMember),
      );
    },

    async updatePicture(
      id: string,
      { blob, name }: { blob: string; name: string },
    ) {
      const blobResponse = await axios.get(blob, {
        responseType: "blob",
      });
      const formData = new FormData();
      formData.append("file", blobResponse.data, name);
      return state.http.post<{ memberId: string }>(
        `${state.baseUrl}/${id}/picture`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );
    },

    membershipOverdue({
      page = 0,
      limit = 10,
      extraParams = {},
      sort,
    }: PaginationParams & {
      extraParams?: any;
      sort?: SortType;
    }) {
      const config = {
        params: {
          page,
          size: limit,
          ...extraParams,
        },
      };
      if (sort) {
        config.params.sort = state.createSortParam(sort.field, sort.desc);
      }

      return state.http.get(
        `${clubPrefix}${userMemberEndpoints.membershipOverdue}`,
        config,
      );
    },

    membershipPaused({
      page = 0,
      limit = 10,
      extraParams = {},
      sort,
    }: PaginationParams & {
      extraParams?: any;
      sort?: SortType;
    }) {
      const config = {
        params: {
          page,
          size: limit,
          ...extraParams,
        },
      };
      if (sort) {
        config.params.sort = state.createSortParam(sort.field, sort.desc);
      }

      return state.http.get(
        `${clubPrefix}${userMemberEndpoints.membershipPaused}`,
        config,
      );
    },

    calculateProRata(
      memberId: string,
      subscriptionId: number,
      resumeDate: string,
    ) {
      const url = `${clubPrefix}${userMemberEndpoints.calculateProRata}`
        .replace(":memberId", memberId)
        .replace(":subscriptionId", `${subscriptionId}`);
      return state.http.get(url, { params: { resumeDate } });
    },

    resumeSubscriptionEarly(
      memberId: string,
      subscriptionId: number,
      resumeDate: string,
      isWaived: boolean,
      paymentMethodId: string | undefined,
    ) {
      const url = `${clubPrefix}${userMemberEndpoints.resumeEarlySubscription}`
        .replace(":memberId", memberId)
        .replace(":subscriptionId", `${subscriptionId}`);

      const body = { isWaived, earlyPauseEndDate: resumeDate } as any;
      if (!isWaived) {
        body.paymentMethodId = paymentMethodId;
      }
      return state.http.post(url, body);
    },

    membershipEnding({
      dateFrom,
      dateTo,
      page = 0,
      limit = 10,
      extraParams = {},
      sort,
      membershipIncluded,
      membershipExcluded,
      membershipType,
    }: MembershipReportParams) {
      const config = {
        params: {
          dateFrom,
          dateTo,
          page,
          size: limit,
          membershipIncluded,
          membershipExcluded,
          membershipType,
          sort: ["subscriptionId"],
          ...extraParams,
        },
      };
      if (sort) {
        config.params.sort.push(state.createSortParam(sort.field, sort.desc));
      }

      return state.http.get(
        `${clubPrefix}${userMemberEndpoints.membershipEnding}`,
        config,
      );
    },

    membershipEndingCsv(filters: MembershipReportCsvParams) {
      return state.http.get(
        `${clubPrefix}${userMemberEndpoints.membershipEndingCsv}`,
        {
          params: filters,
        },
      );
    },

    membershipResuming({
      dateFrom,
      dateTo,
      page = 0,
      limit = 10,
      extraParams = {},
      sort,
      membershipIncluded,
      membershipExcluded,
      membershipType,
    }: MembershipReportParams) {
      const config = {
        params: {
          dateFrom,
          dateTo,
          page,
          size: limit,
          membershipIncluded,
          membershipExcluded,
          membershipType,
          sort: ["subscriptionId"],
          ...extraParams,
        },
      };
      if (sort) {
        config.params.sort.push(state.createSortParam(sort.field, sort.desc));
      }

      return state.http.get(
        `${clubPrefix}${userMemberEndpoints.membershipResuming}`,
        config,
      );
    },

    membershipResumingCsv(filters: MembershipReportCsvParams) {
      return state.http.get(
        `${clubPrefix}${userMemberEndpoints.membershipResumingCsv}`,
        {
          params: filters,
        },
      );
    },

    membershipPausing({
      dateFrom,
      dateTo,
      page = 0,
      limit = 10,
      extraParams = {},
      sort,
      membershipIncluded,
      membershipExcluded,
      membershipType,
    }: MembershipReportParams) {
      const config = {
        params: {
          dateFrom,
          dateTo,
          page,
          size: limit,
          membershipIncluded,
          membershipExcluded,
          membershipType,
          sort: ["subscriptionId"],
          ...extraParams,
        },
      };
      if (sort) {
        config.params.sort.push(state.createSortParam(sort.field, sort.desc));
      }

      return state.http.get(
        `${clubPrefix}${userMemberEndpoints.membershipPausing}`,
        config,
      );
    },

    membershipPausingCsv(filters: MembershipReportCsvParams) {
      return state.http.get(
        `${clubPrefix}${userMemberEndpoints.membershipPausingCsv}`,
        {
          params: filters,
        },
      );
    },

    membershipPending({
      page = 0,
      limit = 10,
      extraParams = {},
      sort,
    }: PaginationParams & {
      extraParams?: any;
      sort?: SortType;
    }) {
      const config = {
        params: {
          page,
          size: limit,
          ...extraParams,
        },
      };
      if (sort) {
        config.params.sort = state.createSortParam(sort.field, sort.desc);
      }

      return state.http.get(
        `${clubPrefix}${userMemberEndpoints.membershipPending}`,
        config,
      );
    },

    eventAttendance({
      startDate,
      endDate,
      gt,
      lt,
      eq,
      membership,
      page = 0,
      limit = 10,
      extraParams = {},
      sort,
    }: {
      startDate: string;
      endDate: string;
      gt?: number;
      lt?: number;
      eq?: number;
      membership?: number[];
    } & PaginationParams & {
        extraParams?: any;
        sort?: SortType;
      }) {
      const config = {
        params: {
          dateFrom: startDate,
          dateTo: endDate,
          gt,
          lt,
          eq,
          membership,
          page,
          size: limit,
          ...extraParams,
        },
      };
      if (sort) {
        config.params.sort = state.createSortParam(sort.field, sort.desc);
      }

      return state.http.get(
        `${clubPrefix}${userMemberEndpoints.eventAttendance}`,
        config,
      );
    },

    eventAttendanceCsv(filters: {
      dateFrom: string;
      dateTo: string;
      gt?: number;
      lt?: number;
      eq?: number;
      membership?: number[];
    }) {
      return state.http.get(
        `${clubPrefix}${userMemberEndpoints.eventAttendanceCsv}`,
        {
          params: filters,
        },
      );
    },

    classesRun({
      startDate,
      endDate,
      activityId,
      eventHostId,
      page = 0,
      limit = 10,
      sort,
    }: {
      startDate?: string;
      endDate?: string;
      activityId?: number[];
      eventHostId?: string[];
    } & PaginationParams & {
        sort?: SortType[];
        extraParams?: any;
      }) {
      const config = {
        params: {
          dateFrom: startDate,
          dateTo: endDate,
          activityId,
          eventHost: eventHostId,
          page,
          size: limit,
        },
      } as any;
      if (sort) {
        const parsedSort = sort.reduce((acc, v) => {
          acc.push(state.createSortParam(v.field, v.desc));
          return acc;
        }, [] as string[]);
        config.params.sort = parsedSort;
      }

      return state.http.get(
        `${clubPrefix}${userMemberEndpoints.classesRun}`,
        config,
      );
    },

    classesRunCsv(filters: {
      dateFrom?: string;
      dateTo?: string;
      activityId?: number[];
      eventHost?: string[];
    }) {
      return state.http.get(
        `${clubPrefix}${userMemberEndpoints.classesRunCsv}`,
        {
          params: filters,
        },
      );
    },

    noShows({
      dateFrom,
      dateTo,
      page = 0,
      limit = 10,
      sort,
    }: {
      dateFrom?: string;
      dateTo?: string;
    } & PaginationParams & {
        sort?: SortType[];
      }) {
      const config = {
        params: {
          dateFrom,
          dateTo,
          page,
          size: limit,
        },
      } as any;
      if (sort) {
        const parsedSort = sort.reduce((acc, v) => {
          acc.push(state.createSortParam(v.field, v.desc));
          return acc;
        }, [] as string[]);
        config.params.sort = parsedSort;
      }

      return state.http.get(
        `${clubPrefix}${userMemberEndpoints.noShows}`,
        config,
      );
    },

    noShowsCsv(filters: { dateFrom?: string; dateTo?: string }) {
      return state.http.get(`${clubPrefix}${userMemberEndpoints.noShowsCsv}`, {
        params: filters,
      });
    },

    lateCancellations({
      startDate,
      endDate,
      page = 0,
      limit = 10,
      sort,
    }: {
      startDate?: string;
      endDate?: string;
    } & PaginationParams & {
        sort?: SortType[];
      }) {
      const config = {
        params: {
          dateFrom: startDate,
          dateTo: endDate,
          page,
          size: limit,
        },
      } as any;
      if (sort) {
        const parsedSort = sort.reduce((acc, v) => {
          acc.push(state.createSortParam(v.field, v.desc));
          return acc;
        }, [] as string[]);
        config.params.sort = parsedSort;
      }

      return state.http.get(
        `${clubPrefix}${userMemberEndpoints.lateCancellations}`,
        config,
      );
    },

    lateCancellationsCsv(filters: { dateFrom?: string; dateTo?: string }) {
      return state.http.get(
        `${clubPrefix}${userMemberEndpoints.lateCancellationsCsv}`,
        {
          params: filters,
        },
      );
    },

    // TODO: Remove
    eventAttendanceTotal(startDate: string, endDate: string) {
      return state.http.get(
        `${clubPrefix}${userMemberEndpoints.eventAttendanceTotal}`,
        {
          params: {
            dateFrom: startDate,
            dateTo: endDate,
          },
        },
      );
    },

    memberStatusList({
      page = 0,
      limit = 10,
      extraParams = {},
      sort,
    }: PaginationParams & {
      extraParams?: any;
      sort?: SortType;
    }) {
      const config = {
        params: {
          page,
          size: limit,
          ...extraParams,
        },
      };
      if (sort) {
        config.params.sort = state.createSortParam(sort.field, sort.desc);
      }

      return state.http.get(
        `${clubPrefix}${userMemberEndpoints.memberStatusList}`,
        config,
      );
    },

    memberStatusListCsv(filters: any) {
      return state.http.get(
        `${clubPrefix}${userMemberEndpoints.memberStatusListCsv}`,
        {
          params: filters,
        },
      );
    },
    async fetchPaymentMethods(memberId: string, clubId: number) {
      const result = await state.http.get<PaymentMethodDTO[]>(
        `${clubPrefix}payment-method/${memberId}`,
      );
      // TODO: cleanup PAYMENT_REDESIGN
      (result as any).data = (result as any).data.map((e: any) => ({
        ...e,
        id: e.paymentMethodId,
      }));
      return result;
    },
    attachPaymentMethod(
      memberId: string,
      clubId: number,
      paymentMethodId: string,
    ) {
      return state.http.put<{ memberId: string; clubId: number }>(
        `${clubPrefix}payment-method/${memberId}/card/${paymentMethodId}`,
      );
    },

    removePaymentMethod(
      memberId: string,
      clubId: number,
      paymentMethodId: string,
    ) {
      return state.http.delete(
        `${clubPrefix}payment-method/${memberId}/${paymentMethodId}`,
      );
    },

    assignDefaultPaymentMethod(
      memberId: string,
      clubId: number,
      paymentMethodId: string,
    ) {
      return state.http.put(
        `${clubPrefix}payment-method/${memberId}/default/${paymentMethodId}`,
      );
    },

    expireSubscription(
      memberId: string,
      subscriptionId: number,
      expiredDate: string | undefined,
    ) {
      const url = `${clubPrefix}${userMemberEndpoints.expireSubscription}`
        .replace(":memberId", memberId)
        .replace(":subscriptionId", `${subscriptionId}`);

      const body = expiredDate ? { expirationDateTime: expiredDate } : {};

      return state.http.patch(url, body);
    },

    pauseSubscription(
      memberId: string,
      subscriptionId: number,
      immediately: boolean,
      pauseDate: string | undefined,
      resumeDate: string,
    ) {
      const url = `${clubPrefix}${userMemberEndpoints.pauseSubscription}`
        .replace(":memberId", memberId)
        .replace(":subscriptionId", `${subscriptionId}`);

      const body = {
        pauseEndDate: resumeDate,
      } as any;

      if (immediately) {
        body.effectiveImmediately = immediately;
      } else {
        body.pauseStartDate = pauseDate;
      }

      return state.http.post(url, body);
    },

    cancelPauseSubscription(memberId: string, subscriptionId: number) {
      const url = `${clubPrefix}${userMemberEndpoints.cancelPauseSubscription}`
        .replace(":memberId", memberId)
        .replace(":subscriptionId", `${subscriptionId}`);

      return state.http.post(url);
    },

    cancelSubscription(
      memberId: string,
      subscriptionId: number,
      immediately: boolean,
      when: string | undefined,
    ) {
      const url = `${clubPrefix}${userMemberEndpoints.cancelSubscription}`
        .replace(":memberId", memberId)
        .replace(":subscriptionId", `${subscriptionId}`);

      const body = {} as any;
      if (immediately) {
        body.effectiveImmediately = true;
      } else if (when) {
        body.cancellationDate = when;
      }

      return state.http.post(url, body);
    },

    updatePendingDate(
      memberId: string,
      subscriptionId: number,
      immediately: boolean,
      when: string | undefined,
    ) {
      const url = `${clubPrefix}${userMemberEndpoints.updatePendingDate}`
        .replace(":memberId", memberId)
        .replace(":subscriptionId", `${subscriptionId}`);

      const body = {} as any;
      if (immediately) {
        body.startSubscriptionEffectiveImmediately = true;
      } else if (when) {
        body.startSubscriptionEffectiveImmediately = false;
        body.pendingStartDate = when;
      }

      return state.http.post(url, body);
    },

    revokeCancellation(memberId: string, subscriptionId: number) {
      const url = `${clubPrefix}${userMemberEndpoints.revokeCancellation}`
        .replace(":memberId", memberId)
        .replace(":subscriptionId", `${subscriptionId}`);

      return state.http.post(url);
    },

    changeSubscription(
      clubId: number,
      memberId: string,
      newMembershipId: number,
      paymentMethod: string,
      isWaived = false,
    ) {
      const url =
        `${clubPrefix}${userMemberEndpoints.changeSubscription}`.replace(
          ":memberId",
          memberId,
        );

      const body = {
        paymentMethod,
        subscriptionSignUpBean: {
          isWaived,
          membershipId: newMembershipId,
        },
      };

      return state.http.post(url, body);
    },

    adjustSessionPack(
      memberId: string,
      subscriptionId: number,
      newAmount: number,
    ) {
      const url = `${clubPrefix}${userMemberEndpoints.updateCredits}`
        .replace(":memberId", memberId)
        .replace(":subscriptionId", `${subscriptionId}`);

      return state.http.patch(url, { newAmount });
    },

    calculateChangeSubscriptionPrice(memberId: string, membershipId: number) {
      const url =
        `${clubPrefix}${userMemberEndpoints.calculatePriceChangeSubscription}`
          .replace(":memberId", memberId)
          .replace(":membershipId", `${membershipId}`);
      return state.http.get(url);
    },

    changeSubscriptionPrice(
      clubId: number,
      memberId: string,
      subscriptionId: number,
      newSubscriptionPrice: number,
    ) {
      const url =
        `${clubPrefix}${userMemberEndpoints.changeSubscriptionPrice}`.replace(
          ":memberId",
          memberId,
        );
      return state.http.patch(url, { subscriptionId, newSubscriptionPrice });
    },

    findInvoices(
      userMemberId: string,
      {
        page = 0,
        limit = 10,
        extraParams = {},
        sort,
      }: PaginationParams & {
        extraParams?: any;
        sort?: SortType;
      },
    ) {
      const url = `${clubPrefix}${userMemberEndpoints.findInvoices}`.replace(
        ":memberId",
        userMemberId,
      );
      const config = {
        params: {
          page,
          size: limit,
          ...extraParams,
        },
      };
      if (sort) {
        config.params.sort = state.createSortParam(sort.field, sort.desc);
      }

      return state.http.get(url, config);
    },
    highlightedInvoice({ memberId }: { memberId: string }) {
      return axiosInstance.get<HighlightedInvoiceDTO>(
        `${clubPrefix}user-member-invoice-list/${memberId}/highlighted`,
      );
    },
    findInvoicesNew({
      memberId,
      ...params
    }: {
      memberId: string;
    } & TokenPageableRequestParams) {
      return axiosInstance.get<ApiTokenListResponse<InvoiceNewDTO>>(
        `${clubPrefix}user-member-invoice-list/${memberId}`,
        { params },
      );
    },
    /*** @deprecated adjustInvoice instead */
    creditInvoice(
      userMemberId: string,
      clubId: number,
      invoiceNumber: string,
      amount: number,
    ) {
      const url =
        `${clubPrefix}${userMemberEndpoints.creditInvoice}`.replace(
          ":memberId",
          userMemberId,
        ) + invoiceNumber;
      return state.http.post(url, {
        amountToCredit: amount,
      });
    },

    /*** @deprecated adjustInvoice instead */
    debitInvoice(
      userMemberId: string,
      clubId: number,
      invoiceNumber: string,
      amount: number,
    ) {
      const url =
        `${clubPrefix}${userMemberEndpoints.debitInvoice}`.replace(
          ":memberId",
          userMemberId,
        ) + invoiceNumber;
      return state.http.post(url, {
        amountToDebit: amount,
      });
    },

    adjustInvoice(invoiceId: string, amount: number) {
      return state.http.put<{}>(`${clubPrefix}invoice/${invoiceId}/adjust`, {
        newDueAmount: amount,
      });
    },
    collectInvoice(
      userMemberId: string,
      clubId: number,
      invoiceNumber: string,
      amount: number,
    ) {
      const url =
        `${clubPrefix}${userMemberEndpoints.collectInvoice}`.replace(
          ":memberId",
          userMemberId,
        ) + invoiceNumber;
      return state.http.post(url, {
        amountToPay: amount,
      });
    },

    refundInvoice(userMemberId: string, clubId: number, invoiceNumber: string) {
      const url =
        `${clubPrefix}${userMemberEndpoints.refundInvoice}`.replace(
          ":memberId",
          userMemberId,
        ) + invoiceNumber;

      return state.http.post(url);
    },

    writeOffInvoice(
      userMemberId: string,
      clubId: number,
      invoiceNumber: string,
    ) {
      const url = `${clubPrefix}invoice/${invoiceNumber}/write-off/`;
      return state.http.put(url);
    },

    invoiceFile(userMemberId: string, clubId: number, invoiceNumber: string) {
      const url = `${clubPrefix}${userMemberEndpoints.invoiceFile}`
        .replace(":memberId", userMemberId)
        .replace(":invoiceNumber", invoiceNumber);

      return state.http.get(url);
    },

    changeBillingDate(clubId: number, subscriptionId: number, newDate: string) {
      const url =
        `${clubPrefix}${userMemberEndpoints.changeBillingDate}`.replace(
          ":subscriptionId",
          `${subscriptionId}`,
        );

      const body = {
        changeDate: newDate,
      };

      return state.http.patch(url, body);
    },
    changeContractEndDate(subscriptionId: number, newDate: string) {
      const url = `${clubPrefix}user/member/change-subscription-contract-date/${subscriptionId}`;
      return state.http.patch(url, { changeDate: newDate });
    },
    assignStaff(memberId: string, staffId: string) {
      const url = `${clubPrefix}${userMemberEndpoints.assignStaff}`
        .replace(":memberId", memberId)
        .replace(":staffId", staffId);
      return state.http.put(url);
    },

    unassignStaff(memberId: string, staffId: string) {
      const url = `${clubPrefix}${userMemberEndpoints.assignStaff}`
        .replace(":memberId", memberId)
        .replace(":staffId", staffId);
      return state.http.delete(url);
    },

    membershipSold({
      dateFrom,
      dateTo,
      membershipIncluded,
      membershipExcluded,
      page = 0,
      limit = 10,
      sort,
    }: MembershipReportParams) {
      const config = {
        params: {
          dateFrom,
          dateTo,
          membershipIncluded,
          membershipExcluded,
          page,
          size: limit,
        },
      } as any;
      if (sort) {
        config.params.sort = state.createSortParam(sort.field, sort.desc);
      }

      return state.http.get(
        `${clubPrefix}${userMemberEndpoints.membershipSold}`,
        config,
      );
    },

    membershipSoldCsv(filters: MembershipReportCsvParams) {
      return state.http.get(
        `${clubPrefix}${userMemberEndpoints.membershipSoldCsv}`,
        {
          params: filters,
        },
      );
    },
    accountDelete(memberId: string, clubId: number) {
      const url = `${clubPrefix}${userMemberEndpoints.accountDelete}`.replace(
        ":memberId",
        memberId,
      );
      return state.http.post(url);
    },
    updateEmailAndPassword(
      memberId: string,
      payload: { email?: string; password?: string },
    ) {
      const url = `${clubPrefix}user/member/${memberId}/account`;
      return state.http.patch(url, payload);
    },
    updateHomeClub(memberId: string, clubId: string) {
      const url = `${clubPrefix}user/member/${memberId}/new-club/${clubId}`;
      return state.http.put(url);
    },
    membershipLinkMember(
      primarySubscriptionId: number,
      secondaryMemberId: string,
    ) {
      const url = `${clubPrefix}user/member/${secondaryMemberId}/link-subscription/${primarySubscriptionId}`;
      return state.http.put(url);
    },
    listMembershipLinkedMembers(primarySubscriptionId: number) {
      const url = `${clubPrefix}user/member/membership/${primarySubscriptionId}/linked-members`;
      return state.http.get(url);
    },
    listNew(params: UserMemberListFilter & TokenPageableRequestParams) {
      return state.http.get<ApiTokenListResponse<UserMemberListItem>>(
        `${clubPrefix}user-member-list`,
        { params },
      );
    },
    listNewCount(params: UserMemberListFilter) {
      return state.http.get<{ count: number }>(
        `${clubPrefix}user-member-list/count`,
        {
          params,
        },
      );
    },
    listNewCsv(params: UserMemberListFilter) {
      return state.http.get(`${clubPrefix}user-member-list/csv`, {
        params,
        headers: {
          "Content-Disposition": "attachment; filename=users.csv",
        },
      });
    },
    addPromotionCodeToSubscription({
      subscriptionId,
      promotionCode,
    }: {
      subscriptionId: number;
      promotionCode: string;
    }) {
      return state.http.put<SubscriptionPromotion>(
        `${clubPrefix}subscription/${subscriptionId}/promotion`,
        {
          promotionCode,
        },
      );
    },
    addPromotionCodeToSubscriptionSummary({
      subscriptionId,
      promotionCode,
    }: {
      subscriptionId: number;
      promotionCode: string;
    }) {
      return state.http.get<SubscriptionPromotionSummary>(
        `${clubPrefix}subscription/${subscriptionId}/promotion`,
        {
          params: { promotionCode },
        },
      );
    },
    removePromotionCodeFromSubscription({
      subscriptionId,
    }: {
      subscriptionId: number;
    }) {
      return state.http.delete(
        `${clubPrefix}subscription/${subscriptionId}/promotion`,
      );
    },
  };
};

userMemberApi.endpoints = userMemberEndpoints;

export default userMemberApi;

export type PaginationParams = {
  page?: number;
  limit?: number;
};

export type MembershipReportCsvParams = {
  dateFrom: string;
  dateTo: string;
  membershipIncluded?: number[];
  membershipExcluded?: number[];
  membershipType?: MembershipType;
};

export type MembershipReportParams = MembershipReportCsvParams &
  PaginationParams & {
    extraParams?: any;
    sort?: SortType;
  };
