import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DATE_FORMAT } from "@gymflow/common";
import React, { ReactNode } from "react";

import { useClubSettings } from "../../providers";
import { Button, DownloadIcon, FilterIcon } from "../atoms";
import DateRangePicker from "../forms/DateRangePicker";

type DateRange = {
  startDate: string;
  endDate: string;
};

export interface ReportProps {
  title: string;
  description: string;
  onDownloadClick?: () => Promise<void>;
  renderMoreButtons?: () => ReactNode;
  showFilters?: () => void;
  handleDateRangeChange?: (newDate: DateRange) => void;
  dateRange?: DateRange;
  close?: () => void;
  table: JSX.Element;
}

export const Report: React.FC<ReportProps> = ({
  title,
  description,
  renderMoreButtons,
  onDownloadClick,
  showFilters,
  handleDateRangeChange,
  dateRange,
  close,
  table,
}) => {
  const settings = useClubSettings();
  const longDateFormat = settings.long_date_format;
  return (
    <div className="flex h-screen flex-col justify-between bg-white">
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-1 flex-row flex-wrap items-center justify-between pb-8 pl-8 pr-4 pt-10">
          <div className="mr-4 flex max-w-[800px] flex-col">
            <div className="text-lg font-semibold">{title}</div>
            <div className="text-sm text-gray-600">{description}</div>
          </div>
          <div className="flex flex-wrap items-center">
            {renderMoreButtons && renderMoreButtons()}
            {onDownloadClick && (
              <Button className="mr-4" onClick={onDownloadClick}>
                <DownloadIcon pathClassName="stroke-gray-500" />
              </Button>
            )}

            {showFilters && (
              <Button className="mr-4 flex h-11" onClick={showFilters}>
                <FilterIcon className="mr-2" pathClassName="stroke-gray-500" />
                Filter
              </Button>
            )}
            {handleDateRangeChange && (
              <DateRangePicker
                className=""
                displayDateFormat={longDateFormat}
                onChange={handleDateRangeChange}
                value={dateRange}
                dateFormat={DATE_FORMAT}
                onSelectionChange={undefined}
                placeholder={undefined}
              />
            )}
          </div>
        </div>
        {close && (
          <div
            className="mr-6 flex h-11 w-11 cursor-pointer items-center justify-center pb-8 pt-10"
            onClick={close}
          >
            <FontAwesomeIcon className="text-xl text-gray-500" icon={faClose} />
          </div>
        )}
      </div>
      {table}
    </div>
  );
};
