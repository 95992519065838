import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  facilityListQueryFn,
  useMutationKisiGenerateSignatureKey,
  useMutationKisiUpdate,
  useQueryKisiGroupList,
  useQueryKisiGroupMappings,
  useQueryKisiSignatureKeyExists,
} from "@gymflow/api";
import { NotificationContext } from "@gymflow/common";
import { cn } from "@gymflow/helpers";
import { HttpStatusCode } from "axios";
import classNames from "classnames";
import { range } from "lodash";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router";

import { copyToClipboard } from "../../../helpers";
import { usePortalRoutes } from "../../../hooks/usePortalRoutes";
import {
  ModalContext,
  useApiResolverContext,
  useClubSettings,
} from "../../../providers";
import { RouteFeature } from "../../../routes/feature";
import { RouteLayout } from "../../../routes/layout";
import useGymflowModels from "../../../store";
import {
  Button,
  eagerSelectAll,
  LookingGlassIcon,
  MembershipSelect,
  PaginatedSelect,
  PlusCircleIcon,
  renderMultiSelectPlaceholder,
  Switch,
  TextInputWithButton,
  WarningIcon,
} from "../../atoms";
import { SettingsRoute } from "../../Settings/SettingsRoute";
import { ConfirmModal } from "../../templates";
import {
  KisiGroupToFacilityPair,
  KisiGroupToMembershipPair,
} from "./KisiGroupToMembershipPair";

export function KisiIntegrationUpdate() {
  const settings = useClubSettings();
  const clubId = settings.clubId;
  const toast = useContext(NotificationContext);
  const { setModal, hide } = useContext(ModalContext);

  const history = useHistory();
  const { createClubLink } = usePortalRoutes();
  const { api } = useGymflowModels();
  const { data: kisiData, isFetching: isFetchingMappings } =
    useQueryKisiGroupMappings({
      clubId,
      api,
    });
  const [membershipValidationError, setMembershipValidationError] =
    useState("");
  const [facilityValidationError, setFacilityValidationError] = useState("");
  const [membershipAssigned, setMembershipAssigned] = useState<
    KisiGroupToMembershipPair[]
  >([]);
  const [facilityAssigned, setFacilityAssigned] = useState<
    KisiGroupToFacilityPair[]
  >([]);
  const { data: kisiGroups, isFetching: isFetchingKisiGroups } =
    useQueryKisiGroupList({
      api,
      clubId,
    });
  const isFetching = isFetchingMappings || isFetchingKisiGroups;

  const generateSignatureKey = useMutationKisiGenerateSignatureKey({
    api,
  });
  const { data: signatureKeyExists } = useQueryKisiSignatureKeyExists({
    api,
    clubId,
  });
  const [signatureKey, setSignatureKey] = useState("");
  const [automaticCheckoutsEnabled, setAutomaticCheckoutsEnabled] =
    useState(false);
  const ranOnce = useRef(false);
  useEffect(() => {
    if (ranOnce.current) {
      return;
    }
    if (signatureKeyExists !== undefined) {
      setAutomaticCheckoutsEnabled(true);
      ranOnce.current = true;
    }
  }, [signatureKeyExists]);

  const kisiUpdateMutation = useMutationKisiUpdate(
    { api },
    {
      onError: (e) => {
        const error = e as {
          status?: number;
          response?: { data?: { errors?: any } };
        };
        if (error?.response?.data?.errors) {
          toast.notify({
            message: JSON.stringify(error.response.data.errors),
            type: "danger",
          });
        }

        if (error?.status === HttpStatusCode.RequestTimeout) {
          toast.notify({
            message:
              "Updating the integration is taking longer than expected. Check the status of it later by refreshing this page.",
            type: "warning",
          });

          history.push(
            createClubLink(
              RouteLayout.Staff,
              RouteFeature.Settings,
              SettingsRoute.Integrations,
            ),
          );
        }
      },
    },
  );
  const [newMembershipPair, setNewMembershipPair] =
    useState<KisiGroupToMembershipPair>();
  const [changedMembershipPairIdx, setChangedMembershipPairIdx] =
    useState<number>();

  const assignedMembershipPairs = useMemo(() => {
    if (!kisiData) {
      return [];
    }
    return kisiData
      .filter((r) => r.kisiGroupId && r.type === "MEMBERSHIP")
      .reduce((acc, v) => {
        const kisiPair = acc.find((r) => r.kisiGroupId === v.kisiGroupId);
        if (kisiPair) {
          kisiPair.memberships.push({
            membershipId: v.id,
            membershipName: v.name,
          });
        } else {
          acc.push({
            kisiGroupId: v.kisiGroupId,
            kisiGroupName: v.kisiGroupName,
            memberships: [
              {
                membershipId: v.id,
                membershipName: v.name,
              },
            ],
          });
        }
        return acc;
      }, [] as KisiGroupToMembershipPair[]);
  }, [kisiData]);
  const [isMembershipsEnabled, setIsMembershipsEnabled] = useState(false);
  useEffect(() => {
    setIsMembershipsEnabled(assignedMembershipPairs.length > 0);
  }, [assignedMembershipPairs]);

  const [newFacilityPair, setNewFacilityPair] =
    useState<KisiGroupToFacilityPair>();
  const [changedFacilityPairIdx, setChangedFacilityPairIdx] =
    useState<number>();
  const assignedFacilityPairs = useMemo(() => {
    if (!kisiData) {
      return [];
    }
    return kisiData
      .filter((r) => r.kisiGroupId && r.type === "FACILITY")
      .reduce((acc, v) => {
        const kisiPair = acc.find((r) => r.kisiGroupId === v.kisiGroupId);
        if (kisiPair) {
          kisiPair.facilities.push({
            facilityId: v.id,
            facilityName: v.name,
          });
        } else {
          acc.push({
            kisiGroupId: v.kisiGroupId,
            kisiGroupName: v.kisiGroupName,
            facilities: [
              {
                facilityId: v.id,
                facilityName: v.name,
              },
            ],
          });
        }
        return acc;
      }, [] as KisiGroupToFacilityPair[]);
  }, [kisiData]);
  const [isFacilitiesEnabled, setIsFacilitiesEnabled] = useState(false);
  useEffect(() => {
    setIsFacilitiesEnabled(assignedFacilityPairs.length > 0);
  }, [assignedFacilityPairs]);

  const getDisabledMembershipIdsForGroupId = (
    kisiGroupId: string | null | undefined,
  ) => {
    return assignedMembershipPairs
      .filter((pair) => {
        return pair.kisiGroupId !== kisiGroupId;
      })
      .reduce(
        (acc, value) =>
          acc.concat(
            value.memberships
              .filter((m) => m.membershipId !== null)
              .map((m) => m.membershipId) as number[],
          ),
        [] as number[],
      );
  };

  const apiResolver = useApiResolverContext();

  const webhookUrl = useMemo(() => {
    return `${apiResolver.urls.api}/webhook/kisi/access/check-in`;
  }, [apiResolver.urls.api]);
  return (
    <>
      <div className="flex max-h-full flex-col">
        <div className="font-semibold">Update Group Associations</div>
        <div className="text-gray-600">
          Update existing group associations or create new ones.
        </div>
        <div className="pt-5" />
        <div className="border-warning-700 bg-warning-50 text-warning-700 flex rounded border p-5">
          <div className="mt-0.5">
            <WarningIcon pathClassName="stroke-warning-700" />
          </div>
          <div className="ml-2">
            <div>
              When removing the association between Kisi Groups and Memberships,
              access will be revoked from all members on that membership. You
              must save each change individually.
            </div>
          </div>
        </div>
        <div className="pt-5" />
      </div>
      <div className="flex h-full max-h-full flex-initial flex-col justify-between overflow-y-hidden rounded border border-gray-200">
        <div className="flex h-full max-h-full flex-col gap-4 overflow-y-scroll p-5">
          <div className="flex items-center justify-between">
            <div>
              <div className="font-semibold">Grant access to members</div>
              <div className="text-sm text-gray-500">
                We'll automatically grant access to memberships with an ACTIVE
                status only.
              </div>
            </div>
            <div>
              <Switch
                value={isMembershipsEnabled}
                onChange={(checked) => {
                  if (checked) {
                    setIsMembershipsEnabled(true);
                    setNewMembershipPair({
                      memberships: [],
                      kisiGroupId: null,
                      kisiGroupName: null,
                    });
                  } else {
                    const membershipsToRemove = kisiData!.filter(
                      (m) => m.type === "MEMBERSHIP" && m.kisiGroupId,
                    );

                    if (membershipsToRemove.length) {
                      setModal(
                        <ConfirmModal
                          type="danger"
                          title="Remove Access"
                          onConfirm={async () => {
                            for (const pair of membershipsToRemove) {
                              await kisiUpdateMutation.mutateAsync({
                                clubId,
                                membershipId: pair.id,
                                kisiGroupId: null,
                              });
                            }
                            hide();
                            setIsMembershipsEnabled(false);
                            setNewMembershipPair(undefined);
                          }}
                          onCancel={() => {
                            hide();
                          }}
                        >
                          Are you sure you want to remove access from all
                          memberships? Access will be revoked from any members
                          on all memberships, this process may take several
                          minutes to complete.
                        </ConfirmModal>,
                      );
                    } else {
                      setIsMembershipsEnabled(false);
                    }
                  }
                }}
              />
            </div>
          </div>
          <div
            className={cn("grid grid-cols-9", {
              hidden: !isMembershipsEnabled,
            })}
          >
            <div className="col-span-4 mx-2">
              <div className="text-sm font-semibold">Membership</div>
              <div className="pt-2 text-sm text-gray-600">
                Select the membership you want associated with an access group.
              </div>
            </div>
            <div className="col-span-4 mx-2">
              <div className="text-sm font-semibold">Access Group</div>
              <div className="pt-2 text-sm text-gray-600">
                Select the access group you want to associate with the
                membership.
              </div>
            </div>
          </div>
          <div className={classNames({ hidden: !isFetching })}>
            {range(0, kisiData?.length || 3).map((n) => (
              <div className="grid grid-cols-9" key={n}>
                <div className="col-span-4 animate-pulse p-2">
                  <div className="ml-4 h-5 w-[90%] rounded-sm bg-gray-400"></div>
                </div>
                <div className="col-span-4 animate-pulse p-2">
                  <div className="ml-4 h-5 w-[90%] rounded-sm bg-gray-400"></div>
                </div>
              </div>
            ))}
          </div>
          {!isFetching &&
            isMembershipsEnabled &&
            assignedMembershipPairs.map((line, idx) => {
              const isAnotherLineBeingEdited =
                newMembershipPair !== undefined ||
                (changedMembershipPairIdx !== undefined &&
                  changedMembershipPairIdx !== idx);
              return (
                <div className="grid grid-cols-9" key={line.kisiGroupId}>
                  <div className="col-span-4 p-2">
                    <MembershipSelect
                      isDisabled={isAnotherLineBeingEdited}
                      value={
                        line.memberships.length > 0
                          ? line.memberships.map((membership) => {
                              return {
                                label: membership.membershipName,
                                value: membership.membershipId,
                              };
                            })
                          : null
                      }
                      isMulti
                      showSelectAll
                      selectAllClick={eagerSelectAll}
                      onChange={(option) => {
                        const newAssigned = assignedMembershipPairs.slice(0);
                        newAssigned[idx].memberships = option.map(
                          ({ value, label }) => {
                            return {
                              membershipName: label,
                              membershipId: value,
                            };
                          },
                        );
                        setMembershipAssigned(newAssigned);
                        setChangedMembershipPairIdx(idx);
                      }}
                      disableMembershipIds={getDisabledMembershipIdsForGroupId(
                        line.kisiGroupId,
                      )}
                      isSearchable
                      placeholder={renderMultiSelectPlaceholder({
                        value: line.memberships.map((membership) => {
                          return {
                            label: membership.membershipName ?? "",
                          };
                        }),
                        noValuePlaceholder: "Select Membership",
                      })}
                    />
                  </div>
                  <div className="col-span-4 p-2">
                    <PaginatedSelect
                      isDisabled
                      loadOptions={() =>
                        Promise.resolve({
                          options: (kisiGroups || []).map(({ name, id }) => ({
                            label: name,
                            value: id,
                          })),
                        })
                      }
                      onChange={() => {}}
                      value={{
                        value: line.kisiGroupId,
                        label: line.kisiGroupName,
                      }}
                    />
                  </div>
                  <div
                    className={classNames(
                      "col-span-1 mx-2 flex flex-row items-center",
                    )}
                  >
                    <Button
                      onClick={() => {
                        setModal(
                          <ConfirmModal
                            title="Update Access"
                            onConfirm={async () => {
                              const lineToEdit = membershipAssigned[idx];

                              const removed = kisiData!.filter((m) => {
                                const newIds = lineToEdit.memberships.map(
                                  (m) => m.membershipId,
                                );
                                return (
                                  m.type === "MEMBERSHIP" &&
                                  m.kisiGroupId === lineToEdit.kisiGroupId &&
                                  !newIds.includes(m.id)
                                );
                              });

                              for (const m of lineToEdit.memberships) {
                                await kisiUpdateMutation.mutateAsync({
                                  clubId,
                                  membershipId: m.membershipId!,
                                  kisiGroupId: lineToEdit.kisiGroupId!,
                                });
                              }

                              for (const m of removed) {
                                await kisiUpdateMutation.mutateAsync({
                                  clubId,
                                  membershipId: m.id!,
                                  kisiGroupId: null,
                                });
                              }

                              setChangedMembershipPairIdx(undefined);
                              hide();
                            }}
                            onCancel={() => {
                              hide();
                            }}
                          >
                            Are you sure you want to update access? Any changes
                            will be synced immediately and may take several
                            minutes to complete.
                          </ConfirmModal>,
                        );
                      }}
                      className={classNames("mx-2 my-0 mr-5", {
                        invisible: changedMembershipPairIdx !== idx,
                      })}
                    >
                      Save
                    </Button>

                    <div>
                      <FontAwesomeIcon
                        onClick={() => {
                          setModal(
                            <ConfirmModal
                              type="danger"
                              title="Remove Access"
                              onConfirm={async () => {
                                const membershipsToRemove = kisiData!.filter(
                                  (m) =>
                                    m.type === "MEMBERSHIP" &&
                                    m.kisiGroupId === line.kisiGroupId,
                                );

                                for (const m of membershipsToRemove) {
                                  await kisiUpdateMutation.mutateAsync({
                                    clubId,
                                    membershipId: m.id,
                                    kisiGroupId: null,
                                  });
                                }
                                hide();
                              }}
                              onCancel={() => {
                                hide();
                              }}
                            >
                              Are you sure you want to remove access from this
                              membership? Access will be revoked from any
                              members on this membership, this process may take
                              several minutes to complete.
                            </ConfirmModal>,
                          );
                        }}
                        className={classNames(
                          "mr-2 cursor-pointer text-xl text-gray-600",
                          { invisible: isAnotherLineBeingEdited },
                        )}
                        icon={faClose}
                      />
                    </div>
                  </div>
                </div>
              );
            })}

          <div
            className={classNames("grid grid-cols-9", {
              hidden: !newMembershipPair || !isMembershipsEnabled,
            })}
          >
            <div className="col-span-4 p-2">
              <MembershipSelect
                value={
                  newMembershipPair?.memberships?.length
                    ? newMembershipPair.memberships.map((m) => {
                        return {
                          value: m.membershipId,
                          label: m.membershipName,
                        };
                      })
                    : null
                }
                isMulti
                showSelectAll
                selectAllClick={eagerSelectAll}
                onChange={(option) => {
                  setNewMembershipPair({
                    kisiGroupId: newMembershipPair?.kisiGroupId || null,
                    kisiGroupName: newMembershipPair?.kisiGroupName || null,
                    ...newMembershipPair,
                    memberships: option.map(({ value, label }) => {
                      return {
                        membershipName: label,
                        membershipId: value,
                      };
                    }),
                  });
                }}
                disableMembershipIds={getDisabledMembershipIdsForGroupId(
                  newMembershipPair?.kisiGroupId,
                )}
                isSearchable
                placeholder={renderMultiSelectPlaceholder({
                  value: newMembershipPair?.memberships.map((m) => {
                    return {
                      label: m.membershipName ?? "",
                    };
                  }),
                  noValuePlaceholder: "Select membership",
                })}
              />
            </div>
            <div className="col-span-4 p-2">
              <PaginatedSelect
                loadOptions={() =>
                  Promise.resolve({
                    options: (kisiGroups || []).map(({ name, id }) => ({
                      label: name,
                      value: id,
                      isDisabled:
                        kisiData &&
                        !!kisiData.find(
                          (m) => m.type === "FACILITY" && m.kisiGroupId === id,
                        ),
                    })),
                  })
                }
                onChange={({
                  value,
                  label,
                }: {
                  value: string;
                  label: string;
                }) => {
                  setNewMembershipPair({
                    memberships: newMembershipPair?.memberships || [],
                    ...newMembershipPair,
                    kisiGroupId: value,
                    kisiGroupName: label,
                  });
                }}
                value={
                  newMembershipPair?.kisiGroupId
                    ? {
                        value: newMembershipPair?.kisiGroupId,
                        label: newMembershipPair?.kisiGroupName,
                      }
                    : null
                }
                placeholder="Select Kisi Group"
              />
            </div>
            <div
              className={classNames(
                "col-span-1 mx-2 flex flex-row items-center",
              )}
            >
              <Button
                onClick={() => {
                  setModal(
                    <ConfirmModal
                      title="Update Access"
                      onConfirm={async () => {
                        for (const m of newMembershipPair!.memberships) {
                          await kisiUpdateMutation.mutateAsync({
                            clubId,
                            membershipId: m!.membershipId!,
                            kisiGroupId: newMembershipPair!.kisiGroupId!,
                          });
                        }
                        setNewMembershipPair(undefined);
                        hide();
                      }}
                      onCancel={() => {
                        hide();
                      }}
                    >
                      Are you sure you want to update access? Any changes will
                      be synced immediately and may take several minutes to
                      complete.
                    </ConfirmModal>,
                  );
                }}
                className={classNames("!my-0 mx-2 mr-5", {
                  invisible:
                    newMembershipPair?.kisiGroupId === null ||
                    !newMembershipPair?.memberships.length,
                })}
              >
                Save
              </Button>
              <FontAwesomeIcon
                onClick={() => {
                  setNewMembershipPair(undefined);
                }}
                className="mr-2 cursor-pointer text-xl text-gray-600"
                icon={faClose}
              />
            </div>
          </div>

          <div
            className={cn("mx-2 flex flex-row-reverse", {
              hidden: !isMembershipsEnabled,
            })}
          >
            <Button
              className="mt-2"
              onClick={() => {
                if (changedMembershipPairIdx !== undefined) {
                  setMembershipValidationError(
                    "Save changes to existing associations before adding a new one",
                  );
                  return;
                }

                if (newMembershipPair) {
                  setMembershipValidationError(
                    "Save changes to new pair before adding another one.",
                  );
                  return;
                }
                setNewMembershipPair({
                  memberships: [],
                  kisiGroupId: null,
                  kisiGroupName: null,
                });
              }}
            >
              <PlusCircleIcon
                className="mr-2 self-center"
                pathClassName="stroke-gray-500"
              />
              <div>Add New</div>
            </Button>
          </div>
          {membershipValidationError && (
            <div className="text-error-500 pt-2">
              {membershipValidationError}
            </div>
          )}

          <div className="flex items-center justify-between">
            <div>
              <div className="font-semibold">Grant access to facilities</div>
              <div className="text-sm text-gray-500">
                We'll automatically grant access to facilities with an ACTIVE
                status only.
              </div>
            </div>
            <div>
              <Switch
                value={isFacilitiesEnabled}
                onChange={(checked) => {
                  if (checked) {
                    setIsFacilitiesEnabled(true);
                    setNewFacilityPair({
                      facilities: [],
                      kisiGroupId: null,
                      kisiGroupName: null,
                    });
                  } else {
                    const facilitiesToRemove = kisiData!.filter(
                      (m) => m.type === "FACILITY" && m.kisiGroupId,
                    );
                    if (facilitiesToRemove.length) {
                      setModal(
                        <ConfirmModal
                          type="danger"
                          title="Remove Access"
                          onConfirm={async () => {
                            for (const m of facilitiesToRemove) {
                              await kisiUpdateMutation.mutateAsync({
                                clubId,
                                facilityId: m.id,
                                kisiGroupId: null,
                              });
                            }
                            hide();
                            setIsFacilitiesEnabled(false);
                            setNewFacilityPair(undefined);
                          }}
                          onCancel={() => {
                            hide();
                          }}
                        >
                          Are you sure you want to remove access from all
                          facilities? This process may take several minutes to
                          complete.
                        </ConfirmModal>,
                      );
                    } else {
                      setIsFacilitiesEnabled(false);
                    }
                  }
                }}
              />
            </div>
          </div>
          <div
            className={cn("grid grid-cols-9", {
              hidden: !isFacilitiesEnabled,
            })}
          >
            <div className="col-span-4 mx-2">
              <div className="text-sm font-semibold">Facility</div>
              <div className="pt-2 text-sm text-gray-600">
                Select the facility you want associated with an access group.
              </div>
            </div>
            <div className="col-span-4 mx-2">
              <div className="text-sm font-semibold">Access Group</div>
              <div className="pt-2 text-sm text-gray-600">
                Select the access group you want to associate with the facility.
              </div>
            </div>
          </div>
          <div className={classNames({ hidden: !isFetching })}>
            {range(0, kisiData?.length || 3).map((n) => (
              <div className="grid grid-cols-9" key={n}>
                <div className="col-span-4 animate-pulse p-2">
                  <div className="ml-4 h-5 w-[90%] rounded-sm bg-gray-400"></div>
                </div>
                <div className="col-span-4 animate-pulse p-2">
                  <div className="ml-4 h-5 w-[90%] rounded-sm bg-gray-400"></div>
                </div>
              </div>
            ))}
          </div>
          {!isFetching &&
            isFacilitiesEnabled &&
            assignedFacilityPairs.map((line, idx) => {
              const isAnotherLineBeingEdited =
                newFacilityPair !== undefined ||
                (changedFacilityPairIdx !== undefined &&
                  changedFacilityPairIdx !== idx);
              return (
                <div className="grid grid-cols-9" key={line.kisiGroupId}>
                  <div className="col-span-4 p-2">
                    <PaginatedSelect
                      value={
                        line.facilities.length > 0
                          ? line.facilities.map((facility) => {
                              return {
                                label: facility.facilityName,
                                value: facility.facilityId,
                              };
                            })
                          : null
                      }
                      loadOptions={async (term, __, additional) => {
                        const facilityList = await facilityListQueryFn({
                          api,
                          opts: {
                            page: additional.page,
                            extraParams: { status: "ACTIVE", name: term },
                          },
                        });
                        return {
                          options: facilityList.content.map((facility) => {
                            return { label: facility.name, value: facility.id };
                          }),
                          hasMore: !facilityList.last,
                          additional: {
                            page: additional.page + 1,
                          },
                        };
                      }}
                      isMulti
                      showSelectAll
                      selectAllClick={eagerSelectAll}
                      onChange={(option) => {
                        const newAssigned = assignedFacilityPairs.slice(0);
                        newAssigned[idx].facilities = option.map(
                          ({
                            value,
                            label,
                          }: {
                            value: number;
                            label: string;
                          }) => {
                            return {
                              facilityName: label,
                              facilityId: value,
                            };
                          },
                        );
                        setFacilityAssigned(newAssigned);
                        setChangedFacilityPairIdx(idx);
                      }}
                      isSearchable
                      placeholder={renderMultiSelectPlaceholder({
                        value: line.facilities.map((facility) => {
                          return {
                            label: facility.facilityName ?? "",
                          };
                        }),

                        noValuePlaceholder: "Select facility",
                      })}
                      icon={
                        <LookingGlassIcon
                          className="h-9 w-9"
                          pathClassName="stroke-grey-600"
                        />
                      }
                    />
                  </div>
                  <div className="col-span-4 p-2">
                    <PaginatedSelect
                      isDisabled
                      loadOptions={() =>
                        Promise.resolve({
                          options: (kisiGroups || []).map(({ name, id }) => ({
                            label: name,
                            value: id,
                          })),
                        })
                      }
                      value={{
                        value: line.kisiGroupId,
                        label: line.kisiGroupName,
                      }}
                      onChange={() => {}}
                    />
                  </div>
                  <div
                    className={classNames(
                      "col-span-1 mx-2 flex flex-row items-center",
                    )}
                  >
                    <Button
                      onClick={() => {
                        setModal(
                          <ConfirmModal
                            title="Update Access"
                            onConfirm={async () => {
                              const lineToEdit = facilityAssigned[idx];

                              const removed = kisiData!.filter((m) => {
                                const newIds = lineToEdit.facilities.map(
                                  (m) => m.facilityId,
                                );
                                return (
                                  m.type === "FACILITY" &&
                                  m.kisiGroupId === lineToEdit.kisiGroupId &&
                                  !newIds.includes(m.id)
                                );
                              });
                              for (const m of lineToEdit.facilities) {
                                await kisiUpdateMutation.mutateAsync({
                                  clubId,
                                  facilityId: m.facilityId!,
                                  kisiGroupId: lineToEdit.kisiGroupId!,
                                });
                              }

                              for (const m of removed) {
                                await kisiUpdateMutation.mutateAsync({
                                  clubId,
                                  facilityId: m.id!,
                                  kisiGroupId: null,
                                });
                              }

                              setChangedFacilityPairIdx(undefined);
                              hide();
                            }}
                            onCancel={() => {
                              hide();
                            }}
                          >
                            Are you sure you want to update access? Any changes
                            will be synced immediately and may take several
                            minutes to complete.
                          </ConfirmModal>,
                        );
                      }}
                      className={classNames("mx-2 my-0 mr-5", {
                        invisible: changedFacilityPairIdx !== idx,
                      })}
                    >
                      Save
                    </Button>

                    <div>
                      <FontAwesomeIcon
                        onClick={() => {
                          setModal(
                            <ConfirmModal
                              type="danger"
                              title="Remove Access"
                              onConfirm={async () => {
                                const facilitiesToRemove = kisiData!.filter(
                                  (m) =>
                                    m.type === "FACILITY" &&
                                    m.kisiGroupId === line.kisiGroupId,
                                );

                                for (const m of facilitiesToRemove) {
                                  await kisiUpdateMutation.mutateAsync({
                                    clubId,
                                    facilityId: m.id,
                                    kisiGroupId: null,
                                  });
                                }
                                hide();
                              }}
                              onCancel={() => {
                                hide();
                              }}
                            >
                              Are you sure you want to remove access from this
                              facility? This process may take several minutes to
                              complete.
                            </ConfirmModal>,
                          );
                        }}
                        className={classNames(
                          "mr-2 cursor-pointer text-xl text-gray-600",
                          { invisible: isAnotherLineBeingEdited },
                        )}
                        icon={faClose}
                      />
                    </div>
                  </div>
                </div>
              );
            })}

          <div
            className={classNames("grid grid-cols-9", {
              hidden: !newFacilityPair || !isFacilitiesEnabled,
            })}
          >
            <div className="col-span-4 p-2">
              <PaginatedSelect
                value={
                  newFacilityPair?.facilities?.length
                    ? newFacilityPair.facilities.map((m) => {
                        return {
                          value: m.facilityId,
                          label: m.facilityName,
                        };
                      })
                    : null
                }
                loadOptions={async (term, __, additional) => {
                  const facilityList = await facilityListQueryFn({
                    api,
                    opts: {
                      page: additional.page,
                      extraParams: { status: "ACTIVE", name: term },
                    },
                  });
                  return {
                    options: facilityList.content.map((facility) => {
                      return { label: facility.name, value: facility.id };
                    }),
                    hasMore: !facilityList.last,
                    additional: {
                      page: additional.page + 1,
                    },
                  };
                }}
                isMulti
                showSelectAll
                selectAllClick={eagerSelectAll}
                onChange={(option) => {
                  setNewFacilityPair({
                    kisiGroupId: newFacilityPair?.kisiGroupId || null,
                    kisiGroupName: newFacilityPair?.kisiGroupName || null,
                    ...newFacilityPair,
                    facilities: option.map(
                      ({ value, label }: { value: number; label: string }) => {
                        return {
                          facilityName: label,
                          facilityId: value,
                        };
                      },
                    ),
                  });
                }}
                isSearchable
                placeholder={renderMultiSelectPlaceholder({
                  value: newFacilityPair?.facilities.map((m) => {
                    return {
                      label: m.facilityName ?? "",
                    };
                  }),
                  noValuePlaceholder: "Select facility",
                })}
                icon={
                  <LookingGlassIcon
                    className="h-9 w-9"
                    pathClassName="stroke-grey-600"
                  />
                }
              />
            </div>
            <div className="col-span-4 p-2">
              <PaginatedSelect
                loadOptions={() =>
                  Promise.resolve({
                    options: (kisiGroups || []).map(({ name, id }) => ({
                      label: name,
                      value: id,
                      isDisabled:
                        kisiData &&
                        !!kisiData.find(
                          (m) =>
                            m.type === "MEMBERSHIP" && m.kisiGroupId === id,
                        ),
                    })),
                  })
                }
                onChange={({
                  value,
                  label,
                }: {
                  value: string;
                  label: string;
                }) => {
                  setNewFacilityPair({
                    facilities: newFacilityPair?.facilities || [],
                    ...newFacilityPair,
                    kisiGroupId: value,
                    kisiGroupName: label,
                  });
                }}
                value={
                  newFacilityPair?.kisiGroupId
                    ? {
                        value: newFacilityPair?.kisiGroupId,
                        label: newFacilityPair?.kisiGroupName,
                      }
                    : null
                }
                placeholder="Select Kisi Group"
              />
            </div>
            <div
              className={classNames(
                "col-span-1 mx-2 flex flex-row items-center",
              )}
            >
              <Button
                onClick={() => {
                  setModal(
                    <ConfirmModal
                      title="Update Access"
                      onConfirm={async () => {
                        for (const m of newFacilityPair!.facilities) {
                          await kisiUpdateMutation.mutateAsync({
                            clubId,
                            facilityId: m!.facilityId!,
                            kisiGroupId: newFacilityPair!.kisiGroupId!,
                          });
                        }
                        setNewFacilityPair(undefined);
                        hide();
                      }}
                      onCancel={() => {
                        hide();
                      }}
                    >
                      Are you sure you want to update access? Any changes will
                      be synced immediately and may take several minutes to
                      complete.
                    </ConfirmModal>,
                  );
                }}
                className={classNames("!my-0 mx-2 mr-5", {
                  invisible:
                    newFacilityPair?.kisiGroupId === null ||
                    !newFacilityPair?.facilities.length,
                })}
              >
                Save
              </Button>
              <FontAwesomeIcon
                onClick={() => {
                  setNewFacilityPair(undefined);
                }}
                className="mr-2 cursor-pointer text-xl text-gray-600"
                icon={faClose}
              />
            </div>
          </div>

          <div
            className={cn("mx-2 flex flex-row-reverse", {
              hidden: !isFacilitiesEnabled,
            })}
          >
            <Button
              className="mt-2"
              onClick={() => {
                if (changedFacilityPairIdx !== undefined) {
                  setFacilityValidationError(
                    "Save changes to existing associations before adding a new one",
                  );
                  return;
                }

                if (newFacilityPair) {
                  setFacilityValidationError(
                    "Save changes to new pair before adding another one.",
                  );
                  return;
                }
                setNewFacilityPair({
                  facilities: [],
                  kisiGroupId: null,
                  kisiGroupName: null,
                });
              }}
            >
              <PlusCircleIcon
                className="mr-2 self-center"
                pathClassName="stroke-gray-500"
              />
              <div>Add New</div>
            </Button>
          </div>

          {facilityValidationError && (
            <div className="text-error-500 pt-2">{facilityValidationError}</div>
          )}

          <div className="flex items-center justify-between">
            <div>
              <div className="font-semibold">Automatic Check Ins</div>
              <div className="text-sm text-gray-500">
                Check members into the club, classes, appointments and get
                access logs in Gymflow every time a member enters using Kisi.
              </div>
            </div>
            <div>
              <Switch
                value={automaticCheckoutsEnabled}
                onChange={async (checked) => {
                  if (checked) {
                    const key = await generateSignatureKey.mutateAsync({
                      clubId,
                    });
                    setSignatureKey(key.secretKey);
                    setAutomaticCheckoutsEnabled(true);
                  } else {
                    setModal(
                      <ConfirmModal
                        title="Are you sure you want to disable automatic checkouts?"
                        onConfirm={async () => {
                          await generateSignatureKey.mutateAsync({ clubId });
                          setAutomaticCheckoutsEnabled(false);
                          hide();
                        }}
                        onCancel={() => {
                          hide();
                        }}
                      >
                        Are you sure you want to disable automatic checkouts?
                        This change will take effect immediatly and will
                        invalidate the signature key. If you want to re-enable
                        this later you will have to generate a new signature key
                        and replace it in the Kisi dashboard.
                      </ConfirmModal>,
                    );
                  }
                }}
              />
            </div>
          </div>
          <div
            className={cn("flex flex-col gap-2", {
              hidden: !automaticCheckoutsEnabled,
            })}
          >
            <div className="text-sm font-semibold">Webhook URL</div>
            <div>
              <TextInputWithButton
                onButtonClick={() => {
                  copyToClipboard(webhookUrl);
                }}
                buttonText="Copy URL"
                value={webhookUrl}
              />
            </div>
          </div>
          <div
            className={cn("flex flex-col gap-2", {
              hidden: !automaticCheckoutsEnabled,
            })}
          >
            <div className="text-sm font-semibold">Signature Key</div>
            <div>
              {signatureKey ? (
                <TextInputWithButton
                  onButtonClick={() => {
                    copyToClipboard(signatureKey);
                  }}
                  buttonText="Copy Key"
                  value={signatureKey}
                />
              ) : (
                <TextInputWithButton
                  buttonText="Generate New"
                  value={"*".repeat(32)}
                  onButtonClick={async () => {
                    setModal(
                      <ConfirmModal
                        title="Generate new key?"
                        onConfirm={async () => {
                          const response =
                            await generateSignatureKey.mutateAsync({ clubId });
                          setSignatureKey(response.secretKey);
                          hide();
                        }}
                        onCancel={() => {
                          hide();
                        }}
                      >
                        Are you sure you want to generate a new key? The old one
                        will be invalidated and will need to be replaced in the
                        Kisi dashboard with the new one. This change will take
                        effect immediatly.
                      </ConfirmModal>,
                    );
                  }}
                />
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-end border-t border-gray-200 px-5 py-3">
          <Button
            intent="link"
            onClick={() => {
              toast.notify({
                message:
                  "This operation is not supported yet. If you want to disable Kisi please contact support.",
                type: "warning",
              });
            }}
            className="text-error-700 mt-2"
          >
            Disable Integration
          </Button>
          <Button
            className="ml-2 mt-2"
            onClick={() => {
              history.push(
                createClubLink(
                  RouteLayout.Staff,
                  RouteFeature.Settings,
                  SettingsRoute.Integrations,
                ),
              );
            }}
          >
            Close
          </Button>
        </div>
      </div>
    </>
  );
}
