import { cn } from "@gymflow/helpers";

import { RadioButton } from "./RadioButton";

export type RadioButtonOption<T extends string | number> = {
  label: string;
  value: T;
};

export type RadioButtonGroupProps<T extends string | number> = {
  options: RadioButtonOption<T>[];
  value: T;
  onChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  className?: string;
  label?: string;
};

export const RadioButtonGroup = <T extends string | number>({
  options,
  value,
  onChange,
  name,
  className,
  label,
}: RadioButtonGroupProps<T>) => (
  <div className="flex flex-col gap-2">
    {label && <div className="text-sm font-medium text-gray-950 ">{label}</div>}
    <div className={cn("flex flex-row gap-5", className)}>
      {options.map((option, index) => (
        <RadioButton
          key={`${option.value}-${index}`}
          value={option.value}
          label={option.label}
          checked={value === option.value}
          onChange={onChange}
          name={name}
        />
      ))}
    </div>
  </div>
);
