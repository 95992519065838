import { AlertContext, NotificationContext, TIME_FORMAT, useGenerateElementId } from "@gymflow/common";
import classNames from "classnames";
import { useStoreState } from "easy-peasy";
import noop from "lodash/noop";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import { useContext } from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { Clock, MapPin, UserCheck, UserPlus } from "react-feather";
import { Card, CardBody, Col, Row, UncontrolledTooltip } from "reactstrap";

import placeholder from "../../../assets/img/placeholder.jpg";
import useGymflowModels from "../../store";
import RsvpButton from "../RsvpButton";
import styles from "./EventCard.module.css";

function EventCard({
  eventId,
  onCardClick,
  activityName,
  activityDescription,
  facilityName,
  startDate,
  capacity,
  waitListCapacity,
  bookedCount,
  waitingCount,
  isBookable,
  hostFirstName,
  hostLastName,
  hostPicture,
  showDateLine,
  showRsvpButton,
  addAttendeeToRsvp,
  updateRsvp,
  rsvpStatus,
}) {
  const { authStore } = useGymflowModels();
  const { notifyDanger } = useContext(NotificationContext);
  const { hide, setAlert } = useContext(AlertContext);
  const generateId = useGenerateElementId();

  const { id: loggedInId } = useStoreState(authStore);

  const style = { fontSize: "0.94286rem" };

  if (onCardClick !== noop) {
    style.cursor = "pointer";
  }

  const iconSize = "18";
  const iconDivStyle = { width: iconSize + "px", marginTop: "-1px" };
  const textDivProps = { className: classNames("ml-1", styles["event-card__text"]) };
  const colSizeProps = { xs: "6", md: "4", xl: "2" };

  const renderFacility = () => {
    return (
      <div className="d-flex">
        <div className="d-inline-block" style={iconDivStyle}>
          <MapPin size={iconSize} />
        </div>
        <div {...textDivProps}>{renderTextSpan(facilityName)}</div>
      </div>
    );
  };

  const renderTextSpan = (text, threshold = 15) => {
    const spanProps = {};
    if (text && text.length > threshold) {
      spanProps.title = text;
    }
    return <span {...spanProps}>{text}</span>;
  };

  const renderStartTime = () => {
    return (
      <div className="d-flex">
        <div className="d-inline-block" style={iconDivStyle}>
          <Clock size={iconSize} />
        </div>
        <div {...textDivProps}>{moment(startDate).format(TIME_FORMAT)}</div>
      </div>
    );
  };

  const renderBooked = () => {
    if (!isBookable) {
      return null;
    }
    return (
      <div className="d-flex">
        <div className="d-inline-block" style={iconDivStyle}>
          <UserCheck size={iconSize} />
        </div>
        <div {...textDivProps}>{renderTextSpan(`${bookedCount}/${capacity} Booked`)}</div>
      </div>
    );
  };

  const renderWaitList = () => {
    if (!isBookable) {
      return null;
    }
    return (
      <div className="d-flex">
        <div className="d-inline-block" style={iconDivStyle}>
          <UserPlus size={iconSize} />
        </div>
        <div {...textDivProps}>{renderTextSpan(`${waitingCount}/${waitListCapacity} Waiting`)}</div>
      </div>
    );
  };

  const renderHost = () => {
    const pictureId = generateId({ key: eventId, prefix: "member-avatar" });
    const hostName = `${hostFirstName} ${hostLastName}`;
    return (
      <div>
        <div>
          <img
            src={hostPicture || placeholder}
            style={{ width: "30px", height: "30px", borderRadius: "15px" }}
            id={pictureId}
            alt="host"
          />
          <span className="d-lg-none ml-2">{renderTextSpan(hostName)}</span>

          <UncontrolledTooltip placement="right" target={pictureId}>
            {hostName}
          </UncontrolledTooltip>
        </div>
      </div>
    );
  };

  const renderRsvpButton = () => {
    return (
      <RsvpButton
        eventCapacity={capacity}
        onClick={({ status }) => {
          if (rsvpStatus) {
            return updateRsvp({
              status,
            }).catch(notifyDanger);
          }

          return addAttendeeToRsvp({
            userMemberId: loggedInId,
            occurrenceId: eventId,
          }).catch(notifyDanger);
        }}
        startDate={startDate}
        bookedCount={bookedCount}
        waitingCount={waitingCount}
        waitListCapacity={waitListCapacity}
        rsvpStatus={rsvpStatus}
      />
    );
  };

  const renderActivity = () => {
    if (activityDescription) {
      return (
        <u
          role="button"
          style={{ cursor: "pointer" }}
          onClick={(e) => {
            e.stopPropagation();
            setAlert(
              <ReactBSAlert title={activityName} onConfirm={hide}>
                {activityDescription}
              </ReactBSAlert>,
            );
          }}
        >
          {activityName}
        </u>
      );
    }
    return <span>{activityName}</span>;
  };

  return (
    <Card className="text-nowrap card-item" style={style} onClick={() => onCardClick({ id: eventId })}>
      <CardBody>
        <Row
          className={classNames("font-weight-bold", "text-xl-left text-center", {
            "d-none": !showDateLine,
          })}
        >
          <Col>{moment(startDate).format("LL")}</Col>
        </Row>
        <Row style={{ minHeight: "68.9px" }}>
          <Col
            {...colSizeProps}
            className={classNames("d-flex", "align-items-center", "font-weight-bold", styles["event-card__text"])}
          >
            {renderActivity()}
          </Col>
          <Col
            {...colSizeProps}
            className={classNames("d-flex", "align-items-center", "pt-3", "pb-3", styles["event-card__text"])}
          >
            {renderFacility()}
          </Col>
          <Col
            {...colSizeProps}
            className={classNames("d-flex", "align-items-center", "pt-3", "pb-3", styles["event-card__text"])}
          >
            {renderStartTime()}
          </Col>
          <Col
            {...colSizeProps}
            className={classNames("d-flex", "align-items-center", "pt-3", "pb-3", styles["event-card__text"])}
          >
            {renderBooked()}
          </Col>
          <Col
            {...colSizeProps}
            className={classNames("align-items-center", "pt-3", "pb-3", styles["event-card__text"], {
              "d-none": showRsvpButton,
              "d-flex": !showRsvpButton,
            })}
          >
            {renderWaitList()}
          </Col>
          <Col
            {...colSizeProps}
            className={classNames("d-flex", "align-items-center", "pt-3", "pb-3", styles["event-card__text"])}
          >
            {renderHost()}
          </Col>
          <Col
            {...colSizeProps}
            className={classNames(
              { "d-flex": showRsvpButton },
              "align-items-center",
              "pt-3",
              "pb-3",
              styles["event-card__"],
              {
                "d-none": !showRsvpButton,
              },
            )}
          >
            {renderRsvpButton()}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

EventCard.defaultProps = {
  onCardClick: noop,
  showDateLine: false,
  showRsvpButton: false,
};

EventCard.propTypes = {
  eventId: PropTypes.number.isRequired,
  onCardClick: PropTypes.func,
  activityName: PropTypes.string.isRequired,
  activityDescription: PropTypes.string,
  facilityName: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  capacity: PropTypes.number.isRequired,
  waitListCapacity: PropTypes.number.isRequired,
  bookedCount: PropTypes.number.isRequired,
  waitingCount: PropTypes.number.isRequired,
  isBookable: PropTypes.bool.isRequired,
  hostFirstName: PropTypes.string.isRequired,
  hostLastName: PropTypes.string.isRequired,
  hostPicture: PropTypes.string,
  showDateLine: PropTypes.bool,
  showRsvpButton: PropTypes.bool,
  addAttendeeToRsvp: PropTypes.func,
  updateRsvp: PropTypes.func,
  rsvpStatus: PropTypes.string,
};

export default EventCard;
