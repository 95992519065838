import {
  CardBodyWithSpinner,
  InvoiceStatus,
  PARAMETER_DATE_ONLY_FORMAT,
} from "@gymflow/common";
import { formatCurrency } from "@gymflow/helpers";
import noop from "lodash";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import ReactTable from "react-table";
import { Card, CardHeader, CardTitle } from "reactstrap";

import { useClubSettings } from "../../providers";
import InvoiceButtons from "./InvoiceButtons";
import InvoiceStatusBadge from "./InvoiceStatusBadge";

const PAGE_SIZE = 20;
const MIN_ROW = 5;

function UserMemberInvoices({
  invoices,
  fetchInvoices,
  refreshInvoices,
  isLoading,
  pageCount,
  userMemberId,
  currency,
  requestAuthorization,
  cancelAuthorization,
  getInvoiceFile,
  creditInvoice,
  debitInvoice,
  collectInvoice,
  payInvoice,
  refundInvoice,
  writeOffInvoice,
}) {
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(PAGE_SIZE);
  const [currentSort, setCurrentSort] = useState(undefined);
  const settings = useClubSettings();

  const clubId = settings.clubId;

  const renderActionButtons = ({
    number,
    invoiceUrl,
    status,
    chargeableAmount,
    refundableAmount,
    writableOff,
    paymentIntentIdForAuthorization,
    totalAmount,
  }) => {
    return (
      <div className="actions-right">
        <InvoiceButtons
          number={number}
          status={status}
          chargeableAmount={chargeableAmount}
          totalAmount={totalAmount}
          clubId={clubId}
          userMemberId={userMemberId}
          currency={currency}
          writableOff={writableOff}
          invoiceUrl={invoiceUrl}
          refundableAmount={refundableAmount}
          refreshInvoices={refreshInvoices}
          paymentIntentIdForAuthorization={paymentIntentIdForAuthorization}
          requestAuthorization={requestAuthorization}
          cancelAuthorization={cancelAuthorization}
          getInvoiceFile={getInvoiceFile}
          creditInvoice={creditInvoice}
          debitInvoice={debitInvoice}
          collectInvoice={collectInvoice}
          payInvoice={payInvoice}
          refundInvoice={refundInvoice}
          writeOffInvoice={writeOffInvoice}
        />
      </div>
    );
  };

  const rows = invoices.map(
    ({
      number,
      invoiceUrl,
      totalAmount,
      status,
      issueDate,
      chargeableAmount,
      refundableAmount,
      writableOff,
      paymentIntentIdForAuthorization,
    }) => ({
      number,
      totalAmount,
      status: <InvoiceStatusBadge status={status} />,
      issueDate,
      actions: renderActionButtons({
        number,
        invoiceUrl,
        status,
        chargeableAmount,
        refundableAmount,
        writableOff,
        paymentIntentIdForAuthorization,
        totalAmount,
      }),
      chargeableAmount,
    }),
  );

  const fetchData = useCallback(({ page, pageSize, sorted }) => {
    const options = { page, limit: pageSize };
    if (sorted?.[0]) {
      options.sort = {
        field: sorted[0].id,
        desc: sorted[0].desc,
      };
    }

    return fetchInvoices(options);
  }, []);

  useEffect(() => {
    fetchData({
      page: currentPage,
      pageSize: currentPageSize,
      sorted: currentSort,
    });
  }, [currentPage, currentPageSize, currentSort, fetchData]);

  return (
    <Card className="floating-table">
      <CardHeader>
        <CardTitle className="d-flex justify-content-between">
          <h2 className="mb-0">Receipts</h2>
        </CardTitle>
      </CardHeader>
      <CardBodyWithSpinner isLoading={isLoading}>
        <ReactTable
          manual
          data={rows}
          pages={pageCount}
          columns={[
            {
              Header: <h3>Date</h3>,
              id: "issueDate",
              accessor: ({ issueDate }) =>
                moment(issueDate, PARAMETER_DATE_ONLY_FORMAT).format(
                  settings.date_format,
                ),
              maxWidth: 150,
            },
            {
              Header: <h3>Amount</h3>,
              id: "totalAmount",
              accessor: (r) => formatCurrency(r.totalAmount, currency),
              maxWidth: 120,
            },
            {
              Header: <h3>Status</h3>,
              accessor: "status",
              maxWidth: 150,
            },
            {
              Header: <h3>Invoice</h3>,
              accessor: "actions",
              sortable: false,
              filterable: false,
              minWidth: 250,
              maxWidth: 470,
            },
          ]}
          defaultSorted={[
            {
              id: "issueDate",
              desc: true,
            },
          ]}
          className="-highlight floating-table"
          noDataText="No purchases made yet"
          defaultPageSize={PAGE_SIZE}
          minRows={MIN_ROW}
          getTrGroupProps={() => ({
            style: {
              maxHeight: "50px",
            },
          })}
          page={currentPage}
          onPageSizeChange={(pageSize) => {
            setCurrentPageSize(pageSize);
            setCurrentPage(0);
          }}
          onPageChange={(page) => {
            setCurrentPage(page);
          }}
          onSortedChange={(newSorted) => {
            setCurrentSort(newSorted);
          }}
        />
      </CardBodyWithSpinner>
    </Card>
  );
}

UserMemberInvoices.defaultProps = {
  fetchInvoices: () => Promise.resolve({ pageCount: 1 }),
  refreshInvoices: () => Promise.resolve(),
  creditInvoice: noop,
  debitInvoice: noop,
  collectInvoice: noop,
  payInvoice: noop,
  refundInvoice: noop,
  writeOffInvoice: noop,
};

UserMemberInvoices.propTypes = {
  fetchInvoices: PropTypes.func,
  refreshInvoices: PropTypes.func,
  requestAuthorization: PropTypes.func.isRequired,
  cancelAuthorization: PropTypes.func.isRequired,
  pageCount: PropTypes.number.isRequired,
  invoices: PropTypes.arrayOf(
    PropTypes.shape({
      number: PropTypes.string.isRequired,
      invoiceUrl: PropTypes.string.isRequired,
      totalAmount: PropTypes.number.isRequired,
      status: PropTypes.oneOf(Object.values(InvoiceStatus)).isRequired,
      issueDate: PropTypes.string.isRequired,
    }),
  ).isRequired,
  isLoading: PropTypes.bool.isRequired,
  userMemberId: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  getInvoiceFile: PropTypes.func.isRequired,
  creditInvoice: PropTypes.func,
  debitInvoice: PropTypes.func,
  collectInvoice: PropTypes.func,
  payInvoice: PropTypes.func,
  refundInvoice: PropTypes.func,
  writeOffInvoice: PropTypes.func,
};

export default UserMemberInvoices;
