import {
  ApiListResponse,
  MembershipStatus,
  SessionPackDTO,
} from "@gymflow/types";
import { useQuery } from "@tanstack/react-query";

import { defaultPage } from "../defaultPage";
import { sessionPackQueryKeys } from "./sessionPackQueryKeys";

export function useSessionPackList({
  api,
  opts,
}: {
  api: {
    creditPackApi: { find: (arg0: { [k: string]: any }) => Promise<any> };
  };
  opts: SessionPackListFilter;
}) {
  const result = useQuery({
    queryKey: sessionPackQueryKeys.list(opts),
    queryFn: async () => {
      const result = await api.creditPackApi.find({ ...opts });
      return result.data as ApiListResponse<SessionPackDTO>;
    },
    initialData: defaultPage<SessionPackDTO>,
  });
  return result;
}

interface SessionPackListFilter {
  readonly extraParams?: {
    readonly unpaged?: boolean;
    readonly status?: MembershipStatus;
    readonly id?: number[];
    readonly hasActivityCategory?: boolean;
    readonly hasAppointmentCategory?: boolean;
    readonly [k: string]: any;
  };
  readonly page?: number;
  readonly limit?: number;
  readonly sort?: {
    readonly field: string;
    readonly desc?: boolean;
  };
}
