import { useMutationMemberUpdateEmailAndPasswordAsMember } from "@gymflow/api";
import { useUserFormFieldConfiguration } from "@gymflow/common";
import { useStoreState } from "easy-peasy";
import { useEffect } from "react";
import { Alert, Col, Row } from "reactstrap";

import { UserMemberProfile } from "../components/UserMember/UserMemberProfile";
import { useClubSettings } from "../providers";
import useGymflowModels from "../store";

const Profile = () => {
  const { api, authStore, UserMemberStore } = useGymflowModels();
  const userMember = UserMemberStore.useStoreState((state) => state.editing);
  const { fetchById, clearEditingRecord, loadingRecord, update, postPicture } =
    UserMemberStore.useStoreActions((actions) => actions);
  const { id } = useStoreState(authStore);
  const settings = useClubSettings();
  const clubId = settings.clubId;
  useEffect(() => {
    fetchById();
    return () => clearEditingRecord();
  }, [id, fetchById, clearEditingRecord]);

  const { data: requiredFields } = useUserFormFieldConfiguration({
    clubId: settings.clubId,
    api: {
      ruleApi: api.public.ruleApi,
    },
  });
  const { mutateAsync: updateEmailAndPassword } =
    useMutationMemberUpdateEmailAndPasswordAsMember({
      api,
    });
  const renderProfile = () => {
    if (!userMember) {
      return null;
    }

    return (
      <>
        {!userMember.isFullUser && (
          <Alert color="primary">
            <span>
              You cannot edit your profile until you buy a membership or credit
              pack once.
            </span>
          </Alert>
        )}

        <UserMemberProfile
          user={userMember}
          isLoading={loadingRecord}
          updateUser={async (updatedUser) => {
            await update({ clubId, ...updatedUser });
            return fetchById(id);
          }}
          onChangePicture={postPicture}
          requiredFields={requiredFields}
          updateEmailAndPassword={updateEmailAndPassword}
        />
      </>
    );
  };

  return (
    <div className="content h-full overflow-y-auto p-8">
      <Row>
        <Col>{renderProfile()}</Col>
      </Row>
    </div>
  );
};

const ProfileWithProvider = () => {
  const { UserMemberStore } = useGymflowModels();
  return (
    <UserMemberStore.Provider>
      <Profile />
    </UserMemberStore.Provider>
  );
};

export default ProfileWithProvider;
