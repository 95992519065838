import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cn } from "@gymflow/helpers";
import { cva, VariantProps } from "class-variance-authority";
import {
  ComponentType,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  components,
  ContainerProps,
  DropdownIndicatorProps,
  MenuProps,
  OptionProps,
  ValueContainerProps,
} from "react-select";
import {
  ActionMeta,
  GroupBase,
  OptionsOrGroups,
  SetValueAction,
} from "react-select/dist/declarations/src/types";
import { AsyncPaginate } from "react-select-async-paginate";
import { twMerge } from "tailwind-merge";
import { useOnClickOutside } from "usehooks-ts";

import CogIcon from "../../../assets/img/cog.svg";
import { Spinner } from "./Spinner";

export interface PaginatedSelectOption {
  value: any;
  label: ReactNode;
  isDisabled?: boolean;
}

export interface PaginatedSelectAdditional {
  page: number;
}

const paginatedSelectVariants = cva("", {
  variants: {
    size: {
      small: "h-[2.375rem] text-sm",
      medium: "",
    },
    color: {
      default: "dark:!border-darkGray-700 border-gray-300",
      accent: "bg-accent hover:bg-accent border-accent",
    },
  },
  defaultVariants: {
    size: "medium",
    color: "default",
  },
});

export type PaginatedSelectVariantProps = VariantProps<
  typeof paginatedSelectVariants
>;

interface LoadOptionsResult {
  readonly options: PaginatedSelectOption[];
  readonly hasMore?: boolean;
  readonly additional?: PaginatedSelectAdditional;
}

export interface SelectAllClickArgs {
  isAllSelected: boolean;
  fetchAllOptions: (searchTerm?: string) => Promise<{
    allOptions: PaginatedSelectOption[];
    pageCount: number;
  }>;
  setValue: (newValue: any, action: SetValueAction, option?: any) => void;
}

export interface PaginatedSelectProps extends PaginatedSelectVariantProps {
  value: any;
  onChange: (newValue: any, action: ActionMeta<any>) => void;
  className?: string;
  loadOptions: (
    inputValue: string,
    loadedOptions: readonly PaginatedSelectOption[],
    additional: PaginatedSelectAdditional,
  ) => Promise<LoadOptionsResult>;
  isSearchable?: boolean;
  isMulti?: boolean;
  placeholder?: string;
  cacheUniqs?: ReadonlyArray<any>;
  isDisabled?: boolean;
  isClearable?: boolean;
  icon?: ReactNode;
  compact?: boolean;
  menuWidthShouldMatchText?: boolean;
  showSelectAll?: boolean;
  selectAllClick?: (params: SelectAllClickArgs) => Promise<boolean | void>;
  showMenuFooterButton?: boolean;
  menuFooterText?: ReactNode;
  menuFooterClick?: () => Promise<void>;
  refetchOnMenuOpen?: boolean;
}

export function PaginatedSelect({
  value,
  onChange: onChangeParam,
  className,
  loadOptions: loadOptionsParam,
  isSearchable = false,
  isMulti = false,
  placeholder,
  cacheUniqs: cacheUniqsParam,
  isDisabled = false,
  isClearable,
  icon,
  size,
  color,
  compact,
  menuWidthShouldMatchText,
  showSelectAll,
  selectAllClick: selectAllClickParam,
  showMenuFooterButton,
  menuFooterText,
  menuFooterClick,
  refetchOnMenuOpen,
}: PaginatedSelectProps) {
  const optionRef = useRef<HTMLDivElement>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const hasAlreadyFocusedValue = useRef(false);
  const [isAllSelected, setIsAllSelected] = useState(false);
  useEffect(() => {
    if (!isMenuOpen) {
      hasAlreadyFocusedValue.current = false;
    }
  }, [isMenuOpen]);

  const loadOptions = useCallback(
    async (
      inputValue: string,
      options: OptionsOrGroups<any, GroupBase<any>>,
      additional:
        | {
            page: number;
          }
        | undefined,
    ) => {
      const result = await loadOptionsParam(
        inputValue,
        options,
        additional || { page: 0 },
      );

      return {
        additional: { page: 0 },
        ...result,
      };
    },
    [loadOptionsParam],
  );

  const onChange = useCallback(
    (newValue: any, action: ActionMeta<any>) => {
      onChangeParam(newValue, action);
      if (isMulti && showSelectAll && isAllSelected) {
        setIsAllSelected(false);
      }
    },
    [isAllSelected, isMulti, onChangeParam, showSelectAll],
  );

  const cacheUniqs = useMemo(() => {
    let deps;
    if (refetchOnMenuOpen) {
      deps = [cacheUniqsParam, isMenuOpen];
    } else if (cacheUniqsParam) {
      deps = cacheUniqsParam;
    }

    return deps;
  }, [cacheUniqsParam, isMenuOpen, refetchOnMenuOpen]);

  const ref = useRef(null);

  useOnClickOutside(ref, () => {
    if (isMulti) {
      setIsMenuOpen(false);
    }
  });

  const customMenu: ComponentType<MenuProps<any, boolean, GroupBase<any>>> =
    useCallback(({ children, ...props }) => {
      const { menuFooterClick, menuFooterText, showMenuFooterButton, size } = (
        props.selectProps as any
      ).customProps;
      return (
        <components.Menu {...props}>
          <SelectHeader {...props} />
          {children}
          <div
            className={cn(
              optionVariants({ size }),
              "flex gap-2 border-t border-t-gray-200 px-3 py-3",
              { hidden: !showMenuFooterButton },
            )}
            onClick={() => {
              if (menuFooterClick) {
                menuFooterClick();
              }
              setIsMenuOpen(false);
            }}
          >
            <img alt="" src={CogIcon} />
            {menuFooterText ?? "Manage"}
          </div>
        </components.Menu>
      );
    }, []);
  const customValueContainer: ComponentType<
    ValueContainerProps<any, boolean, GroupBase<any>>
  > = useCallback(
    ({ children, className, ...props }) => {
      return (
        <>
          <div className={cn("pl-2", { hidden: !icon })}>{icon}</div>
          <components.ValueContainer
            className={twMerge(
              cn(
                "!py-0 !pl-0 text-left font-semibold",
                valueContainerVariants({ color }),
                { "!pr-4": !compact },
                { "ml-4": !icon },
                className,
              ),
            )}
            {...props}
          >
            {children}
          </components.ValueContainer>
        </>
      );
    },
    [color, compact, icon],
  );

  const customOption: ComponentType<OptionProps<any, boolean, GroupBase<any>>> =
    useCallback(
      ({ children, ...props }) => {
        if (!hasAlreadyFocusedValue.current) {
          hasAlreadyFocusedValue.current = true;
          setTimeout(() => {
            if (optionRef.current) {
              optionRef.current?.parentElement?.scroll({
                top: optionRef.current.offsetTop,
              });
            }
          }, 10);
        }
        const isMulti = props.selectProps.isMulti;
        const value = props.selectProps.value;
        return (
          <div
            ref={(props as any).value === value?.value ? optionRef : undefined}
          >
            <components.Option {...props}>
              <div
                className={cn("flex items-center gap-2", {
                  "text-gray-200": props.isDisabled,
                })}
              >
                <div
                  className={cn("flex h-6 items-center", {
                    hidden: !isMulti,
                  })}
                >
                  <input
                    checked={props.isSelected || isAllSelected}
                    type="checkbox"
                    className="text-secondary-600 h-4 w-4 border-gray-300"
                    onChange={() => {}}
                  />
                </div>
                <div className="flex-1">{children}</div>
              </div>
            </components.Option>
          </div>
        );
      },
      [isAllSelected],
    );

  const customDropdownIndicator: ComponentType<
    DropdownIndicatorProps<any, boolean, GroupBase<any>>
  > = useCallback(
    ({ selectProps: { menuIsOpen } }: any) => {
      if (compact) {
        return null;
      }
      if (menuIsOpen) {
        return (
          <FontAwesomeIcon
            icon={faAngleUp}
            className={cn(dropdownIndicatorVariants({ color, size }))}
          />
        );
      }
      return (
        <FontAwesomeIcon
          icon={faAngleDown}
          className={cn(dropdownIndicatorVariants({ color, size }))}
        />
      );
    },
    [color, compact, size],
  );

  const customSelectContainer: ComponentType<
    ContainerProps<any, boolean, GroupBase<any>>
  > = useCallback(({ children, ...props }) => {
    return (
      <components.SelectContainer {...props}>
        <div className="w-100" ref={ref}>
          {children}
        </div>
      </components.SelectContainer>
    );
  }, []);

  return (
    <AsyncPaginate
      loadOptions={loadOptions}
      components={{
        SelectContainer: customSelectContainer,
        DropdownIndicator: customDropdownIndicator,
        IndicatorSeparator,
        Option: customOption,
        ValueContainer: customValueContainer,
        Menu: customMenu,
      }}
      classNames={{
        container: ({ isFocused, className }) =>
          cn(className, {
            "border-secondary-600": isFocused && isMenuOpen,
          }),
        control: () =>
          "!border-none bg-transparent !outline-0 !shadow-none !flex !flex-row justify-between w-full !flex-nowrap min-h-0",
        menu: () =>
          cn("!z-[9999] !rounded-lg dark:bg-gray-950 overflow-hidden", {
            "-ml-[4.2rem]": compact,
            "w-auto": compact || menuWidthShouldMatchText,
          }),
        option: ({ isSelected }: { isSelected: boolean }) =>
          cn(optionVariants({ size }), {
            "font-semibold": isSelected,
          }),
        valueContainer: () =>
          cn("border-none shadow-none cursor-pointer", {
            "!ml-2": compact,
          }),
      }}
      className={cn(
        "dark:hover:bg-darkGray-900 relative flex min-w-fit cursor-pointer items-center !rounded-lg border border-gray-300 text-center hover:bg-gray-100",
        paginatedSelectVariants({ size, color }),
        { "bg-gray-100": isDisabled },
        {
          "after:bg-secondary-200 after:border-secondary-300 after:contents-[123] relative after:absolute after:right-0 after:top-0 after:flex after:aspect-square after:h-6 after:-translate-y-1/2 after:translate-x-1/2 after:items-center after:justify-center after:rounded-full after:border after:p-1 after:text-sm":
            isMulti && value?.length > 0,
        },
        className,
      )}
      styles={{
        container: () => ({
          "&:after":
            isMulti && value?.length > 0
              ? { content: `"${value?.length ?? 0}"` }
              : {},
        }),
      }}
      value={value}
      onChange={onChange}
      isSearchable={isSearchable}
      isMulti={isMulti}
      placeholder={placeholder}
      additional={{ page: 0 }}
      cacheUniqs={cacheUniqs}
      isDisabled={isDisabled}
      isClearable={isClearable}
      menuPlacement="auto"
      menuIsOpen={isMenuOpen}
      onMenuOpen={() => {
        // Fix for menu instantly closing with touch events
        setTimeout(() => {
          setIsMenuOpen(true);
        }, 1);
      }}
      onMenuClose={() => {
        setIsMenuOpen(false);
      }}
      closeMenuOnSelect={!isMulti}
      blurInputOnSelect={!isMulti}
      hideSelectedOptions={false}
      controlShouldRenderValue={!isMulti}
      {...{
        customProps: {
          isAllSelected,
          menuFooterClick,
          menuFooterText,
          selectAllClickParam,
          showMenuFooterButton,
          showSelectAll,
          setIsAllSelected,
          size,
        },
      }}
    />
  );
}

const SelectHeader = function SelectHeader({
  ...props
}: Omit<MenuProps<any, boolean, GroupBase<any>>, "children">) {
  const {
    isAllSelected,
    selectAllClickParam,
    setIsAllSelected,
    showSelectAll,
    size,
  } = (props.selectProps as any).customProps;
  const loadOptions = ((props as any).selectProps as any).loadOptions;
  const isMulti = (props as any).selectProps.isMulti;
  const [isLoading, setIsLoading] = useState(false);
  return (
    <div
      className={cn(
        optionVariants({ size }),
        "flex items-center gap-2 border-b border-b-gray-200 p-3",
        { hidden: !isMulti || !showSelectAll },
      )}
      onClick={async () => {
        if (!selectAllClickParam) {
          return;
        }
        const fetchAllOptions = async (searchTerm?: string) => {
          let allOptions: PaginatedSelectOption[] = [];
          let allLoaded = false;
          let page = 0;

          do {
            const result = await loadOptions(searchTerm || "", [], {
              page: page++,
            });
            allLoaded = !result.hasMore;
            allOptions = allOptions.concat(result.options);
          } while (!allLoaded);
          return { allOptions, pageCount: page };
        };
        setIsLoading(true);
        const result = await selectAllClickParam({
          isAllSelected,
          fetchAllOptions,
          setValue: (props as any).setValue,
        });
        if (result === undefined) {
          setIsAllSelected(!isAllSelected);
        } else {
          setIsAllSelected(result);
        }
        setIsLoading(false);
      }}
    >
      {isLoading && <Spinner className="!h-6 !w-6" />}
      {!isLoading && (
        <>
          <div className="flex h-6 items-center">
            <input
              checked={isAllSelected}
              type="checkbox"
              className="text-secondary-600 h-4 w-4 border-gray-300"
              onChange={() => {}}
            />
          </div>
          <div>{isAllSelected ? "Deselect All" : "Select All"}</div>
        </>
      )}
    </div>
  );
};

const dropdownIndicatorVariants = cva("mr-4 cursor-pointer", {
  variants: {
    color: {
      default: "dark:text-darkGray-50 text-sm font-semibold text-gray-700",
      accent: "text-white",
    },
    size: {
      small: "mt-px",
      medium: "",
    },
  },
  defaultVariants: {
    color: "default",
    size: "medium",
  },
});

const valueContainerVariants = cva("", {
  variants: {
    color: {
      default:
        "dark:text-darkGray-50 dark:[&>div]:text-darkGray-50 text-sm font-semibold text-gray-700 [&>div]:text-gray-700",
      accent: "text-white [&>div]:text-white",
    },
  },
  defaultVariants: {
    color: "default",
  },
});

const optionVariants = cva(
  "dark:hover:!bg-darkGray-900 dark:!text-darkGray-50 !cursor-pointer !bg-white text-left !text-sm font-medium !text-gray-700 hover:!bg-gray-50 dark:!bg-gray-950",
  {
    variants: {
      size: {
        small: "",
        medium: "",
      },
    },
    defaultVariants: {
      size: "medium",
    },
  },
);

function IndicatorSeparator() {
  return null;
}

export async function eagerSelectAll({
  fetchAllOptions,
  setValue,
  isAllSelected,
}: SelectAllClickArgs) {
  if (isAllSelected) {
    setValue([], "deselect-option");
    return;
  }
  const { allOptions } = await fetchAllOptions();
  setValue(allOptions, "select-option");
}

export function renderMultiSelectPlaceholder({
  value,
  itemLabel = "Selected",
  noValuePlaceholder,
}: {
  value?: { label: string }[];
  itemLabel?: string;
  noValuePlaceholder?: string;
}) {
  if (value === undefined || value?.length === 0) {
    return noValuePlaceholder;
  }
  if (value?.length === 1 && value?.[0]?.label) {
    return value[0].label;
  }
  if (value?.length > 1) {
    return `${value.length} ${itemLabel}`;
  }
  return noValuePlaceholder;
}
