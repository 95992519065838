import { leadSourceListAsPublicQueryFn } from "@gymflow/api";
import {
  FormikInput,
  MobileNumberInput,
  renderErrorRowOnTouch,
} from "@gymflow/common";
import { cn } from "@gymflow/helpers";
import { UserFormRule } from "@gymflow/types";
import classNames from "classnames";
import { useFormikContext } from "formik";

import {
  Button,
  Checkbox,
  PaginatedSelect,
  TextInputClassNames,
} from "../../../components/atoms";
import useGymflowModels from "../../../store";
import { useClubSettings } from "../../settings";
import GenderSelect from "./../../../components/atoms/GenderSelect";
import { SignUpSchema, SignUpSchemaKey } from "./schema";

export function SignUpForm({
  requiredFields,
  clubWaiverLink,
  isSubmitting,
  submittingError,
  description,
  className,
}: {
  requiredFields: Record<keyof UserFormRule, boolean> | null;
  clubWaiverLink?: string;
  isSubmitting: boolean;
  submittingError: string;
  description: string;
  className?: string;
}) {
  const { api } = useGymflowModels();
  const formikProps = useFormikContext<SignUpSchema>();

  const settings = useClubSettings();

  if (!requiredFields) {
    return null;
  }

  return (
    <div className={cn("flex flex-col gap-8", className)}>
      <div className="flex flex-col gap-6 px-6">
        <div className="dark:text-darkGray-50 self-center text-2xl font-bold text-gray-900">
          Sign Up
        </div>

        <div className="dark:text-darkGray-400 self-center text-sm font-medium text-gray-600">
          {description}
        </div>
      </div>
      <div
        className="flex max-h-[70vh] w-full flex-col gap-6 overflow-y-auto px-2"
        role="form"
      >
        <div className="flex flex-col gap-1">
          <div className="dark:text-textSecondary-700">Email</div>
          <div>
            <FormikInput
              name={"email" satisfies SignUpSchemaKey}
              placeholder="Enter Email"
              type="email"
              autoComplete="off"
              maxLength={50}
              formikProps={formikProps}
              className={TextInputClassNames}
            />
          </div>
        </div>
        <div className="flex flex-col gap-1">
          <div className="dark:text-textSecondary-700">First Name</div>
          <div>
            <FormikInput
              name={"first-name" satisfies SignUpSchemaKey}
              placeholder="Enter First Name"
              type="text"
              maxLength={128}
              formikProps={formikProps}
              className={TextInputClassNames}
            />
          </div>
        </div>
        <div className="flex flex-col gap-1">
          <div className="dark:text-textSecondary-700">Last Name</div>
          <div>
            <FormikInput
              placeholder="Enter Last Name"
              name={"last-name" satisfies SignUpSchemaKey}
              type="text"
              formikProps={formikProps}
              className={TextInputClassNames}
            />
          </div>
        </div>
        {requiredFields.mobileNumber && (
          <div className="flex flex-col gap-1">
            <div className="dark:text-textSecondary-700">Phone</div>
            <div>
              <FormikInput
                name={"mobile-number" satisfies SignUpSchemaKey}
                placeholder="Enter Mobile Number"
                component={MobileNumberInput}
                type="tel"
                maxLength={32}
                formikProps={formikProps}
                className={TextInputClassNames}
                phone_number_country={settings.phone_number_country}
              />
            </div>
          </div>
        )}
        {requiredFields.dateOfBirth && (
          <div className="flex flex-col gap-1">
            <div className="dark:text-textSecondary-700">Date of Birth</div>
            <div>
              <FormikInput
                name={"date-birth" satisfies SignUpSchemaKey}
                type="text"
                maxLength={settings.date_format.length}
                formikProps={formikProps}
                placeholder={settings.date_format}
                className={TextInputClassNames}
              />
            </div>
          </div>
        )}
        {requiredFields.gender && (
          <div className="flex flex-col gap-1">
            <div className="dark:text-textSecondary-700">Gender</div>
            <div>
              <GenderSelect
                onChange={(newGender: any) =>
                  formikProps.setFieldValue(
                    "gender" satisfies SignUpSchemaKey,
                    newGender,
                  )
                }
                value={formikProps.values.gender ?? "PREFER_NOT_TO_SAY"}
              />
              {renderErrorRowOnTouch(
                "gender" satisfies SignUpSchemaKey,
                formikProps.touched,
                formikProps.errors,
              )}
            </div>
          </div>
        )}

        {(requiredFields.addressLine ||
          requiredFields.city ||
          requiredFields.postCode) && (
          <div className="flex flex-col gap-1">
            <div className="dark:text-textSecondary-700">Address</div>
            {requiredFields.addressLine && (
              <>
                <div>
                  <FormikInput
                    name={"address-line1" satisfies SignUpSchemaKey}
                    placeholder="Address 1"
                    type="text"
                    maxLength={128}
                    formikProps={formikProps}
                    className={TextInputClassNames}
                  />
                </div>
                <div>
                  <FormikInput
                    name={"address-line2" satisfies SignUpSchemaKey}
                    placeholder="Address 2"
                    type="text"
                    maxLength={128}
                    formikProps={formikProps}
                    className={TextInputClassNames}
                  />
                </div>
              </>
            )}
            {(requiredFields.postCode || requiredFields.city) && (
              <div className="flex flex-row">
                {requiredFields.city && (
                  <div>
                    <FormikInput
                      name={"city" satisfies SignUpSchemaKey}
                      placeholder="City"
                      type="text"
                      maxLength={128}
                      formikProps={formikProps}
                      className={TextInputClassNames}
                    />
                  </div>
                )}
                {requiredFields.postCode && (
                  <div>
                    <FormikInput
                      name={"post-code" satisfies SignUpSchemaKey}
                      placeholder="Postcode/Zip"
                      type="text"
                      maxLength={16}
                      formikProps={formikProps}
                      className={classNames(
                        { TextInputClassNames },
                        {
                          "ml-2": requiredFields.city,
                        },
                      )}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        )}

        <div className="flex flex-col gap-1">
          <div className="dark:text-textSecondary-700">
            How did you hear about us?
          </div>
          <div>
            <PaginatedSelect
              placeholder="Select Source"
              value={formikProps.values["source-id"]}
              onChange={(newValue) =>
                formikProps.setFieldValue("source-id", newValue)
              }
              loadOptions={async () => {
                const data = await leadSourceListAsPublicQueryFn({
                  api,
                });

                return {
                  options: data.map((source) => ({
                    label: source.name,
                    value: source.id,
                  })),
                };
              }}
            />
            {renderErrorRowOnTouch(
              "source-id" satisfies SignUpSchemaKey,
              formikProps.touched,
              formikProps.errors,
            )}
          </div>
        </div>
        {requiredFields.emergencyContact && (
          <>
            <div className="flex flex-col gap-1">
              <div className="dark:text-textSecondary-700">
                Emergency Contact Name
              </div>
              <div>
                <FormikInput
                  name={"emergency-contact-name" satisfies SignUpSchemaKey}
                  type="text"
                  maxLength={128}
                  formikProps={formikProps}
                  className={TextInputClassNames}
                  placeholder="Enter Emergency Contact Name"
                />
              </div>
            </div>
            <div className="flex flex-col gap-1">
              <div className="dark:text-textSecondary-700">
                Emergency Contact Phone
              </div>
              <div>
                <FormikInput
                  name={"emergency-contact" satisfies SignUpSchemaKey}
                  component={MobileNumberInput}
                  type="tel"
                  maxLength={32}
                  formikProps={formikProps}
                  placeholder="Enter Emergency Contact Number"
                  phone_number_country={settings.phone_number_country}
                />
              </div>
            </div>
          </>
        )}

        <div className="flex flex-col gap-1">
          <div className="dark:text-textSecondary-700">
            Create Your Password
          </div>
          <div>
            <FormikInput
              name={"password" satisfies SignUpSchemaKey}
              placeholder="Enter your password"
              type="password"
              autoComplete="off"
              maxLength={50}
              formikProps={formikProps}
              className={TextInputClassNames}
            />
          </div>
        </div>
        <div className="flex flex-col gap-1">
          <div className="dark:text-textSecondary-700">
            Confirm Your Password
          </div>
          <div>
            <FormikInput
              name={"confirm-password" satisfies SignUpSchemaKey}
              placeholder="Enter your password"
              type="password"
              autoComplete="off"
              maxLength={50}
              formikProps={formikProps}
              className={TextInputClassNames}
            />
          </div>
        </div>
        <div>
          <div className="flex flex-row items-center justify-between">
            <div className="dark:text-textSecondary-700">
              Keep in touch via Email
            </div>
            <div>
              <Checkbox
                value={formikProps.values["email-communication"]}
                onChange={(checked) => {
                  formikProps.setFieldValue(
                    "email-communication" satisfies SignUpSchemaKey,
                    checked,
                  );
                }}
              />
            </div>
          </div>
        </div>
        <div>
          <div className="flex flex-row items-center justify-between">
            <div className="dark:text-textSecondary-700">
              Keep in touch via SMS
            </div>
            <div>
              <Checkbox
                value={formikProps.values["sms-communication"]}
                onChange={(checked) => {
                  formikProps.setFieldValue(
                    "sms-communication" satisfies SignUpSchemaKey,
                    checked,
                  );
                }}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-1">
          <div className="dark:text-textSecondary-700">Club Waiver</div>
          <div className="flex flex-row items-center justify-between">
            <div className="dark:text-textSecondary-700 text-gray-400">
              By creating an account I agree with the{" "}
              <a target="_blank" href={clubWaiverLink} rel="noreferrer">
                club&apos;s waiver
              </a>
              .
            </div>
            <div>
              <Checkbox
                value={formikProps.values["is-club-waiver-accepted"]}
                onChange={(checked) => {
                  formikProps.setFieldValue(
                    "is-club-waiver-accepted" satisfies SignUpSchemaKey,
                    checked,
                  );
                }}
              />
            </div>
          </div>

          {renderErrorRowOnTouch(
            "is-club-waiver-accepted" satisfies SignUpSchemaKey,
            formikProps.touched,
            formikProps.errors,
          )}
        </div>
      </div>
      <div className="pb-4">
        <div
          className={classNames("text-error-500", { hidden: !submittingError })}
        >
          {submittingError}
        </div>
        <Button
          intent="primary"
          className="mt-2 w-full"
          onClick={() => {
            formikProps.handleSubmit();
          }}
          showSpinner={isSubmitting}
          disabled={isSubmitting}
        >
          Register
        </Button>
      </div>
    </div>
  );
}
