import {
  useClubAsPublic,
  useMemberCreateAsPublic,
  useRuleValuesAsPublic,
} from "@gymflow/api";
import { useRecordForm } from "@gymflow/common";
import { UserFormRule, UserMemberPost } from "@gymflow/types";
import { Formik } from "formik";
import { useContext, useMemo, useState } from "react";
import { toFormikValidationSchema } from "zod-formik-adapter";

import { requestErrorParser } from "../../../helpers/requestErrorParser";
import { getDefaultsFromZodSchema } from "../../../helpers/zod";
import useGymflowModels from "../../../store";
import { useClubSettings } from "../../settings";
import { useAuthenticatedUser } from "../AuthenticatedProvider";
import { LoginOverlayContext } from "./context";
import { EmailExistsForm } from "./EmailExistsForm";
import { SignUpFormMapper } from "./mapper";
import { generateSchema } from "./schema";
import { SignUpForm } from "./SignUpForm";

export function TrySignUpOverlay({
  defaultEmail = "",
  defaultScreen = "TRY_EMAIL",
}: {
  defaultEmail?: string;
  defaultScreen?: ScreenType;
}) {
  const { api } = useGymflowModels();
  const auth = useAuthenticatedUser();
  const { closeOverlay } = useContext(LoginOverlayContext);
  const settings = useClubSettings();
  const { showLoginOverlay } = useContext(LoginOverlayContext);
  const { data: club } = useClubAsPublic({ api, clubId: settings.clubId });
  const [screen, setScreen] = useState<"TRY_EMAIL" | "SIGN_UP_FORM">(
    defaultScreen,
  );
  const [email, setEmail] = useState(defaultEmail);

  const { data } = useRuleValuesAsPublic({ api, clubId: settings.clubId });
  const requiredFields: Record<keyof UserFormRule, boolean> | null =
    useMemo(() => {
      if (!data) {
        return null;
      }
      const formConfig = data.find(
        (r) => r.ruleType === "USER_FORM_REQUIREMENT_CONFIG",
      );
      if (!formConfig) {
        return null;
      }
      return Object.keys(formConfig.userFormRule!).reduce(
        (acc, r) => {
          if (!formConfig.userFormRule) {
            return acc;
          }
          const field = r as keyof UserFormRule;
          acc[field] = formConfig.userFormRule[field].isRequired;
          return acc as Record<keyof UserFormRule, boolean>;
        },
        {} as Record<keyof UserFormRule, boolean>,
      );
    }, [data]);

  const createMemberMutation = useMemberCreateAsPublic({ api });

  const rules = data?.find(
    (r) => r.ruleType === "USER_FORM_REQUIREMENT_CONFIG",
  );
  const schema = generateSchema({
    rules: rules?.userFormRule || undefined,
    defaultNationality: settings.default_nationality,
    postCodeFormat: settings.postal_code_country,
    dateFormat: settings.date_format,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { initialValues, getValues } = useRecordForm({
    record: { email },
    fields: getDefaultsFromZodSchema(schema),
    mapper: new SignUpFormMapper({
      dateFormat: settings.date_format,
      rules: rules?.userFormRule,
    }),
  });
  const [submittingError, setSubmittingError] = useState("");

  return (
    <>
      {screen === "TRY_EMAIL" && (
        <EmailExistsForm
          email={email}
          onSubmit={(newEmail, isAlreadyRegistered) => {
            setEmail(newEmail);
            if (isAlreadyRegistered) {
              showLoginOverlay(newEmail);
            } else {
              setScreen("SIGN_UP_FORM");
            }
          }}
        />
      )}
      {screen === "SIGN_UP_FORM" && (
        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={async (values) => {
            setIsSubmitting(true);
            try {
              const newUser = getValues(values) as UserMemberPost;
              await createMemberMutation.mutateAsync(newUser);

              await auth.login(newUser.email, newUser.password!);
              closeOverlay({ loggedIn: true });
            } catch (e: any) {
              if (e?.response?.data?.errors) {
                setSubmittingError("Error: " + requestErrorParser(e));
              }
            } finally {
              setIsSubmitting(false);
            }
          }}
          validationSchema={toFormikValidationSchema(schema)}
        >
          <SignUpForm
            className="w-full"
            requiredFields={requiredFields}
            clubWaiverLink={club?.termsConditions}
            isSubmitting={isSubmitting}
            submittingError={submittingError}
            description="Sign up to complete your booking or purchase"
          />
        </Formik>
      )}
    </>
  );
}

type ScreenType = "TRY_EMAIL" | "SIGN_UP_FORM";
