import {
  ApiListResponse,
  ProductCategoryDTO,
  ProductDTO,
  ProductStatus,
} from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { defaultPage } from "../defaultPage";
import { productQueryKeys } from "./productQueryKeys";
import {
  ProductCategoryFilter,
  useProductCategorySearchList,
} from "./useProductCategorySearch";

export function useProductList(
  {
    api,
    opts,
  }: {
    api: {
      productApi: {
        find: (arg0: { [k: string]: any }) => Promise<any>;
        findCategories: (
          filter: ProductCategoryFilter,
        ) => Promise<{ data: ApiListResponse<ProductCategoryDTO> }>;
      };
    };
    opts: ProductListFilter;
  },
  queryOpts?: UseQueryOptions<
    ApiListResponse<ProductDTO>,
    unknown,
    ApiListResponse<ProductWithCategoryName>
  >,
) {
  const categories = useProductCategorySearchList({
    api,
    opts: { limit: 1000, page: 0 },
  });

  const result = useQuery({
    queryKey: productQueryKeys.list(opts),
    queryFn: async () => {
      const result = await api.productApi.find({ ...opts });
      return result.data as ApiListResponse<ProductDTO>;
    },
    initialData: defaultPage<ProductWithCategoryName>,
    enabled: categories.isSuccess,
    select: (products) => {
      return {
        ...products,
        content: products.content.map((product) => {
          const productWithCategory: ProductWithCategoryName = {
            ...product,
            categoryName: categories.data!.content.find(
              (category) => category.id === product.productCategoryId,
            )?.name as string,
          };
          return productWithCategory;
        }),
      };
    },
    ...queryOpts,
  });
  return result;
}

export interface ProductWithCategoryName extends ProductDTO {
  categoryName: string;
}

interface ProductListFilter {
  readonly extraParams?: {
    readonly unpaged?: boolean;
    readonly status?: ProductStatus;
    readonly id?: number[];
    readonly [k: string]: any;
  };
  readonly page?: number;
  readonly limit?: number;
  readonly sort?: {
    readonly field: string;
    readonly desc?: boolean;
  };
  readonly dateFrom?: string;
  readonly dateTo?: string;
  readonly unpaged?: boolean;
}
