import {
  InvoiceStatus,
  NotificationContext,
  PaymentConfirmationStatus,
  usePaymentAuthorizationAlert,
} from "@gymflow/common";
import { useCallback, useContext, useMemo } from "react";
import { useHistory } from "react-router-dom";

import { RouteFeature } from "../routes/feature";
import { RouteLayout } from "../routes/layout";
import useGymflowModels from "../store";
import { usePortalRoutes } from "./usePortalRoutes";

function useProcessSaleStaff() {
  const { createClubLink } = usePortalRoutes();
  const { api } = useGymflowModels();
  const history = useHistory();
  const { show: showPaymentConfirmationAlert } = usePaymentAuthorizationAlert();
  const toast = useContext(NotificationContext);

  const createProcessSaleFn = useCallback(
    (apiCall: any, successMessage: string) =>
      async (...args: any[]) => {
        const response = await apiCall(...args);
        if (response.data.checkoutSessionId) {
          return response;
        }

        if (
          response.data?.invoice?.status === InvoiceStatus.PaymentAuthorization
        ) {
          const paymentConfirmationResult = await showPaymentConfirmationAlert({
            paymentIntentIdForAuthorization:
              response.data.invoice.paymentIntentIdForAuthorization,
            confirmPayment: api.strongCustomerAuthorizationApi.confirmPayment,
            messageText: (
              <>
                The user must authorize this payment before it will be
                processed.
                <br />
                Please ask the user to authorize the payment by clicking the
                link sent to their email.
              </>
            ),
          });

          history.push(
            createClubLink(RouteLayout.Staff, RouteFeature.Dashboard),
          );
          if (
            paymentConfirmationResult.status ===
            PaymentConfirmationStatus.Success
          ) {
            toast.notify({ message: `${successMessage}.` });
          } else if (
            paymentConfirmationResult.status ===
            PaymentConfirmationStatus.Waiting
          ) {
            toast.notify({
              message: `${successMessage}, awaiting payment, check later.`,
              type: "warning",
            });
          } else if (
            paymentConfirmationResult.status ===
            PaymentConfirmationStatus.Failed
          ) {
            toast.notify({
              message: `${successMessage}, payment failed.`,
              type: "danger",
            });
          }

          return response;
        }

        history.push(createClubLink(RouteLayout.Staff, RouteFeature.Dashboard));
        toast.notify({ message: successMessage });
        return response;
      },
    [history, showPaymentConfirmationAlert, toast],
  );

  const processNewMemberMembership = useMemo(
    () => createProcessSaleFn(api.memberApi.create, "Membership purchased"),
    [createProcessSaleFn],
  );
  const processExistingMemberMembership = useMemo(
    () => createProcessSaleFn(api.memberApi.update, "Membership purchased"),
    [createProcessSaleFn],
  );
  const processNewMemberCreditPack = useMemo(
    () =>
      createProcessSaleFn(api.memberApi.create, "Credit pack added to account"),
    [createProcessSaleFn],
  );
  const processExistingMemberCreditPack = useMemo(
    () =>
      createProcessSaleFn(api.memberApi.update, "Credit pack added to account"),
    [createProcessSaleFn],
  );

  return {
    processNewMemberMembership,
    processExistingMemberMembership,
    processNewMemberCreditPack,
    processExistingMemberCreditPack,
  };
}

export default useProcessSaleStaff;
