import { Pagination } from "../molecules";
import { Table, TableProps } from "./Table";

export interface PaginatedTableProps<T> {
  tableProps: TableProps<T>;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  goToNextPage: () => void;
  goToPreviousPage: () => void;
}

export const PaginatedTable = <T,>({
  tableProps,
  hasPreviousPage,
  hasNextPage,
  goToNextPage,
  goToPreviousPage,
}: PaginatedTableProps<T>) => {
  return (
    <div className="h-full max-h-full w-full overflow-hidden rounded-xl border border-gray-200 bg-white shadow-sm">
      <div className="flex h-[calc(100%-4.75rem)]">
        <Table {...tableProps} manualSorting rowClassName="h-14" />
      </div>

      <Pagination
        className="min-h-fit p-4"
        hasNextPage={hasNextPage}
        hasPreviousPage={hasPreviousPage}
        goToNextPage={goToNextPage}
        goToPreviousPage={goToPreviousPage}
      />
    </div>
  );
};
