import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  memberQueryKeys,
  useMemberInvoice,
  useMutationMemberInvoice,
  useQueryMemberHighlightedInvoice,
} from "@gymflow/api";
import { NotificationContext } from "@gymflow/common";
import { downloadHtml, formatCurrency } from "@gymflow/helpers";
import { HighlightedInvoiceDTO, UserMemberBean } from "@gymflow/types";
import { useQueryClient } from "@tanstack/react-query";
import { ModalContext, useClubSettings } from "apps/portal/src/providers";
import useGymflowModels from "apps/portal/src/store";
import { DateTime, Interval } from "luxon";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";

import {
  Button,
  CardIcon,
  DownloadIcon,
  Popover,
  PopoverContent,
  PopoverTrigger,
  RefreshIcon,
} from "../../atoms";
import { CalendarWithClockIcon } from "../../atoms/icons/CalendarWithClockIcon";
import { ExclamationCircleIcon } from "../../atoms/icons/ExclamationCircleIcon";
import { PencilIcon } from "../../atoms/icons/PencilIcon";
import { ShieldIcon } from "../../atoms/icons/ShieldIcon";
import { XIcon } from "../../atoms/icons/XIcon";
import { UserMemberAdjustInvoiceModal } from "./UserMemberAdjustInvoiceModal";
import { UserMemberPaymentsCard } from "./UserMemberPaymentsCard";

export type UserMemberHighlightedInvoiceProps = {
  userMember: UserMemberBean;
};

export const UserMemberHighlightedInvoice = ({
  userMember,
}: UserMemberHighlightedInvoiceProps) => {
  const { t } = useTranslation();
  const { api } = useGymflowModels();
  const settings = useClubSettings();
  const {
    data: highlightedInvoice,
    isLoading: isLoadingHighlightedInvoice,
    isSuccess,
    refetch,
  } = useQueryMemberHighlightedInvoice({
    api,
    memberId: userMember.id,
  });
  const { cancelAuthorizationMutation } = useMemberInvoice({
    api,
    memberId: userMember.id,
  });
  const { mutateAsync: downloadInvoice } = useMutationMemberInvoice({
    api,
    memberId: userMember.id,
    clubId: settings.clubId,
  });
  const queryClient = useQueryClient();
  useEffect(() => {
    const THIRTY_SECONDS_IN_MS = 30 * 1000;
    let refetchTimeout: ReturnType<typeof setTimeout>;
    if (isSuccess && highlightedInvoice?.status === "AWAITING_AUTHORIZATION") {
      refetchTimeout = setInterval(async () => {
        const query = await refetch();
        if (
          query.isSuccess &&
          (!query.data || query.data.status !== "AWAITING_AUTHORIZATION")
        ) {
          queryClient.invalidateQueries({
            queryKey: memberQueryKeys.invoicesNew(userMember.id),
          });
        }
      }, THIRTY_SECONDS_IN_MS);
    }
    return () => {
      if (refetchTimeout !== undefined) {
        clearTimeout(refetchTimeout);
      }
    };
  }, [highlightedInvoice, isSuccess, queryClient, refetch, userMember.id]);
  const { stackModal } = useContext(ModalContext);
  return (
    <UserMemberPaymentsCard.Container isLoading={isLoadingHighlightedInvoice}>
      <UserMemberPaymentsCard.Header>
        {highlightedInvoice?.status === "PAST_DUE" && (
          <>
            <div
              data-tooltip-id="highlighted-invoice-error-tooltip"
              data-tooltip-content={t(
                `StripeErrorCodes.Long.${
                  highlightedInvoice.lastPaymentErrorCode ?? "UNKNOWN"
                }`,
              )}
              className="bg-error-100 text-error-600 flex flex-row items-center justify-center gap-2 rounded-full px-3 py-1 font-semibold"
            >
              <ExclamationCircleIcon className="stroke-error-600 h-5 w-5" />
              {t(
                `StripeErrorCodes.Short.${
                  highlightedInvoice.lastPaymentErrorCode ?? "UNKNOWN"
                }`,
              )}
            </div>
            <Tooltip
              id="highlighted-invoice-error-tooltip"
              className="!z-[10000] !max-w-sm !rounded-xl !bg-black !px-3 !py-2 !text-center !text-sm !font-semibold !text-white !opacity-100"
              content={t(
                `StripeErrorCodes.Long.${
                  highlightedInvoice.lastPaymentErrorCode ?? "UNKNOWN"
                }`,
              )}
            />
          </>
        )}

        {highlightedInvoice?.status === "AWAITING_AUTHORIZATION" && (
          <div className="bg-warning-100 text-warning-600 flex flex-row items-center justify-center gap-2 rounded-full px-3 py-1 font-semibold">
            <ShieldIcon className="h-5 w-5" />
            Authorization Needed
          </div>
        )}
        {(!highlightedInvoice ||
          highlightedInvoice?.status === "SCHEDULED") && (
          <div className="flex flex-row items-center gap-2">
            <div className="bg-secondary-100 flex h-9 w-9 items-center justify-center rounded-full">
              <CalendarWithClockIcon className="fill-secondary-500" />
            </div>
            <div className="flex font-semibold text-gray-950">
              Scheduled Invoice
            </div>
          </div>
        )}
        {highlightedInvoice && (
          <Button
            intent="transparent"
            className="h-8 min-h-0 justify-start gap-2 p-2 py-0"
            onClick={async () => {
              const { number } = highlightedInvoice;
              const { data: html } = await downloadInvoice({
                invoiceNumber: number,
              });
              downloadHtml(html, `Gymflow-${number}.html`);
            }}
          >
            <DownloadIcon pathClassName="stroke-gray-500" />
            Invoice
          </Button>
        )}
      </UserMemberPaymentsCard.Header>
      <UserMemberPaymentsCard.Footer>
        {!highlightedInvoice && (
          <div className="text-lg font-medium text-gray-500">
            There are no scheduled invoices.
          </div>
        )}
        {highlightedInvoice && (
          <>
            <div className="flex flex-col">
              <div className="text-2xl font-bold text-gray-950">
                {formatCurrency(
                  highlightedInvoice.totalAmount,
                  settings.defaultCurrency,
                )}
              </div>
              <div className="text-sm font-medium text-gray-500">
                Due{" "}
                {DateTime.fromISO(highlightedInvoice.issueDate).toLocaleString(
                  DateTime.DATE_MED,
                )}
              </div>
            </div>
            {highlightedInvoice.status === "SCHEDULED" && (
              <Button
                className="gap-1"
                onClick={async () => {
                  stackModal(
                    <UserMemberAdjustInvoiceModal
                      invoice={highlightedInvoice}
                      userMember={userMember}
                    />,
                  );
                }}
              >
                <PencilIcon
                  className="h-4 w-4"
                  pathClassName="stroke-gray-950"
                />
                Adjust
              </Button>
            )}
            {highlightedInvoice.status === "PAST_DUE" && (
              <OverdueInvoicePopover
                invoice={highlightedInvoice}
                userMember={userMember}
              />
            )}
            {highlightedInvoice.status === "AWAITING_AUTHORIZATION" && (
              <Button
                tooltip="Cancel Auth"
                className="min-h-0 min-w-0 gap-1 p-3"
                onClick={async () => {
                  if (!highlightedInvoice.paymentIntentId) return;
                  await cancelAuthorizationMutation.mutateAsync({
                    paymentIntentIdForAuthorization:
                      highlightedInvoice.paymentIntentId,
                  });
                }}
              >
                <XIcon className="h-5 w-4 stroke-gray-950" />
                <div className="text-gray-950 max-lg:hidden">Cancel Auth</div>
              </Button>
            )}
          </>
        )}
      </UserMemberPaymentsCard.Footer>
    </UserMemberPaymentsCard.Container>
  );
};

function OverdueInvoicePopover({
  userMember,
  invoice,
}: {
  userMember: UserMemberBean;
  invoice: HighlightedInvoiceDTO;
}) {
  const { api } = useGymflowModels();
  const settings = useClubSettings();

  const { collectInvoiceMutation } = useMemberInvoice({
    api,
    memberId: userMember.id,
  });
  const { notifyDanger } = useContext(NotificationContext);
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button className="min-h-0 min-w-0 gap-1 p-3">
          <div className="text-gray-950 max-lg:hidden">Retries Information</div>
          <div className="text-gray-950 lg:hidden">Retries</div>
          <FontAwesomeIcon
            icon={faAngleDown}
            className="h-4 w-4 text-gray-950"
          />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="flex w-fit flex-col gap-4 p-4" align="end">
        <div className="flex flex-row items-center gap-4">
          <div className="flex h-9 w-9 rotate-90 items-center justify-center rounded-full bg-gray-100">
            <RefreshIcon className="h-5 w-5" pathClassName="stroke-gray-500" />
          </div>
          <div className="flex flex-col">
            <div className="text-base font-semibold text-gray-950">
              Retries information
            </div>
            {invoice?.lastPaymentAttemptDate && (
              <div className="text-xs font-medium text-gray-500">
                Last retried{" "}
                {
                  Interval.fromDateTimes(
                    DateTime.fromISO(invoice.lastPaymentAttemptDate),
                    DateTime.now(),
                  )
                    .toDuration()
                    .rescale()
                    .toHuman()
                    .split(",")[0]
                }{" "}
                ago
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex flex-row justify-between">
            <div className="text-sm font-medium text-gray-500">
              Next auto retry:
            </div>
            <div className="text-sm font-semibold text-gray-950">
              {invoice?.nextAutoRetryDate
                ? DateTime.fromISO(invoice.nextAutoRetryDate)
                    .setZone(settings.timezone)
                    .toLocaleString(DateTime.DATE_MED)
                : "-"}
            </div>
          </div>
          <div className="flex flex-row justify-between">
            <div className="text-sm font-medium text-gray-500">
              Auto retry limit:
            </div>
            <div className="text-sm font-semibold text-gray-950">
              {invoice.paymentAutoRetryCount}/{invoice.paymentAutoRetryLimit}
            </div>
          </div>{" "}
          <div className="flex flex-row justify-between">
            <div className="text-sm font-medium text-gray-500">
              Total retries:
            </div>
            <div className="text-sm font-semibold text-gray-950">
              {invoice.paymentAttemptCount}
            </div>
          </div>
        </div>
        <Button
          onClick={async () => {
            await collectInvoiceMutation
              .mutateAsync({
                invoiceNumber: invoice.number,
                amount: invoice.totalAmount,
                clubId: settings.clubId,
                memberId: userMember.id,
              })
              .catch((e) => {
                notifyDanger(e);
              });
          }}
          className="flex w-full flex-row items-center gap-1 text-gray-950"
        >
          <CardIcon pathClassName="stroke-gray-950" className="h-5 w-5" />
          <div className="text-gray-950">Collect Manually</div>
        </Button>
      </PopoverContent>
    </Popover>
  );
}
