import { useAbility } from "@casl/react";
import { hasStaffRole, useMemberAsMember } from "@gymflow/api";
import { AlertProvider, NotificationProvider } from "@gymflow/common";
import { Switch } from "react-router-dom";

import { TopNavbar } from "../../components/Navbars/TopNavbar";
import { Sidebar } from "../../components/Sidebar/Sidebar";
import { getVisibleRoutes } from "../../helpers/routes";
import { usePageLayout } from "../../hooks";
import { AbilityContext } from "../../permissions";
import {
  ApiResolverProvider,
  AuthenticatedProvider,
  ClubSettingsAsMemberProvider,
  ModalProvider,
  PageTitleProvider,
  PermissionsProvider,
  useAuthenticatedUser,
  useClubSettings,
  YupExtensions,
} from "../../providers";
import { member as routes } from "../../routes";
import useGymflowModels from "../../store";
import MainStyleLayout from "../MainStyleLayout/MainStyleLayout";

function Member() {
  const { isSidebarOpen, getRoutes, closeSidebar, toggleSidebar } =
    usePageLayout({ routes });
  const ability = useAbility(AbilityContext);
  const { id: loggedInId } = useAuthenticatedUser();
  const { api } = useGymflowModels();
  const { timezone } = useClubSettings();
  const { data: member } = useMemberAsMember({
    api,
    memberId: loggedInId,
    tz: timezone,
  });

  const visibleRoutes = getVisibleRoutes(routes, ability);
  return (
    <NotificationProvider>
      <AlertProvider>
        <ModalProvider>
          <Sidebar
            routes={visibleRoutes}
            closeSidebar={closeSidebar}
            sidebarOpened={isSidebarOpen}
            userName={`${member?.firstName} ${member?.lastName}`}
            userEmail={member?.email}
            avatar={member?.picture}
          />
          <div className="flex h-full max-h-full w-full flex-col lg:w-[calc(100vw-280px)]">
            <TopNavbar toggleSidebar={toggleSidebar} />
            <Switch>{getRoutes(routes)}</Switch>
          </div>
        </ModalProvider>
      </AlertProvider>
    </NotificationProvider>
  );
}

function MemberWithProviders() {
  return (
    <ApiResolverProvider userType="MEMBER">
      {({ authController, clubId }) => (
        <AuthenticatedProvider authController={authController!}>
          {({ roles }) => {
            const isStaff = hasStaffRole(roles);
            return (
              <PageTitleProvider>
                <ClubSettingsAsMemberProvider clubId={clubId}>
                  <PermissionsProvider allowedRoleCategory="MEMBER">
                    <MainStyleLayout isStaff={isStaff}>
                      <YupExtensions>
                        <Member />
                      </YupExtensions>
                    </MainStyleLayout>
                  </PermissionsProvider>
                </ClubSettingsAsMemberProvider>
              </PageTitleProvider>
            );
          }}
        </AuthenticatedProvider>
      )}
    </ApiResolverProvider>
  );
}

export default MemberWithProviders;
