import { useRouteMatch } from "react-router-dom";

import { Automations } from "../components/Marketing/Automations";
import { Promotions } from "../components/Marketing/Promotions";
import { Templates } from "../components/Marketing/Templates";
import PillTabsLayout from "../components/molecules/PillTabsLayout";

export function Marketing() {
  const { url } = useRouteMatch();

  const marketingRoute = {
    Automations: `${url}/automations`,
    Promotions: `${url}/promotions`,
    Templates: `${url}/templates`,
  };

  const layoutConfig = [
    {
      text: "Automations",
      path: marketingRoute.Automations,
      component: Automations,
    },
    {
      text: "Promotions",
      path: marketingRoute.Promotions,
      component: Promotions,
    },
    {
      text: "Templates",
      path: marketingRoute.Templates,
      component: Templates,
    },
  ];
  return <PillTabsLayout className="p-8" tabs={layoutConfig} />;
}
