import { TokenPageableRequestParams } from "@gymflow/types";
import { QueryKey } from "@tanstack/react-query";

export const memberQueryKeys = {
  all: () => ["member"] as QueryKey,
  listNew: (
    pagination: TokenPageableRequestParams,
    params?: Readonly<{ [k: string]: any }>,
  ) => [...memberQueryKeys.all(), "list", pagination, params] as QueryKey,
  listNewCount: (params?: Readonly<{ [k: string]: any }>) =>
    [...memberQueryKeys.all(), "listCount", params] as QueryKey,
  list: (filters: Readonly<{ [k: string]: any }>) =>
    [...memberQueryKeys.all(), { filters }] as QueryKey,
  details: (memberId?: string) =>
    [...memberQueryKeys.all(), memberId] as QueryKey,
  paymentMethodsNew: (clubId: number, memberId: string) =>
    [
      ...memberQueryKeys.all(),
      "paymentMethodsNew",
      clubId,
      memberId,
    ] as QueryKey,
  /*** @deprecated - use paymentMethodsNew instead*/
  paymentMethods: (clubId: number, memberId: string) =>
    [...memberQueryKeys.all(), "paymentMethods", clubId, memberId] as QueryKey,
  invoicesNew: (memberId: string, filters?: { [k: string]: any }) => {
    const result = [...memberQueryKeys.all(), memberId, "invoicesNew"];
    if (filters) result.push(filters);
    return result as QueryKey;
  },
  highlightedInvoice: (memberId: string) =>
    [
      ...memberQueryKeys.all(),
      memberId,
      "invoicesNew",
      "highlightedInvoice",
    ] as QueryKey,
  /*** @deprecated - use invoicesNew instead*/
  invoices: (memberId: string, filters?: { [k: string]: any }) =>
    [...memberQueryKeys.all(), memberId, "invoices", filters] as QueryKey,
  invoiceFile: (memberId: string, clubId: number, invoiceNumber: string) =>
    [
      ...memberQueryKeys.all(),
      memberId,
      "invoiceFile",
      clubId,
      invoiceNumber,
    ] as QueryKey,
  searchByEmail: (email: string) =>
    [...memberQueryKeys.all(), "byEmail", email] as QueryKey,
  searchByFullName: (name: string, page: number, limit: number) =>
    [...memberQueryKeys.all(), "byFullName", { name, page, limit }] as QueryKey,
  search: (term: string, size: number, field?: string) =>
    [...memberQueryKeys.all(), "search", term, size, field] as QueryKey,
  calculateChangeSubscription: (memberId: string, membershipId: number) =>
    [
      ...memberQueryKeys.all(),
      "prorataEarlyResumeSubscription",
      memberId,
      membershipId,
    ] as QueryKey,
  timeline: (memberId: string, filters?: Readonly<{ [k: string]: any }>) =>
    [...memberQueryKeys.all(), memberId, "timeline", filters] as QueryKey,
  membershipLinkedMembers: (primarySubscriptionId?: number) =>
    [
      ...memberQueryKeys.all(),
      "membershipLinkedMembers",
      primarySubscriptionId,
    ] as QueryKey,
  addPromotionCodeToSubscription: (filters: {
    subscriptionId?: number;
    promotionCode?: string;
  }) =>
    [
      ...memberQueryKeys.all(),
      "addPromotionCodeToSubscription",
      filters.subscriptionId,
      filters.promotionCode,
    ] as QueryKey,
};
