import {
  clubStaleTime,
  useClub,
  useEventOccurrence,
  useEventOccurrenceCreate,
  useEventOccurrenceEdit,
} from "@gymflow/api";
import {
  DATE_TIME_FORMAT,
  NotificationContext,
  PARAMETER_DATE_FORMAT_WITHOUT_TZ,
} from "@gymflow/common";
import { EventOccurrenceDTO } from "@gymflow/types";
import classNames from "classnames";
import moment from "moment-timezone";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from "react";

import { ModalContext, useClubSettings } from "../../../providers";
import useGymflowModels from "../../../store";
import { RecurrenceEditModal } from "./RecurrenceEditModal";
import { SideBarOccurrenceForm } from "./SideBarOccurrenceForm";

interface SideBarOccurrenceFormProviderProps {
  children: ReactNode;
}

interface SideBarOccurrenceFormProviderContextType {
  openEdit: (occurrenceId: number) => void;
  openNew: (
    referenceDate: string,
    blankTime?: boolean,
    defaultHostId?: string | null,
    defaultFacilityId?: number | null,
  ) => void;
}

export const SideBarOccurrenceFormProviderContext =
  createContext<SideBarOccurrenceFormProviderContextType>({} as any);

export function SideBarOccurrenceFormProvider({
  children,
}: SideBarOccurrenceFormProviderProps) {
  const settings = useClubSettings();
  const clubId = settings.clubId;
  const { api } = useGymflowModels();

  const [isVisible, setIsVisible] = useState(false);
  const [occurrenceId, setOccurrenceId] = useState<number | null>(null);
  const [defaultDate, setDefaultDate] = useState<string | null>(null);
  const [defaultHostId, setDefaultHostId] = useState<string | null>(null);
  const [defaultFacilityId, setDefaultFacilityId] = useState<number | null>(
    null,
  );
  const [isSaving, setIsSaving] = useState(false);
  const [isTimeBlanked, setIsTimeBlanked] = useState(false);
  const [isWrapperVisible, setIsWrapperVisible] = useState(true);

  const { data: club } = useClub({ api, clubId }, { staleTime: clubStaleTime });
  const { data: existingOccurrence, isFetching } = useEventOccurrence({
    api,
    eventId: occurrenceId,
  });

  const { notifyDanger } = useContext(NotificationContext);
  const errorHandler = useCallback(
    (e: any) => {
      notifyDanger(e);
    },
    [notifyDanger],
  );
  const createOccurrenceMutation = useEventOccurrenceCreate(
    {
      api,
      tz: club?.timezone as string,
    },
    { onError: errorHandler },
  );
  const editOccurrenceMutation = useEventOccurrenceEdit(
    {
      api,
      tz: club?.timezone as string,
    },
    { onError: errorHandler },
  );

  const modal = useContext(ModalContext);

  return (
    <SideBarOccurrenceFormProviderContext.Provider
      value={{
        openEdit: (occurrenceId) => {
          setIsTimeBlanked(false);
          setIsVisible(true);
          setIsWrapperVisible(true);
          setOccurrenceId(occurrenceId);
        },
        openNew: (
          referenceDate,
          blankTime = false,
          defaultHostId = null,
          defaultFacilityId = null,
        ) => {
          setIsTimeBlanked(blankTime);
          setDefaultDate(referenceDate);
          setIsWrapperVisible(true);
          setIsVisible(true);
          setDefaultHostId(defaultHostId);
          setDefaultFacilityId(defaultFacilityId);
        },
      }}
    >
      <div className={classNames({ hidden: !isWrapperVisible })}>
        <SideBarOccurrenceForm
          isOpen={isVisible}
          onClose={() => {
            setIsVisible(false);
            setOccurrenceId(null);
            setDefaultHostId(null);
          }}
          defaultStartDate={
            existingOccurrence
              ? moment(
                  existingOccurrence.startDate,
                  PARAMETER_DATE_FORMAT_WITHOUT_TZ,
                ).format(DATE_TIME_FORMAT)
              : defaultDate
          }
          defaultHostId={defaultHostId}
          defaultFacilityId={defaultFacilityId}
          value={existingOccurrence as EventOccurrenceDTO | null}
          blankTime={isTimeBlanked}
          isLoading={isFetching || isSaving}
          onChange={async (newValues) => {
            setIsSaving(true);
            let newOccurrenceId;
            if (occurrenceId) {
              if (existingOccurrence?.event.isRecurring) {
                setIsWrapperVisible(false);
                modal.setModal(
                  <RecurrenceEditModal
                    onConfirm={async (updateAll) => {
                      const params = { ...newValues, isUpdateAll: updateAll };

                      await editOccurrenceMutation.mutateAsync({
                        eventId: occurrenceId,
                        patchedFields: params,
                      });
                      modal.hide();

                      setIsSaving(false);
                      setIsVisible(false);
                    }}
                    onCancel={() => {
                      setIsSaving(false);
                      setIsWrapperVisible(true);
                      modal.hide();
                    }}
                  />,
                );
                return;
              } else if (newValues?.["isRecurring"]) {
                const response = await editOccurrenceMutation.mutateAsync({
                  eventId: occurrenceId,
                  patchedFields: { ...newValues, isUpdateAll: true },
                });
                newOccurrenceId = response.id;
              } else {
                const response = await editOccurrenceMutation.mutateAsync({
                  eventId: occurrenceId,
                  patchedFields: newValues,
                });
                newOccurrenceId = response.id;
              }
            } else {
              const response =
                await createOccurrenceMutation.mutateAsync(newValues);
              newOccurrenceId = response.id;
            }
            // TODO Review this newOccurrenceId
            console.info(newOccurrenceId);
            setIsSaving(false);
            setIsVisible(false);
          }}
        />
      </div>
      {children}
    </SideBarOccurrenceFormProviderContext.Provider>
  );
}
