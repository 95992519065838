import { useRuleList, useRuleValuesEdit } from "@gymflow/api";
import {
  FormMapper,
  NotificationContext,
  RuleName,
  useProgress,
  useRecordForm,
  useUserFormFieldConfiguration
} from "@gymflow/common";
import { useClubSettings } from "apps/portal/src/providers";
import { useFormik } from "formik";
import forIn from "lodash/forIn";
import noop from "lodash/noop";
import { useContext, useEffect, useMemo } from "react";
import * as Yup from "yup";

import useGymflowModels from "../../../store";
import { LabeledSwitch, WarningIcon } from "../../atoms";
import { SettingsContainer } from "../SettingsContainer";

const Field = {
  mobileNumber: "mobile-number",
  dateOfBirth: "date-of-birth",
  addressLine: "address-line",
  postCode: "post-code",
  city: "city",
  gender: "gender",
  emergencyContact: "emergency-contact",
  personalNumber: "personal-number",
};

const validationSchema = Yup.object().shape({
  [Field.mobileNumber]: Yup.boolean().default(true),
  [Field.dateOfBirth]: Yup.boolean().default(true),
  [Field.addressLine]: Yup.boolean().default(true),
  [Field.postCode]: Yup.boolean().default(true),
  [Field.city]: Yup.boolean().default(true),
  [Field.gender]: Yup.boolean().default(true),
  [Field.emergencyContact]: Yup.boolean().default(true),
  [Field.personalNumber]: Yup.boolean().default(false),
});

const mapper = new FormMapper();

export function Forms() {
  const { api } = useGymflowModels();

  const settings = useClubSettings();
  const clubId = settings.clubId;
  const {
    data: { content: schema },
    isFetching: isRuleFetching,
  } = useRuleList({ api, clubId });
  const ruleSchema = useMemo(
    () =>
      schema.find(
        (field) => field.ruleType === RuleName.UserFormRequirementConfig,
      ),
    [schema],
  );
  const { data: ruleValues, isFetching: isRuleValuesFetching } =
    useUserFormFieldConfiguration({ api, clubId });
  const updateValuesMutation = useRuleValuesEdit({ api });
  const isFetching = isRuleFetching || isRuleValuesFetching;

  const { initialValues, getValues } = useRecordForm({
    record: ruleValues,
    fields: validationSchema.default(),
    mapper,
  });

  const formikProps = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: noop,
    validateOnBlur: true,
    validationSchema,
  });
  const { validateForm } = formikProps;

  const { run, isLoading } = useProgress({
    isLoading: isFetching,
  });

  const { notifyDanger } = useContext(NotificationContext);
  const handleSubmit = async () => {
    const formErrors = await validateForm();

    try {
      await run(() => {
        if (Object.keys(formErrors).length !== 0) {
          throw Error();
        }
        const formValues = getValues(formikProps.values);

        const userFormRule = {};
        forIn(formValues, (value, key) => {
          userFormRule[key] = { isRequired: value };
        });

        const patchedFields = [
          {
            ruleId: ruleSchema.id,
            userFormRule,
          },
        ];

        return updateValuesMutation.mutateAsync({ clubId, patchedFields });
      });
    } catch (e) {
      notifyDanger(e);
    }
  };

  useEffect(() => {
    if (formikProps.dirty) {
      handleSubmit();
    }
  }, [formikProps.values]);

  return (
    <SettingsContainer
      title="Form Fields"
      subTitle="Choose what data fields to collect on forms"
    >
      <div className="flex h-full max-h-full w-full flex-row flex-wrap gap-8 overflow-hidden rounded-xl border border-gray-200 bg-white p-6 shadow-sm lg:flex-nowrap">
        <div className="flex w-full flex-col lg:w-1/2">
          <div className="text-lg font-semibold">Account Creation</div>
          <div className="text-gray-600">
            Used for all new registrations, purchases on both web and mobile
            apps. Email and full name are required by default.
          </div>
          <div className="bg-warning-50 border-warning-300 mt-5 flex rounded border-2 p-4">
            <div className="mr-4 mt-2">
              <WarningIcon pathClassName="stroke-warning-600" />
            </div>
            <div className="text-warning-600">
              Any additional fields included will become required by default. If
              you have existing users without these fields it will add
              additional steps during the purchase process. We do not recommend
              turning on any form fields that are not absolutely required.
            </div>
          </div>
        </div>
        <div className="flex w-full flex-col gap-4 lg:w-1/2">
          <LabeledSwitch
            label="Mobile Number"
            value={formikProps.values[Field.mobileNumber]}
            onChange={(checked) => {
              formikProps.setFieldValue(Field.mobileNumber, checked);
            }}
          />
          <LabeledSwitch
            label="Date of Birth"
            value={formikProps.values[Field.dateOfBirth]}
            onChange={(checked) => {
              formikProps.setFieldValue(Field.dateOfBirth, checked);
            }}
          />

          <LabeledSwitch
            label="Address"
            value={formikProps.values[Field.addressLine]}
            onChange={(checked) => {
              formikProps.setFieldValue(Field.addressLine, checked);
            }}
          />
          <LabeledSwitch
            label="Post code"
            value={formikProps.values[Field.postCode]}
            onChange={(checked) => {
              formikProps.setFieldValue(Field.postCode, checked);
            }}
          />
          <LabeledSwitch
            label="City"
            value={formikProps.values[Field.city]}
            onChange={(checked) => {
              formikProps.setFieldValue(Field.city, checked);
            }}
          />
          <LabeledSwitch
            label="Gender"
            value={formikProps.values[Field.gender]}
            onChange={(checked) => {
              formikProps.setFieldValue(Field.gender, checked);
            }}
          />
          <LabeledSwitch
            label="Emergency Contact"
            value={formikProps.values[Field.emergencyContact]}
            onChange={(checked) => {
              formikProps.setFieldValue(Field.emergencyContact, checked);
            }}
          />
          <LabeledSwitch
            label={
              settings.default_nationality === "ESP"
                ? "DNI/Passport"
                : "ID Number"
            }
            value={formikProps.values[Field.personalNumber]}
            onChange={(checked) => {
              formikProps.setFieldValue(Field.personalNumber, checked);
            }}
          />
        </div>
      </div>
    </SettingsContainer>
  );
}
