export const CloseIcon = ({
  className,
  pathClassName,
  strokeWidth = 2,
}: {
  className?: string;
  pathClassName?: string;
  strokeWidth?: number;
}) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className={pathClassName}
      d="M18 6L6 18M6 6L18 18"
      stroke="#667085"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
