import {
  ApiListResponse,
  CheckInDTO,
  NoVisitReportDTO,
  VisitPercentageDTO,
  VisitTotalReportDTO,
} from "@gymflow/types";
import { AxiosInstance } from "axios";

import endpoints from "./endpoints";
import { canFind } from "./features/apiResource";
import { SortType } from "./features/types";

export type AccessApiType = ReturnType<typeof accessApi>;

const accessApi = (
  axiosInstance: AxiosInstance,
  apiUrl: string,
  clubId: number,
) => {
  const clubPrefix = `club/${clubId}/`;
  const initialState = {
    http: axiosInstance,
    baseUrl: `${clubPrefix}${endpoints.access}`,
    apiUrl,
  };
  const state = {
    ...initialState,
    ...canFind(initialState),
  };

  return {
    ...state,
    csv(filters: {
      dateFrom?: string;
      dateTo?: string;
      membershipIncluded?: string[];
      onSite?: boolean;
      guest?: boolean;
    }) {
      return state.http.get<string>(`${clubPrefix}access/csv`, {
        params: filters,
      });
    },
    getCheckIn(userMemberId: string) {
      return state.http.get<{
        isCheckedIn: boolean;
      }>(`${clubPrefix}access/check-in/${userMemberId}/status`);
    },
    checkIn(userMemberId: string, payload: { guest: boolean }) {
      return state.http.put<CheckInDTO>(
        `${clubPrefix}access/check-in/${userMemberId}`,
        payload,
      );
    },
    checkOut(userMemberId: string) {
      return state.http.put(
        `${clubPrefix}access/check-out/${userMemberId}`,
      );
    },
    visitTotalReport({
      dateFrom,
      dateTo,
      sort,
      page,
      size,
    }: {
      dateFrom: string;
      dateTo: string;
      sort?: SortType;
      page?: number;
      size?: number;
    }) {
      const config = {
        params: { dateFrom, dateTo, page, size },
      } as any;
      if (sort) {
        config.params.sort = state.createSortParam(sort.field, sort.desc);
      }
      return state.http.get<ApiListResponse<VisitTotalReportDTO>>(
        `${clubPrefix}visit/total`,
        config,
      );
    },
    visitTotalReportCsv(params: { dateFrom: string; dateTo: string }) {
      return state.http.get<string>(`${clubPrefix}visit/total/csv`, {
        params,
      });
    },
    noVisitReport(params: {
      dateFrom: string;
      dateTo: string;
      page?: number;
      size?: number;
    }) {
      return state.http.get<ApiListResponse<NoVisitReportDTO>>(
        `${clubPrefix}visit/no-visit`,
        {
          params,
        },
      );
    },
    noVisitReportCsv(params: { dateFrom: string; dateTo: string }) {
      return state.http.get<string>(`${clubPrefix}visit/no-visit/csv`, {
        params,
      });
    },
    visitPercentage(params: { dateFrom: string; dateTo: string }) {
      return state.http.get<VisitPercentageDTO>(
        `${clubPrefix}visit/percentage`,
        {
          params,
        },
      );
    },
  };
};

export default accessApi;
