import { subject } from "@casl/ability";
import {
  useClubFeatureFlags,
  useMember,
  useMemberChangePicture,
  useMemberCreate,
  useMemberEdit,
  useMemberInvoiceList,
  useMemberPaymentMethod,
  useMemberPaymentMethodList,
  useMemberSubscription,
  useMemberTimeline,
  useMemberTrainer,
  useMutationLeadStatus,
  useMutationMemberUpdateEmailAndPassword,
} from "@gymflow/api";
import {
  InvoiceStatus,
  NotificationContext,
  PaymentConfirmationStatus,
  useParseErrors,
  usePaymentAuthorizationAlert,
} from "@gymflow/common";
import { useStoreState } from "easy-peasy";
import merge from "lodash/merge";
import { useCallback, useContext, useEffect, useState } from "react";
import { Link, Route, useHistory, useRouteMatch } from "react-router-dom";

import usersIcon from "../../assets/img/users-icon.svg";
import { QuickActionsButton } from "../components/atoms/QuickActionsButton";
import PillTabsLayout from "../components/molecules/PillTabsLayout";
import { ActionMenuButtons } from "../components/SearchGlobal/ActionMenuButtons";
import { ShopSidebarContext } from "../components/Shop/ShopSidebarContext";
import { UserMemberNotes } from "../components/UserMember/Notes/UserMemberNotes";
import Timeline from "../components/UserMember/Timeline/Timeline";
import { UserBookings } from "../components/UserMember/UserBookings";
import { UserMemberAccount } from "../components/UserMember/UserMemberAccount";
import { UserMemberOverview } from "../components/UserMember/UserMemberOverview";
import { UserMemberPayments } from "../components/UserMember/UserMemberPayments/UserMemberPayments";
import UserPayments from "../components/UserMember/UserPayments";
import { UserSessions } from "../components/UserMember/UserSessions";
import { usePortalRoutes } from "../hooks/usePortalRoutes";
import { Can, Subject, Verb } from "../permissions";
import { PageTitleProviderContext, useClubSettings } from "../providers";
import useGymflowModels from "../store";

export const UserMemberPage = {
  Profile: "/profile",
  Account: "/account",
  Sessions: "/sessions",
  Bookings: "/bookings",
  Notes: "/notes",
  Payments: "/payments",
  Timeline: "/timeline",
  AddBacsSuccess: "/account/bacs-payment-method-success",
  AddBacsError: "/account/bacs-payment-method-error",
};

export function UserMember() {
  const history = useHistory();
  const settings = useClubSettings();
  const parseError = useParseErrors();

  const clubId = settings.clubId;
  const { api, settingsStore } = useGymflowModels();
  const { timezone } = useStoreState(settingsStore);
  const { createMemberLink } = usePortalRoutes();
  const match = useRouteMatch();
  const { id } = match.params;
  const { show: showPaymentConfirmationAlert } = usePaymentAuthorizationAlert();
  const mutationOpts = useCallback(
    () => ({
      onError: (error) => {
        parseError(error.response);
      },
    }),
    [],
  );

  const {
    data: userData,
    refetch,
    isFetching: isLoadingUser,
  } = useMember({ api, memberId: id, tz: timezone });
  const { setPageTitle } = useContext(PageTitleProviderContext);
  const editing = userData?.user;
  useEffect(() => {
    if (editing?.firstName && editing?.lastName) {
      setPageTitle({
        title: `${editing.firstName} ${editing.lastName}`,
        iconUrl: usersIcon,
      });
    }
  }, [setPageTitle, editing?.firstName, editing?.lastName]);
  const creditPacks = userData?.creditPacks;
  const editMember = useMemberEdit({ api }, mutationOpts);
  const { createMutation: createMember } = useMemberCreate(
    { api },
    mutationOpts,
  );
  const {
    expireSubscriptionMutation,
    pauseSubscriptionMutation,
    resumeSubscriptionEarlyMutation,
    cancelSubscriptionMutation,
    cancelPauseSubscriptionMutation,
    changeSubscriptionMutation,
    changeSubscriptionPriceMutation,
    updateSubscriptionPendingDateMutation,
    revokeCancellationMutation,
    adjustSessionPackMutation,
    changeBillingDateMutation,
  } = useMemberSubscription({ api, tz: timezone }, mutationOpts);
  const {
    removePaymentMethodMutation,
    attachPaymentMethodMutation,
    assignDefaultPaymentMethodMutation,
  } = useMemberPaymentMethod({ api }, mutationOpts);
  const {
    isFetching: isLoadingPaymentMethods,
    refetch: fetchPaymentMethods,
    data: paymentMethods,
  } = useMemberPaymentMethodList(
    {
      api,
      memberId: id,
      clubId,
    },
    {
      staleTime: 1000,
    },
  );
  const changePictureMutation = useMemberChangePicture({ api }, mutationOpts);
  const [invoicesFilter, setInvoicesFilter] = useState({});
  const {
    data: {
      content: invoices,
      number: invoicesPage,
      totalPages: invoicesPageCount,
    },
    isFetching: loadingInvoices,
    refetch: refreshInvoices,
  } = useMemberInvoiceList(
    {
      api,
      memberId: id,
      opts: invoicesFilter,
    },
    {
      enabled: !!Object.keys(invoicesFilter).length,
    },
  );
  const { assignMutation, unassignMutation } = useMemberTrainer({ api });

  const { defaultCurrency: currency } = useStoreState(settingsStore);

  const [timelineOpts, setTimelineOpts] = useState({
    extraParams: { userMemberId: id },
  });
  const {
    data: { content: nodes, last: nodesIsLast },
    isFetching: isTimelineLoading,
    refetch: refetchTimeline,
  } = useMemberTimeline(
    { api, tz: timezone, opts: timelineOpts },
    {
      enabled: !!timelineOpts?.extraParams?.nodeType,
    },
  );

  const { changeLeadStatusMutation } = useMutationLeadStatus(
    { api },
    mutationOpts,
  );

  const { notify } = useContext(NotificationContext);

  const fetchTimeline = useCallback(
    async ({ page, limit, sort, ...extraParams }) => {
      setTimelineOpts({
        page,
        limit,
        sort,
        extraParams,
      });
      return Promise.resolve();
    },
    [],
  );

  const createUserAndRefresh = useCallback(
    (newUser) => {
      return createMember({ clubId, ...newUser });
    },
    [createMember, clubId, id],
  );

  const updateUserAndRefresh = useCallback(
    (updatedUser) => {
      return editMember.mutateAsync({
        memberId: id,
        clubId,
        patchedFields: updatedUser.patchedFields,
      });
    },
    [editMember, clubId],
  );

  const updateLeadStatusAndRefresh = useCallback(
    async (updatedLeadStatus) => {
      await changeLeadStatusMutation.mutateAsync({
        leadId: updatedLeadStatus.id,
        newColumn: updatedLeadStatus.newColumn,
      });
    },
    [changeLeadStatusMutation, id],
  );

  const cancelSessionPack = useCallback(
    async (params) => {
      await cancelSubscriptionMutation.mutateAsync({
        ...params,
        memberId: editing.id,
        immediately: true,
      });
    },
    [cancelSubscriptionMutation, editing?.id],
  );

  const cancelMembershipSubscription = useCallback(
    async ({ subscriptionId, immediately, memberId, when }) => {
      await cancelSubscriptionMutation.mutateAsync({
        memberId,
        subscriptionId,
        immediately,
        when,
      });
    },
    [],
  );

  const handleAdjustSessionPack = useCallback(
    async (params) => {
      await adjustSessionPackMutation.mutateAsync({
        ...params,
        memberId: editing.id,
      });
    },
    [adjustSessionPackMutation, editing?.id],
  );

  const handleEditSessionPackExpiry = useCallback(
    async (params) => {
      await expireSubscriptionMutation.mutateAsync({
        ...params,
        memberId: editing.id,
      });
    },
    [editing?.id],
  );

  const fetchInvoicesByUser = useCallback(
    (params) => {
      const allTypesButVoid = Object.values(InvoiceStatus).filter(
        (v) => v !== InvoiceStatus.Void,
      );

      setInvoicesFilter(
        merge({}, params, { extraParams: { status: allTypesButVoid } }),
      );
      return Promise.resolve();
    },
    [setInvoicesFilter],
  );

  const checkForScaResponse = useCallback(
    async ({ status, paymentIntentIdForAuthorization }) => {
      if (status === InvoiceStatus.PaymentAuthorization) {
        const paymentConfirmationResult = await showPaymentConfirmationAlert({
          paymentIntentIdForAuthorization,
          confirmPayment: api.strongCustomerAuthorizationApi.confirmPayment,
          messageText: (
            <>
              The user must authorize this payment before it will be processed.
              <br />
              Please ask the user to authorize the payment by clicking the link
              sent to their email.
            </>
          ),
        });

        if (
          paymentConfirmationResult.status === PaymentConfirmationStatus.Waiting
        ) {
          notify({
            message: "Awaiting payment, check later.",
            type: "warning",
          });
        } else if (
          paymentConfirmationResult.status === PaymentConfirmationStatus.Failed
        ) {
          notify({ message: "Payment Failed.", type: "danger" });
        }
      }
    },
    [notify, showPaymentConfirmationAlert],
  );

  const resumeSubscriptionByUser = useCallback(
    async (sub) => {
      const result = await resumeSubscriptionEarlyMutation.mutateAsync({
        ...sub,
        memberId: editing.id,
      });
      await checkForScaResponse({
        status: result.data?.invoice?.status,
        paymentIntentIdForAuthorization:
          result.data?.invoice?.paymentIntentIdForAuthorization,
      });
      refetch();
    },
    [checkForScaResponse, resumeSubscriptionEarlyMutation, editing?.id],
  );

  const revokeCancellation = useCallback(
    async ({ memberId, subscriptionId }) => {
      try {
        await revokeCancellationMutation.mutateAsync({
          memberId,
          subscriptionId,
        });
      } catch (e) {
        parseError(e.response);
      }
    },
    [revokeCancellationMutation],
  );

  const handleChangeSubscriptionPrice = useCallback(
    async (sub) => {
      const result = await changeSubscriptionPriceMutation.mutateAsync({
        ...sub,
        memberId: editing.id,
      });
      return result;
    },
    [changeSubscriptionPriceMutation, editing?.id],
  );

  const attachPaymentMethod = useCallback(
    async ({ userMemberId, paymentMethod }) => {
      await attachPaymentMethodMutation.mutateAsync({
        memberId: userMemberId,
        clubId,
        paymentMethodId: paymentMethod,
      });
    },
    [attachPaymentMethodMutation],
  );

  const removePaymentMethod = useCallback(
    async ({ userMemberId, paymentMethod }) => {
      await removePaymentMethodMutation.mutateAsync({
        memberId: userMemberId,
        clubId,
        paymentMethodId: paymentMethod,
      });
    },
    [removePaymentMethodMutation],
  );

  const setDefaultPaymentMethod = useCallback(
    async ({ userMemberId, paymentMethod }) => {
      assignDefaultPaymentMethodMutation.mutateAsync({
        memberId: userMemberId,
        clubId,
        paymentMethodId: paymentMethod,
      });
    },
    [assignDefaultPaymentMethodMutation],
  );

  const changePicture = useCallback(
    async ({ blob, name }) => {
      changePictureMutation.mutateAsync({ filename: name, blob, memberId: id });
    },
    [changePictureMutation],
  );

  const changeBillingDate = useCallback(
    async ({ subscriptionId, newDate }) => {
      return changeBillingDateMutation.mutateAsync({
        subscriptionId,
        clubId,
        newDate,
      });
    },
    [changeBillingDateMutation, clubId],
  );

  const updateAssignTrainer = useCallback(
    async ({ staffId }) => {
      if (staffId) {
        await assignMutation.mutateAsync({ memberId: id, clubId, staffId });
      } else {
        const assignedStaff = editing?.assignedStaffMembers[0];
        if (assignedStaff) {
          await unassignMutation.mutateAsync({
            memberId: id,
            clubId,
            staffId: assignedStaff.id,
          });
        }
      }
    },
    [assignMutation, clubId, editing, id, unassignMutation],
  );
  const { mutateAsync: updateEmailAndPassword } =
    useMutationMemberUpdateEmailAndPassword({ api });
  const { data: featureFlags } = useClubFeatureFlags({ api, clubId });
  const layoutConfig = [
    {
      text: "Profile",
      path: createMemberLink(id, UserMemberPage.Profile),
      component: UserMemberOverview,
      props: {
        user: editing,
        isLoading: isLoadingUser,
        updateUser: updateUserAndRefresh,
        updateAssignTrainer,
        updateLeadStatus: updateLeadStatusAndRefresh,
        upgradeToFullUser: createUserAndRefresh,
        onChangePicture: changePicture,
        updateEmailAndPassword,
      },
    },
    {
      text: "Membership",
      path: createMemberLink(id, UserMemberPage.Account),
      component: UserMemberAccount,
      props: {
        user: editing,
        subscriptions: userData?.user.subscriptions.filter(
          (e) => e.membershipBean,
        ),
      },
    },
    {
      text: "Credits",
      path: createMemberLink(id, UserMemberPage.Sessions),
      component: UserSessions,
      props: {
        user: editing,
        isLoading: isLoadingUser,
        creditPacks,
        onCancelSubscriptionClick: cancelSessionPack,
        onAdjustSubscriptionClick: handleAdjustSessionPack,
        onEditSessionPackExpiry: handleEditSessionPackExpiry,
      },
    },
    {
      text: "Bookings",
      path: createMemberLink(id, UserMemberPage.Bookings),
      component: UserBookings,
      props: {
        userMemberId: editing?.id,
      },
    },
    {
      text: "Notes",
      path: createMemberLink(id, UserMemberPage.Notes),
      component: UserMemberNotes,
      props: {
        userMemberId: editing?.id,
        name: `${editing?.firstName} ${editing?.lastName}`,
      },
    },
    {
      text: "Payments",
      path: createMemberLink(id, UserMemberPage.Payments),
      component: featureFlags?.featureFlags.PAYMENT_REDESIGN
        ? UserMemberPayments
        : UserPayments,
      props: {
        loadingPaymentMethods: isLoadingPaymentMethods,
        user: editing,
        paymentMethods: paymentMethods,
        fetchPaymentMethods,
        addPaymentMethod: attachPaymentMethod,
        removePaymentMethod: removePaymentMethod,
        assignDefaultPaymentMethod: setDefaultPaymentMethod,
        fetchInvoices: fetchInvoicesByUser,
        invoices,
        loadingInvoices,
        invoicesPage,
        invoicesPageCount,
        currency,
        refreshInvoices,
      },
    },
    {
      text: "Timeline",
      path: createMemberLink(id, UserMemberPage.Timeline),
      component: Timeline,
      props: {
        isLoading: isTimelineLoading,
        userMemberId: editing?.id,
        fetchNodes: fetchTimeline,
        value: timelineOpts?.extraParams?.nodeType?.length ? nodes : [],
        isLast: nodesIsLast,
        refreshNodes: refetchTimeline,
      },
    },
  ];
  const { showShop } = useContext(ShopSidebarContext);
  if (!editing) {
    return null;
  }

  return (
    <>
      <Can not I={Verb.View} a={subject(Subject.MemberProfile, { ...editing })}>
        <div className="content flex h-full max-h-full w-full flex-col p-8">
          You are not authorized to view this member&apos;s profile.
          <Link
            onClick={(e) => {
              e.preventDefault();
              history.goBack();
            }}
          >
            Go back
          </Link>
        </div>
      </Can>
      <Can I={Verb.View} a={subject(Subject.MemberProfile, { ...editing })}>
        <Route path={createMemberLink(id)}>
          <div className="relative flex h-full w-full">
            <PillTabsLayout
              className="absolute inset-0 p-4 lg:p-8"
              tabs={layoutConfig}
              moreActions={
                <>
                  <ActionMenuButtons
                    className="hidden lg:flex"
                    userMemberId={id}
                    showShop={showShop}
                  />
                  <QuickActionsButton
                    className="flex lg:hidden"
                    variant="secondary"
                    userMemberId={id}
                  />
                </>
              }
            />
          </div>
        </Route>
      </Can>
    </>
  );
}
