import { ApiType } from '@gymflow/common/lib/api/ApiType';
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';

import { memberQueryKeys } from './memberQueryKeys';

export function useMemberEdit(
  {
    api,
  }: {
    api: ApiType
  },
  mutationOpts?: UseMutationOptions<
    unknown,
    unknown,
    { memberId: string; clubId: number; patchedFields: { [k: string]: any } }
  >,
) {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: ({
      memberId,
      clubId,
      patchedFields,
    }: {
      memberId: string;
      clubId: number;
      patchedFields: { [k: string]: any };
    }) => api.memberApi.update(memberId, clubId, patchedFields),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: memberQueryKeys.all() });
    },
    ...mutationOpts,
  });
  return mutation;
}
