import { useQueryClubFeatureFlagsAsPublic } from "@gymflow/api";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import useGoogleTagManager from "../../hooks/useGoogleTagManager";
import { LoginOverlayProvider, useClubSettings } from "../../providers";
import { generateSiteWithAuthRoutes, RouteDefinition } from "../../routes";
import useGymflowModels from "../../store";

export function EmbedWithAuth() {
  const match = useRouteMatch();

  const getRoutes = (routes: RouteDefinition[]) => {
    const nodes = routes.map((prop) => {
      return (
        <Route path={match.path + prop.path} key={prop.path}>
          {(props) => <prop.component {...props} />}
        </Route>
      );
    });
    return nodes;
  };

  useGoogleTagManager();

  const { api } = useGymflowModels();
  const settings = useClubSettings();

  const { data: featureFlags } = useQueryClubFeatureFlagsAsPublic({
    api,
    clubId: settings.clubId,
  });
  const routesWithAuth = generateSiteWithAuthRoutes(featureFlags);

  return (
    <LoginOverlayProvider>
      <div className="w-100 flex h-screen flex-col justify-between overflow-hidden bg-white">
        <div className="flex-1 overflow-y-scroll">
          <Switch>{getRoutes(routesWithAuth)}</Switch>
        </div>
      </div>
    </LoginOverlayProvider>
  );
}
