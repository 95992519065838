import {
  clubStaleTime,
  useActivityCategoryList,
  useAppointableCategories,
  useClub,
} from "@gymflow/api";
import {
  AlertContext,
  CardBodyWithSpinner,
  DATE_FORMAT,
  isMobile,
  NotificationContext,
  PARAMETER_DATE_FORMAT,
  SubscriptionStatus,
} from "@gymflow/common";
import { formatCurrency } from "@gymflow/helpers";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import { useContext } from "react";
import ReactTable from "react-table";
import { Button, Card } from "reactstrap";

import { mapCategoriesToCreditPacks } from "../../helpers/creditPack";
import { useClubSettings } from "../../providers";
import useGymflowModels from "../../store";
import AdjustSessionPackAlert from "./AdjustSessionPackAlert";
import CancelSessionPackAlert from "./CancelSessionPackAlert";
import EditSessionPackExpiryAlert from "./EditSessionPackExpiryAlert";

const PAGE_SIZE = 20;
const MIN_ROW = 5;

export function UserSessions({
  isLoading,
  creditPacks,
  onCancelSubscriptionClick,
  onAdjustSubscriptionClick,
  onEditSessionPackExpiry,
}) {
  const { api } = useGymflowModels();
  const alert = useContext(AlertContext);
  const { notifyDanger } = useContext(NotificationContext);
  const settings = useClubSettings();
  const dateFormat = settings.date_format;
  const { data: club } = useClub(
    { api, clubId: settings.clubId },
    { staleTime: clubStaleTime },
  );
  const {
    data: { content: categories },
  } = useActivityCategoryList({ api, filter: { page: 0, limit: 100 } });
  const {
    data: { content: appointableCategories },
  } = useAppointableCategories({
    api,
    paginationOption: { page: 0, size: 100 },
  });

  const creditPacksWithCategories =
    creditPacks &&
    mapCategoriesToCreditPacks(
      creditPacks.slice(0),
      categories,
      appointableCategories,
    );

  const sortDate = (a, b) =>
    moment(a, DATE_FORMAT).valueOf() - moment(b, DATE_FORMAT).valueOf();

  const renderActions = ({ id, staffCancellable, remaining, totalCredits }) => {
    return (
      <>
        {staffCancellable && (
          <Button
            onClick={() =>
              alert.setAlert(
                <CancelSessionPackAlert
                  onConfirm={() =>
                    onCancelSubscriptionClick({ subscriptionId: id })
                  }
                />,
              )
            }
            size="sm"
            color="danger"
            className="btn-round"
          >
            Revoke
          </Button>
        )}
        {remaining !== undefined && (
          <Button
            onClick={() =>
              alert.setAlert(
                <AdjustSessionPackAlert
                  remaining={remaining}
                  totalCredits={totalCredits}
                  onSubmit={(updatedRemaining) =>
                    onAdjustSubscriptionClick({
                      subscriptionId: id,
                      newAmount: updatedRemaining,
                    })
                  }
                />,
              )
            }
            size="sm"
            color="warning"
            className="btn-round"
          >
            Adjust
          </Button>
        )}
      </>
    );
  };

  return (
    <div className="relative flex h-full w-full">
      <div className="absolute inset-0 overflow-auto">
        <Card className="floating-table">
          <CardBodyWithSpinner isLoading={isLoading}>
            <ReactTable
              className="-highlight floating-table"
              columns={[
                {
                  Header: <h3>Name</h3>,
                  accessor: "name",
                  minWidth: 200,
                },
                {
                  Header: <h3>Status</h3>,
                  id: "status",
                  accessor: ({ status }) => {
                    let color = "#3A3A3F";
                    if (colorStatusMap[status]) {
                      color = colorStatusMap[status];
                    }
                    return (
                      <span
                        className="font-weight-bold"
                        style={{ color, textTransform: "capitalize" }}
                      >
                        {status.toLowerCase()}
                      </span>
                    );
                  },
                  maxWidth: 120,
                  sortable: false,
                },
                {
                  Header: <h3>Actions</h3>,
                  id: "actions",
                  accessor: ({
                    id,
                    staffCancellable,
                    remaining,
                    totalCredits,
                  }) => (
                    <div>
                      {renderActions({
                        id,
                        staffCancellable,
                        remaining,
                        totalCredits,
                      })}
                    </div>
                  ),
                  sortable: false,
                  filterable: false,
                  minWidth: 240,
                },
                {
                  Header: <h3>Price</h3>,
                  id: "price",
                  accessor: ({ price }) =>
                    formatCurrency(price, club.defaultCurrency),
                  maxWidth: 120,
                  show: !isMobile(),
                },
                {
                  Header: <h3>Remaining</h3>,
                  id: "remaining",
                  accessor: ({ remaining, unlimited, ...rest }) => {
                    if (unlimited) {
                      return "∞";
                    }
                    if (remaining) {
                      return remaining.toString();
                    }
                    return "0";
                  },
                  maxWidth: 120,
                  sortable: true,
                },
                {
                  Header: <h3>Class Category</h3>,
                  id: "categoryNames",
                  accessor: ({ categoryNames }) => categoryNames?.join(", "),
                  minWidth: 180,
                  show: !isMobile(),
                },
                {
                  Header: <h3>Appointment Category</h3>,
                  id: "appointableCategoryNames",
                  accessor: ({ appointableCategoryNames }) =>
                    appointableCategoryNames?.join(", "),
                  minWidth: 180,
                  show: !isMobile(),
                },
                {
                  Header: <h3>Expiry Date</h3>,
                  id: "expiry",
                  accessor: ({ id, expiry, status, addon }) => (
                    <span>
                      {expiry
                        ? moment(expiry, PARAMETER_DATE_FORMAT)
                            .add(1, "second")
                            .format(dateFormat)
                        : "-"}
                      {status === SubscriptionStatus.Active && !addon && (
                        <Button
                          className="btn-link"
                          onClick={() => {
                            alert.setAlert(
                              <EditSessionPackExpiryAlert
                                expiryDate={expiry}
                                onConfirm={async ({ expiredDate }) => {
                                  try {
                                    await onEditSessionPackExpiry({
                                      subscriptionId: id,
                                      expiredDate,
                                    });
                                  } catch (e) {
                                    notifyDanger(e);
                                  }
                                }}
                              />,
                            );
                          }}
                        >
                          Edit
                        </Button>
                      )}
                    </span>
                  ),
                  minWidth: 150,
                  sortMethod: sortDate,
                  show: !isMobile(),
                },
                {
                  Header: (
                    <h3>
                      <abbr title="Terms & Conditions">T&C</abbr>
                    </h3>
                  ),
                  id: "termsConditions",
                  accessor: ({ termsConditions }) => (
                    <a
                      href={termsConditions}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button className="btn-link">
                        <span>View</span>
                      </Button>
                    </a>
                  ),
                  sortable: false,
                  show: !isMobile(),
                },
              ]}
              data={creditPacksWithCategories}
              defaultPageSize={PAGE_SIZE}
              minRows={0}
              minHeight={300}
              getTrGroupProps={() => ({
                style: {
                  maxHeight: "50px",
                },
              })}
              getTbodyProps={() => ({
                style: {
                  paddingBottom: "10px",
                },
              })}
              showPagination={creditPacksWithCategories.length >= MIN_ROW}
            />
          </CardBodyWithSpinner>
        </Card>
      </div>
    </div>
  );
}

export const colorStatusMap = {
  ACTIVE: "#007E4F",
  CANCELLED: "#0A0D16",
  OVERDUE: "#E2003A",
};

UserSessions.defaultProps = {
  creditPacks: [],
  isLoading: false,
};

UserSessions.propTypes = {
  creditPacks: PropTypes.array,
  isLoading: PropTypes.bool,
  onCancelSubscriptionClick: PropTypes.func.isRequired,
};
