import "react-app-polyfill/ie9";
import "core-js/actual";
import "../assets/scss/black-dashboard-pro-react.scss";
import "../assets/css/nucleo-icons.css";
import "react-notification-alert/dist/animate.css";
import "../assets/tailwind-base.css";
import "../assets/full-calendar.scss";
import "./sentry";
import "@ungap/has-own";
import "./i18n";

import { Settings } from "luxon";
import { createRoot } from "react-dom/client";

import Portal from "./Portal";

Settings.defaultLocale = "en";
const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<Portal />);
