export const SettingsRoute = {
  Club: "/club",
  Product: "/products",
  MembershipType: "/membership-types",
  Sessions: "/sessions",
  Facility: "/facilities",
  Staff: "/staff",
  Activity: "/classes",
  Rule: "/rules",
  Hosted: "/hosted",
  Leads: "/leads",
  Forms: "/forms",
  Integrations: "/integrations",
  Appointable: "/appointable",
  StripePayments: "/stripe-payments",
} as const;
