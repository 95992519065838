import { useMutation, useQueryClient } from '@tanstack/react-query';

import { promotionQueryKeys } from './promotionQueryKeys';
import { PromotionDTO } from '@gymflow/types';

export function usePromotionCreate({
  api,
}: {
  api: {
    promotionApi: {
      create: (arg1: unknown) => Promise<any>;
    };
  };
}) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (newPromotion: { [k: string]: any }) => {
      const response = await api.promotionApi.create(newPromotion);
      return response.data as PromotionDTO;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: promotionQueryKeys.all() });
    },
  });

  return mutation;
}
