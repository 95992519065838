import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { memberQueryFn, useMember } from "@gymflow/api";
import { useMutationMemberSale } from "@gymflow/api/lib/hooks/member/useMutationMemberSale";
import {
  NotificationContext,
  PaymentConfirmationStatus,
  useParseErrors,
  usePaymentAuthorizationAlert,
} from "@gymflow/common";
import { LUXON_DATE_FORMAT } from "@gymflow/helpers";
import { InvoiceDTO, UserMemberSearchByFullNameResult } from "@gymflow/types";
import { Formik, useFormikContext } from "formik";
import { DateTime } from "luxon";
import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from "react";
import { useHistory } from "react-router";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";

import { usePortalRoutes } from "../../hooks";
import { useClubSettings } from "../../providers";
import useGymflowModels from "../../store";
import { UserMemberPage } from "../../views/UserMember";
import { Button, MemberSelect, SlideSideBar, Spinner } from "../atoms";
import {
  CollapsibleSections,
  NewUserSideBarProviderContext,
} from "../molecules";
import { ShopCheckout } from "./ShopCheckout";
import { ShopItemPicker } from "./ShopItemPicker";
import { ShopSidebarContext } from "./ShopSidebarContext";
import { ShopCartType } from "./ShopTypes";

export const ShopSidebarSidebarProvider: React.FC<{
  children: ReactElement | ReactElement[];
}> = ({ children }) => {
  const [sidebarState, setSidebarState] = useState<
    | {
        isVisible: boolean;
        currentShopTab?: number;
      }
    | undefined
  >();
  const { api } = useGymflowModels();
  const clubSettings = useClubSettings();
  const hide = useCallback(() => {
    setSidebarState(undefined);
    setSelectedUser(undefined);
    setCart(undefined);
    setCurrentPaymentMethodId(undefined);
    setPromotionCode(undefined);
    setStartDate(
      DateTime.now()
        .setZone(clubSettings.timezone)
        .startOf("day")
        .toFormat(LUXON_DATE_FORMAT),
    );
    setAllowChangingStartDate(true);
    setOpenSectionIdx(0);
  }, [clubSettings.timezone]);
  const { open: openNewUserSidebar } = useContext(
    NewUserSideBarProviderContext,
  );
  const [selectedUser, setSelectedUser] = useState<{
    label: ReactNode;
    value: UserMemberSearchByFullNameResult;
  }>();
  const [cart, setCart] = useState<ShopCartType>();

  const cartHasSomething =
    cart?.type === "MEMBERSHIP" ||
    cart?.type === "CREDIT_PACK" ||
    cart?.payload?.some((e) => e.quantity > 0);
  const [currentPaymentMethodId, setCurrentPaymentMethodId] =
    useState<string>();
  const [promotionCode, setPromotionCode] = useState<string>();
  const [startDate, setStartDate] = useState<string>(
    DateTime.now()
      .setZone(clubSettings.timezone)
      .startOf("day")
      .toFormat(LUXON_DATE_FORMAT),
  );
  const [allowChangingStartDate, setAllowChangingStartDate] =
    useState<boolean>(true);
  const { data: userMember, isFetching: isFetchingUserMember } = useMember({
    api,
    memberId:
      selectedUser?.value.profileType === "USER"
        ? selectedUser?.value.id
        : undefined,
    tz: clubSettings.timezone,
  });
  const userHasActiveMembershipSubscription =
    userMember?.user.subscriptions.some(
      (e) =>
        !!e.membershipBean &&
        (e.active || e.status === "OVERDUE" || e.status === "PENDING"),
    );
  const sections = [
    {
      title: "Select User",
      body: (
        <div className="flex flex-col gap-2">
          <div className="flex flex-row items-center gap-2">
            <MemberSelect
              className="w-full"
              onChange={(newMember) => {
                setSelectedUser(newMember);
                setCart(undefined);
                setOpenSectionIdx(1);
              }}
              value={selectedUser}
            />
            <Button
              className="min-w-fit"
              onClick={() => {
                setSidebarState((e) => ({ ...e, isVisible: false }));
                const defaultFormValues =
                  selectedUser?.value.profileType === "LEAD"
                    ? {
                        email: selectedUser.value.email,
                        "first-name": selectedUser.value.firstName,
                        "last-name": selectedUser.value.lastName,
                      }
                    : undefined;
                openNewUserSidebar({
                  onClose: async (arg) => {
                    if (arg?.userType === "MEMBER" && arg.userMemberId) {
                      const newMember = await memberQueryFn({
                        memberId: arg.userMemberId,
                        api,
                      });
                      setSelectedUser({
                        label: `${newMember.firstName} ${newMember.lastName}`,
                        value: newMember as any,
                      });
                      setOpenSectionIdx(1);
                      setCart(undefined);
                    }
                    setSidebarState((e) => ({ ...e, isVisible: true }));
                  },
                  defaultFormValues,
                  creationMode: "MEMBER",
                });
              }}
            >
              {selectedUser?.value.profileType === "LEAD"
                ? "Setup Account"
                : "New User"}
            </Button>
          </div>
          {selectedUser?.value.profileType === "LEAD" && (
            <div className="text-sm text-gray-600">
              The user you selected is a lead. Please setup their account to
              continue.
            </div>
          )}
        </div>
      ),
    },
    {
      title: "Select Item",
      body: (
        <div className="flex w-full flex-row items-center gap-2">
          {isFetchingUserMember && <Spinner />}
          {!isFetchingUserMember && (
            <ShopItemPicker
              userHasActiveMembershipSubscription={
                !!userHasActiveMembershipSubscription
              }
              cart={cart}
              setCart={(newCart, stayInSection) => {
                setCart(newCart);
                if (!stayInSection) {
                  setOpenSectionIdx(2);
                }
                if (
                  newCart.type === "MEMBERSHIP" &&
                  newCart.payload.fixedStartDate
                ) {
                  setStartDate(
                    DateTime.fromISO(newCart.payload.fixedStartDate, {
                      setZone: true,
                    })
                      .plus({ seconds: 1 })
                      .setZone(clubSettings.timezone)
                      .toFormat(LUXON_DATE_FORMAT),
                  );
                  setAllowChangingStartDate(false);
                } else {
                  setStartDate(
                    DateTime.now()
                      .setZone(clubSettings.timezone)
                      .startOf("day")
                      .toFormat(LUXON_DATE_FORMAT),
                  );
                  setAllowChangingStartDate(true);
                }
              }}
              hideShopSidebar={() => {
                setSidebarState((e) => ({
                  ...e,
                  isVisible: false,
                }));
              }}
              showShopSidebar={() => {
                setSidebarState((e) => ({
                  ...e,
                  isVisible: true,
                }));
              }}
              sidebarState={sidebarState}
              setSidebarState={setSidebarState}
            />
          )}{" "}
        </div>
      ),
      className: "p-0",
      disabled: !selectedUser || selectedUser.value.profileType === "LEAD",
    },
    {
      title: "Checkout",
      body: !!cart && selectedUser && (
        <ShopCheckout
          cart={cart}
          selectedUser={selectedUser.value}
          setSidebarState={setSidebarState}
          currentPaymentMethodId={currentPaymentMethodId}
          setCurrentPaymentMethodId={setCurrentPaymentMethodId}
          promotionCode={promotionCode}
          setPromotionCode={setPromotionCode}
          startDate={startDate}
          setStartDate={setStartDate}
          allowChangingStartDate={allowChangingStartDate}
        />
      ),
      disabled: !cart || !cartHasSomething,
    },
  ];
  const [openSectionIdx, setOpenSectionIdx] = useState<number>(0);

  const history = useHistory();
  const { createMemberLink } = usePortalRoutes();
  const { mutateAsync: checkout } = useMutationMemberSale({
    api,
    memberId: selectedUser?.value.id ?? "",
    clubId: clubSettings.clubId,
    tz: clubSettings.timezone,
  });
  const parseError = useParseErrors();
  const { show: showPaymentConfirmationAlert } = usePaymentAuthorizationAlert();
  const { notify } = useContext(NotificationContext);

  return (
    <ShopSidebarContext.Provider
      value={{
        showShop: async (user) => {
          if (user) {
            setSelectedUser({
              label: user.firstName + " " + user.lastName,
              value: user as any as UserMemberSearchByFullNameResult,
            });
            if (user.profileType === "USER") {
              setOpenSectionIdx(1);
            }
          }
          setSidebarState({
            isVisible: true,
          });
        },
      }}
    >
      {children}
      <SlideSideBar
        isOpen={!!sidebarState?.isVisible}
        hide={hide}
        className="!w-[32rem] max-w-full"
        isLoading={false}
      >
        <Formik
          initialValues={{ priceOverride: undefined }}
          validationSchema={toFormikValidationSchema(
            z.object({
              priceOverride: z.coerce.number({
                invalid_type_error: "Must include price",
                required_error: "Must include price",
              }),
            }),
          )}
          onSubmit={async (values) => {
            if (!cart) return;
            try {
              const priceOverride = values.priceOverride
                ? +values.priceOverride
                : undefined;
              let result: { invoice: InvoiceDTO };
              if (cart.type === "MEMBERSHIP") {
                result = await checkout({
                  paymentMethod: currentPaymentMethodId,
                  promotionCode: promotionCode,
                  membershipId: cart.payload.id,
                  startDate: startDate,
                  priceOverride,
                });
              } else if (cart.type === "CREDIT_PACK") {
                result = await checkout({
                  paymentMethod: currentPaymentMethodId,
                  promotionCode: promotionCode,
                  sessionPackId: cart.payload.id,
                  startDate: startDate,
                  priceOverride,
                });
              } else {
                result = await checkout({
                  paymentMethod: currentPaymentMethodId,
                  promotionCode: promotionCode,
                  products: cart.payload.map((e) => ({
                    productId: e.product.id,
                    quantity: e.quantity,
                  })),
                  priceOverride,
                });
              }
              hide();
              if (selectedUser?.value.id) {
                history.push(
                  createMemberLink(
                    selectedUser?.value.id,
                    cart.type === "MEMBERSHIP"
                      ? UserMemberPage.Account
                      : cart.type === "CREDIT_PACK"
                      ? UserMemberPage.Sessions
                      : UserMemberPage.Payments,
                  ),
                );
              }
              if (result.invoice.status === "AWAITING_AUTHORIZATION") {
                const paymentConfirmationResult =
                  await showPaymentConfirmationAlert({
                    paymentIntentIdForAuthorization:
                      result.invoice.paymentIntentIdForAuthorization,
                    confirmPayment:
                      api.strongCustomerAuthorizationApi.confirmPayment,
                    messageText: (
                      <>
                        The user must authorize this payment before it will be
                        processed.
                        <br />
                        Please ask the user to authorize the payment by clicking
                        the link sent to their email.
                      </>
                    ),
                  });
                if (
                  paymentConfirmationResult.status ===
                  PaymentConfirmationStatus.Waiting
                ) {
                  notify({
                    message: "Awaiting payment, check later.",
                    type: "warning",
                  });
                } else if (
                  paymentConfirmationResult.status ===
                  PaymentConfirmationStatus.Failed
                ) {
                  notify({
                    message: "Payment Failed.",
                    type: "danger",
                  });
                }
              }
            } catch (e: any) {
              setSidebarState((e) => ({ ...e, isVisible: false }));
              await parseError(e?.response);
              setSidebarState((e) => ({ ...e, isVisible: true }));
            }
          }}
        >
          <div className="flex h-full max-h-full flex-col overflow-hidden">
            <div className="flex flex-col justify-between border-b border-gray-200 p-8">
              <div className="mb-1 flex flex-row items-center justify-between">
                <div className="text-xl font-semibold text-gray-900">
                  Sell Something
                </div>

                <FontAwesomeIcon
                  onClick={() => {
                    hide();
                  }}
                  className="cursor-pointer text-xl text-gray-600"
                  icon={faClose}
                />
              </div>
              <div className="text-sm font-medium text-gray-600">
                Sell a membership, credit pack or products.
              </div>
              {selectedUser && (
                <div className="text-sm font-medium text-gray-600">
                  Sale For:{" "}
                  <b>
                    {selectedUser.value.firstName} {selectedUser.value.lastName}
                  </b>
                  {cartHasSomething && cart
                    ? `, ${
                        cart.type === "MEMBERSHIP"
                          ? cart.payload.name
                          : cart.type === "CREDIT_PACK"
                          ? cart.payload.name
                          : cart.payload
                              .filter((e) => e.quantity > 0)
                              .map(
                                (e) =>
                                  `${e.quantity > 1 ? `${e.quantity} x ` : ""}${
                                    e.product.name
                                  }`,
                              )
                              .join(", ")
                      }`
                    : ""}
                </div>
              )}
            </div>
            <CollapsibleSections
              sections={sections}
              onChangeSection={(_, sectionIdx) => {
                setOpenSectionIdx(sectionIdx);
              }}
              openSectionIndex={openSectionIdx}
            />
            <div className="mt-auto flex h-20 w-full flex-row items-center justify-end gap-x-2 border-t border-gray-200 bg-gray-50 px-6 py-4">
              <Button
                className="flex-1"
                onClick={() => {
                  hide();
                }}
              >
                Cancel
              </Button>
              {cart && openSectionIdx === 2 && <TakePaymentButton />}
            </div>
          </div>
        </Formik>
      </SlideSideBar>
    </ShopSidebarContext.Provider>
  );
};
const TakePaymentButton: React.FC = () => {
  const formik = useFormikContext();
  return (
    <Button
      disabled={!formik.isValid}
      className="flex-1"
      intent="primary"
      onClick={async () => await formik.submitForm()}
    >
      Take Payment
    </Button>
  );
};
