import { createContext, useContext } from "react";

export type OverlayState =
  | "CLOSED"
  | "LOGIN"
  | "SIGNUP_CHECK_EMAIL"
  | "SIGNUP_FORM";

export type OverlayContextType = {
  showLoginOverlay: (email?: string) => Promise<{ loggedIn: boolean }>;
  showSignUpOverlay: (
    skipEmailCheck?: boolean,
  ) => Promise<{ loggedIn: boolean }>;
  closeOverlay: ({ loggedIn }: { loggedIn: boolean }) => void;
};

export const LoginOverlayContext = createContext<OverlayContextType>({
  showLoginOverlay: () => {
    throw new Error("showLoginOverlay called outside context.");
  },
  showSignUpOverlay: () => {
    throw new Error("showSignUpOverlay called outside context.");
  },
  closeOverlay: () => {
    throw new Error("closeOverlay called outside context.");
  },
});

export function useLoginOverlay() {
  return useContext(LoginOverlayContext);
}
