import {
  AlertContext,
  CardBodyWithSpinner,
  isMobile,
  NotificationContext,
  PaymentMethodStatus,
  useGenerateElementId,
} from "@gymflow/common";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useContext } from "react";
import ReactTable from "react-table";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardTitle,
  FormGroup,
  Input,
  Label,
  UncontrolledTooltip,
} from "reactstrap";

import { useAddPaymentMethodAlert } from "../../../hooks/useAddPaymentMethodAlert";

function UserMemberBilling({
  fetchPaymentMethods,
  onRemovePaymentMethodClick,
  onAddPaymentMethodClick,
  onAssignDefaultPaymentMethodClick,
  isLoading,
  defaultCardholdersName,
  paymentMethods,
  fetchBacsCheckoutId,
  user,
}) {
  const { notifyDanger } = useContext(NotificationContext);
  const alert = useContext(AlertContext);

  const generateElementId = useGenerateElementId();

  const renderRemoveButton = (id, isDefault) => {
    const removeButtonId = generateElementId({ prefix: "btn-remove", key: id });
    return (
      <>
        <div id={removeButtonId} className="d-inline">
          <Button
            onClick={() => {
              alert.showWarning(
                "Removing it will make it unavailable for future use.",
                {
                  title:
                    "Are you sure you want to remove this method of payment?",
                  onConfirm: async () => {
                    alert.hide();
                    try {
                      await onRemovePaymentMethodClick({ paymentMethod: id });
                    } catch (e) {
                      notifyDanger(e);
                    }
                  },
                  confirmBtnText: "Yes, remove it!",
                  confirmBtnBsStyle: "danger",
                },
              );
            }}
            size="sm"
            color="danger"
            className="btn-round"
            disabled={isDefault}
          >
            Delete
          </Button>
        </div>
        {isDefault && (
          <UncontrolledTooltip target={removeButtonId}>
            Cannot remove default payment method.
          </UncontrolledTooltip>
        )}
      </>
    );
  };

  const renderPrimaryMethod = (paymentMethod, isPrimaryMethod) => {
    return (
      <FormGroup check style={{ textAlign: "center" }}>
        <Label check>
          <Input
            type="checkbox"
            disabled={isPrimaryMethod}
            checked={isPrimaryMethod}
            onChange={async () => {
              await alert.showWarning(
                "You sure you want to make this the default payment method?",
                {
                  title: "Confirmation",
                  onConfirm: async () => {
                    alert.hide();
                    try {
                      await onAssignDefaultPaymentMethodClick({
                        paymentMethod,
                      });
                    } catch (e) {
                      notifyDanger(e);
                    }
                  },
                  confirmBtnText: "Yes, change it!",
                },
              );
            }}
          />
          <span className="form-check-sign" />
        </Label>
      </FormGroup>
    );
  };

  const rows = paymentMethods.map((method) => {
    let expiredText;
    let statusColor;

    if (method.expired) {
      expiredText = (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div>
            <Badge color="secondary">Expired</Badge>
          </div>
        </div>
      );
    } else {
      expiredText = (
        <>
          <span className="text-muted">*****</span> {method.last4Digits}
        </>
      );
    }

    switch (method.paymentMethodStatus) {
      case PaymentMethodStatus.Active:
        statusColor = "primary";
        break;
      case PaymentMethodStatus.Removed:
      case PaymentMethodStatus.Invalid:
        statusColor = "danger";
        break;
    }

    return {
      billingMethod: "Card",
      accountNo: expiredText,
      expiry: method.expMonth && `${method.expMonth}/${method.expYear}`,
      status: (
        <div>
          <div>
            <Badge color={statusColor}>{method.paymentMethodStatus}</Badge>
          </div>
        </div>
      ),
      isPrimaryMethod: renderPrimaryMethod(
        method.id,
        method.defaultPaymentMethod,
      ),
      actions: (
        <div>{renderRemoveButton(method.id, method.defaultPaymentMethod)}</div>
      ),
    };
  });

  const showAddPaymentMethodAlert = useAddPaymentMethodAlert({
    userMember: user,
  });
  return (
    <Card className="floating-table">
      <CardHeader>
        <CardTitle className="d-flex justify-content-between">
          <h2 className="mb-0">Payment methods</h2>
          <Button
            size="sm"
            className={classNames("btn-round", {})}
            onClick={() => showAddPaymentMethodAlert()}
          >
            Add Method
          </Button>
        </CardTitle>
      </CardHeader>
      <CardBodyWithSpinner isLoading={isLoading}>
        <ReactTable
          manual
          data={rows}
          pages={1}
          onFetchData={fetchPaymentMethods}
          columns={[
            {
              Header: <h3>Type</h3>,
              accessor: "billingMethod",
            },
            {
              Header: <h3>Account No</h3>,
              accessor: "accountNo",
            },
            {
              Header: <h3>Expiry</h3>,
              accessor: "expiry",
              show: !isMobile(),
            },
            {
              Header: <h3>Status</h3>,
              accessor: "status",
            },
            {
              Header: <h3>Primary</h3>,
              accessor: "isPrimaryMethod",
            },
            {
              Header: <h3>Actions</h3>,
              accessor: "actions",
              sortable: false,
              filterable: false,
              maxWidth: 160,
              minWidth: 160,
            },
          ]}
          defaultPageSize={5}
          minRows={0}
          className="-highlight floating-table table"
          minHeight={300}
          getTrGroupProps={() => ({
            style: {
              maxHeight: "50px",
            },
          })}
          showPagination={false}
        />
      </CardBodyWithSpinner>
    </Card>
  );
}

UserMemberBilling.propTypes = {
  fetchPaymentMethods: PropTypes.func.isRequired,
  onAddPaymentMethodClick: PropTypes.func,
  fetchBacsCheckoutId: PropTypes.func.isRequired,
  onRemovePaymentMethodClick: PropTypes.func.isRequired,
  onAssignDefaultPaymentMethodClick: PropTypes.func.isRequired,
  paymentMethods: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      expired: PropTypes.bool.isRequired,
      last4Digits: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      expMonth: PropTypes.number,
      expYear: PropTypes.number,
      defaultPaymentMethod: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  isLoading: PropTypes.bool.isRequired,
  defaultCardholdersName: PropTypes.string.isRequired,
};

export default UserMemberBilling;
