import { DATE_FORMAT } from "@gymflow/common";
import moment from "moment-timezone";
import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";

import { Button, FilterIcon } from "../../components/atoms";
import DateRangePicker from "../../components/forms/DateRangePicker";
import PillTabsLayout from "../../components/molecules/PillTabsLayout";
import { Events } from "../../components/Reports/Events";
import { Leads } from "../../components/Reports/Leads";
import { Members } from "../../components/Reports/Members";
import { Revenue } from "../../components/Reports/Revenue";
import { Visits } from "../../components/Reports/Visits";
import { useClubSettings } from "../../providers";
import { reportHomeRoutes } from "./reportHomeRoutes";

const ReportInterval = {
  Revenue: "Revenue",
  Members: "Members",
  Leads: "Leads",
  Events: "Classes",
  Visits: "Visits",
} as const;

export function ReportHome() {
  const history = useHistory();
  const location = useLocation();
  const { url } = useRouteMatch();
  const isMemberTabOpen = useRouteMatch(`${url}${reportHomeRoutes.Members}`);

  const settings = useClubSettings();
  const dateFormat = settings.date_format;
  const longDateFormat = settings.long_date_format;

  const defaultDates = useMemo(
    () => ({
      startDate: moment().startOf("month").format(DATE_FORMAT),
      endDate: moment().format(DATE_FORMAT),
    }),
    [],
  );

  const dates = useMemo(() => {
    const { dates } = qs.parse(location.search.slice(1));
    return dates as { startDate: string; endDate: string };
  }, [location.search]);

  const setDates = useCallback(
    ({ startDate, endDate }: { startDate: string; endDate: string }) => {
      history.push({
        search: qs.stringify({
          dates: { startDate, endDate },
        }),
      });
    },
    [],
  );

  const [isFilterOpen, setIsFilterOpen] = useState(false);

  useEffect(() => {
    if (!dates) {
      setDates(defaultDates);
    }
  }, [dates]);

  const [selectingDate, setSelectingDate] = useState(defaultDates);

  const tabs = [
    {
      text: ReportInterval.Revenue,
      component: Revenue,
      path: `${url}${reportHomeRoutes.Revenue}`,
      default: true,
      queryParams: { dates },
    },
    {
      text: ReportInterval.Members,
      component: Members,
      props: {
        isFilterOpen,
        onCloseFilterSidebar: () => {
          setIsFilterOpen(false);
        },
      },
      path: `${url}${reportHomeRoutes.Members}`,
      queryParams: { dates },
    },
    {
      text: ReportInterval.Leads,
      component: Leads,
      path: `${url}${reportHomeRoutes.Leads}`,
      queryParams: { dates },
    },
    {
      text: ReportInterval.Events,
      component: Events,
      path: `${url}${reportHomeRoutes.Events}`,
      queryParams: { dates },
    },
    {
      text: ReportInterval.Visits,
      component: Visits,
      path: `${url}${reportHomeRoutes.Visits}`,
    },
  ];

  const handleDateSelectionChange = useCallback(
    ({ startDate: newStartDate, endDate: newEndDate }: any) => {
      setSelectingDate({
        startDate: moment(newStartDate, dateFormat).format(DATE_FORMAT),
        endDate: moment(newEndDate, dateFormat).format(DATE_FORMAT),
      });
    },
    [setSelectingDate, dateFormat],
  );

  const handleDateRangeChange = useCallback(
    (newDate: any) => {
      if (newDate) {
        const { startDate, endDate } = newDate;
        setDates({
          startDate: moment(startDate, dateFormat).format(DATE_FORMAT),
          endDate: moment(endDate, dateFormat).format(DATE_FORMAT),
        });
      } else {
        setSelectingDate(defaultDates);
      }
    },
    [defaultDates, setDates, dateFormat],
  );

  return (
    <div className="content h-full overflow-y-auto p-8">
      <div className="w-100 d-sm-block d-md-flex align-items-center">
        <PillTabsLayout
          tabs={tabs}
          moreActions={
            <div className="flex flex-wrap gap-2">
              {isMemberTabOpen && (
                <Button
                  className="flex h-11"
                  onClick={() => {
                    setIsFilterOpen(true);
                  }}
                >
                  <FilterIcon
                    className="mr-2"
                    pathClassName="stroke-gray-500"
                  />
                  Filter
                </Button>
              )}
              <DateRangePicker
                displayDateFormat={longDateFormat}
                onSelectionChange={handleDateSelectionChange}
                onChange={handleDateRangeChange}
                value={
                  dates
                    ? {
                        startDate: moment(dates.startDate, DATE_FORMAT).format(
                          dateFormat,
                        ),
                        endDate: moment(dates.endDate, DATE_FORMAT).format(
                          dateFormat,
                        ),
                      }
                    : null
                }
                disabledDay={(date: string) => {
                  return (
                    moment(date, DATE_FORMAT).isAfter(
                      moment(selectingDate.startDate, DATE_FORMAT).add(
                        1,
                        "year",
                      ),
                    ) ||
                    moment(date, DATE_FORMAT).isBefore(
                      moment(selectingDate.endDate, DATE_FORMAT).subtract(
                        1,
                        "year",
                      ),
                    )
                  );
                }}
                dateFormat={dateFormat}
              />
            </div>
          }
        />
      </div>
    </div>
  );
}
