import {
  faCreditCard,
  faShoppingCart,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  AccordionWizard,
  Checkout,
  PARAMETER_DATE_ONLY_FORMAT,
  ProductStockType,
  SaleType,
  SERVICE_START_DATE_LIMIT,
  usePaymentSummary,
  useSaleStepTitleBuilder,
} from "@gymflow/common";
import { formatCurrency } from "@gymflow/helpers";
import { useStoreState } from "easy-peasy";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import { usePortalRoutes } from "../../hooks/usePortalRoutes";
import { useClubSettings } from "../../providers";
import { RouteFeature } from "../../routes/feature";
import { RouteLayout } from "../../routes/layout";
import useGymflowModels from "../../store";
import ProductCart from "./ProductCart";
import ProductPurchaserDetails from "./ProductPurchaserDetails";
import SalesRoute from "./SalesRoute";

function SellProductsAccordion({
  products,
  onUserSubmit,
  searchUsers,
  currency,
  fetchPaymentMethods,
  dateFormat,
  summary,
  purchase,
}) {
  const { createClubLink } = usePortalRoutes();
  const { settingsStore } = useGymflowModels();
  const { stripeApplicationId } = useStoreState(settingsStore);
  const settings = useClubSettings();
  const stripeApiKey = settings.stripe_api_key;
  const [activeStep, setActiveStep] = useState(0);

  const [cart, setCart] = useState([]);
  const [user, setUser] = useState(null);

  const { summary: paymentSummary, onUpdateProductsSummary: onUpdateSummary } =
    usePaymentSummary({
      fetchSummary: summary,
      currency,
    });

  const { step1: step1Title, step2: step2Title } = useSaleStepTitleBuilder({
    saleType: SaleType.Product,
    activeStep,
    user,
    cart,
    recurringMembershipLink: createClubLink(
      RouteLayout.Staff,
      RouteFeature.Sales,
      SalesRoute.RecurringMembership,
    ),
    prepaidMembershipLink: createClubLink(
      RouteLayout.Staff,
      RouteFeature.Sales,
      SalesRoute.PrepaidMembership,
    ),
    sessionPackLink: createClubLink(
      RouteLayout.Staff,
      RouteFeature.Sales,
      SalesRoute.SessionsPack,
    ),
    productLink: createClubLink(
      RouteLayout.Staff,
      RouteFeature.Sales,
      SalesRoute.Product,
    ),
    cartTotal: paymentSummary.beforeDiscount,
  });

  useEffect(() => {
    if (cart.length === 0) {
      return;
    }
    onUpdateSummary({ userMemberId: user?.id, cart });
  }, [JSON.stringify(cart), user]);

  const steps = [
    {
      title: step1Title,
      icon: <FontAwesomeIcon icon={faShoppingCart} />,
      component: ProductCart,
      props: {
        products,
        value: cart,
        currency,
        onSelectClick: (productCart) => {
          setCart(productCart);
          setActiveStep(1);
        },
      },
    },
    {
      title: step2Title,
      icon: <FontAwesomeIcon icon={faUserPlus} />,
      component: ProductPurchaserDetails,
      contentInsideTitleCard: true,
      props: {
        initialValues: user,
        searchUsers,
        onUserSelectClick: async (userSelected) => {
          const updatedUser = await onUserSubmit(userSelected);
          if (!updatedUser) {
            return;
          }
          setUser(updatedUser);
          setActiveStep(2);
        },
      },
    },
    {
      title: "3. Checkout",
      icon: <FontAwesomeIcon icon={faCreditCard} />,
      component: Checkout,
      contentInsideTitleCard: true,
      props: {
        stripeApplicationId,
        stripeApiKey,
        cardHoldersName: user ? `${user.firstName} ${user.lastName}` : "",
        userEmail: user?.email,
        lineItems: paymentSummary.lineItems,
        total: paymentSummary.total,
        discount: paymentSummary.discount,
        fetchPaymentMethods: () =>
          fetchPaymentMethods({ userMemberId: user.id }),
        dateFormat,
        validateDate: (date) => {
          const todayInGym = moment()
            .tz(settings.timezone)
            .format("YYYY-MM-DD");
          const maxDate = moment(todayInGym, "YYYY-MM-DD")
            .add(SERVICE_START_DATE_LIMIT)
            .format(PARAMETER_DATE_ONLY_FORMAT);
          const minDate = moment(todayInGym, "YYYY-MM-DD")
            .subtract(1, "day")
            .endOf("day")
            .format(PARAMETER_DATE_ONLY_FORMAT);

          return date.isAfter(minDate) && date.isBefore(maxDate);
        },
        onTakePaymentClick: (paymentDetails) => {
          return purchase({ ...user, ...paymentDetails, cart });
        },
        onUpdateSummary: (rest) =>
          onUpdateSummary({ userMemberId: user.id, cart, ...rest }),
        termsAndConditionsUrl: cart[0] && cart[0].termsAndConditionsLink,
        skipPaymentMethod: paymentSummary.total === formatCurrency(0, currency),
        paymentMethodOptional:
          paymentSummary.total === formatCurrency(0, currency),
        timezone: settings.timezone,
      },
    },
  ];
  return (
    <AccordionWizard
      steps={steps}
      activeStep={activeStep}
      onStepTitleClick={(stepClicked) => setActiveStep(stepClicked)}
    />
  );
}

SellProductsAccordion.defaultProps = {
  products: [],
};

SellProductsAccordion.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
      stockOnHand: PropTypes.string.isRequired,
      stockType: PropTypes.oneOf(Object.values(ProductStockType)),
    }),
  ),
  onUserSubmit: PropTypes.func.isRequired,
  purchase: PropTypes.func.isRequired,
  searchUsers: PropTypes.func.isRequired,
  summary: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired,
  fetchPaymentMethods: PropTypes.func.isRequired,
  dateFormat: PropTypes.string.isRequired,
};

export default SellProductsAccordion;
