import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  useMembership,
  useMembershipDelete,
  useMembershipDisable,
  useMembershipEdit,
  useMembershipEnable,
  useMembershipList,
} from "@gymflow/api";
import { NotificationContext, ServiceType } from "@gymflow/common";
import { formatCurrency, pluralize } from "@gymflow/helpers";
import { MembershipBean, MembershipType } from "@gymflow/types";
import { createColumnHelper, SortingState } from "@tanstack/react-table";
import { usePageSize } from "apps/portal/src/hooks";
import classNames from "classnames";
import { format, parse } from "date-fns";
import { useCallback, useContext, useEffect, useRef, useState } from "react";

import { ModalContext, useClubSettings } from "../../../providers";
import useGymflowModels from "../../../store";
import { Button, PaginatedSelect, Switch } from "../../atoms";
import { PaginatedTable } from "../../organisms/PaginatedTable";
import { ConfirmModal } from "../../templates";
import { SettingsContainer } from "../SettingsContainer";
import { PrepaidMembershipFormSidebarProviderContext } from "./PrepaidMembershipFormSidebarProvider";
import { RecurringMembershipFormSidebarProviderContext } from "./RecurringMembershipFormSidebarProvider";
import { TrialMembershipFormSidebarProviderContext } from "./TrialMembershipFormSidebarProvider";

const tabs = ["recurring", "prepaid", "trial"] as const;
const typeToString: { [key in (typeof tabs)[number]]: string } = {
  recurring: "Recurring",
  prepaid: "Pre-Paid",
  trial: "Trial",
};
const filters: {
  [key in (typeof tabs)[number]]: {
    isTrial?: boolean;
    type: MembershipType;
  };
} = {
  recurring: { type: "RECURRING" },
  prepaid: { isTrial: false, type: "PREPAID" },
  trial: { isTrial: true, type: "PREPAID" },
};
export function MembershipsTab() {
  const [currentPage, setCurrentPage] = useState(0);
  const [showingStatus, setShowingStatus] = useState<"ACTIVE" | "INACTIVE">(
    "ACTIVE",
  );
  const [showingType, setShowingType] =
    useState<(typeof tabs)[number]>("recurring");
  const [sort, setSort] = useState<SortingState>([]);

  const { api } = useGymflowModels();

  const settings = useClubSettings();
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const pageSize = usePageSize({
    tableContainerRef,
    rowHeight: 56,
  });

  const { data, isLoading } = useMembershipList({
    api,
    opts: {
      page: currentPage,
      limit: pageSize,
      sort: sort?.[0]
        ? {
            field: sort[0].id,
            desc: sort[0].desc,
          }
        : { field: "createdDate", desc: true },
      extraParams: {
        status: showingStatus,
        ...filters[showingType],
      },
    },
  });

  const [editingMembershipId, setEditingMembershipId] = useState<number>();

  const { data: editing } = useMembership({
    api,
    membershipId: editingMembershipId,
  });
  const editMembership = useMembershipEdit({ api });

  const enableMembership = useMembershipEnable({ api });
  const disableMembership = useMembershipDisable({ api });
  const deleteMembership = useMembershipDelete({ api });

  const { showPrepaidMembershipForm: triggerPrepaidMembershipForm } =
    useContext(PrepaidMembershipFormSidebarProviderContext);
  const { showRecurringMembershipForm: triggerRecurringMembershipForm } =
    useContext(RecurringMembershipFormSidebarProviderContext);
  const { showTrialMembershipForm: triggerTrialMembershipForm } = useContext(
    TrialMembershipFormSidebarProviderContext,
  );

  const showAlert = useCallback(() => {
    if (showingType === "recurring") {
      triggerRecurringMembershipForm({
        currentRecurringMembership: (editing ?? undefined) as
          | MembershipBean
          | undefined,
        onClose: () => {
          setEditingMembershipId(undefined);
        },
      });
    } else if (showingType === "prepaid") {
      triggerPrepaidMembershipForm({
        currentPrepaidMembership: (editing ?? undefined) as
          | MembershipBean
          | undefined,
        onClose: () => {
          setEditingMembershipId(undefined);
        },
      });
    } else if (showingType === "trial") {
      triggerTrialMembershipForm({
        currentTrialMembership: (editing ?? undefined) as
          | MembershipBean
          | undefined,
        onClose: () => {
          setEditingMembershipId(undefined);
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    showingType,
    triggerRecurringMembershipForm,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(editing),
    triggerPrepaidMembershipForm,
    triggerTrialMembershipForm,
  ]);

  useEffect(() => {
    if (editingMembershipId) {
      showAlert();
    }
  }, [editingMembershipId, showAlert]);
  const { setModal, hide } = useContext(ModalContext);
  const { notifyDanger } = useContext(NotificationContext);
  const columnHelper = createColumnHelper<MembershipBean>();
  const columns = [
    columnHelper.accessor("name", {}),
    columnHelper.accessor(
      (e) => ({
        type: e.type,
        nextMonthlyPayment: e.nextMonthlyPayment,
        defaultPrice: e.defaultPrice,
      }),
      {
        id: "defaultPrice",
        header: "Price",
        cell: (column) => {
          const value = column.getValue();
          return formatCurrency(
            value.type === ServiceType.Recurring
              ? value.nextMonthlyPayment
              : value.defaultPrice,
            settings.defaultCurrency,
          );
        },
      },
    ),
    ...(showingType === "recurring"
      ? [
          columnHelper.accessor(
            (e) => ({
              billingPeriod: e.billingPeriod,
              billingType: e.billingType,
            }),
            {
              id: "billingType,billingPeriod",
              header: () => <div>Billing Period</div>,
              cell: (column) => {
                const value = column.getValue();
                return `Every ${value.billingPeriod} ${pluralize(
                  { MONTHLY: "Month", WEEKLY: "Week" }[
                    value?.billingType ?? "MONTHLY"
                  ],
                  { MONTHLY: "Months", WEEKLY: "Weeks" }[
                    value?.billingType ?? "MONTHLY"
                  ],
                  value.billingPeriod,
                )}`;
              },
              enableSorting: false,
            },
          ),
          columnHelper.accessor(
            (e) => ({
              monthlyBillingDay: e.monthlyBillingDay,
              weeklyBillingDay: e.weeklyBillingDay,
              billingType: e.billingType,
              calculateProrata: e.calculateProrata,
              container: e,
            }),
            {
              id: "billingDay",
              header: () => <div>Bills On</div>,
              cell: (column) => {
                const value = column.getValue();
                return value.billingType === "MONTHLY"
                  ? value.calculateProrata === "NO"
                    ? "Start Date"
                    : `${format(
                        parse(`${value.monthlyBillingDay}`, "d", new Date()),
                        "do",
                      )} of the Month`
                  : value.calculateProrata === "NO"
                  ? "Start Day"
                  : `${value.weeklyBillingDay
                      ?.split("")
                      .map((e, i) =>
                        i === 0 ? e.toUpperCase() : e.toLocaleLowerCase(),
                      )
                      .join("")}s`;
              },
              enableSorting: false,
            },
          ),
        ]
      : []),
    ...(showingType === "prepaid" || showingType === "trial"
      ? [
          columnHelper.accessor(
            (e) => ({
              duration: e.duration,
              durationType: e.durationType,
            }),
            {
              id: "duration",
              header: () => <div>Length</div>,
              cell: (column) => {
                const value = column.getValue();
                return `${value.duration} ${pluralize(
                  {
                    DAILY: "Day",
                    WEEKLY: "Week",
                    MONTHLY: "Month",
                    NO_CONTRACT: "None",
                  }[value.durationType ?? "NO_CONTRACT"],
                  {
                    DAILY: "Days",
                    WEEKLY: "Weeks",
                    MONTHLY: "Months",
                    NO_CONTRACT: "None",
                  }[value.durationType ?? "NO_CONTRACT"],
                  value.duration,
                )}`;
              },
              enableSorting: false,
            },
          ),
        ]
      : []),

    columnHelper.accessor(
      (e) => ({
        isPublic: e.isPublic,
        id: e.id,
      }),
      {
        id: "isPublic",
        header: () => <div>Available Online</div>,
        cell: (column) => {
          const value = column.getValue();
          return (
            <Switch
              value={!!value.isPublic}
              onChange={(newValue) => {
                editMembership.mutate({
                  membershipId: value.id,
                  patchedFields: {
                    isPublic: newValue,
                  },
                });
              }}
            />
          );
        },
      },
    ),
    columnHelper.accessor("id", {
      header: () => <div>Actions</div>,
      cell: (column) => {
        const value = column.getValue();
        return (
          <div className="flex flex-row items-center gap-x-2">
            <div
              className="text-primary-700 cursor-pointer text-sm font-semibold"
              onClick={() => {
                setEditingMembershipId(value);
              }}
            >
              Edit
            </div>
            <div
              className="text-warning-600 cursor-pointer text-sm font-semibold"
              onClick={() => {
                if (showingStatus === "ACTIVE") {
                  disableMembership.mutate(value);
                } else {
                  enableMembership.mutate(value);
                }
              }}
            >
              {showingStatus === "ACTIVE" ? "Disable" : "Enable"}
            </div>
            <div
              className="text-error-500 cursor-pointer text-sm font-semibold"
              onClick={() => {
                setModal(
                  <ConfirmModal
                    title="Delete Membership"
                    type="danger"
                    onConfirm={async () => {
                      try {
                        deleteMembership.mutate(value);
                      } catch (e) {
                        notifyDanger(e);
                      }
                      hide();
                    }}
                    onCancel={() => {
                      hide();
                    }}
                  >
                    Are you sure you want to delete this membership?
                  </ConfirmModal>,
                );
              }}
            >
              Delete
            </div>
          </div>
        );
      },
      enableSorting: false,
    }),
  ];

  return (
    <SettingsContainer
      title="Memberships"
      subTitle="Create, Update and manage all your different membership types."
      actions={
        <>
          <PaginatedSelect
            className="h-11 bg-white"
            placeholder="Enabled"
            value={[
              {
                label: "Enabled",
                value: "ACTIVE",
              },
              {
                label: "Disabled",
                value: "INACTIVE",
              },
            ].find((e) => e.value === showingStatus)}
            onChange={({ value }) => setShowingStatus(value)}
            loadOptions={async () => {
              return {
                options: [
                  {
                    label: "Enabled",
                    value: "ACTIVE",
                  },
                  {
                    label: "Disabled",
                    value: "INACTIVE",
                  },
                ],
              };
            }}
          />
          <Button
            intent="secondary"
            className="text-white"
            onClick={() => {
              showAlert();
            }}
          >
            <div className="flex flex-row items-center gap-x-2">
              <FontAwesomeIcon icon={faPlus} />
              <div>{typeToString[showingType]}</div>
            </div>
          </Button>
        </>
      }
    >
      <div className="flex flex-row gap-x-2 border-b border-gray-200">
        {tabs.map((e) => (
          <div
            key={e}
            onClick={() => setShowingType(e)}
            className={classNames(
              "max-w-min cursor-pointer flex-nowrap whitespace-nowrap px-1 pb-3 text-sm font-semibold text-gray-500",
              {
                "text-accent border-accent border-b-2 ": showingType === e,
              },
            )}
          >
            {typeToString[e]}
          </div>
        ))}
      </div>
      <PaginatedTable
        tableProps={{
          data: data?.content ?? [],
          columns: columns,
          pageCount: data?.totalPages,
          pageIndex: data?.number,
          onSortingChange: setSort,
          sort: sort,
          isFetching: isLoading,
          pageSize: pageSize,
          tableContainerRef
        }}
        hasNextPage={!!data && data?.number < data?.totalPages - 1}
        hasPreviousPage={!!data && data?.number > 0}
        goToNextPage={() => {
          setCurrentPage(currentPage + 1);
        }}
        goToPreviousPage={() => {
          setCurrentPage(currentPage - 1);
        }}
      />
    </SettingsContainer>
  );
}
